// @flow
import React from 'react'
import type { EncouragementPost } from 'fabled/types/Post'
import { parasAndNewlines } from 'fabled/helpers/text'
import styled from 'styled-components'
import colors from 'fabled/styles/colors'

type Props = {
    post: EncouragementPost | { content: { text: string } },
    height: number | null,
}

const defaultProps = {
    post: null,
    height: null,
}

const FabledPost = ( props: Props ) => {
    const style = ( props.height !== null ) ? { height: `${ props.height }px` } : {}

    return (
        <StyledFabledPost style={ style }>
            { parasAndNewlines( props.post.content.text ) }
        </StyledFabledPost>
    )
}

FabledPost.defaultProps = defaultProps

const StyledFabledPost = styled.div`
    display: block;
    width: auto;
    padding: 1em;
    background: ${ colors.red };
    color: white;
    font-size: 18px;
    line-height: 1.333em;
`

export default FabledPost
