import io from 'socket.io-client'
import feathers from '@feathersjs/feathers'
import rest from '@feathersjs/rest-client'
import socketio from '@feathersjs/socketio-client'
import authentication from '@feathersjs/authentication-client'
import fetch from 'cross-fetch'
import config from '../config'

const socket = io( config.apiUrl )
const socketClient = feathers()

socketClient.configure( socketio( socket ) )
socketClient.configure( authentication( {
    storage: typeof window !== 'undefined' ? window.localStorage : undefined,
} ) )
socketClient.service( 'authManagement' ).timeout = 10000

// REST Client
const restClient = feathers().configure( rest( config.apiUrl ).fetch( fetch ) )

export { socketClient, restClient }
