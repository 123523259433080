// @flow
import React from 'react'
import type { Post as TPost } from 'fabled/types/Post'
import type { ChildUser } from 'fabled/types/User'
import find from 'lodash/find'
import PostUIWrapper from './PostUIWrapper'
import TextPost from './TextPost'
import ImagePost from './ImagePost'
import AudioPost from './AudioPost/AudioPost'
import FabledPost from './FabledPost'
import InspirationPost from './InspirationPost'
import AnimatedStoryBlock from './AnimatedStoryBlock'

type Props = {
    post: TPost,
    storyAuthors: Array<ChildUser>,
    showPostAuthor: boolean | null,
    isEditable: boolean | null,
    storyStyle: 'editor' | 'viewer',
    transitionState: string,
}

const defaultProps = {
    post: null,
    showPostAuthor: null,
    isEditable: null,
    transitionState: '',
}

const Post = ( props: Props ) => {
    let postAuthor = null

    if ( props.post.postType === 'encouragement' ) {
        postAuthor = 'fabled'
    }
    else if ( props.post.userId ) {
        postAuthor = find( props.storyAuthors, { id: props.post.userId } )
    }

    const showPostAuthor = props.showPostAuthor !== null
        ? props.showPostAuthor
        : props.storyAuthors.length > 1

    const isEditable = props.isEditable !== null
        ? props.isEditable
        : !!( postAuthor && postAuthor !== 'fabled' && postAuthor.isCurrentUser )

    const postRenderer = () => {
        switch ( props.post.postType ) {
            case 'text-block':
                return (
                    <TextPost
                        post={ props.post }
                        boxed={ !!( props.storyStyle === 'editor' ) }
                    />
                )

            case 'image':
                return <ImagePost post={ props.post } />

            case 'audio':
                return <AudioPost post={ props.post } />

            case 'inspiration':
                return <InspirationPost post={ props.post } />

            case 'encouragement':
                return <FabledPost post={ props.post } />

            default:
                return null
        }
    }

    const UIColor = () => {
        switch ( props.post.postType ) {
            case 'audio':
                return 'purple'

            case 'encouragement':
                return 'red'

            default:
                return 'white'
        }
    }

    return (
        <AnimatedStoryBlock transitionState={ props.transitionState }>
            <PostUIWrapper
                post={ props.post }
                postAuthor={ postAuthor }
                showPostAuthor={ showPostAuthor }
                isEditable={ isEditable }
                storyStyle={ props.storyStyle }
                color={ UIColor() }
            >
                { postRenderer() }
            </PostUIWrapper>
        </AnimatedStoryBlock>
    )
}

Post.defaultProps = defaultProps

export default Post
