import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { colors } from '../../styles'

const InputWrapper = styled.div`
    position: relative;
    margin-bottom: 0.7em;
    font-size: 18px;
    line-height: 22px;

    &:after {
        content: ' ';
        position: absolute;
        top: 0;
        right: 0;
        width: 0;
        height: 0;
        opacity: 0;
    }

    ${ props => typeof props.valid !== 'undefined' && props.valid !== null && css`
        &:after {
            content: "${ p => ( p.valid === false ? '!' : ' ' ) }";
            text-align: center;
            color: ${ colors.paper };
            font-weight: bold;
            font-size: 22px;
            line-height: 30px;
            top: 36px;
            margin-top: -15px;
            right: 15px;
            height: 30px;
            width: 30px;
            border-radius: 100%;
            background-repeat: no-repeat;
            background-position: center center;
            background-size: auto 10px;
            background-color: ${ p => ( p.valid ? colors.green : colors.red ) };
            background-image: ${ p => ( p.valid ? 'url(/images/icon-tick.svg)' : 'none' ) };
            opacity: 1;
            transition:
                opacity .2s ease-out,
                background-color .2s ease-out;
        }
    ` }
`

InputWrapper.propTypes = {
    valid: PropTypes.bool,
}

export default InputWrapper
