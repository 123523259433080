import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const NotificationIcon = props => (
    <StyledNotificationIcon>
        <img src={ props.url } alt={ props.altText } />
    </StyledNotificationIcon>
)

NotificationIcon.propTypes = {
    url: PropTypes.string.isRequired,
    altText: PropTypes.string,
}

NotificationIcon.defaultProps = {
    altText: '',
}

const StyledNotificationIcon = styled.div`
    flex: 0 0 40px;
    width: 40px;
    height: 40px;
    overflow: hidden;
    border-radius: 100%;

    img {
        width: 100%;
    }
`

export default NotificationIcon
