import React from 'react'
import { Route } from 'react-router-dom'
import AuthFormWrapper from '../AuthFormWrapper'
import PasswordReset from './PasswordReset'
import NewPassword from './NewPassword'

const AuthActions = () => (
    <AuthFormWrapper>
        <Route exact path="/auth/password" component={ PasswordReset } />
        <Route
            exact
            path="/auth/reset/:token"
            render={ ( { match } ) => <NewPassword token={ match.params.token } /> }
        />
    </AuthFormWrapper>
)

export default AuthActions
