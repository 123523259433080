import React from 'react'
import styled, { css } from 'styled-components'
import Section from 'fabled/components/Section'
import FabledLogo from 'fabled/components/FabledLogo'
import RoundedButton from 'fabled/components/RoundedButton'

const PageHeader = ( { bgColor = null } ) => (
    <StyledPageHeader bgColor={ bgColor }>
        <Section>
            <StyledPageHeaderInner>
                <StyledPageHeaderLogo>
                    <FabledLogo color="almostWhite" />
                </StyledPageHeaderLogo>
                <StyledPageHeaderButtons>
                    <RoundedButton to="/signup" solid bgColor={ bgColor || 'white' }>
                        Sign up
                    </RoundedButton>
                    <RoundedButton to="/login" bgColor={ bgColor || 'white' }>
                        Login
                    </RoundedButton>
                </StyledPageHeaderButtons>
            </StyledPageHeaderInner>
        </Section>
    </StyledPageHeader>
)

const StyledPageHeader = styled.div`
    z-index: 100;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;

    ${ p => p.bgColor && css`
        background: var( --${ p.bgColor } );
    ` }
`

const StyledPageHeaderInner = styled.div`
    display: flex;
    align-items: center;
`

const StyledPageHeaderLogo = styled.div`
    width: 148px;
    height: 28px;
    margin-right: auto;
`

const StyledPageHeaderButtons = styled.div`
    display: flex;

    > * {
        margin-right: 0.6rem;

        &:last-child {
            margin-right: 0;
        }
    }
`

export default PageHeader