// @flow
import React from 'react'
import type { Post as PostType } from 'fabled/types/Post'
import type { ChildUser } from 'fabled/types/User'
import { Transition, TransitionGroup } from 'react-transition-group'
import Post from './Post/Post'

type Props = {
    chapterPosts: Array<PostType>,
    onLoad: ?() => void,
    storyAuthors: Array<ChildUser>,
    showPostAuthor: boolean | null,
    isEditable: boolean | null,
    storyStyle: 'editor' | 'viewer',
}

const defaultProps = {
    chapterPosts: [],
    onLoad: null,
    showPostAuthor: null,
    isEditable: null,
}

const Chapter = ( props: Props ) => {
    const {
        onLoad,
        isEditable,
        storyAuthors,
        showPostAuthor,
        storyStyle,
    } = props

    return (
        <TransitionGroup>
            {
                props.chapterPosts.map( post => (
                    <Transition
                        key={ post.uuid }
                        timeout={ 900 }
                        classNames="fab-is-animated"
                    >
                        {
                            transitionState => (
                                <Post
                                    post={ post }
                                    onLoad={ onLoad }
                                    storyAuthors={ storyAuthors }
                                    showPostAuthor={ showPostAuthor }
                                    isEditable={ isEditable }
                                    storyStyle={ storyStyle }
                                    transitionState={ transitionState }
                                />
                            )
                        }
                    </Transition>
                ) )
            }
        </TransitionGroup>
    )
}

Chapter.defaultProps = defaultProps

export default Chapter
