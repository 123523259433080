import React from 'react'
import PropTypes from 'prop-types'
import padStart from 'lodash/padStart'
import TextInput from './TextInput'
import SelectInput from './SelectInput'
import ValidationMessage from './ValidationMessage'

class DOBInput extends React.Component {
    static parseDateString( dateString ) {
        if ( !dateString ) {
            return { year: null, month: null, day: null }
        }

        const [year, month, day] = dateString.split( 'T' )[ 0 ].split( '-' )
        return { year, month, day }
    }

    static dateStringFromObject( dateObject ) {
        let day = ''

        if ( dateObject.day ) {
            day = padStart( dateObject.day.toString(), 2, '0' )
        }

        return `${ dateObject.year }-${ dateObject.month }-${ day }`
    }

    constructor( props ) {
        super( props )
        const dateObject = DOBInput.parseDateString( props.value )
        this.state = {
            value: DOBInput.dateStringFromObject( dateObject ) || null,
            year: dateObject.year || '',
            month: dateObject.month || '',
            day: dateObject.day || '',
            shouldValidate: false,
            validationMessage: null,
        }

        this.handleMonthChange = this.handleMonthChange.bind( this )
        this.handleDayChange = this.handleDayChange.bind( this )
        this.handleYearChange = this.handleYearChange.bind( this )
    }

    componentDidMount() {
        this.setShouldValidate()
    }

    componentDidUpdate( prevProps, prevState ) {
        const { state: { value } } = this

        if (
            value !== prevState.value
            || ( !prevState.shouldValidate && this.state.shouldValidate )
        ) {
            this.validate( value )
                .then( ( validated ) => {
                    if ( validated && this.props.onChange instanceof Function ) {
                        this.props.onChange( value )
                    }
                } )
        }
    }

    setShouldValidate() {
        if ( this.state.day && this.state.month && this.state.year && !this.state.shouldValidate ) {
            this.setState( { shouldValidate: true } )
        }
    }

    handleChange( name, value ) {
        if ( this.props.disabled ) {
            return
        }

        this.setState( { [ name ]: value }, () => {
            const { state: { year, month, day } } = this
            const val = DOBInput.dateStringFromObject( {
                year,
                month,
                day,
            } )
            this.setState( { value: val } )

            this.setShouldValidate()

            if ( this.props.setFormState instanceof Function ) {
                this.props.setFormState( this.props.name, val )
            }
        } )
    }

    handleMonthChange( value ) {
        this.handleChange( 'month', value )
    }

    handleDayChange( value ) {
        this.handleChange( 'day', value )
    }

    handleYearChange( value ) {
        this.handleChange( 'year', value )
    }

    validate( value ) {
        const self = this

        if ( this.state.shouldValidate && this.props.validator instanceof Function ) {
            // Do this async so that we can optionally use validators that return promises
            return new Promise( ( resolve ) => {
                resolve( this.props.validator( value ) )
            } )
                .then( ( result ) => {
                    // result is an error message string, or true
                    const validated = ( result === true )
                    const validationMessage = ( result !== true ) ? result : null

                    if ( self.props.formValidator instanceof Function ) {
                        self.props.formValidator( self.props.id, validated, validationMessage )
                    }

                    self.setState( { validated, validationMessage } )

                    return result
                } )
                .then( result => result === true )
        }

        if ( self.props.formValidator instanceof Function ) {
            self.props.formValidator( self.props.id, true, null )
        }

        self.setState( { validated: true, validationMessage: null } )

        return Promise.resolve( true )
    }

    render() {
        const monthOptions = [
            { value: '', label: '' },
            { value: '01', label: 'January' },
            { value: '02', label: 'February' },
            { value: '03', label: 'March' },
            { value: '04', label: 'April' },
            { value: '05', label: 'May' },
            { value: '06', label: 'June' },
            { value: '07', label: 'July' },
            { value: '08', label: 'August' },
            { value: '09', label: 'September' },
            { value: '10', label: 'October' },
            { value: '11', label: 'November' },
            { value: '12', label: 'December' },
        ]

        const validationMessage = this.state.validationMessage || <span>&nbsp;</span>

        return (
            <div>
                <p className="fab-c-input-label">{ this.props.label }</p>

                <div className="fab-c-dob-input__inputs">
                    <div className="fab-c-dob-input__input fab-c-dob-input__input--select">
                        <SelectInput
                            id={ `${ this.props.id }-month` }
                            name={ `${ this.props.name }-month` }
                            label="Month"
                            options={ monthOptions }
                            onChange={ this.handleMonthChange }
                            value={ this.state.month }
                            disableValidationMessage
                            disableValidationIcon
                        />
                    </div>

                    <div className="fab-c-dob-input__input">
                        <TextInput
                            name={ `${ this.props.name }-day` }
                            id={ `${ this.props.id }-day` }
                            label="Day"
                            onChange={ this.handleDayChange }
                            value={ this.state.day }
                            disableValidationMessage
                            disableValidationIcon
                        />
                    </div>

                    <div className="fab-c-dob-input__input">
                        <TextInput
                            name={ `${ this.props.name }-year` }
                            id={ `${ this.props.id }-year` }
                            label="Year"
                            onChange={ this.handleYearChange }
                            value={ this.state.year }
                            disableValidationMessage
                            disableValidationIcon
                        />
                    </div>
                </div>
                {
                    this.props.disableValidationMessage
                        ? null
                        : <ValidationMessage>{ validationMessage }</ValidationMessage>
                }
            </div>
        )
    }
}

DOBInput.propTypes = {
    value: PropTypes.string,
    disabled: PropTypes.bool,
    setFormState: PropTypes.func,
    validator: PropTypes.func,
    onChange: PropTypes.func,
    disableValidationMessage: PropTypes.bool,
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    label: PropTypes.node.isRequired,
}

DOBInput.defaultProps = {
    value: '',
    disabled: false,
    setFormState: null,
    validator: null,
    onChange: null,
    disableValidationMessage: false,
}

export default DOBInput
