import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { colors } from '../../styles'
import DropdownMenu from '../DropdownMenu'

const StoryMenu = ( props ) => {
    const handleClick = ( e ) => {
        e.preventDefault()
        props.toggleMenu()
    }

    const handleKeyDown = ( e ) => {
        if ( ['Enter', ' '].includes( e.key ) ) {
            props.toggleMenu()
        }
    }

    return (
        <StyledStoryNav
            onClick={ handleClick }
            onKeyDown={ handleKeyDown }
            role="button"
            tabIndex="0"
        >
            <StyledStoryMenuToggle isActive={ props.isActive }>
                <span>Story Menu</span>
            </StyledStoryMenuToggle>
            {
                props.isActive
                    ? (
                        <DropdownMenu
                            menuItems={ props.menuItems }
                            tip="right: 16px"
                            marginTop="40"
                        />
                    )
                    : null
            }
        </StyledStoryNav>
    )
}

StoryMenu.propTypes = {
    menuItems: PropTypes.arrayOf( PropTypes.element ).isRequired,
    isActive: PropTypes.bool.isRequired,
    toggleMenu: PropTypes.func.isRequired,
}

const StyledStoryNav = styled.div`
    position: absolute;
    z-index: 2;
    top: 10px;
    right: 10px;

    &:focus {
        outline: none;
    }
`

const StyledStoryMenuToggle = styled.div`
    position: absolute;
    right: 0;
    display: block;
    width: 30px;
    height: 32px;
    cursor: pointer;

    span,
    span:before,
    span:after {
        position: absolute;
        right: 0;
        display: block;
        text-indent: -999em;
        width: 8px;
        height: 8px;
        border-radius: 4px;
        background: ${ colors.red };

        ${ props => props.isActive && css`
            width: 100%;
        ` }
    }

    &:hover span,
    &:hover span:before,
    &:hover span:after {
        width: 100%;
    }

    span {
        top: 12px;
        transition: width .3s ease-out;
    }

    span:before,
    span:after {
        content: ' ';
    }

    span:before {
        top: -12px;
        transition: width .3s ease-out .1s;
    }

    span:after {
        top: 12px;
        transition: width .3s ease-out .2s;
    }
`

export default StoryMenu
