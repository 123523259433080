import React from 'react'
import PropTypes from 'prop-types'
import find from 'lodash/find'
import Api from 'fabled/api'
import NotificationsWithUsersLoader from 'fabled/components/loaders/NotificationsWithUsersLoader'
import Notification from './Notification'

const Notifications = class extends React.Component {
    constructor( props ) {
        super( props )
        this.state = {
            openNotificationId: null,
        }
        this.toggleNotification = this.toggleNotification.bind( this )
        this.timer = null
    }

    componentWillUnmount() {
        if ( this.timer ) {
            clearTimeout( this.timer )
        }
    }

    toggleNotification( notification ) {
        return () => {
            if ( this.state.openNotificationId === notification.id ) {
                this.setState( { openNotificationId: null } )
            }
            else {
                this.setState( { openNotificationId: notification.id } )
                this.timer = setTimeout( () => {
                    if ( !notification.markedAsRead ) {
                        Api.service( 'myNotifications' ).patch( notification.id, { markedAsRead: true } )
                    }
                }, 2500 )
            }
        }
    }

    render() {
        return (
            <NotificationsWithUsersLoader
                userId={ this.props.userId }
                render={
                    ( { notifications, users } ) => notifications.map( ( n ) => {
                        const notification = {
                            ...n,
                            user: find( users, { id: n.userId } ),
                            associatedUser: find( users, { id: n.associatedUserId } ),
                        }

                        return (
                            <Notification
                                key={ notification.id }
                                notification={ notification }
                                onClick={ this.toggleNotification( notification ) }
                                isCompact={ this.state.openNotificationId !== notification.id }
                            />
                        )
                    } )
                }
            />
        )
    }
}

Notifications.propTypes = {
    userId: PropTypes.number.isRequired,
}

export default Notifications
