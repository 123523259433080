// @flow
import React from 'react'
import type { Node } from 'react'
import type { Collaboration } from 'fabled/types/Collaboration'
import { connect } from 'react-redux'
import actionCreators from 'fabled/actions/actionCreators'
import {
    getCollaborationById,
    getCollaborationsByUserIdOrCollaboratorId,
} from 'fabled/selectors/collaborations'
import DataLoader from './DataLoader'

type Props = {
    service?: string,
    requestId?: string,
    userId?: number | Array<number>,
    includePending?: boolean,
    waitForQuery?: boolean,
    render: ( { collaborations: Array<Collaboration> } ) => Node,
    onSuccess?: ( { data: mixed } ) => mixed,

    // from connect
    collaborations: Array<Collaboration>,
    collaborationsReplaceOrAdd: ( collaborations: Array<Collaboration> ) => void,
    collaborationsRemove: ( id: string | number ) => void,
}

const defaultProps = {
    service: 'myCollaborations',
    requestId: null,
    userId: null,
    onSuccess: null,
    includePending: false,
    waitForQuery: true,
}

const CollaborationsLoader = ( props: Props ) => {
    let query = {}
    let method = 'find'

    if ( props.requestId ) {
        query = props.requestId
        method = 'get'
    }
    else if ( props.userId ) {
        query.$or = {
            userId: props.userId,
            collaboratorId: props.userId,
        }

        if ( !props.includePending ) {
            query.collaboratorId = { $gt: 0 }
        }
    }

    if ( !props.waitForQuery
        && (
            typeof query !== 'string'
            && !Object.keys( query ).length
        )
    ) {
        throw new Error( 'No valid query props.' )
    }

    const onSuccess = ( response ) => {
        if ( response && response.total ) {
            props.collaborationsReplaceOrAdd( response.data )
        }
        else if ( response ) {
            props.collaborationsReplaceOrAdd( response )
        }

        if ( props.onSuccess ) {
            props.onSuccess( response.data )
        }
    }

    const listener = {
        service: 'collaborations',
        events: ['created', 'updated', 'patched', 'removed'],
        key: 'collaborations',
        listener: ( serviceName, eventName, message ) => {
            if ( eventName === 'removed' ) {
                props.collaborationsRemove( message.id )
            }
            else {
                props.collaborationsReplaceOrAdd( message )
            }
        },
    }

    const render = propsFromDataLoader => props.render( {
        collaborations: props.collaborations,
        ...propsFromDataLoader,
    } )

    return (
        <DataLoader
            service={ props.service }
            query={ query }
            method={ method }
            listener={ listener }
            onSuccess={ onSuccess }
            render={ render }
            waitForAuth
        />
    )
}

CollaborationsLoader.defaultProps = defaultProps

const mapStateToProps = ( state, props ) => {
    let collaborations = []

    if ( props.requestId ) {
        const [id] = props.requestId.split( '__' )
        collaborations = [getCollaborationById( state, parseInt( id, 10 ) )]
    }
    else if ( props.userId ) {
        collaborations = getCollaborationsByUserIdOrCollaboratorId( state, props.userId )
    }

    return { collaborations }
}

const mapDispatchToProps = {
    collaborationsReplaceOrAdd: actionCreators.collaborations.data.replaceOrAdd,
    collaborationsRemove: actionCreators.collaborations.data.remove,
}

export default connect( mapStateToProps, mapDispatchToProps )( CollaborationsLoader )
