import React from 'react'
import UsersLoader from './loaders/UsersLoader'
import Form from './Form'
import AvatarSelectInput from './FormInputs/AvatarSelectInput/AvatarSelectInput'
import TextInput from './FormInputs/TextInput'
import RadioInput from './FormInputs/RadioInput'
import DOBInput from './FormInputs/DOBInput'
import Button from './Button'
import Loader from './Loader'
import * as validators from '../validators'
import Api from '../api'
import DelayedPromise from '../helpers/DelayedPromise'

const Aux = props => props.children

class UserSettings extends React.Component {
    constructor( props ) {
        super( props )
        this.state = {
            failed: false,
            succeeded: false,
            waiting: false,
        }
        this.handleSubmit = this.handleSubmit.bind( this )
    }

    handleSubmit() {
        return ( e, formComponent ) => {
            this.setState( {
                failed: false,
                succeeded: false,
                waiting: true,
            } )
            DelayedPromise(
                Api.service( 'me' ).patch( formComponent.getFields() ),
                1000,
            )
                .then( () => this.setState( {
                    failed: false,
                    succeeded: true,
                    waiting: false,
                } ) )
                .catch( () => this.setState( {
                    failed: true,
                    succeeded: false,
                    waiting: false,
                } ) )
                .then( () => window.scrollTo( 0, 0 ) )
        }
    }

    render() {
        const errorMessage = ( failed ) => {
            if ( failed ) {
                return (
                    <p className="fab-c-signup-form__error-msg">
                        There was a problem. Please try again.
                    </p>
                )
            }

            return null
        }

        const successMessage = ( succeeded ) => {
            if ( succeeded ) {
                return (
                    <p className="fab-c-signup-form__success-msg">
                        Your settings have been saved.
                    </p>
                )
            }

            return null
        }

        const userSettingFields = ( user ) => {
            if ( user ) {
                if ( user.role === 'child' ) {
                    return (
                        <Aux>
                            <AvatarSelectInput
                                name="avatar"
                                id="fab-id-user-settings-avatar"
                                label="Avatar"
                                value={ user.avatar }
                            />
                            <TextInput
                                name="username"
                                id="fab-id-user-settings-username"
                                label="Nickname"
                                value={ user.username }
                                validator={ validators.username }
                            />
                            <TextInput
                                name="firstName"
                                id="fab-id-user-settings-first-name"
                                label="First name"
                                value={ user.firstName }
                                validator={ validators.firstName }
                            />
                            <RadioInput
                                wrapperClassName="fab-c-signup-form__input-row"
                                name="gender"
                                id="fab-id-user-settings-gender"
                                label="You are a…"
                                // validator={ validators.gender }
                                options={ [
                                    { value: 'male', label: 'Boy' },
                                    { value: 'female', label: 'Girl' },
                                ] }
                                value={ user.gender }
                            />
                            <DOBInput
                                name="dob"
                                id="fab-id-user-settings-dob"
                                label="Your birthday"
                                // validator={ validators.dob }
                                value={ user.dob }
                            />
                            <TextInput
                                name="parentEmail"
                                id="fab-id-user-settings-parent-email"
                                label="Your Parent’s Email Address"
                                value={ user.parentEmail }
                                validator={ validators.email }
                            />
                        </Aux>
                    )
                }

                return (
                    <Aux>
                        <TextInput
                            name="email"
                            id="fab-id-user-settings-email"
                            label="Email"
                            value={ user.email }
                            validator={ validators.email }
                        />
                        <TextInput
                            name="firstName"
                            id="fab-id-user-settings-first-name"
                            label="First name"
                            value={ user.firstName }
                            validator={ validators.firstName }
                        />
                        <TextInput
                            name="parentName"
                            id="fab-id-user-settings-parent-name"
                            label="How does your child refer to you?"
                            value={ user.parentName }
                            validator={ validators.parentName }
                        />
                    </Aux>
                )
            }

            return null
        }

        return (
            <div className="fab-c-signup-wrapper">
                <div className="fab-c-signup-panel">
                    <div className="fab-c-signup-form-wrapper">
                        <div className="fab-c-signup-form fab-c-signup-form--front">

                            <UsersLoader
                                service="me"
                                waitForAuth
                                render={
                                    ( { users, loaderState } ) => {
                                        const user = users ? users[ 0 ] : null

                                        if ( loaderState.isComplete && loaderState.error ) {
                                            return <p>{ loaderState.error.message }</p>
                                        }

                                        return (
                                            <Form onSubmit={ this.handleSubmit( user ) }>
                                                <div className="fab-c-signup-form__header">
                                                    <h2 className="fab-c-signup-form__heading">Update your settings</h2>
                                                </div>

                                                { errorMessage( this.state.failed ) }
                                                { successMessage( this.state.succeeded ) }
                                                { userSettingFields( user ) }
                                                <Button primary>Save</Button>

                                                {
                                                    !user || this.state.waiting
                                                        ? <Loader isLoading /> : null
                                                }
                                            </Form>
                                        )
                                    }
                                }
                            />

                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default UserSettings
