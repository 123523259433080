// @flow
import type { Node } from 'react'
import type { AuthUser } from 'fabled/types/User'
import { connect } from 'react-redux'
import actionCreators from 'fabled/actions/actionCreators'
import { getAuthUser } from 'fabled/selectors/users'

type Props = {
    children: ( {} ) => Node,

    // from connect
    authUser: AuthUser,
    modalOpenShare: ( storyId: number ) => void,
    modalOpenSetCollection: ( storyId: number ) => void,
    modalOpenRequestCollaboration: ( storyId: number ) => void,
    modalOpenPassStory: ( storyId: number ) => void,
    modalOpenPublish: ( storyId: number ) => void,
    modalOpenDialog: ( {
        title: string,
        body: Node | string,
        confirmText?: string,
    } ) => void,
}

const ActionManager = ( {
    children,
    authUser,
    modalOpenShare,
    modalOpenSetCollection,
    modalOpenRequestCollaboration,
    modalOpenPassStory,
    modalOpenPublish,
    modalOpenDialog,
}: Props ) => {
    const openStoryNotFinishedDailog = () => {
        modalOpenDialog( {
            title: 'This story isn’t published yet',
            body: 'This story isn’t finished yet. Encourage your child to finish the '
                + 'story using the green “Finished?” button when they’re writing their story.',
            confirmText: 'Ok',
        } )
    }

    const openShareModal = story => () => {
        if ( story.published ) {
            modalOpenShare( story.id )
        }
        else {
            openStoryNotFinishedDailog()
        }
    }

    const openCollectionModal = story => () => {
        if ( story.published ) {
            modalOpenSetCollection( story.id )
        }
        else {
            openStoryNotFinishedDailog()
        }
    }

    const openRequestCollaborationModal = story => () => {
        if ( authUser.parentId ) {
            modalOpenRequestCollaboration( story.id )
        }
        else {
            modalOpenDialog( {
                title: 'Your Grown-Up doesn’t have a Fabled account yet',
                body: 'Ask your Grown-Up to create their Fabled account so that they can help you work with a friend. If you add their address they’ll have an email with instructions on how to do this.',
                confirmText: 'Ok',
            } )
        }
    }

    const openPassStoryModal = story => () => {
        modalOpenPassStory( story.id )
    }

    const openPublishModal = story => () => {
        modalOpenPublish( story.id )
    }

    return children( {
        openStoryNotFinishedDailog,
        openShareModal,
        openCollectionModal,
        openRequestCollaborationModal,
        openPassStoryModal,
        openPublishModal,
    } )
}

const mapStateToProps = state => ( {
    authUser: getAuthUser( state ),
} )

const mapDispatchToProps = {
    modalOpenShare: actionCreators.ui.modal.openShare,
    modalOpenSetCollection: actionCreators.ui.modal.openSetCollection,
    modalOpenRequestCollaboration: actionCreators.ui.modal.openRequestCollaboration,
    modalOpenDialog: actionCreators.ui.modal.openDialog,
    modalOpenPassStory: actionCreators.ui.modal.openPassStory,
    modalOpenPublish: actionCreators.ui.modal.openPublish,
}

export default connect( mapStateToProps, mapDispatchToProps )( ActionManager )
