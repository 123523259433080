import React, { Fragment as F } from 'react'
import PropTypes from 'prop-types'
import AppHeader from 'fabled/components/AppHeader/AppHeader'
import StoryWrapper from 'fabled/components/StoryWrapper'
import Story from 'fabled/components/Story'
import LoadingStory from 'fabled/components/sharedStoryComponents/LoadingStory'
import StoryErrorMessage from 'fabled/components/sharedStoryComponents/StoryErrorMessage'
import ScrollToTopOnMount from 'fabled/components/ScrollToTopOnMount'

const PublicStoryRoute = ( { match: { params: { publicStoryId } } } ) => (
    <F>
        <ScrollToTopOnMount />
        <AppHeader />
        <StoryWrapper
            key={ publicStoryId } // recreate the component when storyId changes
            publicStoryId={ publicStoryId }
            service="storiesInTheme"
            loadingComponent={ LoadingStory }
            successComponent={ Story }
            errorComponent={ StoryErrorMessage }
            storyPromoButton
        />
    </F>
)

PublicStoryRoute.propTypes = {
    match: PropTypes.shape( {
        params: PropTypes.shape( {
            publicStoryId: PropTypes.string.isRequired,
        } ).isRequired,
    } ).isRequired,
}

export default PublicStoryRoute
