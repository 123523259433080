import { combineActions } from 'redux-actions'
import cloneDeep from 'lodash/cloneDeep'
import isArray from 'lodash/isArray'

const findById = ( keyedObject, id ) => (
    Object.values( keyedObject ).find( item => item.id && id && item.id === id )
)

export function toggleCreator(
    actionCreators,
    prefix,
    trueVerb,
    falseVerb,
    toggleName,
    toggleProp,
) {
    const ac = prefix.split( '.' ).reduce( ( a, b ) => a[ b ], actionCreators )

    if ( !ac ) {
        throw new Error( `No action creators resolved for ${ prefix }` )
    }

    if ( !ac[ `${ trueVerb }${ toggleName }` ] ) {
        throw new Error( `No action creators resolved for ${ prefix }.${ trueVerb }${ toggleName }` )
    }

    if ( !ac[ `${ falseVerb }${ toggleName }` ] ) {
        throw new Error( `No action creators resolved for ${ prefix }.${ falseVerb }${ toggleName }` )
    }

    return {
        [ ac[ `${ trueVerb }${ toggleName }` ] ]( state ) {
            const newState = Object.assign( {}, state )
            newState[ toggleProp ] = true
            return newState
        },

        [ ac[ `${ falseVerb }${ toggleName }` ] ]( state ) {
            const newState = Object.assign( {}, state )
            newState[ toggleProp ] = false
            return newState
        },
    }
}

export function startStop( actionCreators, prefix, toggleName, toggleProp ) {
    // {prefix}.start{toggleName}
    // {prefix}.stop{toggleName}
    return toggleCreator( actionCreators, prefix, 'start', 'stop', toggleName, toggleProp )
}

export function isWaiting( actionCreators, prefix ) {
    // {prefix}.startWaiting
    // {prefix}.stopWaiting
    return startStop( actionCreators, prefix, 'Waiting', 'isWaiting' )
}

export function openClose( actionCreators, prefix, toggleName, toggleProp ) {
    // {prefix}.open{toggleName}
    // {prefix}.close{toggleName}
    return toggleCreator( actionCreators, prefix, 'open', 'close', toggleName, toggleProp )
}

export function enableDisable( actionCreators, prefix ) {
    // {prefix}.enable{toggleName}
    // {prefix}.disable{toggleName}
    return toggleCreator( actionCreators, prefix, 'enable', 'disable', '', 'isEnabled' )
}

const dataArrayAdd = ( state, action ) => {
    const newState = state.slice()
    newState.push( action.payload )
    return newState
}

const dataArrayReplace = ( state, action ) => {
    const newState = state.slice()

    return newState.map( ( item ) => {
        if ( item.id === action.payload.id ) {
            return action.payload
        }

        return item
    } )
}

const dataArrayUpdate = ( state, action ) => {
    const newState = state.slice()

    return newState.map( ( item ) => {
        if ( item.id === action.payload.id ) {
            return Object.assign( {}, item, action.payload )
        }

        return item
    } )
}

export function dataArray( actionCreators, storeProp, resetOnAuthUserUnset = false ) {
    const ac = storeProp.split( '.' ).reduce( ( a, b ) => a[ b ], actionCreators )

    if ( !ac ) {
        throw new Error( `No action creators resolved for ${ storeProp }` )
    }

    const reducers = {}
    const resetActions = []

    if ( ac.reset ) {
        resetActions.push( ac.reset )
    }

    if ( resetOnAuthUserUnset ) {
        resetActions.push( actionCreators.auth.userId.unset )
    }

    if ( resetActions.length ) {
        reducers[ combineActions( ...resetActions ) ] = ( state ) => {
            const newState = Object.assign( {}, state )
            newState.data = []
            return newState
        }
    }

    if ( ac.add ) {
        reducers[ ac.add ] = ( state, action ) => {
            const newState = Object.assign( {}, state )
            newState.data = dataArrayAdd( newState.data, action )
            return newState
        }
    }

    if ( ac.replace ) {
        reducers[ ac.replace ] = ( state, action ) => {
            const newState = Object.assign( {}, state )
            newState.data = dataArrayReplace( newState.data, action )
            return newState
        }
    }

    if ( ac.update ) {
        reducers[ ac.update ] = ( state, action ) => {
            const newState = Object.assign( {}, state )
            newState.data = dataArrayUpdate( newState.data, action )
            return newState
        }
    }

    return reducers
}

const dataObjectAdd = ( state, action ) => {
    const newState = cloneDeep( state )

    if (
        !action.payload.id
        || !Object.values( newState ).find( i => i.id === action.payload.id )
    ) {
        newState[ action.payload.uuid ] = action.payload
    }

    return newState
}

const dataObjectReplace = ( state, action, addIfNotExists = false ) => {
    const newState = cloneDeep( state )
    const payload = cloneDeep( action.payload )
    const itemById = payload.id ? findById( newState, payload.id ) : null

    if ( itemById ) {
        payload.uuid = itemById.uuid
        newState[ itemById.uuid ] = payload
    }
    else if ( newState[ payload.uuid ] ) {
        newState[ payload.uuid ] = payload
    }
    else if ( addIfNotExists ) {
        return dataObjectAdd( state, action )
    }

    return newState
}

const dataObjectUpdate = ( state, action, addIfNotExists = false ) => {
    const newState = cloneDeep( state )
    const payload = cloneDeep( action.payload )
    const itemById = payload.id ? findById( newState, payload.id ) : null

    if ( itemById ) {
        payload.uuid = itemById.uuid
        Object.assign( newState[ itemById.uuid ], payload )
    }
    else if ( newState[ payload.uuid ] ) {
        Object.assign( newState[ payload.uuid ], payload )
    }
    else if ( addIfNotExists ) {
        return dataObjectAdd( state, action )
    }

    return newState
}

const dataObjectRemove = ( state, action ) => {
    const newState = cloneDeep( state )
    const itemById = findById( newState, action.payload )
    const uuid = itemById ? itemById.uuid : action.payload

    if ( newState[ uuid ] ) {
        delete newState[ uuid ]
    }

    return newState
}

const handleMultiple = ( func, state, action, ...rest ) => {
    let newState = cloneDeep( state )

    if ( isArray( action.payload ) ) {
        action.payload.forEach( ( item ) => {
            const singleAction = {
                type: action.type,
                payload: item,
            }
            newState = func( newState, singleAction, ...rest )
        } )
    }
    else {
        newState = func( newState, action, ...rest )
    }

    return newState
}

export function dataObject( actionCreators, storeProp, resetOnAuthUserUnset = false ) {
    const ac = storeProp.split( '.' ).reduce( ( a, b ) => a[ b ], actionCreators )

    if ( !ac ) {
        throw new Error( `No action creators resolved for ${ storeProp }` )
    }

    const reducers = {}
    const resetActions = []

    if ( ac.reset ) {
        resetActions.push( ac.reset )
    }

    if ( resetOnAuthUserUnset ) {
        resetActions.push( actionCreators.auth.userId.unset )
    }

    if ( resetActions.length ) {
        reducers[ combineActions( ...resetActions ) ] = ( state ) => {
            const newState = Object.assign( {}, state )
            newState.data = {}
            return newState
        }
    }

    if ( ac.add ) {
        reducers[ ac.add ] = ( state, action ) => {
            const newState = Object.assign( {}, state )
            newState.data = handleMultiple( dataObjectAdd, newState.data, action )
            return newState
        }
    }

    if ( ac.replace ) {
        reducers[ ac.replace ] = ( state, action ) => {
            const newState = Object.assign( {}, state )
            newState.data = handleMultiple( dataObjectReplace, newState.data, action )
            return newState
        }
    }

    if ( ac.replaceOrAdd ) {
        reducers[ ac.replaceOrAdd ] = ( state, action ) => {
            const newState = Object.assign( {}, state )
            newState.data = handleMultiple( dataObjectReplace, newState.data, action, true )
            return newState
        }
    }

    if ( ac.update ) {
        reducers[ ac.update ] = ( state, action ) => {
            const newState = Object.assign( {}, state )
            newState.data = handleMultiple( dataObjectUpdate, newState.data, action )
            return newState
        }
    }

    if ( ac.updateOrAdd ) {
        reducers[ ac.updateOrAdd ] = ( state, action ) => {
            const newState = Object.assign( {}, state )
            newState.data = handleMultiple( dataObjectUpdate, newState.data, action, true )
            return newState
        }
    }

    if ( ac.remove ) {
        reducers[ ac.remove ] = ( state, action ) => {
            const newState = Object.assign( {}, state )
            newState.data = handleMultiple( dataObjectRemove, newState.data, action )
            return newState
        }
    }

    return reducers
}
