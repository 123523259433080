// @flow
import React from 'react'

type Props = {
    buttonText: string,
}

const SubmitButton = ( { buttonText }: Props ) => (
    <div className="fab-c-signup-form__input-row">
        <button type="submit" className="fab-c-signup-form__submit-btn">
            { buttonText }
        </button>
    </div>
)

export default SubmitButton
