// @flow
import React from 'react'
import type { User } from 'fabled/types/User'
import type { Collaboration } from 'fabled/types/Collaboration'
import find from 'lodash/find'
import styled from 'styled-components'
import { rgba } from 'polished'
import colors from 'fabled/styles/colors'
import Api from 'fabled/api'
import UserAvatar from 'fabled/components/UserAvatar'
import Button from 'fabled/components/Button'

type Props = {
    user: User,
    users: Array<User>,
    collaborations: Array<Collaboration>,
}

const removeCollaboration = collaborationId => () => {
    Api.service( 'collaborationsForParent' ).remove( collaborationId )
}

const getUserForList = ( user, collaboration, users ) => {
    if ( user.id === collaboration.collaboratorId ) {
        // user is collaborator (not requesting user) and
        // collaboration is approved
        return find( users, { id: collaboration.userId } )
    }
    if ( collaboration.collaboratorId ) {
        // user is requesting user, and collaboration is approved
        return find( users, { id: collaboration.collaboratorId } )
    }

    return null
}

const getUsernameComponent = ( user, collaboration ) => {
    let usernameString = `${ collaboration.collaboratorName } (pending)`

    if ( user ) {
        if ( user.id === collaboration.collaboratorId ) {
            usernameString = `${ user.username } (${ collaboration.collaboratorName })`
        }
        else {
            usernameString = user.username
        }
    }

    return (
        <StyledCollaborationsListUsername>
            { usernameString }
        </StyledCollaborationsListUsername>
    )
}

const CollaborationsList = ( props: Props ) => (
    <div>
        {
            props.collaborations.length
                ? props.collaborations.map( ( collaboration ) => {
                    const user = getUserForList( props.user, collaboration, props.users )

                    return (
                        <StyledCollaborationsListItem key={ collaboration.id }>
                            <UserAvatar
                                user={ user }
                                size={ 30 }
                            />
                            { getUsernameComponent( user, collaboration ) }
                            <StyledCollaborationsListButton>
                                <Button
                                    tiny
                                    inline
                                    onClick={ removeCollaboration( collaboration.id ) }
                                >
                                        Remove this connection
                                </Button>
                            </StyledCollaborationsListButton>
                        </StyledCollaborationsListItem>
                    )
                } )
                : <p>Not working with anyone yet.</p>
        }
    </div>
)

const StyledCollaborationsListItem = styled.div`
    display: flex;
    align-items: center;
    padding: 6px 0;
    border-top: 1px solid ${ rgba( colors.purple, 0.3 ) };

    &:first-child {
        border-top: 0;
    }
`

const StyledCollaborationsListUsername = styled.span`
    margin-left: 1em;
`

const StyledCollaborationsListButton = styled.span`
    margin-left: auto;
`

export default CollaborationsList
