import styled, { css } from 'styled-components'
import { darken } from 'polished'
import colors from 'fabled/styles/colors'

const MediaButton = styled.label`
    position: absolute;
    bottom: 0;
    right: 0;
    width: var(--post-input-btn-size);
    height: var(--post-input-btn-size);
    background-color: rgb(253, 193, 185);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: auto 30%;
    text-indent: -999em;
    transition: background-color .3s ease-out;

    &:hover {
        background-color: ${ darken( 0.05, 'rgb(253, 193, 185)' ) };
    }

    ${ p => p.photo && css`
        right: var(--post-input-btn-size);
        border-right: 1px solid ${ colors.red };
        background-image: url(/images/image-icon-red.svg);
    ` }

    ${ p => p.audio && css`
        background-image: url(/images/audio-icon-red.svg);
        background-size: auto 38%;
    ` }

    ${ p => p.isActive && css`
        background-color: rgb(51, 51, 102);
    ` }
`

export default MediaButton
