import cloneDeep from 'lodash/cloneDeep'
import RandomIterator from './RandomIterator'

class EncouragementFactory {
    constructor( encouragementMap, delay = 3000 ) {
        const clonedEncouragementMap = cloneDeep( encouragementMap )
        this.encouragement = new RandomIterator( clonedEncouragementMap )
        this.delay = delay
        this.rejectPromise = null
        this.timer = null
    }

    next( delay ) {
        const timerDelay = delay || this.delay

        if ( this.timer ) {
            clearTimeout( this.timer )
        }

        if ( this.rejectPromise ) {
            this.rejectPromise( 'expired' )
        }

        const promise = new Promise( ( resolve, reject ) => {
            this.rejectPromise = reject
            this.timer = setTimeout( () => {
                const encouragementItem = this.encouragement.next()
                resolve( encouragementItem )
            }, timerDelay )
        } )

        return promise
    }
}

export default EncouragementFactory
