// @flow
import React from 'react'
import styled, { css } from 'styled-components'
import { colors } from 'fabled/styles'

type Props = {
    iconUrl: string,
    altText: string,
    count?: number,
    isHighlighted?: boolean,
    hasFocus?: boolean,
}

const AppNavIcon = ( props: Props ) => (
    <StyledAppNavIcon
        isHighlighted={ props.isHighlighted }
        hasFocus={ props.hasFocus }
    >
        <img src={ props.iconUrl } alt={ props.altText } />
        { props.count ? <StyledAppNavCounter>{ props.count }</StyledAppNavCounter> : null }
    </StyledAppNavIcon>
)

AppNavIcon.defaultProps = {
    count: null,
    isHighlighted: false,
    hasFocus: false,
}

const StyledAppNavIcon = styled.div`
    position: relative;
    display: block;
    width: 46px;
    height: 46px;
    margin-left: 10px;
    border-radius: 100%;
    transition: box-shadow .3s ease-out;

    ${ props => ( props.isHighlighted || props.hasFocus ? css`
        box-shadow: 0 0 0 3px ${ colors.greenBlue };
    ` : null ) }

    img {
        max-width: 100%;
    }
`

const StyledAppNavCounter = styled.div`
    position: absolute;
    top: 0;
    right: -3px;
    background: ${ colors.red };
    color: white;
    border-radius: 100%;
    width: 18px;
    height: 18px;
    text-align: center;
    font-size: 11px;
    line-height: 18px;
`

export default AppNavIcon
