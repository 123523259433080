import React from 'react'
import PropTypes from 'prop-types'
import { eventTracker } from 'fabled/eventTracker'
import Api from 'fabled/api'

const UserLogout = ( { className } ) => {
    const logout = () => {
        Api.logout()
            .then( () => {
                eventTracker( {
                    category: 'Users',
                    action: 'Logged out',
                } )
            } )
    }

    return <button type="button" className={ className } onClick={ logout }>Log out</button>
}

UserLogout.propTypes = {
    className: PropTypes.string,
}

UserLogout.defaultProps = {
    className: '',
}

export default UserLogout
