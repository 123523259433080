const variables = {
    pageLayoutWidth: 1200,
    verticalPadding: {
        none: '0',
        small: '1rem',
        medium: '2rem',
        large: '4rem',
        hero: '6rem',
        headerPush: '117px',
    },
    layoutSmallWriterWidth: 850,
    postInputButton: {
        size: {
            large: 80,
            small: 50,
        },
        breakpoint: 600,
    },
}

export default variables
