import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'

class AnimatedStoryBlock extends React.Component {
    constructor( props ) {
        super( props )
        this.state = {
            componentHeight: null,
        }
    }

    componentDidUpdate( prevProps ) {
        if (
            prevProps.transitionState !== 'exiting' &&
            this.props.transitionState === 'exiting' &&
            this.node
        ) {
            // if the component is exiting, animate it's height to zero
            this.animatePostOut()
        }
    }

    animatePostOut() {
        // (transition is in the CSS)
        this.setState( { componentHeight: this.node.offsetHeight }, () => {
            window.requestAnimationFrame( () => {
                window.requestAnimationFrame( () => {
                    this.setState( { componentHeight: 0 } )
                } )
            } )
        } )
    }

    render() {
        return (
            <StyledAnimatedStoryBlock
                innerRef={ ( node ) => {
                    this.node = node
                } }
                componentHeight={ this.state.componentHeight }
                transitionState={ this.props.transitionState }
            >
                { this.props.children }
            </StyledAnimatedStoryBlock>
        )
    }
}

AnimatedStoryBlock.propTypes = {
    transitionState: PropTypes.string,
    children: PropTypes.oneOfType( [
        PropTypes.arrayOf( PropTypes.node ),
        PropTypes.node,
    ] ).isRequired,
}

AnimatedStoryBlock.defaultProps = {
    transitionState: '',
}

const StyledAnimatedStoryBlock = styled.div`
    transform: rotateX(90deg);
    transition:
        transform .2s ease-in,
        height .3s ease-out .1s;

    ${ props => props.componentHeight !== null && css`
        height: ${ props.componentHeight }px;
    ` }

    ${ props => ['entering', 'entered'].includes( props.transitionState ) && css`
        transform: rotateX(0deg);
    ` }

    ${ props => props.transitionState === 'exiting' && css`
        transform: rotateX(90deg);
    ` }

    &.fab-is-animated-enter {
        transform: rotateX(90deg);
    }

    &.fab-is-animated-enter-active {
        transition: transform .2s ease-in;
        transform: rotateX(0deg);
    }

    &.fab-is-animated-exit {
        transform: rotateX(0deg);
    }

    &.fab-is-animated-exit-active {
        transition:
            transform .2s ease-in,
            height .3s ease-out .1s;
        transform: rotateX(90deg);
    }
`

export default AnimatedStoryBlock
