import React from 'react'
import PropTypes from 'prop-types'
import {
    getStoriesByThemeIdNotApprovedByAdmin,
    // getStoriesByThemeIdNotApprovedByParent,
} from '../../selectors/stories'
import StoriesLoader from '../loaders/StoriesLoader'
import StoriesGrid from './StoriesGrid'
import Loader from '../Loader'

const getStoriesNotApprovedByAdmin = ( state, props ) => (
    getStoriesByThemeIdNotApprovedByAdmin( state, props.themeId )
)

const AdminCollectionStories = ( { collectionId } ) => (
    <div>
        <h3 className="fab-c-collection__heading">Stories not approved by admin</h3>
        <StoriesLoader
            service="storiesForAdmin"
            themeId={ collectionId }
            waitForAuth
            selector={ getStoriesNotApprovedByAdmin }
            render={
                ( { stories } ) => (
                    stories
                        ? <StoriesGrid stories={ stories } />
                        : <Loader isLoading />
                )
            }
        />
    </div>
)

AdminCollectionStories.propTypes = {
    collectionId: PropTypes.number.isRequired,
}

export default AdminCollectionStories
