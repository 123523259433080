import React from 'react'
import Loader from '../Loader'

const LoadingStory = () => (
    <div className="fab-c-writer">
        <div className="fab-c-writer__story">
            <Loader isLoading />
        </div>
    </div>
)

export default LoadingStory
