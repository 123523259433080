// @flow
import React from 'react'
import type { Story } from 'fabled/types/Story'
import type { Post } from 'fabled/types/Post'
import type { AuthUser } from 'fabled/types/User'
import Api from 'fabled/api'
import { formData } from 'fabled/api/helpers'
import postFactory from 'fabled/helpers/postFactory'
import { IMAGE } from 'fabled/postTypes'
import { eventTracker } from 'fabled/eventTracker'
import MediaButton from './MediaButton'

type Props = {
    story: Story,
    posts: Array<Post>,
    currentChapter: number,
    authUser: AuthUser,
    postReplaceOrAdd: ( post: Post ) => void,
}

const PostImageUploader = ( props: Props ) => {
    /**
     * Given the result from FileReader.readAsArrayBuffer, returns an objectURL
     * for the image with EXIF data removed.
     * Useful JPEG marker info: https://www.media.mit.edu/pia/Research/deepview/exif.html
     */
    const stripExifDataFromFileContent = ( fileContent ) => {
        const dataView = new DataView( fileContent )

        if ( dataView.getUint16( 0, false ) === 0xFFD8 ) { // this is a jpeg (SOI marker)
            const preExifPositions = []
            let startPosition = 0
            let offset = 2

            while ( offset < dataView.byteLength ) {
                const marker = dataView.getUint16( offset )
                offset += 2

                if ( marker === 0xFFE1 ) { // found exif (APP1 marker)
                    // mark the start of the file up to the start of the exif data
                    preExifPositions.push( {
                        begin: startPosition,
                        end: offset - 2,
                    } )
                    startPosition = offset + dataView.getUint16( offset )
                }
                else if ( marker === 0xFFDA ) {
                    break
                }

                offset += dataView.getUint16( offset )
            }

            if ( preExifPositions.length > 0 ) {
                const newPieces = []

                preExifPositions.forEach( ( v ) => {
                    newPieces.push( fileContent.slice( v.begin, v.end ) )
                } )

                newPieces.push( fileContent.slice( startPosition ) )
                const imageBlob = new Blob( newPieces, { type: 'image/jpeg' } )
                return URL.createObjectURL( imageBlob )
            }
        }

        return URL.createObjectURL( new Blob( [fileContent] ) )
    }

    const stripExifData = file => new Promise( ( resolve ) => {
        const reader = new FileReader()
        reader.onload = ( e ) => {
            const objectUrl = stripExifDataFromFileContent( e.target.result )
            resolve( objectUrl )
        }
        reader.readAsArrayBuffer( file )
    } )

    /**
     * Given the result from FileReader.readAsArrayBuffer, returns an integer
     * representing the EXIF orientation. Returns 1 (default orientation) if
     * not a jpeg or EXIF data is not found.
     * Useful JPEG marker info: https://www.media.mit.edu/pia/Research/deepview/exif.html
     */
    const getExifOrientationFromFileContent = ( fileContent ) => {
        const dataView = new DataView( fileContent )

        if ( dataView.getUint16( 0 ) === 0xFFD8 ) { // this is a jpeg (SOI marker)
            let offset = 2

            while ( offset < dataView.byteLength ) {
                const marker = dataView.getUint16( offset )
                offset += 2

                if ( marker === 0xFFE1 ) { // found exif (APP1 marker)
                    offset += 2

                    if ( dataView.getUint32( offset ) === 0x45786966 ) { // found exif header
                        offset += 6
                        // specifies byte alignment (Intel or Motorola),
                        // 0x4949 is Intel, which is little-endian
                        const isLittleEndian = dataView.getUint16( offset ) === 0x4949
                        offset += dataView.getUint32( offset + 4, isLittleEndian )
                        const tags = dataView.getUint16( offset, isLittleEndian )
                        offset += 2

                        for ( let i = 0; i < tags; i += 1 ) {
                            if (
                                dataView.getUint16( offset + ( i * 12 ), isLittleEndian ) === 0x0112
                            ) { // found the orientation tag (0112)
                                return dataView.getUint16( offset + ( i * 12 ) + 8, isLittleEndian )
                            }
                        }
                    }
                }
                else if ( ( marker & 0xFF00 ) !== 0xFF00 ) { /* eslint-disable-line no-bitwise */
                    break
                }
                else {
                    offset += dataView.getUint16( offset )
                }
            }
        }

        return 1
    }

    const readExifOrientation = file => new Promise( ( resolve ) => {
        const reader = new FileReader()
        reader.onload = ( e ) => {
            const orientation = getExifOrientationFromFileContent( e.target.result )
            resolve( orientation )
        }
        reader.readAsArrayBuffer( file )
    } )

    const handleImageFile = ( file ) => {
        const postContent = {}
        let post

        readExifOrientation( file )
            .then( ( orientation ) => {
                postContent.orientation = orientation
                return stripExifData( file )
            } )
            .then( ( dataUrl ) => {
                postContent.dataUrl = dataUrl

                post = postFactory( {
                    storyId: props.story.id,
                    chapterId: props.currentChapter,
                    postType: IMAGE,
                    userId: props.authUser.id,
                    content: postContent,
                }, props.posts )

                props.postReplaceOrAdd( post )
                const uploadPayload = formData( { uri: file, storyId: post.storyId } )
                return Api.uploadImage( uploadPayload )
            } )
            .then( ( imageUploadResponse ) => {
                post.content = {
                    imageId: imageUploadResponse.id,
                    orientation: post.content.orientation,
                }
                return Api.service( 'postsForCollaborator' ).create( post )
            } )
            .then( ( response ) => {
                props.postReplaceOrAdd( response )
                eventTracker( {
                    category: 'Story Writer',
                    action: 'Added an image post',
                } )
            } )
    }

    const handleImageInput = ( e ) => {
        e.preventDefault()

        Array.from( e.target.files ).forEach( ( file ) => {
            handleImageFile( file )
        } )
    }

    return (
        <MediaButton photo htmlFor="image-upload-input">
            Upload an image
            <input
                id="image-upload-input"
                className="fab-h-visually-hidden"
                type="file"
                name="image"
                accept=".svg,image/*"
                onChange={ handleImageInput }
            />
        </MediaButton>
    )
}

export default PostImageUploader
