import { handleActions } from 'redux-actions'
import { dataObject } from './helpers'
import actionCreators from '../actions/actionCreators'

const posts = handleActions(
    Object.assign( dataObject( actionCreators, 'posts.data', true ) ),
    {
        data: {},
    },
)

export default posts
