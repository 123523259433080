import { handleActions } from 'redux-actions'
import { dataObject, enableDisable } from './helpers'
import actionCreators from '../actions/actionCreators'

const themes = handleActions(
    Object.assign(
        dataObject( actionCreators, 'themes.data', true ),
        enableDisable( actionCreators, 'themes' ),
        {
            [ actionCreators.themes.setCurrent ]( state, action ) {
                const newState = Object.assign( {}, state )
                newState.currentThemeId = action.payload
                return newState
            },
        },
    ),
    {
        isEnabled: false,
        currentThemeId: null,
        data: {},
    },
)

export default themes
