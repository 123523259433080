import React from 'react'
import styled from 'styled-components'

const HeroTitle = ( { children, color = 'white' } ) => (
    <StyledHeroTitle color={ color }>{ children }</StyledHeroTitle>
)

const StyledHeroTitle = styled.div`
    color: var( --${ p => p.color } );
    font-size: 50px;
    font-weight: normal;

    @media all and ( min-width: 600px ) {
        font-size: 60px;
    }
`

export default HeroTitle