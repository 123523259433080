import React from 'react'
import PropTypes from 'prop-types'
import Api from 'fabled/api'
import { eventTracker } from 'fabled/eventTracker'
import Button from 'fabled/components/Button'
import Modal from './Modal'

const OnboardModal = ( props ) => {
    const dismiss = () => {
        Api.service( 'me' ).patch( { onboardingDismissed: true } )
        props.modalClose()
        eventTracker( {
            category: 'Users',
            action: 'Dismissed onboarding',
            nonInteraction: true,
        } )
    }

    return (
        <Modal title="A few hints…" hasCloseButton={ false }>
            <div className="fab-c-onboard">
                <p>You can add to your story by typing, or taking a photo, or adding an image from your computer, or recording yourself talking.</p>

                <img src="/images/writer-ui-example.png" alt="" />

                <p>Write in the box, add a bit of your story at a time, and then press the arrow to save it.</p>

                <img src="/images/writer-ui-example-text.png" alt="" />

                <p>
                    <span className="fab-c-onboard__media-btn fab-c-onboard__media-btn--image" />
                    <span>Click or tap the picture to add a photo or image.</span>
                </p>

                <p>
                    <span className="fab-c-onboard__media-btn fab-c-onboard__media-btn--audio" />
                    Click or tap the microphone to record yourself.
                </p>

                <p>
                    <span className="fab-c-onboard__finish-btn" />
                    When you’re ready, you can publish your story — just click the green tick!
                </p>

                <div className="fab-c-signup-form__input-row">
                    <Button primary onClick={ dismiss }>Ok, got it!</Button>
                </div>
            </div>
        </Modal>
    )
}

OnboardModal.propTypes = {
    modalClose: PropTypes.func.isRequired,
}

export default OnboardModal
