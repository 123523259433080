import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import actionCreators from 'fabled/actions/actionCreators'
import { getCurrentTheme } from 'fabled/selectors/themes'
import { getStoryById } from 'fabled/selectors/stories'
import Api from 'fabled/api'
import history from 'fabled/history'
import { eventTracker } from 'fabled/eventTracker'
import FabledTextInput from 'fabled/components/FormInputs/FabledTextInput'
import RadioInput from 'fabled/components/FormInputs/RadioInput'
import FormattedContent from 'fabled/components/FormattedContent'
import NoticeBox from 'fabled/components/NoticeBox'
import Button from 'fabled/components/Button'
import Loader from 'fabled/components/Loader'
import Modal from './Modal'

class PublishModal extends React.Component {
    static titleValidator( value ) {
        return value.length ? true : 'Give your story a title'
    }

    constructor( props ) {
        super( props )
        this.state = {
            waiting: false,
            themeId: props.story.themeId,
            title: props.story.title,
        }
        this.onTitleChange = this.onTitleChange.bind( this )
        this.onPublish = this.onPublish.bind( this )
        this.closeModal = this.closeModal.bind( this )
        this.setTitle = this.setTitle.bind( this )
        this.setTheme = this.setTheme.bind( this )
    }

    componentDidMount() {
        if ( this.props.story ) {
            this.setTitle( this.props.story.title )
        }
    }

    componentDidUpdate( prevProps ) {
        if ( this.props.story && prevProps.story && prevProps.story.id !== this.props.story.id ) {
            this.setTitle( this.props.story.title )
        }
    }

    onTitleChange( value ) {
        this.setState( { title: value } )
        const storyPayload = {
            id: this.props.story.id,
            uuid: this.props.story.uuid,
            title: value,
        }

        this.props.storiesDataUpdate( storyPayload )
        Api.service( 'storiesForCollaborator' ).patch( storyPayload.id, storyPayload )
    }

    onPublish( e ) {
        e.preventDefault()

        if ( this.props.story.title ) {
            this.setState( { waiting: true } )

            Api.service( 'storiesForCollaborator' ).patch( this.props.story.id, {
                uuid: this.props.story.uuid,
                published: true,
                themeId: this.state.themeId,
                title: this.state.title,
            } )
                .then( () => {
                    eventTracker( {
                        category: 'Story Writer',
                        action: 'Published story',
                    } )
                    history.push( `/story/${ this.props.story.id }` )
                    this.props.modalClose()
                } )
                .catch( () => this.setState( { waiting: false } ) )
        }
    }

    setTitle( title ) {
        this.setState( { title } )
    }

    setTheme( value ) {
        const themeId = value === 'true' && this.props.theme ? this.props.theme.id : null
        this.setState( { themeId } )
    }

    closeModal( e ) {
        e.preventDefault()
        this.props.modalClose()
        eventTracker( {
            category: 'Story Writer',
            action: 'Closed publish window without publishing',
        } )
    }

    render() {
        if ( !this.props.story ) {
            return null
        }

        return (
            <Modal title="Finished your story?">

                <FormattedContent>
                    <p>Great work! Now give your story a title…</p>
                </FormattedContent>

                <FabledTextInput
                    id="story-title"
                    name="story-title"
                    label="Story title"
                    onChange={ this.onTitleChange }
                    value={ this.state.title }
                    validator={ this.titleValidator }
                />

                { this.props.theme ? (
                    <RadioInput
                        name="theme"
                        id="fab-id-story-theme"
                        label={ `Add your story to our collection, ${ this.props.theme.title }, for other people to see?` }
                        options={ [
                            { value: 'true', label: 'Yes' },
                            { value: 'false', label: 'No' },
                        ] }
                        value={ this.props.story.themeId && this.props.story.themeId === this.props.theme.id ? 'true' : 'false' }
                        onChange={ this.setTheme }
                    />
                ) : null }

                <FormattedContent>
                    <p>And then see your finished story!…</p>
                </FormattedContent>
                <div className="fab-c-signup-form__input-row">
                    <Button type="submit" primary onClick={ this.onPublish }>See my story</Button>
                </div>

                <button
                    type="button"
                    className="fab-c-publish__cancel"
                    onClick={ this.closeModal }
                >
                    I’m not finished yet!
                </button>

                <Loader isLoading={ this.state.waiting } />

            </Modal>
        )
    }
}

PublishModal.propTypes = {
    // eslint-disable-next-line react/no-unused-prop-types
    storyId: PropTypes.number.isRequired,
    modalClose: PropTypes.func.isRequired,

    // from connect
    story: PropTypes.shape( {
        id: PropTypes.number,
        uuid: PropTypes.string,
        themeId: PropTypes.number,
        title: PropTypes.string,
    } ).isRequired,
    theme: PropTypes.shape( {
        id: PropTypes.number,
        title: PropTypes.string,
    } ),
    storiesDataUpdate: PropTypes.func.isRequired,
}

PublishModal.defaultProps = {
    theme: null,
}

const mapStateToProps = ( state, { storyId } ) => ( {
    story: getStoryById( state, storyId ),
    theme: getCurrentTheme( state ),
} )

const mapDispatchToProps = {
    storiesDataUpdate: actionCreators.stories.data.update,
}

export default connect( mapStateToProps, mapDispatchToProps )( PublishModal )
