const avatars = [
    'birdie',
    'boyhat',
    'boyshades',
    'cyclops',
    'dracula',
    'feline',
    'girl',
    'girlbun',
    'greenface',
    'monkey',
    'moustache',
    'ninja',
    'penguin',
    'pirateboy',
    'pirateboy2',
    'pirategirl',
    'pirategirl2',
    'purpleface',
    'scaryface',
    'smiler',
]

export default avatars
