import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import actionCreators from '../actions/actionCreators'

const MyStoriesButton = ( { className, modalOpenStoriesList } ) => (
    <button
        type="button"
        className={ className }
        onClick={ modalOpenStoriesList }
    >
        My Stories
    </button>
)

MyStoriesButton.propTypes = {
    modalOpenStoriesList: PropTypes.func.isRequired,
    className: PropTypes.string,
}

MyStoriesButton.defaultProps = {
    className: '',
}

const mapDispatchToProps = {
    modalOpenStoriesList: actionCreators.ui.modal.openStoriesList,
}

export default connect( null, mapDispatchToProps )( MyStoriesButton )
