import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import StoryLoader from './loaders/StoryLoader'
import ContentWrapper from './ContentWrapper'
import StartStoryButton from './StartStoryButton'
import LoadingStory from './sharedStoryComponents/LoadingStory'
import StoryErrorMessage from './sharedStoryComponents/StoryErrorMessage'

const StoryWrapper = props => (
    <ContentWrapper>
        <StoryLoader
            storyId={ props.storyId }
            publicStoryId={ props.publicStoryId }
            themeId={ props.themeId }
            waitForAuth={ props.waitForAuth }
            loadingComponent={ LoadingStory }
            successComponent={ props.successComponent }
            errorComponent={ StoryErrorMessage }
        />

        {
            props.storyPromoButton ? (
                <StyledStoryPromo>
                    <StartStoryButton />
                </StyledStoryPromo>
            ) : null
        }
    </ContentWrapper>
)

StoryWrapper.propTypes = {
    storyId: PropTypes.number,
    publicStoryId: PropTypes.string,
    themeId: PropTypes.number,
    waitForAuth: PropTypes.bool,
    storyPromoButton: PropTypes.bool,
    successComponent: PropTypes.func.isRequired,
}

StoryWrapper.defaultProps = {
    storyId: null,
    publicStoryId: null,
    themeId: null,
    waitForAuth: false,
    storyPromoButton: false,
}

const StyledStoryPromo = styled.div`
    text-align: center;
    margin-top: 10%;
    margin-bottom: 10%;

    @media all and (min-width: 500px) {
        margin-top: 6%;
        margin-bottom: 6%;
    }
`

export default StoryWrapper
