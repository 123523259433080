import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import actionCreators from '../actions/actionCreators'
import { getAuthUser } from '../selectors/users'
import Api from '../api'
import StoryPromptLoader from './loaders/StoryPromptLoader'
import ThemeLoader from './loaders/ThemeLoader'
import Loader from './Loader'
import ContentWrapper from './ContentWrapper'
import Dashboard, {
    DashboardTitle,
    DashboardIntro,
    DashboardSection,
    DashboardSectionTitle,
    DashboardSectionContent,
    DashboardSectionField,
} from './Dashboard/Dashboard'
import Button from './Button'

class AdminDashboard extends React.Component {
    static setGlobal( name, boolValue ) {
        return () => {
            Api.service( 'globals' ).patch( name, {
                content: !!boolValue,
            } )
        }
    }

    render() {
        const themeTitle = theme => (
            theme && theme.title
                ? (
                    <span>
                        { theme.title } <a href={ `/collection/${ theme.id }` }>Theme page</a>
                    </span>
                )
                : <span>No theme yet.</span>
        )

        if ( !this.props.authUser ) {
            return (
                <ContentWrapper>
                    <Loader isLoading />
                </ContentWrapper>
            )
        }

        const {
            modalOpenStoryPrompt,
            modalOpenTheme,
        } = this.props

        return (
            <ContentWrapper>
                <Dashboard>
                    <DashboardTitle>
                        Hi { this.props.authUser.firstName }
                    </DashboardTitle>

                    <DashboardIntro>
                        <p>Admin dashboard.</p>
                    </DashboardIntro>

                    <StoryPromptLoader
                        waitForAuth
                        loadingComponent={ () => <p>Loading…</p> }
                        errorComponent={ () => <p>Loading error.</p> }
                        successComponent={
                            ( { storyPrompt, storyPromptsEnabled } ) => (
                                <DashboardSection>
                                    <DashboardSectionTitle>
                                        Current Story Prompt
                                        {
                                            !storyPromptsEnabled
                                                ? <span> — disabled</span> : null
                                        }
                                    </DashboardSectionTitle>
                                    <DashboardSectionContent>
                                        <DashboardSectionField>
                                            { storyPrompt || 'No story prompt yet.' }
                                        </DashboardSectionField>
                                    </DashboardSectionContent>
                                    <Button
                                        primary
                                        tiny
                                        inline
                                        onClick={ modalOpenStoryPrompt }
                                    >
                                        New story prompt
                                    </Button>
                                    &nbsp;
                                    <Button
                                        tiny
                                        inline
                                        onClick={
                                            AdminDashboard.setGlobal( 'storyPromptsEnabled', !storyPromptsEnabled )
                                        }
                                    >
                                        { storyPromptsEnabled ? 'Turn off and use default' : 'Turn story prompt on' }
                                    </Button>
                                </DashboardSection>
                            )
                        }
                    />

                    <ThemeLoader
                        waitForAuth
                        loadingComponent={ () => <p>Loading…</p> }
                        errorComponent={ () => <p>Loading error.</p> }
                        successComponent={
                            ( { theme, themesEnabled } ) => (
                                <DashboardSection>
                                    <DashboardSectionTitle>
                                        Current Theme
                                        {
                                            !themesEnabled
                                                ? <span> — disabled</span> : null
                                        }
                                    </DashboardSectionTitle>
                                    <DashboardSectionContent>
                                        <DashboardSectionField>
                                            { themeTitle( theme ) }
                                        </DashboardSectionField>
                                    </DashboardSectionContent>
                                    <Button
                                        primary
                                        tiny
                                        inline
                                        onClick={ modalOpenTheme }
                                    >
                                        New theme
                                    </Button>
                                    &nbsp;
                                    <Button
                                        tiny
                                        inline
                                        onClick={
                                            AdminDashboard.setGlobal( 'themesEnabled', !themesEnabled )
                                        }
                                    >
                                        { themesEnabled ? 'Turn off theme' : 'Turn on theme' }
                                    </Button>
                                </DashboardSection>
                            )
                        }
                    />

                </Dashboard>
            </ContentWrapper>
        )
    }
}

AdminDashboard.propTypes = {
    authUser: PropTypes.shape( {
        firstName: PropTypes.string,
    } ),
    modalOpenTheme: PropTypes.func.isRequired,
    modalOpenStoryPrompt: PropTypes.func.isRequired,
}

AdminDashboard.defaultProps = {
    authUser: null,
}

const mapStateToProps = state => ( {
    authUser: getAuthUser( state ),
} )

const mapDispatchToProps = {
    modalOpenTheme: actionCreators.ui.modal.openTheme,
    modalOpenStoryPrompt: actionCreators.ui.modal.openStoryPrompt,
}

export default connect( mapStateToProps, mapDispatchToProps )( AdminDashboard )
