// @flow
import React, { Fragment as F } from 'react'
import type { Collaboration } from 'fabled/types/Collaboration'
import type { User, ChildUser, AuthUser } from 'fabled/types/User'
import { Link } from 'react-router-dom'
import Api from 'fabled/api'
import history from 'fabled/history'
import CollaborationApprovalLoader from 'fabled/components/loaders/CollaborationApprovalLoader'
import ContentWrapper from 'fabled/components/ContentWrapper'
import Dashboard, {
    DashboardTitle,
    DashboardIntro,
} from 'fabled/components/Dashboard/Dashboard'
import UserRow from 'fabled/components/UserRow'
import Button from 'fabled/components/Button'

type Props = {
    token: string,
}

type CollaborationApprovalRenderProps = {
    collaboration?: Collaboration,
    requestingUser?: User,
    childUsers: Array<ChildUser>,
    authUser: AuthUser,
}

const CollaborationApprovalRender = ( {
    collaboration,
    requestingUser,
    childUsers,
    authUser,
}: CollaborationApprovalRenderProps ) => {
    const requestingUserName = requestingUser ? requestingUser.username : '…'
    const collaboratorName = collaboration ? collaboration.collaboratorName : '…'

    if ( authUser && authUser.role === 'child' ) {
        const currentLocation = history.location
        Api.logout()
            .then( () => setTimeout(
                () => history.replace( {
                    location: '/login',
                    state: { from: currentLocation },
                } ),
                2000,
            ) )
    }

    const approveCollaboration = ( id, token, collaboratorId ) => () => {
        const requestId = `${ id }__${ token }`
        Api.service( 'collaborationsForParent' ).patch( requestId, { collaboratorId } )
            .then( () => history.push( '/parent-dashboard' ) )
    }

    return (
        <F>
            <DashboardTitle>
                Fabled kid with username, <span>{ requestingUserName }</span>, would like to create stories with your child, <span>{ collaboratorName }</span>
            </DashboardTitle>

            <DashboardIntro>
                <p>Note: the name shown above, <strong>{ collaboratorName }</strong>, was used by { requestingUserName } to identify your child, so it might not match the name or username your child is currently using with Fabled.</p>
                <p>You can ignore this if you don’t want your child to create stories with { requestingUserName }.</p>
            </DashboardIntro>

            {
                childUsers.map( ( childUser ) => {
                    let userRowContent = null

                    if ( collaboration ) {
                        if ( collaboration.userId === childUser.id ) {
                            userRowContent = (
                                <span>
                                    { childUser.firstName } made this invitation.
                                </span>
                            )
                        }
                        else if ( collaboration.collaboratorId === childUser.id ) {
                            userRowContent = (
                                <span>
                                    Approved. Visit your <Link to="/parent-dashboard" href="/parent-dashboard">parent dashboard</Link> to manage who your child can create stories with.
                                </span>
                            )
                        }
                        else if ( !collaboration.collaboratorId ) {
                            const approve = approveCollaboration(
                                collaboration.id,
                                collaboration.token,
                                childUser.id,
                            )

                            userRowContent = (
                                <Button
                                    small
                                    inline
                                    primary
                                    onClick={ approve }
                                    onKeyDown={ approve }
                                >
                                    Approve
                                </Button>
                            )
                        }
                    }

                    return (
                        <UserRow
                            key={ childUser.id }
                            user={ childUser }
                        >
                            { userRowContent }
                        </UserRow>
                    )
                } )
            }
        </F>
    )
}

CollaborationApprovalRender.defaultProps = {
    collaboration: null,
    requestingUser: null,
}

const CollaborationApproval = ( props: Props ) => (
    <ContentWrapper>
        <Dashboard>

            <CollaborationApprovalLoader
                token={ props.token }
                render={ CollaborationApprovalRender }
            />

        </Dashboard>
    </ContentWrapper>
)


export default CollaborationApproval
