import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import * as validators from '../validators'
import Api from '../api'
import AuthFormWrapper from './AuthFormWrapper'
import Form from './Form'
import TextInput from './FormInputs/TextInput'
import CheckboxInput from './FormInputs/CheckboxInput'
import UINotice from './UINotice'
import Loader from './Loader'

class ParentSignup extends React.Component {
    constructor( props ) {
        super( props )
        this.state = {
            tokenData: null,
            waiting: true,
            error: null,
        }
        this.submitSignupForm = this.submitSignupForm.bind( this )
    }

    componentDidMount() {
        Api.service( 'childUsers' ).get( this.props.token )
            .then( response => this.setState( { tokenData: response, waiting: false } ) )
    }

    submitSignupForm( e, formComponent ) {
        this.setState( { waiting: true } )
        const data = {
            ...formComponent.getFields(),
            email: this.state.tokenData.parentEmail,
        }
        const params = {
            query: {
                signupToken: this.state.tokenData.parentSignupToken,
            },
        }

        Api.service( 'parentUsers' ).create( data, params )
            .then( () => {
                Api.authenticate( {
                    email: data.email,
                    password: data.password,
                    strategy: 'local-email',
                } )
            } )
            .catch( error => this.setState( { error, waiting: false } ) )
    }

    render() {
        const emailAddress = (
            this.state.tokenData && this.state.tokenData.parentEmail
        ) ? this.state.tokenData.parentEmail : ''

        const childFirstName = (
            this.state.tokenData && this.state.tokenData.firstName
        ) ? this.state.tokenData.firstName : ''

        const isWaiting = !!( this.state.waiting || !this.state.tokenData )

        const signupLink = classNameModifier => (
            <div className={ `fab-c-signup-note ${ classNameModifier ? `fab-c-signup-note--${ classNameModifier }` : '' }` }>
                <p>
                    Already have an account?
                    <Link to="/login" href="/login" className="fab-c-signup-note__link">Log in here</Link>
                </p>
                <p>
                    What is Fabled?
                    <a href="/about" className="fab-c-signup-note__link">Find out here</a>
                </p>
            </div>
        )

        return (
            <AuthFormWrapper signupLink={ signupLink }>

                <div className="fab-c-signup-form-wrapper">

                    <Form className="fab-c-signup-form fab-c-signup-form--front" onSubmit={ this.submitSignupForm }>
                        <div className="fab-c-signup-form__header">
                            <h2 className="fab-c-signup-form__heading">Create your parent account</h2>
                            <p className="fab-c-signup-form__sub-heading">See and share the strories { childFirstName } is creating with Fabled…</p>
                        </div>

                        {
                            this.state.error
                                ? (
                                    <UINotice type="error">
                                        Have you already created a Fabled parent&nbsp;
                                        account using this email address?
                                    </UINotice>
                                )
                                : null
                        }

                        <TextInput
                            name="email"
                            id="fab-id-signup-email"
                            label="Email"
                            value={ emailAddress }
                            disabled
                            validator={ validators.email }
                        />
                        <TextInput
                            name="password"
                            id="fab-id-signup-password"
                            label="Pick a password"
                            validator={ validators.password }
                        />
                        <TextInput
                            name="firstName"
                            id="fab-id-signup-first-name"
                            label="What’s your first name?"
                            validator={ validators.firstName }
                        />
                        <TextInput
                            name="parentName"
                            id="fab-id-signup-parent-name"
                            label={ `How does ${ childFirstName } refer to you?` }
                            validator={ validators.parentName }
                        />
                        <CheckboxInput
                            name="terms"
                            id="fab-id-signup-terms"
                            label={ (
                                <span>
                                    Please tick this box to confirm that you agree to our
                                    <a href="/terms" target="_blank">Terms of Service and Privacy Policy</a>
                                </span>
                            ) }
                            validator={ validators.terms }
                            value="yes"
                        />
                        <div className="fab-c-signup-form__input-row">
                            <button type="submit" className="fab-c-signup-form__submit-btn">Create account</button>
                        </div>

                        <Loader isLoading={ isWaiting } />
                    </Form>

                </div>

            </AuthFormWrapper>
        )
    }
}

ParentSignup.propTypes = {
    token: PropTypes.string,
}

ParentSignup.defaultProps = {
    token: null,
}

export default ParentSignup
