import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import styled from 'styled-components'
import actionCreators from 'fabled/actions/actionCreators'
import { getAuthUser } from 'fabled/selectors/users'
import history from 'fabled/history'
import colors from 'fabled/styles/colors'
import media from 'fabled/styles/media'
import AppNav from './AppNav/AppNav'
import HeaderNotice from './HeaderNotice'

class AppHeader extends React.Component {
    constructor() {
        super()
        this.state = {
            headerHeight: null,
        }
        this.handleFabledLogoClick = this.handleFabledLogoClick.bind( this )
        this.setHeaderHeight = this.setHeaderHeight.bind( this )
    }

    componentDidMount() {
        this.setDefaultHeaderNotice()
        this.setHeaderHeight()
        window.addEventListener( 'resize', this.setHeaderHeight )
    }

    componentDidUpdate( prevProps ) {
        this.setDefaultHeaderNotice( prevProps )
        this.setHeaderHeight()
    }

    componentWillUnmount() {
        window.removeEventListener( 'resize', this.setHeaderHeight )
    }

    setHeaderHeight() {
        if (
            this.header
            && this.header.offsetHeight
            && this.header.offsetHeight !== this.state.headerHeight
        ) {
            this.setState( { headerHeight: this.header.offsetHeight } )
        }
    }

    setDefaultHeaderNotice( prevProps ) {
        if ( !prevProps || !prevProps.authUser ) {
            const user = this.props.authUser

            if ( user && user.role === 'child' && !user.parentEmail && !user.isVerified ) {
                this.props.setHeaderNotice( {
                    linkTo: '/signup/parent-email',
                    content: 'Don’t forget to give us a parent or guardian’s email, we’ll need this to share your stories!',
                } )
            }
        }
    }

    handleFabledLogoClick( e ) {
        e.preventDefault()

        if ( this.props.authUser ) {
            switch ( this.props.authUser.role ) {
                case 'parent':
                    history.push( '/parent-dashboard' )
                    break

                case 'child':
                    this.props.modalOpenStoriesList()
                    break

                case 'admin':
                    history.push( '/admin' )
                    break

                default:
                    break
            }
        }
        else {
            history.push( '/signup' )
        }
    }

    render() {
        return (
            <div>
                <StyledAppHeader
                    innerRef={ ( node ) => {
                        this.header = node
                    } }
                >
                    <StyledAppHeaderInner>
                        <StyledLogoLink href="/" onClick={ this.handleFabledLogoClick }>
                            Fabled
                        </StyledLogoLink>
                        <AppNav authUser={ this.props.authUser } theme={ this.props.theme } />
                    </StyledAppHeaderInner>

                    <HeaderNotice notice={ this.props.ui.notice } />
                </StyledAppHeader>
                <StyledHeaderSpacer height={ this.state.headerHeight } />
            </div>
        )
    }
}

AppHeader.propTypes = {
    authUser: PropTypes.shape( {
        role: PropTypes.string,
    } ),
    theme: PropTypes.shape( {
        id: PropTypes.number,
    } ),
    ui: PropTypes.shape( {
        notice: PropTypes.shape( {
            aTag: PropTypes.bool,
            linkTo: PropTypes.string,
            content: PropTypes.node,
        } ),
        layout: PropTypes.shape( {
            headerHeight: PropTypes.number,
        } ),
    } ),
    modalOpenStoriesList: PropTypes.func,
    setHeaderNotice: PropTypes.func.isRequired,
}

AppHeader.defaultProps = {
    authUser: null,
    theme: null,
    ui: {
        notice: null,
    },
    modalOpenStoriesList: null,
}

const defaultHeaderHeight = 64

const StyledAppHeader = styled.div`
    position: fixed;
    z-index: 5;
    top: 0;
    left: 0;
    right: 0;
    background: ${ colors.paper };
`

const StyledAppHeaderInner = styled.div`
    position: relative;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 5%;
    height: ${ defaultHeaderHeight }px;
    border-bottom: 1px solid ${ colors.red };

    ${ media.tablet`
        border-bottom: 0;
    ` }

`

const StyledLogoLink = styled.a`
    position: absolute;
    height: 100%;
    width: 40px;
    background: transparent url(/images/fabled-logo.svg) no-repeat center top;
    background-size: 100%;
    text-indent: -999em;

    @media (min-width: 600px) {
        width: 110px;
        background: transparent url(/images/fabled-logo-full.svg) no-repeat center center;
    }
`

const StyledHeaderSpacer = styled.div`
    height: ${ props => props.height || defaultHeaderHeight }px;
`

const mapStateToProps = state => ( {
    authUser: getAuthUser( state ),
} )

const mapDispatchToProps = {
    setHeaderNotice: actionCreators.ui.notice.set,
    modalOpenStoriesList: actionCreators.ui.modal.openStoriesList,
}

export default connect( mapStateToProps, mapDispatchToProps )( AppHeader )
