import Api from './api'

export const dob = ( value ) => {
    const hasValidMonth = ( dateString ) => {
        const month = dateString.split( '-' )[ 1 ]
        const date = new Date( 1999, month - 1, 1 )
        return date.getMonth() === parseInt( month, 10 ) - 1
    }

    const hasValidDay = ( dateString ) => {
        const [year, month, day] = dateString.split( '-' )
        const date = new Date( year, month - 1, day )
        return date.getDate() === parseInt( day, 10 )
    }

    const hasValidYear = ( dateString ) => {
        const year = dateString.split( '-' )[ 0 ]
        const date = new Date( year, 1, 1 )
        const thisYear = new Date().getFullYear()
        return (
            date.getFullYear() === parseInt( year, 10 )
            && parseInt( year, 10 ) > thisYear - 150
            && parseInt( year, 10 ) <= thisYear
        )
    }

    if ( !value ) {
        return 'Please enter your birthday.'
    }

    if ( !hasValidMonth( value ) ) {
        return 'Please select a month.'
    }

    if ( !hasValidYear( value ) ) {
        return 'Please check the year.'
    }

    if ( !hasValidDay( value ) ) {
        return 'Please check the date.'
    }

    return true
}

export const email = ( value ) => {
    if ( !value.length ) {
        return 'Please enter an email address.'
    }

    return true
}

export const firstName = ( value ) => {
    if ( !value.length ) {
        return 'Please enter your first name.'
    }

    return true
}

export const gender = ( value ) => {
    if ( !value ) {
        return 'Please select one.'
    }

    return true
}

export const parentName = ( value ) => {
    if ( !value.length ) {
        return 'Please fill this in.'
    }

    return true
}

export const password = ( value ) => {
    if ( value.length < 6 ) {
        return 'Your password must be at least 6 letters.'
    }

    return true
}

export const username = ( value ) => {
    if ( !value.length ) {
        return 'Please enter a nickname.'
    }

    const testUsernameResult = ( result ) => {
        if ( result && result.total > 0 && !result.data[ 0 ].isCurrentUser ) {
            return 'Username is already taken.'
        }

        return true
    }

    const query = { auth_username: value.trim().toLowerCase() }

    return Api.service( 'usernames' ).find( { query } )
        .then( testUsernameResult )
        // if the API call fails for any reason, validate anyway
        .catch( () => true )
}

export const terms = ( value ) => {
    if ( !value ) {
        return 'You must agree to our terms and conditions to create a Fabled account.'
    }

    return true
}
