// @flow
import React, { Fragment as F } from 'react'
import type { Node } from 'react'
import styled, { css } from 'styled-components'
import colors from 'fabled/styles/colors'

type Props = {
    color: string,
    position: 'left' | 'right',
    children: Node,
}

const defaultProps = {
    color: 'white',
    position: 'left',
}

const PostAvatar = ( props: Props ) => (
    <F>
        <StyledAvatar color={ props.color } position={ props.position }>
            { props.children }
        </StyledAvatar>
        <StyledPointer color={ props.color } position={ props.position } />
    </F>
)

PostAvatar.defaultProps = defaultProps

const StyledAvatar = styled.div`
    position: absolute;
    top: 1em;
    width: 54px;
    height: 54px;
    border-radius: 100%;
    background: ${ p => colors[ p.color ] };

    ${ p => p.position === 'left' && css`
        left: 0;
    ` }

    ${ p => p.position === 'right' && css`
        right: 0;
    ` }
`

const StyledPointer = styled.div`
    content: ' ';
    position: absolute;
    top: 34px;
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;

    ${ p => p.position === 'left' && css`
        left: 62px;
        border-right: 10px solid ${ pr => colors[ pr.color ] };
    ` }

    ${ p => p.position === 'right' && css`
        right: 62px;
        border-left: 10px solid ${ pr => colors[ pr.color ] };
    ` }
`

export default PostAvatar
