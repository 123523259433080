import { css } from 'styled-components'

const colors = {
    background: 'rgb(199, 234, 225)',
    paper: 'rgb(254, 242, 219)',

    purple: 'rgb(99, 95, 155)',
    purple_dark: 'rgb(51, 51, 102)',
    red: 'rgb(255, 90, 93)',
    green: 'rgb(99, 202, 186)',
    yellow: 'rgb(254, 182, 0)',
    salmon: 'rgb(255, 129, 136)',
    greenBlue: 'rgb(99, 202, 186)',

    typeGrey: 'rgb(87, 87, 86)',
    typeGrey_light: 'rgb(163, 163, 163)',

    veryLightGrey: 'rgb(246, 244, 243)',
    almostBlack: 'rgb(40, 40, 40)',
    white: 'rgb(255, 255, 255)',
}

export const colorsCss = css`
  :root {
    --backgroundRgb: 199, 234, 225;
    --paperRgb: 254, 242, 219;
    --purpleRgb: 99, 95, 155;
    --purple_darkRgb: 51, 51, 102;
    --redRgb: 255, 90, 93;
    --greenRgb: 99, 202, 186;
    --yellowRgb: 254, 182, 0;
    --salmonRgb: 255, 129, 136;
    --greenBlueRgb: 99, 202, 186;
    --kickstarterGreenRgb: 0, 158, 116;
    --midBlueRgb: 100, 177, 188;
    --deepBlueRgb: 0, 58, 112;
    --typeGreyRgb: 87, 87, 86;
    --typeGrey_lightRgb: 163, 163, 163;
    --veryLightGreyRgb: 246, 244, 243;
    --almostBlackRgb: 40, 40, 40;
    --whiteRgb: 255, 255, 255;
    --almostWhiteRgb: 255, 248, 236;

    --background: rgb( var( --backgroundRgb ) );
    --paper: rgb( var( --paperRgb ) );
    --purple: rgb( var( --purpleRgb ) );
    --purple_dark: rgb( var( --purple_darkRgb ) );
    --red: rgb( var( --redRgb ) );
    --green: rgb( var( --greenRgb ) );
    --yellow: rgb( var( --yellowRgb ) );
    --salmon: rgb( var( --salmonRgb ) );
    --greenBlue: rgb( var( --greenBlueRgb ) );
    --kickstarterGreen: rgb( var( --kickstarterGreenRgb ) );
    --midBlue: rgb( var( --midBlueRgb ) );
    --deepBlue: rgb( var( --deepBlueRgb ) );
    --typeGrey: rgb( var( --typeGreyRgb ) );
    --typeGrey_light: rgb( var( --typeGrey_lightRgb ) );
    --veryLightGrey: rgb( var( --veryLightGreyRgb ) );
    --almostBlack: rgb( var( --almostBlackRgb ) );
    --white: rgb( var( --whiteRgb ) );
    --almostWhite: rgb( var( --almostWhiteRgb ) );
  }
`

export default colors
