import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { colors } from '../../../../styles'

const AudioPostWrapper = ( { children, deleteButton } ) => (
    <StyledAudioPostWrapper>
        <StyledAudioPostInner>{ children }</StyledAudioPostInner>
        { deleteButton }
    </StyledAudioPostWrapper>
)

AudioPostWrapper.propTypes = {
    children: PropTypes.node.isRequired,
    deleteButton: PropTypes.element,
}

AudioPostWrapper.defaultProps = {
    deleteButton: null,
}

const StyledAudioPostWrapper = styled.div`
    position: relative;
`

const StyledAudioPostInner = styled.div`
    display: block;
    width: 100%;
    transform: translate3d(0,0,0); // Fix browser issue where fixed elements anywhere on the page disappear
    overflow: hidden;
    background: ${ colors.purple };
`

export default AudioPostWrapper
