/**
 * Same API as Route and PrivateRoute but whereas PrivateRoute redirects
 * non-logged in users to /login, LoginRoute redirects logged in users
 * to the page they were redirected from before logging in or the appropriate
 * landing page.
 */
import React from 'react'
import PropTypes from 'prop-types'
import { Route, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import { getAuthUser } from 'fabled/selectors/users'
import Api from 'fabled/api'
import StoriesLoader from 'fabled/components/loaders/StoriesLoader'

const loginRedirect = redirectTo => (
    <Redirect
        to={ {
            pathname: redirectTo,
            state: { from: null },
        } }
    />
)

const LoginRoute = ( {
    component: Component,
    forceLogout,
    authUser,
    ...routeProps
} ) => (
    <Route
        { ...routeProps }
        render={
            ( props ) => {
                if ( authUser ) {
                    if ( forceLogout ) {
                        Api.logout()
                    }

                    let redirectTo = (
                        props.location.state
                        && props.location.state.from
                    ) ? props.location.state.from.pathname : null

                    if ( redirectTo === '/login' ) {
                        redirectTo = null
                    }

                    if ( redirectTo ) {
                        return loginRedirect( redirectTo )
                    }

                    if ( authUser.role === 'child' ) {
                        return (
                            <StoriesLoader
                                userId={ authUser.id }
                                sort={ { published: 1, updatedAt: -1 } }
                                limit={ 1 }
                                render={
                                    ( { stories } ) => {
                                        if ( !stories.length ) {
                                            return null
                                        }

                                        const story = stories[ 0 ]
                                        const page = story.published ? 'story' : 'create'
                                        const path = `/${ page }/${ story.id }`

                                        return loginRedirect( path )
                                    }
                                }
                            />
                        )
                    }

                    if ( authUser.role === 'parent' ) {
                        redirectTo = '/parent-dashboard'
                    }
                    else if ( authUser.role === 'admin' ) {
                        redirectTo = '/admin'
                    }

                    return loginRedirect( redirectTo )
                }

                return <Component { ...props } />
            }
        }
    />
)

LoginRoute.propTypes = {
    component: PropTypes.func.isRequired,
    forceLogout: PropTypes.bool,

    // from connect
    authUser: PropTypes.shape( {
        id: PropTypes.number,
        role: PropTypes.string,
    } ),

    // from router
    location: PropTypes.shape( {
        pathname: PropTypes.string,
        state: PropTypes.shape( {
            from: PropTypes.shape( {
                pathname: PropTypes.string,
            } ),
        } ),
    } ).isRequired,
}

LoginRoute.defaultProps = {
    forceLogout: false,
    authUser: null,
}

const mapStateToProps = state => ( {
    authUser: getAuthUser( state ),
} )

export default connect( mapStateToProps )( LoginRoute )
