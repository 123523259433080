// @flow
import React from 'react'
import styled from 'styled-components'
import UsersLoader from 'fabled/components/loaders/UsersLoader'
import UserAvatar from 'fabled/components/UserAvatar'

type Props = {
    userIds: Array<number>,
}

const StoryCollaborators = ( props: Props ) => {
    if ( props.userIds.length < 2 ) {
        return null
    }

    return (
        <UsersLoader
            userId={ props.userIds }
            render={
                ( { users } ) => ( users
                    ? users.map( user => (
                        <StyledCollaborator key={ user.id }>
                            <UserAvatar user={ user } size={ 24 } />
                        </StyledCollaborator>
                    ) )
                    : null )
            }
        />
    )
}

const StyledCollaborator = styled.div`
    display: inline-block;
    padding-left: 6px;
`

export default StoryCollaborators
