// @flow
import React from 'react'
import type { Post } from 'fabled/types/Post'
import type { ChildUser } from 'fabled/types/User'
import Chapter from './Chapter'

type Props = {
    chaptersWithVisiblePosts: Array<{
        id: number,
        key: string,
        posts: Array<Post>,
    }>,
    storyAuthors: Array<ChildUser>,
    showPostAuthor: boolean | null,
    isEditable: boolean | null,
    storyStyle: 'editor' | 'viewer',
}

const defaultProps = {
    showPostAuthor: null,
    isEditable: null,
}

const Chapters = ( props: Props ) => (
    <div>
        {
            props.chaptersWithVisiblePosts.map( chapter => (
                <Chapter
                    key={ chapter.key }
                    chapterPosts={ chapter.posts }
                    storyAuthors={ props.storyAuthors }
                    showPostAuthor={ props.showPostAuthor }
                    isEditable={ props.isEditable }
                    storyStyle={ props.storyStyle }
                />
            ) )
        }
    </div>
)

Chapters.defaultProps = defaultProps

export default Chapters
