import React from 'react'
import PropTypes from 'prop-types'
import config from '../config'

const ShareLink = ( { story } ) => {
    const url = `${ config.webclientUrl }/tales/${ story.publicId }`

    return (
        <a className="fab-c-share-link" href={ url }>{ url }</a>
    )
}

ShareLink.propTypes = {
    story: PropTypes.shape( {
        publicId: PropTypes.string,
    } ),
}

ShareLink.defaultProps = {
    story: null,
}

export default ShareLink
