// @flow
import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import GlobalStyles from 'fabled/styles/GlobalStyles'
import PrivateRoute from './routes/PrivateRoute'
import LoginRoute from './routes/LoginRoute'
import StoryMakerRoute from './routes/StoryMakerRoute'
import StoryRoute from './routes/StoryRoute'
import PublicStoryRoute from './routes/PublicStoryRoute'
import CollectionStoryRoute from './routes/CollectionStoryRoute'
import ParentDashboardRoute from './routes/ParentDashboardRoute'
import AdminDashboardRoute from './routes/AdminDashboardRoute'
import UserSettingsRoute from './routes/UserSettingsRoute'
import CollectionRoute from './routes/CollectionRoute'
import SignupRoute from './routes/SignupRoute'
import ParentSignupRoute from './routes/ParentSignupRoute'
import CollaborationApprovalRoute from './routes/CollaborationApprovalRoute'
import Authentication from './Authentication'
import Login from './Login'
import Modals from './Modals/Modals'
import AuthActions from './AuthActions/AuthActions'
import Marketing from './StaticPages/Marketing'
import About from './StaticPages/About'
import Terms from './StaticPages/Terms'
import NotFound from './NotFound'

const App = () => (
    <Authentication>
        <Helmet
            defaultTitle="Fabled — Tell Your Story"
            titleTemplate="%s — Fabled"
        >
            <html lang="en" />
            <meta charSet="utf-8" />
            <meta name="viewport" content="width=device-width, initial-scale=1" />
            <link rel="shortcut icon" type="image/png" href="/favicon.png" />
            <meta name="description" content="Fabled is a place to create & share your stories. It’s like the best notebook ever – it chats back, gives inspiration, and even publishes." />
        </Helmet>
        <GlobalStyles />

        <Switch>
            <Route exact path="/" component={ Marketing } />
            <Route exact path="/about" component={ About } />
            <Route exact path="/terms" component={ Terms } />

            <PrivateRoute exact path="/create/:storyId" component={ StoryMakerRoute } />
            <PrivateRoute exact path="/story/:storyId" component={ StoryRoute } />
            <Route exact path="/tales/:publicStoryId" component={ PublicStoryRoute } />
            <PrivateRoute exact path="/collection/:collectionId/story/:storyId" component={ CollectionStoryRoute } />
            <PrivateRoute exact path="/collection/:collectionId" component={ CollectionRoute } />
            <PrivateRoute roles={ ['parent', 'admin', 'super-admin'] } exact path="/parent-dashboard" component={ ParentDashboardRoute } />
            <PrivateRoute exact path="/admin" component={ AdminDashboardRoute } />
            <PrivateRoute exact path="/user/:userId/settings" component={ UserSettingsRoute } />
            <LoginRoute exact path="/signup" component={ SignupRoute } />
            <PrivateRoute exact path="/signup/parent-email" component={ SignupRoute } />
            <LoginRoute exact path="/parent-signup/:token" forceLogout component={ ParentSignupRoute } />
            <PrivateRoute exact path="/collaborations/:token" component={ CollaborationApprovalRoute } />
            <LoginRoute exact path="/login" component={ Login } />
            <LoginRoute path="/auth" component={ AuthActions } />

            <Route component={ NotFound } />
        </Switch>

        <Modals />
    </Authentication>
)

export default App
