// @flow
import React from 'react'
import type { Story } from 'fabled/types/Story'
import type { Post } from 'fabled/types/Post'
import { colors } from 'fabled/styles'
import inspiration from 'fabled/data/inspiration'
import postFactory from 'fabled/helpers/postFactory'
import InspirationFactory from 'fabled/helpers/InspirationFactory'
import { INSPIRATION } from 'fabled/postTypes'
import { eventTracker } from 'fabled/eventTracker'
import ActionButton from 'fabled/components/ActionButton'

const inspirationFactory = new InspirationFactory( inspiration )

type Props = {
    story: Story,
    posts: Array<Post>,
    currentChapter: number,
    postReplaceOrAdd: ( post: Post ) => void,
}

const InspirationBtn = ( {
    currentChapter, story, posts, postReplaceOrAdd,
}: Props ) => {
    const newInspirationPost = () => {
        const inspirationContent = inspirationFactory.next()
        const inspirationPost = postFactory( {
            storyId: story.id,
            chapterId: currentChapter,
            postType: INSPIRATION,
            content: inspirationContent,
        }, posts )

        if ( posts.length && posts[ posts.length - 1 ].postType === 'inspiration' ) {
            inspirationPost.uuid = posts[ posts.length - 1 ].uuid
            inspirationPost.position = posts[ posts.length - 1 ].position
        }

        postReplaceOrAdd( inspirationPost )

        eventTracker( {
            category: 'Story Writer',
            action: 'Requested inspiration',
            label: inspirationContent.text,
        } )
    }

    const handleClick = ( e ) => {
        if ( e.key && !['Enter', ' '].includes( e.key ) ) {
            return
        }

        e.preventDefault()
        newInspirationPost()
    }

    return (
        <ActionButton
            label="Inspiration?"
            color={ colors.purple }
            textColor="white"
            icon="/images/i-inspiration.svg"
            iconSize={ 35 }
            position="left"
            onClick={ handleClick }
            onKeyDown={ handleClick }
        />
    )
}

export default InspirationBtn
