import sortBy from 'lodash/sortBy'
import { createSelector } from 'reselect'
import { return2ndArgAsArray } from './utils'

export const getNotifications = state => state.notifications.data

export const getNotificationsAsArray = createSelector(
    getNotifications,
    notifications => Object.values( notifications ),
)

export const getNotificationsByUserId = createSelector(
    getNotificationsAsArray,
    return2ndArgAsArray,
    ( notifications, userIds ) => {
        const filteredNotifications = notifications
            .filter( n => userIds.includes( n.userId ) )
        return sortBy( filteredNotifications, ['createdAt'] ).reverse()
    },
)

export const getNotificationsCount = state => state.notifications.count
