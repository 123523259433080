import React, { Fragment as F } from 'react'
import PropTypes from 'prop-types'
import AppHeader from 'fabled/components/AppHeader/AppHeader'
import Collection from 'fabled/components/Collection/Collection'
import ScrollToTopOnMount from 'fabled/components/ScrollToTopOnMount'

const PublicStoryRoute = ( { match: { params: { collectionId } } } ) => (
    <F>
        <ScrollToTopOnMount />
        <AppHeader />
        <Collection
            key={ collectionId } // recreate the component when storyId changes
            collectionId={ parseInt( collectionId, 10 ) }
        />
    </F>
)

PublicStoryRoute.propTypes = {
    match: PropTypes.shape( {
        params: PropTypes.shape( {
            collectionId: PropTypes.string.isRequired,
        } ).isRequired,
    } ).isRequired,
}

export default PublicStoryRoute
