/* eslint-disable react/no-array-index-key */
import React from 'react'

export function newLines( text ) {
    return text.split( '\n' ).map( ( item, i ) => ( ( i === 0 ) ? item : [<br key={ i } />, item] ) )
}

export function parasAndNewlines( text ) {
    return text.split( /\n([ \t]*\n)+/ ).map( ( para, i ) => <p key={ i }>{ newLines( para ) }</p> )
}

export function slugify( string ) {
    return string.toLowerCase().replace( /[^\w ]+/g, '' ).replace( / +/g, '-' )
}
