// @flow
import React from 'react'
import type { Story } from 'fabled/types/Story'
import StoryTile from './StoryTile'

type Props = {
    stories: Array<Story>,
    href: ( story: Story ) => string,
}

const defaultProps = {
    href: story => `/story/${ story.id }`,
}

const StoriesGrid = ( props: Props ) => {
    if ( !props.stories.length ) {
        return <p>No stories.</p>
    }

    return (
        <div className="fab-c-stories-grid">
            {
                props.stories.map( ( story, i ) => {
                    const href = props.href( story )

                    return (
                        <div className="fab-c-stories-grid__item" key={ story.id }>
                            <StoryTile story={ story } href={ href } index={ i } />
                        </div>
                    )
                } )
            }
        </div>
    )
}

StoriesGrid.defaultProps = defaultProps

export default StoriesGrid
