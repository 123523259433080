import React from 'react'
import PropTypes from 'prop-types'
import cloneDeep from 'lodash/cloneDeep'

const DataLoaderGroup = class extends React.Component {
    constructor( props ) {
        super( props )
        this.loaders = cloneDeep( props.loaders )
        this.loaders.push( {
            key: 'render',
            loader: props.render,
        } )
        this.loaders.reverse()

        this.reducedLoaders = this.loaders.reduce(
            ( accumulatedLoaders, loader, i, loadersArray ) => (
                ( accumulatedProps = {}, parentLoader ) => (
                    ( p = {} ) => {
                        let mappedProps

                        if ( parentLoader ) {
                            mappedProps = parentLoader.mapper
                                ? parentLoader.mapper( p, accumulatedProps )
                                : { ...accumulatedProps, ...p }

                            const loaderState = mappedProps.loaderState || p.loaderState
                            /* eslint-disable-next-line no-param-reassign */
                            parentLoader.state = loaderState
                            mappedProps.loaderState = loaderState
                        }
                        else {
                            mappedProps = p
                        }

                        const loadersOnly = loadersArray.filter( l => l.key !== 'render' )
                        mappedProps.loaderStates = DataLoaderGroup.getLoaderState( loadersOnly )

                        if ( accumulatedLoaders ) {
                            if (
                                loader.requiredProps
                                && !loader.requiredProps.find( rp => !!mappedProps[ rp ] )
                            ) {
                                return accumulatedLoaders(
                                    mappedProps,
                                    loader,
                                )( mappedProps )
                            }

                            const wrappedLoader = accumulatedLoaders( mappedProps, loader )
                            return loader.loader( wrappedLoader, mappedProps )
                        }

                        return loader.loader( mappedProps )
                    }
                )
            ),
            false,
        )
    }

    static getLoaderState( loadersArray ) {
        return {
            all: loadersArray.reduce(
                ( loaderState, loader ) => {
                    if ( !loader.state ) {
                        return {
                            isLoading: loaderState.isLoading,
                            isComplete: false,
                            error: loaderState.error,
                        }
                    }

                    return {
                        isLoading: loader.state.isLoading ? true : loaderState.isLoading,
                        isComplete: !loader.state.isComplete ? false : loaderState.isComplete,
                        error: loader.state.error ? loader.state.error : loaderState.error,
                    }
                },
                {
                    isLoading: false,
                    isComplete: true,
                    error: null,
                },
            ),
            loaders: loadersArray.reduce(
                ( loaderStates, loader, i ) => ( {
                    ...loaderStates,
                    [ `${ loader.key } - ${ i }` ]: loader.state,
                } ),
                {},
            ),
        }
    }

    render() {
        return this.reducedLoaders()( {} )
    }
}

DataLoaderGroup.propTypes = {
    loaders: PropTypes.arrayOf( PropTypes.shape( {
        key: PropTypes.string.isRequired,
        loader: PropTypes.func.isRequired,
        mapper: PropTypes.func,
        requiredProps: PropTypes.arrayOf( PropTypes.string ),
    } ) ).isRequired,
    render: PropTypes.func.isRequired,
}

export default DataLoaderGroup
