// NOTE: don’t use Flow here, doesn’t work for a factory like this

// import type { Post, PostType } from 'fabled/types/Post'
import * as postTypes from 'fabled/postTypes'

const findHigestPostPositionNumber = ( posts ) => {
    if ( !posts || !posts.length ) {
        return 0
    }

    return Math.max( ...posts.map( post => post.position ) )
}

const isPostTypeValid = postType => Object.values( postTypes ).includes( postType )

// type PostFactoryProps = {
//     storyId: number,
//     chapterId: number,
//     userId?: number,
//     postType: PostType,
//     content: {
//         [key: string]: mixed,
//     }
// }

const postFactory = (
    {
        storyId, chapterId, userId, postType, content,
    },
    posts,
) => {
    if ( !isPostTypeValid( postType ) ) {
        throw new Error( `Invalid postType property: ${ postType }` )
    }

    const postContent = content || {}

    return {
        storyId,
        chapterId,
        userId,
        postType,
        content: postContent,
        position: findHigestPostPositionNumber( posts ) + 1,
    }
}

export default postFactory
