import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { darken } from 'polished'
import { Link } from 'react-router-dom'
import { colors } from 'fabled/styles'

const HeaderNotice = ( { notice } ) => {
    if ( notice && notice.content ) {
        if ( notice.linkTo ) {
            if ( notice.aTag ) {
                return (
                    <StyledHeaderNoticeA href={ notice.linkTo }>
                        <StyledHeaderNoticeText>{ notice.content }</StyledHeaderNoticeText>
                    </StyledHeaderNoticeA>
                )
            }

            return (
                <StyledHeaderNoticeLink to={ notice.linkTo } href={ notice.linkTo }>
                    <StyledHeaderNoticeText>{ notice.content }</StyledHeaderNoticeText>
                </StyledHeaderNoticeLink>
            )
        }

        return (
            <StyledHeaderNoticeDiv>
                <StyledHeaderNoticeText>{ notice.content }</StyledHeaderNoticeText>
            </StyledHeaderNoticeDiv>
        )
    }

    return null
}

HeaderNotice.propTypes = {
    notice: PropTypes.shape( {
        aTag: PropTypes.bool,
        linkTo: PropTypes.string,
        content: PropTypes.node,
    } ),
}

HeaderNotice.defaultProps = {
    notice: null,
}

const StyledHeaderNoticeCss = css`
    display: block;
    background: ${ colors.red };
    color: white;
    text-decoration: none;
    transition: background-color .3s ease-out;
`

const StyledHeaderNoticeDiv = styled.div`
    ${ StyledHeaderNoticeCss }
`

const StyledHeaderNoticeA = styled.a`
    ${ StyledHeaderNoticeCss }
    &:hover {
        background: ${ darken( 0.05, colors.red ) };
    }
`

const StyledHeaderNoticeLink = styled( Link )`
    ${ StyledHeaderNoticeCss }
    &:hover {
        background: ${ darken( 0.05, colors.red ) };
    }
`

const StyledHeaderNoticeText = styled.p`
    max-width: 1200px;
    margin: 0 auto;
    padding: 1em 5%;
    line-height: 1.3;
    text-align: center;
`

export default HeaderNotice
