import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { getAuthUser } from 'fabled/selectors/users'
import actionCreators from 'fabled/actions/actionCreators'
import history from 'fabled/history'
import Api from 'fabled/api'
import { eventTracker } from 'fabled/eventTracker'
import Button from './Button'

const StartStoryButton = ( props ) => {
    const handleStartNewStory = ( e ) => {
        e.preventDefault()
        Api.service( 'myStories' ).create( { userId: props.authUser.id } )
            .then( ( newStory ) => {
                eventTracker( {
                    category: 'Stories',
                    action: 'Create a new story',
                } )
                props.modalClose()
                history.push( `/create/${ newStory.id }` )
            } )
    }

    const buttonProps = {}
    let buttonText = ''

    if ( props.authUser && props.authUser.role === 'child' ) {
        buttonProps.onClick = handleStartNewStory
        buttonText = 'Start a new story'
    }
    else if ( props.authUser && props.authUser.role === 'parent' ) {
        buttonProps.to = '/parent-dashboard'
        buttonText = 'My dashboard'
    }
    else if ( props.authUser && props.authUser.role === 'admin' ) {
        buttonProps.to = '/admin'
        buttonText = 'Admin dashboard'
    }
    else {
        buttonProps.to = '/signup'
        buttonText = 'Sign up and tell your story…'
    }

    return (
        <Button
            small
            inline
            primary
            { ...buttonProps }
        >
            { buttonText }
        </Button>
    )
}

StartStoryButton.propTypes = {
    authUser: PropTypes.shape( {
        id: PropTypes.number,
        role: PropTypes.string,
    } ),
    modalClose: PropTypes.func,
}

StartStoryButton.defaultProps = {
    authUser: null,
    modalClose: null,
}

const mapStateToProps = state => ( {
    authUser: getAuthUser( state ),
} )

const mapDispatchToProps = {
    modalClose: actionCreators.ui.modal.close,
}

export default connect( mapStateToProps, mapDispatchToProps )( StartStoryButton )
