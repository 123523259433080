import React from 'react'
import PropTypes from 'prop-types'
import DropdownMenu from 'fabled/components/DropdownMenu'
import AppNavItem from './AppNavItem'

const ContentNav = ( props ) => {
    const menuItems = []

    menuItems.push( {
        text: 'About Fabled',
        href: '/about',
    } )
    menuItems.push( {
        text: 'Privacy, T&Cs',
        href: '/terms',
    } )

    const contentMenu = <DropdownMenu menuItems={ menuItems } tip="right" marginTop="66" />

    return (
        <AppNavItem
            icon={ { url: '/images/content-nav-icon.svg', altText: 'Main Menu' } }
            isSubNavOpen={ props.isSubNavOpen }
            subNav={ contentMenu }
            onClick={ props.onClick }
        />
    )
}

ContentNav.propTypes = {
    theme: PropTypes.shape( {
        id: PropTypes.number.isRequired,
    } ),
    isSubNavOpen: PropTypes.bool,
    onClick: PropTypes.func.isRequired,
}

ContentNav.defaultProps = {
    theme: null,
    isSubNavOpen: false,
}

export default ContentNav
