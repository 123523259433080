import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import actionCreators from '../../actions/actionCreators'
import { getThemesById, getCurrentTheme } from '../../selectors/themes'
import DataLoader from './DataLoader'

const ThemesLoader = ( props ) => {
    const query = { active: true }

    if ( props.themeId ) {
        query.id = props.themeId
    }
    else {
        query.$sort = { id: -1 }
        query.$limit = 1
    }

    const themesSetCurrent = ( data ) => {
        if ( !props.themeId ) {
            props.themesSetCurrent( data )
        }
    }

    const onSuccess = ( response ) => {
        if ( response && response.total ) {
            props.themesReplaceOrAdd( response.data )
            themesSetCurrent( response.data[ 0 ].id )
        }
    }

    const listener = {
        events: ['created', 'updated'],
        key: 'themesLoader',
        listener: ( serviceName, eventName, message ) => {
            props.themesReplaceOrAdd( message )
            themesSetCurrent( message.id )
        },
    }

    const render = propsFromDataLoader => props.render( {
        themes: props.themes,
        ...propsFromDataLoader,
    } )

    return (
        <DataLoader
            service="themes"
            query={ query }
            listener={ listener }
            onSuccess={ onSuccess }
            render={ render }
            waitForAuth={ props.waitForAuth }
        />
    )
}

ThemesLoader.propTypes = {
    themeId: PropTypes.number,
    waitForAuth: PropTypes.bool,
    render: PropTypes.func.isRequired,

    // from connect
    themes: PropTypes.arrayOf( PropTypes.shape( {
        title: PropTypes.string,
    } ) ),
    themesSetCurrent: PropTypes.func.isRequired,
    themesReplaceOrAdd: PropTypes.func.isRequired,
}

ThemesLoader.defaultProps = {
    themeId: null,
    waitForAuth: false,
    themes: [],
}

const mapStateToProps = ( state, props ) => {
    let themes = []

    if ( props.themeId ) {
        themes = getThemesById( state, props.themeId )
    }
    else {
        themes.push( getCurrentTheme( state ) )
    }

    return { themes }
}

const mapDispatchToProps = {
    themesSetCurrent: actionCreators.themes.setCurrent,
    themesReplaceOrAdd: actionCreators.themes.data.replaceOrAdd,
}

export default connect( mapStateToProps, mapDispatchToProps )( ThemesLoader )
