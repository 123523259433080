import React from 'react'
import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'

const getLink = ( { to, children } ) => {
    if ( /^https?:\/\//i.test( to ) ) {
        return (
            <a href={ to }>
                { children }
                <ButtonArrow />
            </a>
        )
    }

    return (
        <Link to={ to }>
            { children }
            <ButtonArrow />
        </Link>
    )
}

const ButtonArrow = () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26.7 13.5">
        <rect y="6.3" width="25.7" height="1"/>
        <polygon points="19.9,13.5 19.2,12.8 25.3,6.8 19.2,0.7 19.9,0 26.7,6.8"/>
    </svg>
)

const HeroButton = ( {
    to,
    solid,
    small = false,
    color = 'deepBlue',
    hoverColor = 'white',
    children,
} ) => (
    <StyledHeroButton
        color={ color }
        hoverColor={ hoverColor }
        solid={ solid }
        small={ small }
    >
        { getLink( { to, children } ) }
    </StyledHeroButton>
)

const StyledHeroButton = styled.div`
    a {
        position: relative;
        display: inline-block;
        white-space: nowrap;
        font-size: ${ p => p.small ? '18px' : '22px' };
        font-weight: bold;
        line-height: 1.2em;
        color: var( --${ p => p.color } );
        text-decoration: none;
        transition: color .3s ease-out;

        svg {
            display: inline-block;
            font-size: 0.6em;
            height: 1.2em;
            padding-left: 0.6em;
            vertical-align: middle;
            transform: translateY( -0.1em );
            transition: padding-left .3s linear;

            rect,
            polygon {
                fill: var( --${ p => p.color } );
                transition: fill .3s ease-out;
            }
        }

        &:hover {
            color: var( --${ p => p.hoverColor } );

            svg {
                padding-left: 1.2em;

                rect,
                polygon {
                    fill: var( --${ p => p.hoverColor } );
                }
            }
        }
    }
`

export default HeroButton