import styled, { css } from 'styled-components'

const NoticeBox = styled.div`
    padding: 1rem;
    margin-bottom: 1rem;
    background: var( --green );
    color: white;

    a {
        color: white;
    }

    p:last-child {
        padding-bottom: 0;
    }
`

export default NoticeBox
