import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import sortBy from 'lodash/sortBy'
import isArray from 'lodash/isArray'
import actionCreators from 'fabled/actions/actionCreators'
import {
    getStoriesById,
    getStoriesByPublicId,
    getStoriesByUserId,
    getStoriesByUserIds,
    getStoriesByThemeIdApproved,
} from 'fabled/selectors/stories'
import DataLoader from './DataLoader'

const queryBuilder = ( props ) => {
    let query
    let method = 'find'

    if ( props.storyId ) {
        query = props.storyId
        method = 'get'
    }
    else if ( props.publicStoryId ) {
        query = props.publicStoryId
        method = 'get'
    }
    else if ( props.userId ) {
        query = { userId: props.userId }
    }
    else if ( props.userIds ) {
        query = { userIds: isArray( props.userIds ) ? props.userIds : [props.userIds] }
    }
    else if ( props.themeId ) {
        query = { themeId: props.themeId }
    }

    if ( !query ) {
        throw new Error( 'No valid query props.' )
    }

    if ( Object.keys( query ).length ) {
        if ( props.limit ) {
            query.$limit = props.limit
        }

        if ( props.sort ) {
            query.$sort = props.sort
        }
    }

    return { query, method }
}

const StoriesLoader = ( props ) => {
    const { query, method } = queryBuilder( {
        storyId: props.storyId,
        publicStoryId: props.publicStoryId,
        userId: props.userId,
        userIds: props.userIds,
        themeId: props.themeId,
        limit: props.limit,
        sort: props.sort,
    } )

    const onSuccess = ( response ) => {
        if ( response && response.total ) {
            props.storiesReplaceOrAdd( response.data )
        }
        else if ( response ) {
            props.storiesReplaceOrAdd( response )
        }

        if ( props.onSuccess ) {
            props.onSuccess( response.data )
        }
    }

    const render = propsFromDataLoader => props.render( {
        stories: props.stories,
        ...propsFromDataLoader,
    } )

    return (
        <DataLoader
            service={ props.service }
            method={ method }
            query={ query }
            onSuccess={ onSuccess }
            render={ render }
            waitForAuth={ props.waitForAuth }
        />
    )
}

StoriesLoader.propTypes = {
    service: PropTypes.oneOfType( [
        PropTypes.func,
        PropTypes.oneOf( [
            'myStories',
            'storiesForCollaborator',
            'storiesForParent',
            'storiesForAdmin',
            'publicStories',
            'storiesInTheme',
        ] ),
    ] ),
    sort: PropTypes.object, // eslint-disable-line react/forbid-prop-types
    storyId: PropTypes.oneOfType( [
        PropTypes.number,
        PropTypes.arrayOf( PropTypes.number ),
    ] ),
    userId: PropTypes.oneOfType( [
        PropTypes.number,
        PropTypes.arrayOf( PropTypes.number ),
    ] ),
    userIds: PropTypes.oneOfType( [
        PropTypes.number,
        PropTypes.arrayOf( PropTypes.number ),
    ] ),
    themeId: PropTypes.oneOfType( [
        PropTypes.number,
        PropTypes.arrayOf( PropTypes.number ),
    ] ),
    publicStoryId: PropTypes.oneOfType( [
        PropTypes.string,
        PropTypes.arrayOf( PropTypes.string ),
    ] ),
    limit: PropTypes.number,
    waitForAuth: PropTypes.bool,
    render: PropTypes.func.isRequired,
    onSuccess: PropTypes.func,
    // eslint-disable-next-line react/no-unused-prop-types
    selector: PropTypes.func,

    // from connect
    storiesReplaceOrAdd: PropTypes.func.isRequired,
    stories: PropTypes.arrayOf( PropTypes.shape( {
        id: PropTypes.number,
    } ) ),
}

StoriesLoader.defaultProps = {
    service: 'storiesForCollaborator',
    sort: null,
    storyId: null,
    userId: null,
    userIds: null,
    themeId: null,
    publicStoryId: null,
    limit: null,
    waitForAuth: false,
    onSuccess: null,
    selector: null,
    stories: [],
}

const mapStateToProps = ( state, props ) => {
    let stories = []

    if ( props.selector ) {
        stories = props.selector( state, props )
    }
    else if ( props.userId ) {
        stories = getStoriesByUserId( state, props.userId )
    }
    else if ( props.userIds ) {
        stories = getStoriesByUserIds( state, props.userIds )
    }
    else if ( props.storyId ) {
        stories = getStoriesById( state, props.storyId )
    }
    else if ( props.publicStoryId ) {
        stories = getStoriesByPublicId( state, props.publicStoryId )
    }
    else if ( props.themeId ) {
        stories = getStoriesByThemeIdApproved( state, props.themeId )
    }

    stories = sortBy( stories, ['updatedAt', 'createdAt'] )
    stories.reverse()
    return { stories }
}

const mapDispatchToProps = {
    storiesReplaceOrAdd: actionCreators.stories.data.replaceOrAdd,
}

export default connect( mapStateToProps, mapDispatchToProps )( StoriesLoader )
