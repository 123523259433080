import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import actionCreators from 'fabled/actions/actionCreators'
import { getAuthUser, getParentOfChildWithId } from 'fabled/selectors/users'
import { getStoryById } from 'fabled/selectors/stories'
import Api from 'fabled/api'
import { eventTracker } from 'fabled/eventTracker'
import FormattedContent from 'fabled/components/FormattedContent'
import RadioInput from 'fabled/components/FormInputs/RadioInput'
import Modal from './Modal'

const SetCollectionModal = ( props ) => {
    const userIsAdmin = ['admin', 'super-admin'].includes( props.authUser.role )

    const approval = ( value ) => {
        const approvalValue = value === 'true' ? props.story.theme.id : 0

        if (
            approvalValue === props.story.themeApprovedByParent
            && approvalValue === props.story.themeApprovedByAdmin
        ) {
            return null
        }

        const payload = {
            id: props.story.id,
            uuid: props.story.uuid,
            themeId: props.story.theme.id,
        }

        if (
            props.storyAuthorParentUser
            && props.storyAuthorParentUser.id === props.authUser.id
        ) {
            payload.themeApprovedByParent = approvalValue
        }

        if ( userIsAdmin ) {
            payload.themeApprovedByAdmin = approvalValue
        }

        const serviceName = userIsAdmin ? 'storiesForAdmin' : 'storiesForParent'

        return Api.service( serviceName ).patch( payload.id, payload )
            .then( ( response ) => {
                props.storiesReplaceOrAdd( response )
                eventTracker( {
                    category: 'Collection Modal',
                    action: approvalValue
                        ? `Added story to collection: ${ props.story.theme.title }`
                        : `Removed story from collection: ${ props.story.theme.title }`,
                } )
                props.modalClose()
            } )
    }

    let value = null

    if ( userIsAdmin ) {
        value = props.story.themeApprovedByAdmin === props.story.theme.id ? 'true' : 'false'
    }
    else if ( props.story.themeApprovedByParent === null ) {
        value = null
    }
    else {
        value = props.story.themeApprovedByParent === props.story.theme.id ? 'true' : 'false'
    }

    if ( !props.story.theme ) {
        return null
    }

    return (
        <Modal title={ `Add to the collection: ${ props.story.theme.title }?` }>
            <FormattedContent>
                <p>
                    Stories in Fabled collections are visible to anyone with a Fabled account. <a href={ `/collection/${ props.story.theme.id }` } target="_blank" rel="noopener noreferrer">See the collection</a>.
                </p>
            </FormattedContent>

            <RadioInput
                inputClassName="fab-c-signup-form__text-input"
                labelClassName="fab-c-signup-form__input-label"
                wrapperClassName="fab-c-signup-form__input-row"
                name="in-collection"
                id="fab-id-in-collection"
                label={ `Add to collection: ${ props.story.theme.title }?` }
                options={ [
                    { value: 'true', label: 'Yes' },
                    { value: 'false', label: 'No' },
                ] }
                value={ value }
                onChange={ approval }
            />
        </Modal>
    )
}

SetCollectionModal.propTypes = {
    // eslint-disable-next-line react/no-unused-prop-types
    storyId: PropTypes.number.isRequired,
    modalClose: PropTypes.func.isRequired,

    // from connect
    authUser: PropTypes.shape( {
        id: PropTypes.number,
        role: PropTypes.string,
    } ).isRequired,
    story: PropTypes.shape( {
        id: PropTypes.number,
        uuid: PropTypes.string,
        userId: PropTypes.number,
        theme: PropTypes.shape( {
            id: PropTypes.number,
            title: PropTypes.string,
        } ),
        themeApprovedByParent: PropTypes.number,
        themeApprovedByAdmin: PropTypes.number,
    } ).isRequired,
    storyAuthorParentUser: PropTypes.shape( {
        id: PropTypes.number,
    } ),
    storiesReplaceOrAdd: PropTypes.func.isRequired,
}

SetCollectionModal.defaultProps = {
    storyAuthorParentUser: null,
}

const mapStateToProps = ( state, { storyId } ) => {
    const story = getStoryById( state, storyId )

    return {
        story,
        storyAuthorParentUser: getParentOfChildWithId( state, story.userId ),
        authUser: getAuthUser( state ),
    }
}

const mapDispatchToProps = {
    storiesReplaceOrAdd: actionCreators.stories.data.replaceOrAdd,
}

export default connect( mapStateToProps, mapDispatchToProps )( SetCollectionModal )
