import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { rgba } from 'polished'
import { colors } from '../styles'
import Icon from './Icon'
import icons from '../icons'

const CloseButton = ( { onClick } ) => (
    <StyledCloseButton onClick={ onClick }>
        <Icon icon={ icons.cross } />
    </StyledCloseButton>
)

CloseButton.propTypes = {
    onClick: PropTypes.func.isRequired,
}

const StyledCloseButton = styled.button`
    position: absolute;
    top: 1em;
    right: 1em;
    background-color: transparent;
    border: 1px solid ${ colors.red };
    fill: ${ colors.red };
    width: 40px;
    height: 40px;
    border-radius: 100%;

    &:hover {
        background-color: ${ rgba( colors.red, 0.2 ) };
    }

    svg {
        position: absolute;
        width: 12px;
        height: 12px;
        top: 50%;
        margin-top: -6px;
        left: 50%;
        margin-left: -6px;
    }
`

export default CloseButton
