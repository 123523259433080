// @flow
import React from 'react'
import type { Story } from 'fabled/types/Story'
import type { Post } from 'fabled/types/Post'
import type { AuthUser, ChildUser } from 'fabled/types/User'
import { connect } from 'react-redux'
import actionCreators from 'fabled/actions/actionCreators'
import { getAuthUser } from 'fabled/selectors/users'
import { getUiModalIsPublish, getUiAudioIsRecording } from 'fabled/selectors/ui'
import styled, { css } from 'styled-components'
import media, { minWidth } from 'fabled/styles/media'
import variables from 'fabled/styles/variables'
import PostImageUploader from './PostImageUploader'
import PostAudioUploader from './PostAudioUploader'
import PostTextArea from './PostTextArea'
import AudioRecorder from './AudioRecorder'
import StoryActionButtons from './StoryActionButtons/StoryActionButtons'

type Props = {
    story: Story,
    storyAuthors: Array<ChildUser>,
    posts: Array<Post>,
    currentChapter: number,
    disabled: boolean,

    // from connect
    authUser: AuthUser,
    publishModalIsOpen: boolean,
    audioIsRecording: boolean,
    postReplaceOrAdd: ( post: Post ) => void,
}

const defaultProps = {
    disabled: false,
}

const StoryInputUI = ( props: Props ) => {
    const propsForNewPosts = {
        authUser: props.authUser,
        story: props.story,
        posts: props.posts,
        currentChapter: props.currentChapter,
        postReplaceOrAdd: props.postReplaceOrAdd,
    }

    return (
        <StyledStoryInputUI disabled={ !!props.disabled }>
            <PostTextArea
                { ...propsForNewPosts }
                activeAuthor={ props.storyAuthors.find( u => u.id === props.story.activeUserId ) }
            />
            <PostImageUploader { ...propsForNewPosts } />
            <PostAudioUploader { ...propsForNewPosts } />
            <AudioRecorder { ...propsForNewPosts } />
            <StoryActionButtons
                { ...propsForNewPosts }
                isCollaborative={ props.storyAuthors.length > 1 }
            />
        </StyledStoryInputUI>
    )
}

StoryInputUI.defaultProps = defaultProps

const mapStateToProps = state => ( {
    authUser: getAuthUser( state ),
    publishModalIsOpen: getUiModalIsPublish( state ),
    audioIsRecording: getUiAudioIsRecording( state ),
} )

const mapDispatchToProps = {
    postReplaceOrAdd: actionCreators.posts.data.replaceOrAdd,
}

const storyInputBreakpoint = minWidth( variables.postInputButton.breakpoint )

const StyledStoryInputUI = styled.div`
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;

    ${ media.tablet`
        position: sticky;
    ` }

    --post-input-btn-size: ${ variables.postInputButton.size.small }px;

    ${ storyInputBreakpoint`
        --post-input-btn-size: ${ variables.postInputButton.size.large }px;
    ` }

    ${ p => p.disabled && css`
        filter: grayscale( 100% );

        > * {
            pointer-events: none;
        }
    ` }
`

export default connect( mapStateToProps, mapDispatchToProps )( StoryInputUI )
