import React from 'react'
import PropTypes from 'prop-types'
import isEqual from 'lodash/isEqual'
import TextInput from './FormInputs/TextInput'
import RadioInput from './FormInputs/RadioInput'
import CheckboxInput from './FormInputs/CheckboxInput'
import DOBInput from './FormInputs/DOBInput'
import AvatarSelectInput from './FormInputs/AvatarSelectInput/AvatarSelectInput'

class Form extends React.Component {
    constructor( props ) {
        super( props )
        this.state = {
            validated: null,
            validatedInputs: {},
            fields: {},
        }
        this.formValidator = this.formValidator.bind( this )
        this.handleSubmit = this.handleSubmit.bind( this )
    }

    componentDidUpdate( prevProps, prevState ) {
        if ( !isEqual( prevState.validatedInputs, this.state.validatedInputs ) ) {
            this.setValidated()
        }
    }

    setValidated() {
        Object.entries( this.state.validatedInputs ).forEach( ( validatedInput ) => {
            if ( !validatedInput ) {
                this.setState( { validated: false } )
            }
        } )

        this.setState( { validated: true } )
    }

    getFields( key ) {
        if ( key ) {
            return this.state.fields[ key ]
        }

        return this.state.fields
    }

    formValidator( inputId, validated ) {
        this.setState( { validatedInputs: { [ inputId ]: validated } } )
    }

    handleSubmit( e ) {
        e.preventDefault()

        if ( !this.state.validated ) {
            return
        }

        if ( this.props.onSubmit ) {
            this.props.onSubmit( e, this )
        }
    }

    mapPropsToChildInputs( children, propsForChildInputs ) {
        const textInput = <TextInput id="" name="" label="" />
        const radioInput = <RadioInput id="" name="" label="" options={ [] } />
        const checkboxInput = <CheckboxInput />
        const dobInput = <DOBInput id="" name="" label="" />
        const avatarSelectInput = <AvatarSelectInput id="" name="" label="" />

        const inputTypeNames = [
            textInput.type.name,
            radioInput.type.name,
            checkboxInput.type.name,
            dobInput.type.name,
            avatarSelectInput.type.name,
        ]

        return React.Children.map( children, ( child, i ) => {
            if ( !React.isValidElement( child ) ) {
                return child
            }

            let props = {
                key: i,
                children: this.mapPropsToChildInputs( child.props.children, propsForChildInputs ),
            }

            if ( child && child.type && inputTypeNames.includes( child.type.name ) ) {
                props = { ...props, ...propsForChildInputs }
            }

            return React.cloneElement( child, props )
        } )
    }

    render() {
        const propsForChildInputs = {
            formValidator: this.formValidator,
            setFormState: ( name, value ) => {
                const fields = { ...this.getFields() }
                fields[ name ] = value
                this.setState( { fields } )
            },
        }

        const childrenWithProps = this.mapPropsToChildInputs(
            this.props.children,
            propsForChildInputs,
        )

        return (
            <form className={ this.props.className } onSubmit={ this.handleSubmit }>
                { childrenWithProps }
            </form>
        )
    }
}

Form.propTypes = {
    onSubmit: PropTypes.func,
    children: PropTypes.arrayOf( PropTypes.oneOfType( [
        PropTypes.arrayOf( PropTypes.node ),
        PropTypes.node,
    ] ) ),
    className: PropTypes.string,
}

Form.defaultProps = {
    onSubmit: null,
    children: null,
    className: '',
}

export default Form
