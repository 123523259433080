import { handleActions, combineActions } from 'redux-actions'
import { startStop } from './helpers'
import actionCreators from '../actions/actionCreators'
import modalTypes from '../components/Modals/modalTypes'

const ui = handleActions(
    {
        [
        combineActions(
            actionCreators.ui.modal.set,
            actionCreators.ui.modal.close,
            actionCreators.ui.modal.openStoriesList,
            actionCreators.ui.modal.openShare,
            actionCreators.ui.modal.openSetCollection,
            actionCreators.ui.modal.openOnboard,
            actionCreators.ui.modal.openDialog,
            actionCreators.ui.modal.openPublish,
            actionCreators.ui.modal.openConfirmDeletePost,
            actionCreators.ui.modal.openStoryPrompt,
            actionCreators.ui.modal.openTheme,
            actionCreators.ui.modal.openNotifications,
            actionCreators.ui.modal.openRequestCollaboration,
            actionCreators.ui.modal.openPassStory,
        )
        ]( state, action ) {
            const newState = Object.assign( {}, state )

            if (
                !Object.values( modalTypes ).includes( action.payload.id )
                && action.payload.id !== null
            ) {
                throw new Error( `Modal ID must be one of modalTypes or null. Recieved ${ action.payload.id } for action type ${ action.type }` )
            }

            newState.modal = action.payload
            return newState
        },
        [ actionCreators.ui.notice.set ]( state, action ) {
            const newState = Object.assign( {}, state )
            newState.notice = action.payload
            return newState
        },
        ...startStop( actionCreators, 'ui.audioRecorder', '', 'audioIsRecording' ),
    },
    {
        audioIsRecording: false,
        modal: {
            id: null,
            props: {},
        },
        notice: null,
    },
)

export default ui
