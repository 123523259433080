import React from 'react'
import styled, { css } from 'styled-components'
import VerticalPadding from 'fabled/styles/VerticalPadding'

const SectionTitle = ( {
    children,
    color = 'white',
    small = false,
    left = false,
    vPad = 'hero',
    isResponsive = true,
} ) => (
    <StyledSectionTitle
        as="h2"
        color={ color }
        small={ small }
        left={ left }
        top="none"
        bottom={ vPad }
        breakpoint="600px"
    >
        { children }
    </StyledSectionTitle>
)

const StyledSectionTitle = styled( VerticalPadding )`
    color: var( --${ p => p.color } );
    font-size: 40px;
    font-weight: normal;

    ${ p => p.small && css`
        font-size: 20px;

        &:after {
            content: '';
            display: block;
            width: 100%;
            max-width: 220px;
            margin: 0.6em auto 0;
            border-bottom: 1px solid var( --${ p => p.color } );
        }
    ` }

    ${ p => !p.left && css`
        margin: 0 auto;
        max-width: 500px;
        text-align: center;
    ` }

    ${ p => p.isResponsive && !p.small && css`
        font-size: 36px;

        @media all and ( min-width: ${ p.breakpoint } ) {
            font-size: 40px;
        }
    ` }
`

export default SectionTitle