import React, { Fragment as F } from 'react'
import PropTypes from 'prop-types'
import Signup from 'fabled/components/Signup'
import ScrollToTopOnMount from 'fabled/components/ScrollToTopOnMount'

const SignupRoute = ( { location: { pathname } } ) => {
    const parentEmailForm = !!( pathname === '/signup/parent-email' )
    return (
        <F>
            <ScrollToTopOnMount />
            <Signup parentEmailForm={ parentEmailForm } />
        </F>
    )
}

SignupRoute.propTypes = {
    location: PropTypes.shape( {
        pathname: PropTypes.string.isRequired,
    } ).isRequired,
}

export default SignupRoute
