import React from 'react'
import styled, { css } from 'styled-components'
import { ContainerQuery } from 'react-container-query'

const SimpleGrid = ( {
  minColumnWidth,
  gridGap,
  verticalGap,
  maxColumns,
  children,
} ) => {
  const maxColsBreakpoint =
    ( maxColumns * minColumnWidth )
    + ( gridGap * ( maxColumns - 1 ) )

  const query = {}

  if ( maxColumns ) {
    for ( var x = 1; x <= maxColumns; x ++ ) {
      const minWidth = x > 1
        ? ( ( minColumnWidth * x ) + ( gridGap * ( x - 1 ) ) )
        : 0
      const maxWidth = x < maxColumns
        ? ( ( minColumnWidth * ( x + 1 ) ) + ( gridGap * x ) ) - 1
        : null

      query[ `columns-${ x }` ] = {
        minWidth,
        maxWidth,
      }
    }
  }

  query[ 'maxColsBreakpointReached' ] = {
    minWidth: maxColsBreakpoint,
  }

  const renderChildren = ( containerQueryParams ) => {
    if ( typeof children === 'function' ) {
      return children( containerQueryParams )
    }

    return children
  }

  const gridGapCss = verticalGap
    ? `${ verticalGap }px ${ gridGap }px`
    : `${ gridGap }px`

  return (
    <ContainerQuery query={ query }>
      { params => (
        <StyledSimpleGrid
          minColumnWidth={ minColumnWidth }
          gridGap={ gridGapCss }
          maxColumns={ maxColumns }
          maxColsBreakpointReached={ params.maxColsBreakpointReached }
        >
          { renderChildren( params ) }
        </StyledSimpleGrid>
      ) }
    </ContainerQuery>
  )
}

const StyledSimpleGrid = styled.div`
  display: grid;
  grid-template-columns: repeat( auto-fill, minmax( ${ p => p.minColumnWidth }px, 1fr ) );
  grid-gap: ${ p => p.gridGap };

  ${ p => p.maxColsBreakpointReached && css`
    grid-template-columns: repeat( ${ p => p.maxColumns }, 1fr );
  ` }
`

export default SimpleGrid