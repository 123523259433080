import { handleActions } from 'redux-actions'
import { dataObject } from './helpers'
import actionCreators from '../actions/actionCreators'

const notifications = handleActions(
    {
        ...dataObject( actionCreators, 'notifications.data', true ),
        [ actionCreators.notifications.count.set ]( state, action ) {
            const newState = { ...state }
            newState.count = action.payload
            return newState
        },
    },
    {
        data: {},
        count: 0,
    },
)

export default notifications
