import React, { Fragment as F } from 'react'
import PropTypes from 'prop-types'
import AppHeader from 'fabled/components/AppHeader/AppHeader'
import UserSettings from 'fabled/components/UserSettings'
import ScrollToTopOnMount from 'fabled/components/ScrollToTopOnMount'

const UserSettingsRoute = ( { match: { params: { userId } } } ) => (
    <F>
        <ScrollToTopOnMount />
        <AppHeader />
        <UserSettings userId={ parseInt( userId, 10 ) } />
    </F>
)

UserSettingsRoute.propTypes = {
    match: PropTypes.shape( {
        params: PropTypes.shape( {
            userId: PropTypes.string.isRequired,
        } ).isRequired,
    } ).isRequired,
}

export default UserSettingsRoute
