import isObject from 'lodash/isObject'

const getValue = ( item, key ) => {
    if ( key && isObject( item ) && key in item ) {
        return item[ key ]
    }

    return item
}

const arrayToList = ( items, key, conjuntive = 'and' ) => items.reduce(
    ( result, item, i ) => {
        if ( i === 0 ) {
            return getValue( item, key )
        }
        if ( i === items.length - 1 ) {
            return `${ result } ${ conjuntive } ${ getValue( item, key ) }`
        }

        return `${ result }, ${ getValue( item, key ) }`
    },
    '',
)

export default arrayToList
