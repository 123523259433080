import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import colors from '../styles/colors'
import { spinY } from '../styles/animations'

const Loader = ( { isLoading, color } ) => {
    const styles = { fill: color }

    const loader = (
        <StyledLoader>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40">
                <title>fabled-logo</title>
                <path style={ styles } d="M0,17.56v22.5a.54.54,0,0,0,.81.47L20.31,29.29Z" />
                <path style={ Object.assign( { opacity: 0.8 }, styles ) } d="M40.61,17.56V.54A.54.54,0,0,0,40.07,0H.54A.54.54,0,0,0,0,.54v17L20.31,29.29Z" />
                <path style={ Object.assign( { opacity: 0.6 }, styles ) } d="M20.31,29.29,39.79,40.54a.54.54,0,0,0,.81-.47V17.56Z" />
            </svg>
        </StyledLoader>
    )

    return ( isLoading ) ? loader : null
}

Loader.propTypes = {
    isLoading: PropTypes.bool,
    color: PropTypes.string,
}

Loader.defaultProps = {
    isLoading: false,
    color: '#ff5959',
}

const StyledLoader = styled.div`
    z-index: 999;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: ${ colors.paper };

    svg {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 60px;
        height: 60px;
        margin-left: -30px;
        margin-top: -30px;
        text-indent: -999em;
        animation: ${ spinY } 1s infinite alternate ease-in-out;
    }
`

export default Loader
