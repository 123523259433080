import React from 'react'
import styled, { css } from 'styled-components'

const PodcastLink = ( {
    title,
    date,
    url,
} ) => (
    <StyledPodcastLink>
        <a href={ url }>
            <h4>{ title }</h4>
            <p>{ date }</p>
            <StyledPlayIcon>
                <svg viewBox="0 0 13 13">
                    <path d="M0,13V0l13,6.5L0,13z M1.4,2.3v8.3l8.3-4.2L1.4,2.3z"/>
                </svg>
            </StyledPlayIcon>
        </a>
    </StyledPodcastLink>
)

const StyledPodcastLink = styled.div`
    a {
        position: relative;
        display: block;
        font-size: 18px;
        line-height: 1.333;
        padding: 0 2.7em 1em 0;
        margin-bottom: 1.3em;
        border-bottom: 1px solid rgba( var( --whiteRgb ), 0.3 );
        color: white;
        text-decoration: none;

        h4 {
            font-weight: bold;
        }
    }
`

const StyledPlayIcon = styled.div`
    position: absolute;
    right: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2.3em;
    height: 2.3em;
    border-radius: 100%;
    background: var( --greenBlue );

    svg {
        width: 35%;
        transform: translateX( 12% );

        path {
            fill: var( --white );
        }
    }
`

export default PodcastLink