// @flow
import React from 'react'
import type { User } from 'fabled/types/User'
import { connect } from 'react-redux'
import actionCreators from 'fabled/actions/actionCreators'
import {
    getUsersById,
    getUsersByParentId,
    getAuthUser,
    getParentOfAuthUser,
} from 'fabled/selectors/users'
import DataLoader from './DataLoader'

type Props = {
    service: 'usernames'
        | 'myChildren'
        | 'me',
    userId: number | Array<number>,
    parentId: number | Array<number>,
    waitForAuth: boolean,
    waitForQuery: boolean,
    render: ( props: {
        users: Array<User>,
        [key: string]: any,
    } ) => mixed,
    onSuccess: ( Array<User> ) => mixed,

    // from connect
    usersUpdateOrAdd: ( Array<User> ) => mixed,
    users: Array<User>,
}

const defaultProps = {
    service: 'usernames',
    userId: null,
    parentId: null,
    waitForAuth: false,
    waitForQuery: true,
    onSuccess: null,
    users: [],
}

const UsersLoader = ( props: Props ) => {
    let query = null
    let method = 'find'

    if ( ['me', 'myParent'].includes( props.service ) ) {
        query = 'inferred'
        method = 'get'
    }
    else if ( props.userId ) {
        query = { id: props.userId }
    }
    else if ( props.parentId ) {
        query = { parentId: props.parentId }
    }

    if ( !props.waitForQuery && !query ) {
        throw new Error( 'No valid query props.' )
    }

    const onSuccess = ( response ) => {
        if ( response && response.total ) {
            props.usersUpdateOrAdd( response.data )
        }
        else if ( response ) {
            props.usersUpdateOrAdd( response )
        }

        if ( props.onSuccess ) {
            props.onSuccess( response.data )
        }
    }

    const listener = {
        service: 'users',
        events: ['created', 'updated', 'patched', 'removed'],
        key: 'users',
        listener: ( serviceName, eventName, message ) => {
            props.usersUpdateOrAdd( message )
        },
    }

    const render = propsFromDataLoader => props.render( {
        users: props.users,
        ...propsFromDataLoader,
    } )

    return (
        <DataLoader
            service={ props.service }
            method={ method }
            query={ query }
            listener={ listener }
            onSuccess={ onSuccess }
            render={ render }
            waitForAuth={ props.waitForAuth }
        />
    )
}

UsersLoader.defaultProps = defaultProps

const mapStateToProps = ( state, props ) => {
    let users = []

    if ( props.service === 'me' ) {
        users = [getAuthUser( state )]
    }
    else if ( props.service === 'myParent' ) {
        users = [getParentOfAuthUser( state )]
    }
    else if ( props.userId ) {
        users = getUsersById( state, props.userId )
    }
    else if ( props.parentId ) {
        users = getUsersByParentId( state, props.parentId )
    }

    return { users }
}

const mapDispatchToProps = {
    usersUpdateOrAdd: actionCreators.users.data.updateOrAdd,
}

export default connect( mapStateToProps, mapDispatchToProps )( UsersLoader )
