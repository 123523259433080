const modalTypes = {
    STORIES_LIST: 'STORIES_LIST',
    SHARE: 'SHARE',
    SET_COLLECTION: 'SET_COLLECTION',
    ONBOARD: 'ONBOARD',
    DIALOG: 'DIALOG',
    PUBLISH: 'PUBLISH',
    CONFIRM_DELETE_POST: 'CONFIRM_DELETE_POST',
    STORY_PROMPT: 'STORY_PROMPT',
    THEME: 'THEME',
    NOTIFICATIONS: 'NOTIFICATIONS',
    REQUEST_COLLABORATION: 'REQUEST_COLLABORATION',
    PASS_STORY: 'PASS_STORY',
}

export default modalTypes
