// @flow
import React from 'react'
import type { ImagePost as TImagePost } from 'fabled/types/Post'
import config from 'fabled/config'
import styled, { css } from 'styled-components'

type Props = {
    post: TImagePost,
}

type State = {
    style: {
        [key: string]: string | number,
    }
}

class ImagePost extends React.Component<Props, State> {
    state = {
        style: { width: 'auto' },
    }

    // eslint-disable-next-line react/sort-comp
    orientation = this.props.post.content.orientation

    img = null

    setDimensionsForRotatedImage() {
        if ( !this.orientation || this.orientation < 5 ) {
            // either has no orientation info, or doesn't change aspect ratio
            return
        }

        const w = this.img ? this.img.offsetWidth : 0
        const h = this.img ? this.img.offsetHeight : 0
        const ratio = w / h
        const newWidth = w * ratio
        const newHeight = h * ratio
        const leftOffset = ( w - newWidth ) / 2
        const topOffset = ( newWidth - w ) / 2

        this.setState( {
            style: {
                height: newHeight,
                width: newWidth,
                marginTop: topOffset,
                marginLeft: leftOffset,
                maxWidth: 'none',
            },
        } )
    }

    imgUrl = () => {
        if ( this.props.post.content.dataUrl ) {
            return this.props.post.content.dataUrl
        }
        if ( this.props.post.content.imageId ) {
            return `${ config.cdnUrl }/${ this.props.post.content.imageId }`
        }

        return ''
    }

    handleOnLoad = () => {
        this.setDimensionsForRotatedImage()
    }

    render() {
        const imgOrientations = [
            'flip',
            '180',
            '180-flip',
            '270-flip',
            '270',
            '90-flip',
            '90',
        ]

        const orientation = this.orientation
            ? imgOrientations[ this.orientation - 2 ]
            : null

        return (
            <StyledImagePost>
                <StyledImageWrapper style={ { height: this.state.style.width } }>
                    <StyledImage
                        orientation={ orientation }
                        innerRef={ ( node ) => {
                            this.img = node
                        } }
                        src={ this.imgUrl() }
                        alt=""
                        onLoad={ this.handleOnLoad }
                        style={ this.state.style }
                    />
                </StyledImageWrapper>
            </StyledImagePost>
        )
    }
}

const StyledImagePost = styled.div`
   position: relative;
   background: white;
`

const StyledImageWrapper = styled.div`
   overflow: hidden;
   display: block;
   max-width: 100%;
`

const StyledImage = styled.img`
   display: block;
   max-width: 100%;
   margin: 0 auto;

   ${ p => p.orientation === '90' && css`
       transform: rotate(270deg);
   ` }

   ${ p => p.orientation === '180' && css`
       transform: rotate(180deg);
   ` }

   ${ p => p.orientation === '270' && css`
       transform: rotate(90deg);
   ` }

   ${ p => p.orientation === '90-flip' && css`
       transform: rotate(270deg) rotateY(180deg);
       backface-visibility: visible;
   ` }

   ${ p => p.orientation === '180-flip' && css`
       transform: rotate(180deg) rotateY(180deg);
       backface-visibility: visible;
   ` }

   ${ p => p.orientation === '270-flip' && css`
       transform: rotate(90deg) rotateY(180deg);
       backface-visibility: visible;
   ` }

   ${ p => p.orientation === 'flip' && css`
       transform: rotateY(180deg);
       backface-visibility: visible;
   ` }
`

export default ImagePost
