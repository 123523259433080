import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import actionCreators from '../../actions/actionCreators'
import { getStoryByPublicId } from '../../selectors/stories'
import { getPostsByStoryId } from '../../selectors/posts'
import DataLoader from './DataLoader'

const PostsLoader = ( props ) => {
    let query

    if ( props.storyId ) {
        query = { storyId: props.storyId }
    }
    else if ( props.publicStoryId ) {
        query = { publicStoryId: props.publicStoryId }
    }

    const onSuccess = ( response ) => {
        if ( response && response.total ) {
            props.postsReplaceOrAdd( response.data )
        }

        if ( props.onSuccess ) {
            props.onSuccess( response.data )
        }
    }

    const render = propsFromDataLoader => props.render( {
        posts: props.posts,
        ...propsFromDataLoader,
    } )

    return (
        <DataLoader
            service={ props.service }
            query={ query }
            onSuccess={ onSuccess }
            render={ render }
            waitForAuth={ props.waitForAuth }
        />
    )
}

PostsLoader.propTypes = {
    service: PropTypes.oneOfType( [
        PropTypes.func,
        PropTypes.oneOf( [
            'postsForCollaborator',
            'postsForPublicStory',
            'postsInTheme',
        ] ),
    ] ),
    storyId: PropTypes.number,
    publicStoryId: PropTypes.string,
    waitForAuth: PropTypes.bool,
    render: PropTypes.func.isRequired,
    onSuccess: PropTypes.func,

    // from connect
    posts: PropTypes.arrayOf( PropTypes.shape( {
        id: PropTypes.number,
    } ) ),
    postsReplaceOrAdd: PropTypes.func.isRequired,
}

PostsLoader.defaultProps = {
    service: 'posts',
    storyId: null,
    publicStoryId: null,
    waitForAuth: false,
    onSuccess: null,
    posts: [],
}

const mapStateToProps = ( state, props ) => {
    let { storyId } = props

    if ( !storyId ) {
        const story = getStoryByPublicId( state, props.publicStoryId )
        storyId = story ? story.id : null
    }

    return {
        posts: storyId ? getPostsByStoryId( state, storyId ) : [],
    }
}

const mapDispatchToProps = {
    postsReplaceOrAdd: actionCreators.posts.data.replaceOrAdd,
}

export default connect( mapStateToProps, mapDispatchToProps )( PostsLoader )
