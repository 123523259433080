import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import styled, { css } from 'styled-components'
import { colors } from 'fabled/styles'
import { UIBox } from 'fabled/styles/shared'
import actionCreators from 'fabled/actions/actionCreators'
import DateTime from 'fabled/components/DateTime'
import {
    parseNotificationBody,
    parseNotificationTitle,
} from 'fabled/helpers/parseNotification'
import NotificationIcon from './NotificationIcon'
import NotificationBody from './NotificationBody'

const Notification = ( props ) => {
    const notificationName = 'Fabled'

    const data = {
        user: props.notification.user,
        associatedUser: props.notification.associatedUser,
    }

    const notification = {
        ...props.notification,
        title: parseNotificationTitle( props.notification.title, data ),
        content: {
            body: parseNotificationBody(
                props.notification.content.body,
                data,
                props.modalClose,
            ),
        },
    }

    return (
        <StyledNotification
            onClick={ props.onClick }
            subdued={ notification.markedAsRead && props.isCompact }
            isCompact={ props.isCompact }
        >
            <NotificationIcon
                url="/images/fabled-icon.svg"
                altText="A message from Fabled"
            />

            <StyledContent>
                <StyledHeader>
                    <StyledName highlight>{ notificationName }</StyledName>
                    <StyledTime>
                        <DateTime dateTime={ new Date( notification.createdAt ) } />
                    </StyledTime>

                    { notification.markedAsRead ? null : <StyledUnreadMarker /> }
                </StyledHeader>
                <StyledBodyWrapper>
                    <StyledTitle>{ notification.title }</StyledTitle>
                    <NotificationBody
                        content={ notification.content }
                        isCompact={ props.isCompact }
                    />
                </StyledBodyWrapper>
            </StyledContent>
        </StyledNotification>
    )
}

Notification.propTypes = {
    notification: PropTypes.shape( {
        id: PropTypes.number.isRequired,
        createdAt: PropTypes.string.isRequired,
        markedAsRead: PropTypes.bool.isRequired,
        title: PropTypes.string.isRequired,
        content: PropTypes.shape( {
            body: PropTypes.oneOfType( [
                PropTypes.arrayOf( PropTypes.oneOfType( [
                    PropTypes.string,
                    PropTypes.arrayOf( PropTypes.oneOfType( [
                        PropTypes.string,
                        PropTypes.shape( {
                            action: PropTypes.shape( {
                                text: PropTypes.string,
                            } ),
                        } ),
                    ] ) ),
                    PropTypes.shape( {
                        action: PropTypes.shape( {
                            text: PropTypes.string.isRequired,
                        } ),
                    } ),
                ] ) ),
            ] ),
        } ).isRequired,
        user: PropTypes.shape( {
            id: PropTypes.number.isRequired,
        } ).isRequired,
        associatedUser: PropTypes.shape( {
            id: PropTypes.number,
        } ),
    } ).isRequired,
    isCompact: PropTypes.bool,
    onClick: PropTypes.func,

    // from connect
    modalClose: PropTypes.func.isRequired,
}

Notification.defaultProps = {
    isCompact: false,
    onClick: null,
}

const StyledTitle = styled.h2`
    position: absolute;
    top: 0;
    font-size: 16px;
    font-weight: normal;
    transition: top .1s ease-out;
`

const StyledNotification = styled.div`
    ${ UIBox }
    display: flex;
    padding: 1em;
    margin-bottom: 0.8em;
    background: white;
    font-size: 16px;

    ${ p => p.subdued && css`
        filter: grayscale(100%);

        > * {
            opacity: 0.5;
        }
    ` }

    ${ p => p.onClick && css`
        cursor: pointer;
        transition: box-shadow .3s ease-out;

        &:hover {
            box-shadow: 0 0 3px 1px ${ colors.greenBlue };
        }

        > * {
            transition: opacity .3s ease-out;

            &:hover {
                opacity: 0.8;
            }
        }
    ` }

    ${ p => !p.isCompact && css`
        ${ StyledTitle } {
            top: 2em;
            font-weight: bold;
        }
    ` }
`

const StyledContent = styled.div`
    flex-grow: 1;
    padding-left: 1em;
`

const StyledHeader = styled.div`
    position: relative;
`

const StyledName = styled.p`
    display: inline-block;
    margin-right: 0.8em;
    color: ${ p => ( p.highlight ? colors.red : colors.typeGrey ) };
    font-weight: bold;
`

const StyledTime = styled.p`
    display: inline-block;
    color: ${ colors.typeGrey_light }
`

const StyledUnreadMarker = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    width: 12px;
    height: 12px;
    border-radius: 100%;
    background: ${ colors.red };
`

const StyledBodyWrapper = styled.div`
    position: relative;
`

const mapDispatchToProps = {
    modalClose: actionCreators.ui.modal.close,
}

export default connect( null, mapDispatchToProps )( Notification )
