// @flow
import React, { Fragment as F } from 'react'
import AppHeader from 'fabled/components/AppHeader/AppHeader'
import CollaborationApproval from 'fabled/components/CollaborationApproval'
import ScrollToTopOnMount from 'fabled/components/ScrollToTopOnMount'

type Props = {
    match: {
        params: {
            token: string,
        }
    }
}

const CollaborationApprovalRoute = ( { match: { params: { token } } }: Props ) => (
    <F>
        <ScrollToTopOnMount />
        <AppHeader />
        <CollaborationApproval token={ token } />
    </F>
)

export default CollaborationApprovalRoute
