import styled from 'styled-components'
import { colors } from '../../styles'

const DashboardTitle = styled.h1`
    margin: 0 5%;
    padding: 1.8em 0;
    font-size: 30px;
    font-weight: 700;
    text-align: center;
    color: ${ colors.red };

    span {
        color: ${ colors.purple };
    }
`

export default DashboardTitle
