// @flow
import React from 'react'
import type { Story } from 'fabled/types/Story'

type Props = {
    story: Story,
    href: string,
    index: number,
    removed: boolean,
}

const defaultProps = {
    removed: false,
}

const StoryTile = ( {
    story, href, index, removed,
}: Props ) => {
    const colors = [
        'red',
        'yellow',
        'green',
        'purple',
        'salmon',
    ]
    const color = colors[ index < colors.length ? index : index % colors.length ]
    const removedClass = removed ? 'fab-c-story-tile--removed' : ''

    return (
        <a className={ `fab-c-story-tile fab-c-story-tile--${ color } ${ removedClass }` } href={ href }>
            <h3 className="fab-c-story-tile__title">{ story.title }</h3>
            <div className="fab-c-story-tile__cta">Read the story</div>
        </a>
    )
}

StoryTile.defaultProps = defaultProps

export default StoryTile
