import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import actionCreators from 'fabled/actions/actionCreators'
import { getNotificationsByUserId } from 'fabled/selectors/notifications'
import DataLoader from './DataLoader'

const NotificationsLoader = ( props ) => {
    let query = null

    if ( props.userId ) {
        query = { userId: props.userId }
    }

    if ( !query ) {
        throw new Error( 'No valid query props.' )
    }

    const onSuccess = ( response ) => {
        if ( response && response.total ) {
            props.notificationsReplaceOrAdd( response.data )
        }

        if ( props.onSuccess ) {
            props.onSuccess( response.data )
        }
    }

    const listener = {
        service: 'notifications',
        events: ['created', 'updated', 'patched', 'removed'],
        key: `userNotifications:${ props.userId }`,
        listener: ( serviceName, eventName, message ) => {
            props.notificationsReplaceOrAdd( message )
        },
    }

    const render = propsFromDataLoader => props.render( {
        notifications: props.notifications,
        ...propsFromDataLoader,
    } )

    return (
        <DataLoader
            service="myNotifications"
            query={ query }
            onSuccess={ onSuccess }
            listener={ listener }
            render={ render }
            waitForAuth={ props.waitForAuth }
        />
    )
}

NotificationsLoader.propTypes = {
    userId: PropTypes.oneOfType( [
        PropTypes.number,
        PropTypes.arrayOf( PropTypes.number ),
    ] ).isRequired,
    waitForAuth: PropTypes.bool,
    render: PropTypes.func.isRequired,
    onSuccess: PropTypes.func,

    // from connect
    notificationsReplaceOrAdd: PropTypes.func.isRequired,
    notifications: PropTypes.arrayOf( PropTypes.shape( {
        id: PropTypes.number,
    } ) ).isRequired,
}

NotificationsLoader.defaultProps = {
    waitForAuth: false,
    onSuccess: null,
}

const mapStateToProps = ( state, props ) => {
    const notifications = getNotificationsByUserId( state, props.userId )
    return { notifications }
}

const mapDispatchToProps = {
    notificationsReplaceOrAdd: actionCreators.notifications.data.replaceOrAdd,
}

export default connect( mapStateToProps, mapDispatchToProps )( NotificationsLoader )
