import Raven from 'raven-js'

const sentryKey = '4f398add914746f6b3545be6f1e51ba2'
const sentryApp = '222575'
export const sentryUrl = `https://${ sentryKey }@app.getsentry.com/${ sentryApp }`

export function logException( ex, context ) {
    Raven.captureException( ex, {
        extra: context,
    } )
    /* eslint no-console:0 */
    if ( window && window.console && console.error ) {
        console.error( ex )
    }
}
