// @flow
import React from 'react'
import type { Node } from 'react'
import type { Story } from 'fabled/types/Story'
import type { Post } from 'fabled/types/Post'
import type { ChildUser, AuthUser } from 'fabled/types/User'
import DataLoaderGroup from './DataLoaderGroup'
import StoriesLoader from './StoriesLoader'
import PostsLoader from './PostsLoader'
import UsersLoader from './UsersLoader'

/**
 * The StoryLoader component combines StoriesLoader, PostsLoader and UsersLoader
 * in sequence to load all necessary data to display a single story
 */

const createRenderer = ( LoadingComponent, SuccessComponent, ErrorComponent ) => {
    type RendererProps = {
        loaderState: {
            isLoading: boolean,
            isComplete: boolean,
            error: ?string,
        },
        story?: ?Story,
        posts?: Array<Post>,
        storyAuthors?: Array<ChildUser>,
        authUser?: ?AuthUser,
    }

    const Renderer = ( props: RendererProps ) => {
        if ( !props.loaderState.isComplete ) {
            return LoadingComponent ? <LoadingComponent /> : null
        }
        if ( props.loaderState.error ) {
            return ErrorComponent ? <ErrorComponent error={ props.loaderState.error } /> : null
        }

        return (
            <SuccessComponent
                story={ props.story }
                posts={ props.posts }
                storyAuthors={ props.storyAuthors }
                authUser={ props.authUser }
            />
        )
    }

    Renderer.defaultProps = {
        story: null,
        posts: [],
        storyAuthors: [],
        authUser: null,
    }

    return Renderer
}

type Props = {
    storyId?: ?number,
    publicStoryId?: ?string,
    themeId?: ?number,
    waitForAuth?: boolean,
    loadingComponent?: null | () => Node,
    successComponent: () => Node,
    errorComponent?: null | () => Node,
}

const StoryLoader = ( props: Props ) => {
    const {
        storyId,
        publicStoryId,
        themeId,
        waitForAuth,
        loadingComponent: LoadingComponent,
        successComponent: SuccessComponent,
        errorComponent: ErrorComponent,
    } = props

    const service: { [key: string]: string | ( authUser?: AuthUser ) => string } = {
        stories: ( authUser ) => {
            if ( !authUser ) {
                return 'publicStories'
            }

            switch ( authUser.role ) {
                case 'admin': return 'storiesForAdmin'
                case 'parent': return 'storiesForParent'
                default: return 'storiesForCollaborator'
            }
        },
        posts: ( authUser ) => {
            if ( !authUser ) {
                return 'postsForPublicStories'
            }

            switch ( authUser.role ) {
                case 'admin': return 'postsForAdmin'
                case 'parent': return 'postsForParent'
                default: return 'postsForCollaborator'
            }
        },
    }

    if ( themeId ) {
        service.stories = 'storiesInTheme'
        service.posts = 'postsInTheme'
    }
    else if ( publicStoryId ) {
        service.stories = 'publicStories'
        service.posts = 'postsForPublicStory'
    }

    const loaders = [
        {
            key: 'stories',
            loader: render => (
                <StoriesLoader
                    service={ service.stories }
                    storyId={ storyId }
                    publicStoryId={ publicStoryId }
                    themeId={ themeId }
                    waitForAuth={ waitForAuth }
                    render={ render }
                />
            ),
            mapper: ( { stories, loaderState } ) => {
                const clonedLoaderState = { ...loaderState }
                const story = stories && stories.length ? stories[ 0 ] : null

                if ( clonedLoaderState.isComplete && !story ) {
                    clonedLoaderState.error = {
                        message: 'Story not found.',
                    }
                }

                return { story, loaderState: clonedLoaderState }
            },
        },
        {
            key: 'posts',
            requiredProps: ['story'],
            loader: render => (
                <PostsLoader
                    service={ service.posts }
                    storyId={ storyId }
                    publicStoryId={ publicStoryId }
                    render={ render }
                />
            ),
        },
        {
            key: 'users',
            requiredProps: ['story'],
            loader: ( render, { story } ) => (
                <UsersLoader
                    userId={ story.userIds }
                    render={ render }
                />
            ),
            mapper: ( { users: storyAuthors, authUser }, { story, posts } ) => ( {
                story, posts, storyAuthors, authUser,
            } ),
        },
    ]

    return (
        <DataLoaderGroup
            loaders={ loaders }
            render={ createRenderer( LoadingComponent, SuccessComponent, ErrorComponent ) }
        />
    )
}

StoryLoader.defaultProps = {
    storyId: null,
    publicStoryId: null,
    themeId: null,
    waitForAuth: false,
    loadingComponent: null,
    errorComponent: null,
}

export default StoryLoader
