// @flow
import React from 'react'
import type { Story } from 'fabled/types/Story'
import styled from 'styled-components'
import { colors } from 'fabled/styles'
import history from 'fabled/history'
import StoriesLoader from 'fabled/components/loaders/StoriesLoader'
import StoryCollaborators from 'fabled/components/StoryCollaborators'

type StoriesListProps = {
    userId: number,
    onStorySelect?: ( story: { id: number } ) => void,
}

const StoriesList = ( props: StoriesListProps ) => (
    <StoriesLoader
        userIds={ props.userId }
        render={
            ( { stories } ) => (
                <ul>
                    {
                        stories.map( story => (
                            <StoryItem
                                key={ story.uuid }
                                story={ story }
                                onStorySelect={ props.onStorySelect }
                            />
                        ) )
                    }
                </ul>
            )
        }
    />
)

StoriesList.defaultProps = {
    onStorySelect: null,
}

type StoryItemProps = {
    story: Story,
    onStorySelect?: ( story: Story ) => void,
}

const StoryItem = ( { story, onStorySelect }: StoryItemProps ) => {
    const storyUrl = story.published ? `/story/${ story.id }` : `/create/${ story.id }`

    const handleSelect = ( e ) => {
        if ( e.key && !['Enter', ' '].includes( e.key ) ) {
            return
        }

        e.preventDefault()

        if ( typeof onStorySelect === 'function' ) {
            onStorySelect( story )
        }

        history.push( storyUrl )
    }

    return (
        <StyledStoryItem>
            <StyledStoryLink
                href={ storyUrl }
                onClick={ handleSelect }
                onKeyDown={ handleSelect }
                highlighted={ story.published }
            >
                { story.title || 'No title yet (working on it!)' }
                <StoryCollaborators userIds={ story.userIds } />
            </StyledStoryLink>
        </StyledStoryItem>
    )
}

StoryItem.defaultProps = {
    onStorySelect: null,
}

const StyledStoryItem = styled.li`
    list-style: none;
    margin: 0;
    padding: 0;
`

const StyledStoryLink = styled.a`
    display: block;
    padding: 0.6em 0;
    border-bottom: 1px solid ${ colors.purple };
    color: ${ colors.typeGrey };
    text-decoration: none;
    font-weight: ${ props => ( props.highlighted ? 'bold' : 'normal' ) };

    &:hover {
        cursor: pointer;
        color: ${ colors.purple };
    }
`

export default StoriesList
