// @flow
import React from 'react'
import { connect } from 'react-redux'
import actionCreators from 'fabled/actions/actionCreators'
import styled, { css } from 'styled-components'
import colors from 'fabled/styles/colors'
import { UIBox } from 'fabled/styles/shared'

type Props = {
    postId: number,
    postUuid: string,
    size: number,
    position: 'left' | 'right',

    // from connect
    modalOpenConfirmDeletePost: ( props: {
        postId: ?number,
        postUuid: ?string,
    } ) => void,
}

const defaultProps = {
    postId: null,
    postUuid: null,
    size: 34,
}

const PostDeleteButton = ( props: Props ) => {
    const handleClick = ( e ) => {
        if ( e.key && !['Enter', ' '].includes( e.key ) ) {
            return
        }

        e.preventDefault()

        props.modalOpenConfirmDeletePost( {
            postId: props.postId,
            postUuid: props.postUuid,
        } )
    }

    return (
        <StyledPostDeleteButton size={ props.size } position={ props.position }>
            <StyledPostDeleteButtonBtn
                size={ props.size }
                position={ props.position }
                onClick={ handleClick }
                onKeyDown={ handleClick }
                role="button"
                tabIndex="0"
            >
                <StyledPostDeleteButtonLabel size={ props.size } position={ props.position }>
                    Delete
                </StyledPostDeleteButtonLabel>
            </StyledPostDeleteButtonBtn>
        </StyledPostDeleteButton>
    )
}

PostDeleteButton.defaultProps = defaultProps

const mapDispatchToProps = {
    modalOpenConfirmDeletePost: actionCreators.ui.modal.openConfirmDeletePost,
}

const StyledPostDeleteButton = styled.div`
    position: absolute;
    top: 1em;
    margin-top: -10px;
    cursor: pointer;
    opacity: 0;
    transition: opacity .3s ease-out;

    *:hover > & {
        opacity: 1;
    }

    ${ p => p.position === 'left' && css`
        left: 0;
        margin-left: -10px;
    ` }

    ${ p => p.position === 'right' && css`
        right: 0;
        margin-right: -10px;
    ` }
`

const StyledPostDeleteButtonBtn = styled.div`
    display: inline-block;

    ${ p => p.position === 'left' && css`
        text-align: left;
        float: left;
    ` }

    ${ p => p.position === 'right' && css`
        text-align: right;
        float: right;
    ` }

    &:${ p => ( p.position === 'left' ? 'before' : 'after' ) } {
        content: ' ';
        display: inline-block;
        vertical-align: middle;
        margin: 0 auto;
        width: ${ p => p.size }px;
        height: ${ p => p.size }px;
        background:
            ${ colors.red }
            url(/images/icon-cross.svg)
            no-repeat
            center center;
        background-size: 40%;
        border-radius: 100%;
    }
`

const StyledPostDeleteButtonLabel = styled.span`
    position: relative;
    display: inline-block;
    vertical-align: middle;
    padding: 0 1em;
    font-size: 18px;
    line-height: ${ p => p.size }px;
    color: white;
    background: ${ colors.red };
    opacity: 0;
    ${ UIBox }
    transition:
        transform .2s ease-out,
        opacity .3s ease-out;

    &:before {
        content: ' ';
        position: absolute;
        top: 50%;
        margin-top: -8px;
        display: block;
        width: 0;
        height: 0;
        border-top: 8px transparent solid;
        border-bottom: 8px transparent solid;
    }

    ${ p => p.position === 'left' && css`
        margin-left: 12px;

        &:before {
            left: -8px;
            border-right: 8px ${ colors.red } solid;
        }
    ` }

    ${ p => p.position === 'right' && css`
        margin-right: 12px;

        &:before {
            right: -8px;
            border-left: 8px ${ colors.red } solid;
        }
    ` }

    ${ StyledPostDeleteButton }:hover & {
        opacity: 1;
        transform: translateX(${ p => ( p.position === 'left' ? '5px' : '-5px' ) });
    }
`

export default connect( null, mapDispatchToProps )( PostDeleteButton )
