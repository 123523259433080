// @flow
import React from 'react'
import type { AuthUser } from 'fabled/types/User'
import styled from 'styled-components'
import { ellipsis } from 'polished'
import userAvatar from 'fabled/helpers/userAvatar'
import AppNavItem from 'fabled/components/AppHeader/AppNav/AppNavItem'
import UserMenu from './UserMenu'

type Props = {
    user?: AuthUser,
    isSubNavOpen?: boolean,
    onClick: () => mixed,
}

const UserNav = ( props: Props ) => {
    const { user } = props
    const userMenu = user ? <UserMenu user={ user } /> : null
    const textForLabel = user ? user.firstName : 'Create your profile'
    const textLabel = <StyledUserName>{ textForLabel }</StyledUserName>
    const userIcon = userAvatar( user )

    return (
        <AppNavItem
            isSubNavOpen={ props.isSubNavOpen }
            subNav={ userMenu }
            icon={ userIcon }
            textLabel={ textLabel }
            href={ user ? null : '/signup' }
            onClick={ props.onClick }
        />
    )
}

UserNav.defaultProps = {
    user: {
        gender: null,
        firstName: '',
    },
    isSubNavOpen: false,
}

const StyledUserName = styled.div`
    ${ ellipsis( '120px' ) }
`

export default UserNav
