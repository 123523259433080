import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import history from 'fabled/history'
import * as validators from 'fabled/validators'
import Api from 'fabled/api'
import { eventTracker } from 'fabled/eventTracker'
import AuthFormWrapper from './AuthFormWrapper'
import FormattedContent from './FormattedContent'
import Form from './Form'
import TextInput from './FormInputs/TextInput'
import RadioInput from './FormInputs/RadioInput'
import CheckboxInput from './FormInputs/CheckboxInput'
import DOBInput from './FormInputs/DOBInput'
import Button from './Button'
import UINotice from './UINotice'
import Loader from './Loader'

class Signup extends React.Component {
    constructor( props ) {
        super( props )
        this.state = {
            waiting: false,
            error: false,
        }
        this.submitSignupForm = this.submitSignupForm.bind( this )
        this.submitParentEmail = this.submitParentEmail.bind( this )
    }

    submitSignupForm( e, formComponent ) {
        this.setState( { waiting: true } )
        const payload = formComponent.getFields()

        return Api.service( 'childUsers' ).create( payload )
            .then( () => {
                Api.authenticate( {
                    username: payload.username,
                    password: payload.password,
                    strategy: 'local-username',
                } )
                    .then( () => {
                        history.push( '/signup/parent-email' )
                    } )
            } )
            .catch( error => this.setState( { error, waiting: false } ) )
    }

    submitParentEmail( a, formComponent ) {
        this.setState( { waiting: true } )
        const { parentEmail } = formComponent.getFields()

        return Api.service( 'me' ).patch( { parentEmail } )
            .then( () => {
                eventTracker( {
                    category: 'Users',
                    action: 'Added parent email',
                } )
                history.push( '/login' )
            } )
            .catch( error => this.setState( { error, waiting: false } ) )
    }

    render() {
        const formWrapperClassName = `fab-c-signup-form-wrapper${ this.props.parentEmailForm ? ' fab-c-signup-form-wrapper--rotated' : '' }`

        const signupLink = classNameModifier => (
            <div className={ `fab-c-signup-note ${ classNameModifier ? `fab-c-signup-note--${ classNameModifier }` : '' }` }>
                <p>
                    Already a Fabled kid? <Link to="/login" href="/login" className="fab-c-signup-note__link">Log in here</Link>
                </p>
                <p>
                    What is Fabled? <a href="/about" className="fab-c-signup-note__link">Find out here</a>
                </p>
            </div>
        )

        return (
            <AuthFormWrapper signupLink={ signupLink }>

                <div className={ formWrapperClassName }>

                    <Form className="fab-c-signup-form fab-c-signup-form--front" onSubmit={ this.submitSignupForm }>

                        { this.state.waiting ? <Loader isLoading /> : null }

                        <div className="fab-c-signup-form__header">
                            <h2 className="fab-c-signup-form__heading">Tell your story…</h2>
                            <p className="fab-c-signup-form__sub-heading">Create your profile</p>
                        </div>

                        {
                            this.state.error ? (
                                <UINotice type="error">
                                    Please check that you have filled eveything in below.
                                </UINotice>
                            )
                                : null
                        }

                        <TextInput
                            name="username"
                            id="fab-id-signup-username"
                            label="Pick a nickname"
                            validator={ validators.username }
                        />
                        <TextInput
                            name="password"
                            id="fab-id-signup-password"
                            label="Pick a password"
                            validator={ validators.password }
                        />
                        <TextInput
                            name="firstName"
                            id="fab-id-signup-first-name"
                            label="What’s your first name?"
                            validator={ validators.firstName }
                        />
                        {/* <RadioInput
                            name="gender"
                            id="fab-id-signup-gender"
                            label="You are a…"
                            validator={ validators.gender }
                            options={ [
                                { value: 'male', label: 'Boy' },
                                { value: 'female', label: 'Girl' },
                            ] }
                        />
                        <DOBInput
                            name="dob"
                            id="fab-id-signup-dob"
                            label="Your birthday"
                            validator={ validators.dob }
                        /> */}
                        <CheckboxInput
                            name="terms"
                            id="fab-id-signup-terms"
                            label={ (
                                <span>
                                    Please tick this box to confirm that you agree to our <a href="/terms" target="_blank">Terms of Service and Privacy Policy</a>
                                </span>
                            ) }
                            validator={ validators.terms }
                            value="yes"
                        />
                        <Button primary>Join now</Button>
                    </Form>

                    <Form className="fab-c-signup-form fab-c-signup-form--back" onSubmit={ this.submitParentEmail }>

                        { this.state.waiting ? <Loader isLoading /> : null }

                        <div className="fab-c-signup-form__header">
                            <h2 className="fab-c-signup-form__heading">Your parent’s email address</h2>
                        </div>

                        <FormattedContent>
                            <p>We’ll send an invitation to this email address. This person can use the invitation to create a Fabled Grown-Up account. They’ll be able to help you publish and share your stories, work with friends, and reset your password if you ever forget it.</p>
                        </FormattedContent>

                        <TextInput
                            name="parentEmail"
                            id="fab-id-signup-parent-email"
                            label="Your Parent’s Email Address"
                            validator={ validators.email }
                        />
                        <div className="fab-c-signup-form__input-row">
                            <Button primary>Add</Button>
                        </div>
                        <Link
                            className="fab-c-signup-form__link"
                            to="/login"
                            href="/login"
                        >
                            Skip this and do it later
                        </Link>

                        <FormattedContent topMargin>
                            <h3>Note for grown-ups</h3>
                            <p>A Fabled Grown-Up account gives you your own Fabled log in and allows you to manage your child’s participation here. Setting up your grown-up account allows your child to do some really fun things using Fabled…</p>
                            <ul>
                                <li>Share their stories with friends, family, or anyone you like using a secret link</li>
                                <li>Create stories with a friend, passing the story back-and-forth</li>
                                <li>Add stories to Fabled collections, where other Fabled users can find them (be a published author!)</li>
                            </ul>
                            <p>If you don’t create your grown-up account your child won’t be able to access their account if they forget their password.</p>
                        </FormattedContent>
                    </Form>

                </div>

            </AuthFormWrapper>
        )
    }
}

Signup.propTypes = {
    parentEmailForm: PropTypes.bool,
}

Signup.defaultProps = {
    parentEmailForm: false,
}

export default Signup
