import React from 'react'
import styled from 'styled-components'

const Background = ( { bgColor = 'paper', children } ) => (
    <StyledBackground bgColor={ bgColor }>{ children }</StyledBackground>
)

const StyledBackground = styled.div`
    background-color: var( --${ p => p.bgColor } );
`

export default Background