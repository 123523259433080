// @flow
import React from 'react'
import type { TextBlockPost } from 'fabled/types/Post'
import styled, { css } from 'styled-components'
import colors from 'fabled/styles/colors'
import { parasAndNewlines } from 'fabled/helpers/text'

type Props = {
    post: TextBlockPost,
    boxed: boolean,
}

const TextPost = ( props: Props ) => (
    <StyledTextPost boxed={ props.boxed }>
        { parasAndNewlines( props.post.content.text ) }
    </StyledTextPost>
)

const StyledTextPost = styled.div`
    font-size: 18px;
    line-height: 1.333em;
    color: ${ colors.typeGrey };

    ${ p => p.boxed && css`
        padding: 1em 1.2em;
        background: white;
    ` }
`

export default TextPost
