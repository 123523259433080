/* eslint-disable max-len */
import React from 'react'
import AuthFormWrapper from 'fabled/components/AuthFormWrapper'
import ScrollToTopOnMount from 'fabled/components/ScrollToTopOnMount'

const Terms = () => (
    <AuthFormWrapper isWide>
        <ScrollToTopOnMount />

        <div className="fab-c-signup-form-wrapper">

            <div className="fab-c-signup-form fab-c-signup-form--front">
                <p>Last updated: November 2017</p>

                <p><strong>Please read these terms of service and privacy policy carefully before using the Fabled platform (as defined below). By using the Fabled platform, you agree to these terms of service and the Fabled privacy policy (together, the “Fabled user agreement”).</strong></p>

                <div className="fab-c-signup-form__header">
                    <h2 className="fab-c-signup-form__heading">Terms of Service</h2>
                </div>

                <p>The following is an agreement between Fabled. (“Fabled”, “us”, “our” and “we”), and any person or other legal entity, (“you”, “your” or “account holder”) who wishes to use the Fabled website or any mobile or other apps that we provide (together, the “Site”, and together with the service provided on our website the “Service”), whether as the holder of an account on the Site (“Member”) or merely to browse the Site (Members and other users of the Site are collectively described in this agreement as “Users”).</p>

                <p>This agreement (“Agreement”) consists of these terms and conditions, and any other terms incorporated in these terms and conditions by reference, and any information that you provide to us as part of our registration processes. By using the Site in any way, including browsing the Site or completing the Member registration process, you enter into and are bound by this Agreement.</p>

                <p>If you are under the age of 18, you should review this Agreement with your parent or guardian to make sure that you and your parent or guardian understand it.</p>

                <div className="fab-c-signup-form__header">
                    <h3 className="fab-c-signup-form__sub-heading">1. Acceptance and Amendment of Terms</h3>
                </div>

                <p>Welcome to the Fabled Site. Except as expressly permitted on the Site, the Service is for personal and non-commercial use only, and may not be used for any purpose not expressly permitted by this Agreement. In addition, you may not reproduce, copy, duplicate, sell or resell any part of the Service.</p>

                <p>Your use of the Service, including new features and enhancements to the Service, is entirely conditioned on and subject to your compliance with this Agreement. If you do not agree with this Agreement, you should leave the Site and discontinue use of the Services immediately. Please read this Agreement carefully and save it. If you wish to become a Member, communicate with other Members through the Site and make full use of the Services, you must read this Agreement and indicate your acceptance during the registration process.</p>

                <p>You may not change, supplement or amend this Agreement in any manner. Fabled may unilaterally amend or supplement this Agreement from time to time. Fabled will notify you of any amendments at the email address you provide to us on registration (or that of a parent or guardian), or we will post a notice visible to you the next time you access the Site or use the Service, and we will give you the opportunity to terminate this Agreement if you do not agree with such amendments. If you do not agree with the amendments you must terminate your Member account and cease use of the Site and the Service. Your use of the Site or the Service after notice of an amendment to the Agreement is your agreement to that amendment. Please note that it is your responsibility to keep your registration information with us, including your email address for notification purposes, up-to-date. Any such amendments that amend the licenses provided in this Agreement shall amend any licenses granted before the amendment takes effect, as well as any granted after the amendment takes effect. These terms shall always bear a “last updated” date at the top of the page showing the date on which they were last updated.</p>

                <div className="fab-c-signup-form__header">
                    <h3 className="fab-c-signup-form__sub-heading">2. User Conduct</h3>
                </div>

                <ol>
                    <li>In order to become a Member, you need to create a Member account. To create an account, you must submit certain requested information to Fabled, including a correct email address for a parent/guardian. If you are under the age of 13, you should use the Fabled Platform only with the permission of a parent or legal guardian. When such registration information is requested by the Service, the information you provide must be true, accurate, current, and complete.</li>
                    <li>You are responsible for any activity and charges that occur under your screen name and Member account. You may not transfer your account.</li>
                    <li>You are responsible for keeping your Member password secure and for notifying Fabled immediately of any breach of security or unauthorized use of your Member account.</li>
                    <li>You are responsible for maintaining the confidentiality of any personal or personally identifiable data you upload and store on the Site and are responsible for complying with all applicable laws governing collection, storage, processing, use and transfer of such information.</li>
                    <li>You are solely responsible for your conduct on the Site and use of the Service, and for any content, including but not limited to designs, text, graphics, pictures, information, applications, software, music, sound and other files (collectively, “Content”), that you contribute to the Site.</li>
                    <li>You must not abuse, harass, threaten, impersonate or intimidate other Users of the Service. </li>
                    <li>You may not use the Service for any illegal or unauthorized purpose. International Users agree to comply with all local laws regarding online conduct and acceptable content.</li>
                    <li>You may not collect or harvest any personally identifiable information, including account names, from the Site.</li>
                    <li>
                        <p>You may not use the Service to upload, transmit or link to Prohibited Content. Generally, Prohibited Content includes Content or other material that Fabled believes:</p>
                        <ol>
                            <li>is abusive, deceptive, pornographic, obscene, defamatory, slanderous, offensive, encourages conduct that would violate or violates any law, harassing, hateful, racially or ethnically offensive, or otherwise inappropriate;</li>
                            <li>comprises material that is copyrighted or protected by trade secret or otherwise subject to third party proprietary rights, including privacy and publicity rights, unless express written permission of the owner is obtained to use the material and to grant all of the license rights granted herein;</li>
                            <li>violates or otherwise encroaches on the rights of others;</li>
                            <li>contains viruses, worms, corrupt files, Trojan horses or other forms of corruptive code, or any other content which may compromise the Service;</li>
                            <li>advocates illegal activity;</li>
                            <li>harms anyone, including minors; or</li>
                            <li>provides a link to any of the above.</li>
                        </ol>

                        <p>Fabled has the sole discretion to determine whether Content is Prohibited Content, and any Content submitted to the Service may be subject to examination from time to time. Although Fabled does not and will not examine or otherwise review all Content submitted or transmitted to the Service, Fabled may delete, move, and edit Content for any reason, at any time, without notice. Fabled in no way guarantees the accuracy, non-infringement, quality or appropriateness of Content available through the Service.</p>
                    </li>
                    <li>You may not use or launch any automated system, including without limitation, “robots,” “spiders,” “offline readers,” etc., that accesses the Service in a manner that sends more request messages to the Fabled servers in a given period of time than a human can reasonably produce in the same period by using a conventional on-line web browser in ordinary and customary usage.</li>
                </ol>

                <p>Any breach or non-compliance of any of these User Conduct terms will entitle Fabled to immediately and without notice (i) terminate any Member account, which could result in the permanent loss of any data in the account and any User Content of the Member, and (ii) block you from any further use of the Site and Service.</p>

                <div className="fab-c-signup-form__header">
                    <h3 className="fab-c-signup-form__sub-heading">3. Content Contributed to the Site by Members</h3>
                </div>

                <ol>
                    <li>The Site allows Members to create their own stories, and for the approved parent/guardian User to share those stories through their own web link outside of the Site or within the Site itself. In this Agreement we refer to all of this Content provided by Users as “User Content”.</li>
                    <li>Subject to the licence granted to Us as set forth in this Agreement, Users retain the ownership of copyright and other intellectual property rights subsisting in User Content submitted by them (unless any part of such User Content is owned by a third party who has given their express permission for their material to be used in the User Content).</li>
                    <li>You (or your licensors, as appropriate) retain ownership of your User Content and all intellectual property rights subsisting therein.  By submitting User Content, you grant Us an unconditional, non-exclusive, fully transferable, royalty-free, perpetual, irrevocable, worldwide licence to use, store, archive, syndicate, publish, transmit, adapt, edit, reproduce, distribute, prepare derivative works from, display, perform and sub-licence your User Content for the purposes of operating and promoting Our Site. </li>
                    <li>If you wish to remove User Content, you may do so by deleting posts.  We will use reasonable efforts to remove the User Content in question from Our Site.</li>
                    <li>We may from time to time change the manner in which User Content can be used by the Service, and if we do so any license you have granted to us under Section 3 shall automatically be extended to new uses permitted by the Service, and any licenses previously granted to us for uses no longer permitted by the Service shall continue in accordance with Section 3. If you contribute User Content to the Site, it is your responsibility to check the Site from time to time to review how we permit User Content to be used.</li>
                    <li>You may at any time, on written notice to us, terminate your use of the Site and this Agreement in the manner provided below, and upon such termination your license to us and our sublicenses to others shall also terminate, provided that after such termination that license and those sublicenses will continue with respect to any Permitted Use of your User Content that commenced prior to the effective time of the termination, until that Permitted Use ceases. A Permitted Use is any use of Content permitted by this Agreement or the Service. </li>
                    <li>Fabled reserves the right to remove Content from the Site at any time, without notice, for any reason, or for no reason, in its sole discretion.</li>
                    <li>You represent and warrant to Fabled that (a) you are the sole owner, author and copyright owner of User Content you contribute to the Site or you have written permission from the author, owner or copyright owner to make such User Content available to the Service as “User Content”, and (b) such User Content does not infringe upon any third party rights (including but not limited to any copyright, patent, trademark, trade secret, other intellectual property rights, moral rights, privacy rights or publicity rights); (c) such User Content complies with this Agreement and does not contain any defamatory, libellous or obscene material; (d) such User Content does not violate any applicable laws, regulations, or contain any false statements or misrepresentations; and (e) you have the written consent, release, and/or permission of each and every identifiable individual person, or the owner/author of any objects or work in such User Content to use the name or likeness of each and every such identifiable individual person, object or work, to enable inclusion and use of such User Content in the manner contemplated by the Service and this Agreement.</li>
                </ol>

                <div className="fab-c-signup-form__header">
                    <h3 className="fab-c-signup-form__sub-heading">4. Our Intellectual Property Rights; Our License of Content to Members</h3>
                </div>

                <ol>
                    <li>All Content (including User Content) is protected by applicable United Kingdom and international intellectual property laws and treaties.</li>
                    <li>Except as otherwise expressly indicated, the Site, and all text, images, marks, logos and other content contained therein, including, without limitation, our logo and all designs, text, graphics, pictures, information, data, software, sound files, other files, Content and the selection and arrangement thereof are the proprietary property of Fabled or its licensors and are protected by British and international copyright laws. All rights to the Content are expressly reserved, and except for licenses expressly granted in this Agreement no right to any Content on the Site is granted to you.</li>
                    <li>You have a limited right, for the term of and subject to this Agreement, to use Content provided on the Site (i) only in connection with and during your use of the Site in accordance with this Agreement, (ii) only for the purpose for which, and in the manner in which, the Site makes such Content available and, unless the Site expressly permits other uses, only for personal, non-commercial use, and (iii) only on payment therefore as required by the Site. This right is not exclusive or transferable, and may be terminated at any time by Fabled.</li>
                </ol>

                <div className="fab-c-signup-form__header">
                    <h3 className="fab-c-signup-form__sub-heading">5. Privacy</h3>
                </div>

                <p>Registration data and certain other information about you is subject to our Privacy Policy, the terms of which are incorporated into this Agreement by reference. The terms of our Privacy Policy may be found below. You represent and warrant to Fabled that you have obtained all required consents with respect to any personal information (of any person) provided by you to Fabled, and that subject to the Privacy Policy Fabled may use such personal information for the purposes of operating the Service.</p>

                <div className="fab-c-signup-form__header">
                    <h3 className="fab-c-signup-form__sub-heading">6. Communications, Feedback, Suggestions</h3>
                </div>

                <p>Notwithstanding any other provision of this Agreement, Fabled is under no obligation to refrain from reproducing, publishing or otherwise using any communications, other than personal information, you send to or receive from us by electronic mail or other electronic means, including any ideas, inventions, concepts, techniques or know-how disclosed therein, for any purpose, including the developing, manufacturing and/or marketing or products or services incorporating such information. You hereby waive and release us from any interest or claims you may have had, have or in the future have in any such ideas, inventions, concepts, techniques or know-how, and in any such developments, manufactures, products or services. Please don’t send us any such communications if you want to preserve any interest or rights you may have in them.</p>

                <div className="fab-c-signup-form__header">
                    <h3 className="fab-c-signup-form__sub-heading">7. Respecting the Intellectual Property Rights of Others</h3>
                </div>

                <p>Fabled respects intellectual property rights. If you believe that any Content on the Site infringes on another’s intellectual property rights you may contact us with the contact information on the Site. Fabled reserves the right to remove Content and User Content without prior notice. Fabled may also terminate a Users’ access to the Site, immediately and without notice, if Fabled believes, in its sole discretion, that the User is infringing any third party rights (including but not limited to any copyright, patent, trademark, trade secret, other intellectual property rights, moral rights, privacy rights or publicity rights).</p>

                <div className="fab-c-signup-form__header">
                    <h3 className="fab-c-signup-form__sub-heading">8. Indemnity</h3>
                </div>

                <p>You agree to indemnify and hold harmless the Fabled Parties from any damage, liability, cost, expense, loss, claim or demand (collectively, “Losses”) including reasonable lawyers’ fees, due to or arising out of your use of the Site or the Services, including your breach of this Agreement, and including also any claims that Content provided by you to us or Members infringes the intellectual property or other rights of any third party.</p>

                <div className="fab-c-signup-form__header">
                    <h3 className="fab-c-signup-form__sub-heading">9. Disclaimers</h3>
                </div>

                <ol>
                    <li>Nothing on Our Site constitutes advice on which you should rely.  It is provided for general information purposes only.  </li>
                    <li>Insofar as is permitted by law, We make no representation, warranty, or guarantee that Our Site will meet your requirements, that it will not infringe the rights of third parties, that it will be compatible with all software and hardware, or that it will be secure.</li>
                    <li>Nothing on Our Site constitutes advice on which you should rely.  It is provided for general information purposes only.  </li>
                    <li>Insofar as is permitted by law, We make no representation, warranty, or guarantee that Our Site will meet your requirements, that it will not infringe the rights of third parties, that it will be compatible with all software and hardware, or that it will be secure.</li>
                    <li>If, as a result of Our failure to exercise reasonable care and skill, any digital content from Our Site created by Us (that is not User Content) damages your device or other digital content belonging to you, you may be entitled to certain legal remedies.  For more details concerning your rights and remedies as a consumer, please contact your local Citizens Advice Bureau or Trading Standards Office.</li>
                    <li>We make reasonable efforts to ensure that Our Content on Our Site is complete, accurate, and up-to-date.  We do not, however, make any representations, warranties or guarantees (whether express or implied) that such Content is complete, accurate, or up-to-date.</li>
                    <li>We are not responsible for the content or accuracy of, or for any opinions, views, or values expressed in User Content.  Any such opinions, views, or values are those of the relevant User and do not reflect Our opinions, views, or values in any way.</li>
                </ol>

                <div className="fab-c-signup-form__header">
                    <h3 className="fab-c-signup-form__sub-heading">10. Limitation of Liability</h3>
                </div>

                <ol>
                    <li>To the fullest extent permissible by law, We accept no liability to any User for any loss or damage, whether foreseeable or otherwise, in contract, tort (including negligence), for breach of statutory duty, or otherwise, arising out of or in connection with the use of (or inability to use) Our Site or the use of or reliance upon any Content (whether it is provided by Us or whether it is User Content) included on Our Site.</li>
                    <li>To the fullest extent permissible by law, We exclude all representations, warranties, and guarantees (whether express or implied) that may apply to Our Site or any Content (including User Content) included on Our Site.</li>
                    <li>Our Site is intended for non-commercial use only.  If you are a business user, We accept no liability for loss of profits, sales, business or revenue; loss of business opportunity, goodwill or reputation; loss of anticipated savings; business interruption; or for any indirect or consequential loss or damage.</li>
                    <li>We exercise all reasonable skill and care to ensure that Our Site is free from viruses and other malware. Subject to sub-Clause 9.5, We accept no liability for any loss or damage resulting from a virus or other malware, a distributed denial of service attack, or other harmful material or event that may adversely affect your hardware, software, data or other material that occurs as a result of your use of Our Site (including the downloading of any Content (including User Content) from it) or any other site referred to on Our Site.</li>
                    <li>We neither assume nor accept responsibility or liability arising out of any disruption or non-availability of Our Site resulting from external causes including, but not limited to, ISP equipment failure, host equipment failure, communications network failure, natural events, acts of war, or legal restrictions and censorship.</li>
                    <li>Nothing in these Terms and Conditions excludes or restricts Our liability for fraud or fraudulent misrepresentation, for death or personal injury resulting from negligence, or for any other forms of liability which cannot be excluded or restricted by law.  For full details of consumers’ legal rights, including those relating to digital content, please contact your local Citizens’ Advice Bureau or Trading Standards Office.</li>
                </ol>

                <div className="fab-c-signup-form__header">
                    <h3 className="fab-c-signup-form__sub-heading">11. Viruses, Malware and Security</h3>
                </div>

                <ol>
                    <li>We exercise all reasonable skill and care to ensure that Our Site is secure and free from viruses and other malware.  We do not, however, guarantee that Our Site is secure or free from viruses or other malware and accept no liability in respect of the same, as detailed in sub-Clause 10.4.</li>
                    <li>You are responsible for protecting your hardware, software, data and other material from viruses, malware, and other internet security risks.</li>
                    <li>You must not deliberately introduce viruses or other malware, or any other material which is malicious or technologically harmful either to or via Our Site.</li>
                    <li>You must not attempt to gain unauthorised access to any part of Our Site, the server on which Our Site is stored, or any other server, computer, or database connected to Our Site.</li>
                    <li>You must not attack Our Site by means of a denial of service attack, a distributed denial of service attack, or by any other means.</li>
                    <li>By breaching the provisions of sub-Clauses 11.3 to 11.5 you may be committing a criminal offence under the Computer Misuse Act 1990.  Any and all such breaches will be reported to the relevant law enforcement authorities and We will cooperate fully with those authorities by disclosing your identity to them.  Your right to use Our Site will cease immediately in the event of such a breach.</li>
                </ol>

                <div className="fab-c-signup-form__header">
                    <h3 className="fab-c-signup-form__sub-heading">12. Electronic Communications</h3>
                </div>

                <ol>
                    <li>The communications between you and Fabled use electronic means, whether you visit the Site or use the Service or send Fabled e-mails, or whether Fabled posts notices on or through the Site or communicates with you via e-mail. For contractual purposes, you (a) consent to receive communications from Fabled in an electronic form; and (b) agree that all terms and conditions, agreements, notices, disclosures, and other communications that Fabled provides to you electronically satisfy any legal requirement that such communications would satisfy if it were be in writing. The foregoing does not affect your statutory rights.</li>
                    <li>If you have an Account, We may from time to time send you important notices by email to the parent/guardian email supplied at the time of signing up.  Such notices may relate to matters including, but not limited to, service changes, service updates, changes to these Terms and Conditions, and changes to your Account.</li>
                    <li>We may also send Fabled newsletters which may include promotional and marketing information for the Fabled service.  You may opt out at any time.  Any and all marketing emails sent by Us include an unsubscribe link. </li>
                    <li>For questions or complaints about communications from Us (including, but not limited to marketing emails), please contact Us at messages@fabledkids.com</li>
                </ol>

                <div className="fab-c-signup-form__header">
                    <h3 className="fab-c-signup-form__sub-heading">13. Termination</h3>
                </div>

                <p>This Agreement continues until terminated by either Fabled or you. Fabled reserves the right to refuse access to the Service to any person at any time for any reason, or for no reason, in its sole discretion.</p>

                <p>Fabled may terminate this Agreement and your account, delete your account and any Content or information that you have posted on the Site or and/or prohibit you from using or accessing the Service or the Site (or any portion, aspect or feature of the Service or the Site) for any reason, or no reason, at any time in its sole discretion, with or without notice. If we terminate your account or this Agreement, our license to your User Content shall continue as described in Section 3 of this Agreement.</p>

                <p>If you terminate this Agreement, the effective time of termination is the later of the effective time you specify in your notice and a reasonable period of time, not less than 30 days, after we receive your notice. After termination of your license to us with respect to all of the User Content you contribute to the Site or termination of your account, you will no longer have access to your account and all information contained therein may be deleted. If you need assistance, please contact us at messages@fabledkids.com.</p>

                <div className="fab-c-signup-form__header">
                    <h3 className="fab-c-signup-form__sub-heading">14. Law and Jurisdiction</h3>
                </div>

                <ol>
                    <li>These Terms and Conditions, and the relationship between you and Us (whether contractual or otherwise) shall be governed by, and construed in accordance with, English law.</li>
                    <li>If you are a consumer, any disputes concerning these Terms and Conditions, the relationship between you and Us, or any matters arising therefrom or associated therewith (whether contractual or otherwise) shall be subject to the jurisdiction of the courts of England, Wales, Scotland, or Northern Ireland, as determined by your residency.</li>
                    <li>If you are a business, any disputes concerning these Terms and Conditions, the relationship between you and Us, or any matters arising therefrom or associated therewith (whether contractual or otherwise) shall be subject to the exclusive jurisdiction of the courts of England and Wales.</li>
                </ol>

                <div className="fab-c-signup-form__header">
                    <h3 className="fab-c-signup-form__sub-heading">15. General</h3>
                </div>

                <p>You and Fabled are independent contractors, and no agency, partnership, joint venture, employee-employer or franchisor-franchisee relationship is intended or created by this Agreement. The licenses granted by you to Fabled herein shall pass with any transfer by you of the copyright in such Content. You may not transfer or assign this Agreement. Fabled may without notice or consent assign its rights and obligations under this Agreement. The Agreement, including the Privacy Policy, the information provided to you and by you during the registration process, and any other documents that are incorporated into this Agreement by reference, constitute the entire agreement between you and Fabled with respect to the subject matter hereof, superseding any prior agreements between you and Fabled (including, but not limited to, any prior versions of the Agreement). Your use of the Services includes the ability to enter into agreements and/or to make transactions electronically. You acknowledge that your electronic submissions constitute your agreement and intent to be bound by and to pay for such agreements and transactions. Your agreement and intent to be bound by electronic submissions applies to all records relating to all transactions you enter into on this Site, including notices of cancellation, policies, contracts, and applications. We are not responsible for typographic errors. </p>


                <div className="fab-c-signup-form__header">
                    <h2 className="fab-c-signup-form__heading">Privacy Policy</h2>
                </div>

                <p>Last Updated: November 2017</p>

                <p>This is the Privacy Policy of the Fabled website and any mobile or other services that we provide (together, the ”Site”), operated by Fabled. (”Fabled”, ”us”, ”our” and ”we” and terms of similar meaning). It describes the information that we collect from you as part of the normal operation of our Site, and how we use and disclose that information.</p>

                <p>By accepting the Privacy Policy in registration or by visiting and using the Site, you expressly consent to our collection, use and disclosure of your personal information in accordance with this Privacy Policy. This Privacy Policy is incorporated into and subject to our Terms of Service.</p>

                <p>In this Privacy Policy, we use the term ”Member” to describe a registered user of the Site. </p>

                <div className="fab-c-signup-form__header">
                    <h3 className="fab-c-signup-form__sub-heading">Information That We Collect</h3>
                </div>

                <p>When your computer or mobile device contacts our web servers (for example, when you visit this Site, or view an HTML e-mail), our web servers automatically collect usage information. Such usage information includes information about how our visitors use and navigate the Fabled Platform. It can include the number and frequency of visitors to each web page and the length of their stays, browser type, referrer data that identifies the web page visited prior and subsequent to visiting the Fabled Platform and IP addresses (see below for more information on IP addresses). We also may determine the applicable technology available in order to serve you the most appropriate version of a web page, e-mail, advertising or promotional announcement or similar service. This information is used for internal purposes only to analyze and improve this Site and to provide you with a more fulfilling and relevant experience. This information is not tied to any personally identifiable information we may have collected from users (when applicable).</p>

                <p>Like many websites and applications, we may use browser ”cookies” on the fabledkids.com website and local storage on the Fabled application. Cookies and local storage are a way of remembering who you are based on your device. A cookie is a small text file that is stored on your computer’s hard drive or stored temporarily in your computer’s memory. There are two kinds of cookies: those that are ”session” oriented and those that are ”persistent”. Session cookies delete from your computer when you close your browser. Persistent cookies retain information for later use tomorrow, next week, next month, or whenever they are set to expire. We use cookies to help us to identify account holders and to optimize their experience on the Fabled Platform. Also, we will use cookies to monitor and maintain information about your use of the Fabled Platform. Most web browsers accept cookies automatically. You can delete cookies manually or set your browser to automatically delete cookies on a pre-determined schedule. For example, in the Internet Explorer menu bar, select: Tools →Internet Options→ Browsing History→ Delete to view manual and automatic options. If you decline to accept cookies, you may not be able to take advantage of or participate in certain features of the Fabled Platform. Cookies are used for internal purposes only and are not linked to personally identifiable information about you.</p>

                <p>An IP address is a number that is assigned to your computer or network when you are linked to the Internet. When you request pages from the Fabled Platform, our servers log your IP address. We may use IP address for a number of internal operations purposes, such as system administration, to report aggregate information or to audit the use of the Fabled Platform.</p>

                <div className="fab-c-signup-form__header">
                    <h3 className="fab-c-signup-form__sub-heading">Our Use of Your Information</h3>
                </div>

                <p>Any personal information provided in your Account will be collected, used and held in accordance with your rights and Our obligations under the Data Protection Act 1998.</p>

                <p>We may use your personal information to:</p>

                <ul>
                    <li>Provide and administer your Account;</li>
                    <li>Reply to any communications you send to Us;</li>
                    <li>Send you important notices;</li>
                    <li>Send occasional newsletters or other email communications related to the service;</li>
                </ul>

                <p>We will not pass on your personal information to any third parties</p>

                <p>Aggregate Information: We may disclose non-identifying, aggregated user statistics to third parties for a variety of purposes, including describing our services to prospective partners and other third parties. Examples of such non-personal information include the number of users who visited the Fabled Platform during a specific time.</p>

            </div>

        </div>
    </AuthFormWrapper>
)

export default Terms
