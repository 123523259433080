import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { rgba } from 'polished'
import { colors } from '../../styles'
import InputLabel from './InputLabel'

const SelectInputField = props => (
    <div>
        <StyledSelectInput
            id={ props.id }
            name={ props.name }
            onChange={ props.onChange }
            disabled={ props.disabled }
            value={ props.value }
        >
            {
                props.options.map( option => (
                    <option
                        key={ option.value }
                        value={ option.value }
                    >
                        { option.label }
                    </option>
                ) )
            }
        </StyledSelectInput>
        <InputLabel
            htmlFor={ props.id }
            minimized={ !!( ( props.hasFocus || props.value ) ) }
        >
            { props.label }
        </InputLabel>
    </div>
)

SelectInputField.propTypes = {
    id: PropTypes.string,
    name: PropTypes.string,
    label: PropTypes.string,
    options: PropTypes.arrayOf( PropTypes.shape( {
        value: PropTypes.string,
        label: PropTypes.string,
    } ) ),
    value: PropTypes.string,
    onChange: PropTypes.func,
    hasFocus: PropTypes.bool,
    disabled: PropTypes.bool,
}

SelectInputField.defaultProps = {
    id: '',
    name: '',
    label: '',
    options: [],
    value: '',
    onChange: null,
    hasFocus: false,
    disabled: false,
}

const StyledSelectInput = styled.select`
    -webkit-appearance: none;
    z-index: 1;
    width: 100%;
    padding: 30px 16px 16px;
    font-size: inherit;
    line-height: inherit;
    font-weight: 700;
    border: 1px solid ${ colors.purple };
    border-radius: 2px;
    background: transparent;
    color: ${ colors.purple };
    transition: all .3s ease-out;

    &:focus {
        outline: none;
        box-shadow: inset 0 0 0 3px ${ rgba( colors.purple, 0.3 ) };
    }
`

export default SelectInputField
