import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import actionCreators from '../../actions/actionCreators'
import { getThemesEnabled } from '../../selectors/themes'
import DataLoader from './DataLoader'

const SimpleThemeStatusLoader = ( props ) => {
    const query = 'themesEnabled'

    const toggle = ( boolValue ) => {
        if ( boolValue ) {
            props.themesEnable()
        }
        else {
            props.themesDisable()
        }
    }

    const onSuccess = ( response ) => {
        toggle( response )
    }

    const listener = {
        events: ['patched'],
        key: 'themeStatusLoader',
        listener: ( serviceName, eventName, message ) => {
            if ( message.name === query ) {
                toggle( message.content.content )
            }
        },
    }

    const render = propsFromDataLoader => props.render( {
        themesEnabled: props.themesEnabled,
        ...propsFromDataLoader,
    } )

    return (
        <DataLoader
            service="globals"
            method="get"
            query={ query }
            listener={ listener }
            onSuccess={ onSuccess }
            render={ render }
            waitForAuth={ props.waitForAuth }
        />
    )
}

SimpleThemeStatusLoader.propTypes = {
    waitForAuth: PropTypes.bool,
    render: PropTypes.func.isRequired,
    themesEnabled: PropTypes.bool,
    themesEnable: PropTypes.func.isRequired,
    themesDisable: PropTypes.func.isRequired,
}

SimpleThemeStatusLoader.defaultProps = {
    waitForAuth: false,
    themesEnabled: null,
}

const mapStateToProps = state => ( {
    themesEnabled: getThemesEnabled( state ),
} )

const mapDispatchToProps = {
    themesEnable: actionCreators.themes.enable,
    themesDisable: actionCreators.themes.disable,
}

export default connect( mapStateToProps, mapDispatchToProps )( SimpleThemeStatusLoader )
