// @flow
import React from 'react'
import type { Node } from 'react'
import type { User } from 'fabled/types/User'
import styled, { css } from 'styled-components'
import { rgba } from 'polished'
import colors from 'fabled/styles/colors'
import UserAvatar from './UserAvatar'

type Props = {
    user: User,
    layout?: 'card' | 'row',
    children?: Node,
}

const UserRow = ( props: Props ) => {
    const { user } = props
    const userTitle = user.firstName || user.username || '…'
    const username = user.firstName
        ? <StyledUsername>{ `username: ${ user.username || '…' }` }</StyledUsername>
        : null

    return (
        <StyledUserRow layout={ props.layout }>
            <UserAvatar user={ props.user } size={ props.layout === 'card' ? 72 : 46 } />
            <StyledUserDetails>
                <StyledUserTitle>{ userTitle }</StyledUserTitle>
                { username }
            </StyledUserDetails>
            <StyledUserRowContent>
                { props.children }
            </StyledUserRowContent>
        </StyledUserRow>
    )
}

UserRow.defaultProps = {
    children: null,
    layout: 'row',
}

const StyledUserDetails = styled.div``

const StyledUserTitle = styled.span`
    margin-left: 10px;
    margin-right: 10px;
    font-weight: bold;
`

const StyledUsername = styled.span`
    margin-right: 10px;
    font-weight: normal;
`

const StyledUserRowContent = styled.div`
    margin-left: auto;
`

const StyledUserRow = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    padding: 0.4em 0;
    border-bottom: 1px solid ${ rgba( colors.purple, 0.3 ) };

    &:last-child {
        border-bottom: 0;
    }

    ${ p => p.layout === 'card' && css`
        flex-direction: column;
        padding-top: 16px;
        padding-bottom: 16px;

        ${ StyledUserDetails } {
            margin-top: 10px;
            margin-bottom: 16px;
            text-align: center;
        }

        ${ StyledUsername } {
            display: block;
            margin-left: 10px;
        }

        ${ StyledUserRowContent } {
            margin-left: inherit;
        }
    ` }
`

export default UserRow
