import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import isEqual from 'lodash/isEqual'
import isString from 'lodash/isString'
import isNumber from 'lodash/isNumber'
import isObjectLike from 'lodash/isObjectLike'
import isFunction from 'lodash/isFunction'
import { getAuthUser } from 'fabled/selectors/users'
import Api from 'fabled/api'
import listeners from 'fabled/api/listeners'

const DataLoader = class extends React.Component {
    constructor( props ) {
        super( props )
        this.state = {
            isLoading: false,
            isComplete: false,
            error: null,
        }
        this.mounted = null
    }

    componentDidMount() {
        this.mounted = true
        this.registerListener( this.props.listener )
        this.load()
    }

    componentDidUpdate( prevProps ) {
        if (
            // if query has changed
            !isEqual( prevProps.query, this.props.query )
            // if user has just authenticated
            || ( !prevProps.authUser && this.props.authUser )
            // if user has logged out
            || ( prevProps.authUser && !this.props.authUser )
            // if authenticated user ID has changed
            || (
                prevProps.authUser
                && this.props.authUser
                && prevProps.authUser.id !== this.props.authUser.id
            )
        ) {
            this.load()
        }
    }

    componentWillUnmount() {
        this.unregisterListener( this.props.listener )
        this.mounted = false
    }

    registerListener( listener ) {
        if ( listener ) {
            listeners.createAndAdd(
                listener.service || this.props.service,
                listener.events,
                listener.key,
                listener.listener,
            )
        }
    }

    unregisterListener( listener ) {
        if ( listener ) {
            listeners.remove(
                listener.service || this.props.service,
                listener.events,
                listener.key,
            )
        }
    }

    load() {
        // if the waitForAuth prop is true and there’s no
        // authenticated user, or if there’s no query, don’t
        // start loading and set isComplete state to false
        if (
            ( this.props.waitForAuth && !this.props.authUser )
            || !this.props.query
        ) {
            if ( this.state.isComplete ) {
                this.setState( { isComplete: false } )
            }
            return
        }

        const useService = isFunction( this.props.service )
            ? this.props.service( this.props.authUser )
            : this.props.service

        this.loadWithQuery(
            useService,
            this.props.method,
            this.props.query,
        )
    }

    loadWithQuery( service, method, query ) {
        // TODO: a way to check if data meeting this critera are already
        // in the redux store

        // if we’re already loading, don’t load
        // TODO: compare query and load latest only
        if ( this.state.isLoading ) {
            return
        }

        if (
            ( method === 'get' && !isString( query ) && !isNumber( query ) )
            || ( method === 'find' && !isObjectLike( query ) )
        ) {
            throw new Error( `Query does not match method. method: ${ method }, query type: ${ typeof query }` )
        }

        this.setState( { isLoading: true, isComplete: false } )
        const payload = isString( query ) || isNumber( query ) ? query : { query }
        Api.service( service )[ method ]( payload )
            .then( response => this.handleSuccess( response ) )
            .catch( error => this.handleError( error ) )
    }

    handleSuccess( response ) {
        if ( this.props.onSuccess ) {
            this.props.onSuccess( response, Api )
        }

        if ( this.props.onComplete ) {
            this.props.onComplete( response, Api )
        }

        if ( this.mounted ) {
            this.setState( { isLoading: false, isComplete: true } )
        }
    }

    handleError( error ) {
        if ( this.props.onError ) {
            this.props.onError( error, Api )
        }

        if ( this.props.onComplete ) {
            this.props.onComplete( error, Api )
        }

        if ( this.mounted ) {
            this.setState( { error, isLoading: false, isComplete: true } )
        }
    }

    render() {
        const loaderState = {
            isLoading: this.state.isLoading,
            isComplete: this.state.isComplete,
            error: this.state.error,
        }

        if ( this.props.waitForAuth && !this.props.authUser ) {
            loaderState.isComplete = false
        }

        return this.props.render( {
            loaderState,
            authUser: this.props.authUser,
        } )
    }
}

DataLoader.propTypes = {
    waitForAuth: PropTypes.bool,
    authUser: PropTypes.shape( {
        id: PropTypes.number,
    } ),
    service: PropTypes.oneOfType( [
        PropTypes.string,
        PropTypes.func,
    ] ).isRequired,
    method: PropTypes.oneOf( [
        'find',
        'get',
    ] ),
    query: PropTypes.oneOfType( [
        PropTypes.object, // eslint-disable-line react/forbid-prop-types
        PropTypes.string,
        PropTypes.number,
    ] ),
    listener: PropTypes.oneOfType( [
        PropTypes.func,
        PropTypes.shape( {
            service: PropTypes.string,
            listener: PropTypes.func,
        } ),
    ] ),
    onSuccess: PropTypes.func,
    onError: PropTypes.func,
    onComplete: PropTypes.func,
    render: PropTypes.func.isRequired,
}

DataLoader.defaultProps = {
    waitForAuth: false,
    authUser: null,
    method: 'find',
    query: null,
    listener: null,
    onSuccess: null,
    onError: null,
    onComplete: null,
}

const mapStateToProps = state => ( {
    authUser: getAuthUser( state ),
} )

export default connect( mapStateToProps )( DataLoader )
