import { handleActions } from 'redux-actions'
import { dataObject } from './helpers'
import actionCreators from '../actions/actionCreators'

const stories = handleActions(
    dataObject( actionCreators, 'stories.data', true ),
    {
        data: {},
    },
)

export default stories
