/* global module, require */
/* eslint-disable no-underscore-dangle */
import { createStore, compose, applyMiddleware } from 'redux'
import rootReducer from './reducers/index'
import { actionEventTracker } from './eventTracker'

let composeEnhancers = compose

if (
    typeof window !== 'undefined'
    && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
) {
    composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
}

const store = createStore(
    rootReducer,
    composeEnhancers( applyMiddleware( actionEventTracker ) ),
)

if ( module.hot ) {
    module.hot.accept( () => {
        const nextRootReducer = require( './reducers/index' ).default // eslint-disable-line global-require
        store.replaceReducer( nextRootReducer )
    } )
}

export default store
