function currentChapterIncrement( state ) {
    return state
}

function currentChapterDecrement( state ) {
    return state
}

function story( state = 1, action ) {
    switch ( action.type ) {
        case 'CURRENT_CHAPTER_INCREMENT': return currentChapterIncrement( state, action )
        case 'CURRENT_CHAPTER_DECREMENT': return currentChapterDecrement( state, action )
        default: return state
    }
}

export default story
