import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Raven from 'raven-js'
import isEqual from 'lodash/isEqual'
import pick from 'lodash/pick'
import actionCreators from 'fabled/actions/actionCreators'
import { getAuthUser } from 'fabled/selectors/users'
import Api from 'fabled/api'

class Authentication extends Component {
    constructor( props ) {
        super( props )
        this.onAuthenticated = this.onAuthenticated.bind( this )
        this.onLogout = this.onLogout.bind( this )
        this.updateAuthUser = this.updateAuthUser.bind( this )
        this.onUserChangedEvent = this.onUserChangedEvent.bind( this )
    }

    componentDidMount() {
        Api.onAuthenticated( message => this.onAuthenticated( message ) )
        Api.onLogout( () => this.onLogout( false ) )
        Api.authenticate().catch( () => this.onLogout() )
    }

    componentDidUpdate( prevProps ) {
        const prev = prevProps.authUser
            ? pick( prevProps.authUser, ['id', 'username', 'email'] )
            : {}

        const current = this.props.authUser
            ? pick( this.props.authUser, ['id', 'username', 'email'] )
            : {}

        if ( !isEqual( prev, current ) ) {
            /**
             * Add user info to Sentry logs
             */
            if ( process.env.NODE_ENV !== 'development' ) {
                Raven.setUserContext( {
                    id: current.id,
                    username: current.username,
                    email: current.email,
                } )
            }
        }
    }

    componentWillUnmount() {
        Api.service( 'me' ).off( ['patched', 'updated'], this.onUserChangedEvent )
    }

    onAuthenticated() {
        Api.service( 'me' ).get()
            .then( ( authUser ) => {
                this.props.authFailedUnset()
                this.updateAuthUser( authUser )
                Api.service( 'me' ).on( ['patched', 'updated'], this.onUserChangedEvent )
            } )
    }

    onUserChangedEvent( eventName, message ) {
        this.updateAuthUser( message )
    }

    onLogout( withRedirect = true ) {
        if ( !withRedirect ) {
            this.props.authNoRedirect( true )
        }

        this.props.uiHeaderNoticeSet( null )
        this.props.authUserIdUnset()
        this.props.authFailedSet()
        this.props.authNoRedirect( false )

        /**
         * Remove user info from Sentry logs
         */
        if ( process.env.NODE_ENV !== 'development' ) {
            Raven.setUserContext()
        }
    }

    updateAuthUser( authUser ) {
        this.props.usersUpdateOrAdd( authUser )
        this.props.authUserIdSet( authUser.id )
    }

    render() {
        return <div>{ this.props.children }</div>
    }
}

Authentication.propTypes = {
    // from connect
    authUser: PropTypes.shape( {
        id: PropTypes.number.isRequired,
        username: PropTypes.string,
        email: PropTypes.string,
    } ),
    authUserIdSet: PropTypes.func.isRequired,
    authUserIdUnset: PropTypes.func.isRequired,
    authFailedSet: PropTypes.func.isRequired,
    authFailedUnset: PropTypes.func.isRequired,
    authNoRedirect: PropTypes.func.isRequired,
    usersUpdateOrAdd: PropTypes.func.isRequired,
    uiHeaderNoticeSet: PropTypes.func.isRequired,
    children: PropTypes.arrayOf( PropTypes.oneOfType( [
        PropTypes.arrayOf( PropTypes.node ),
        PropTypes.node,
    ] ) ).isRequired,
}

Authentication.defaultProps = {
    authUser: null,
}

const mapStateToProps = state => ( {
    authUser: getAuthUser( state ),
} )

const mapDispatchToProps = {
    authUserIdSet: actionCreators.auth.userId.set,
    authUserIdUnset: actionCreators.auth.userId.unset,
    authFailedSet: actionCreators.auth.failed.set,
    authFailedUnset: actionCreators.auth.failed.unset,
    authNoRedirect: actionCreators.auth.noRedirect,
    usersUpdateOrAdd: actionCreators.users.data.updateOrAdd,
    uiHeaderNoticeSet: actionCreators.ui.notice.set,
}

export default connect( mapStateToProps, mapDispatchToProps )( Authentication )
