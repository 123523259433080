import React from 'react'
import PropTypes from 'prop-types'
import InputWrapper from './InputWrapper'
import SelectInputField from './SelectInputField'
import ValidationMessage from './ValidationMessage'

class SelectInput extends React.Component {
    constructor( props ) {
        super( props )
        this.state = {
            value: props.value || '',
            shouldValidate: false,
            validated: null,
            validationMessage: null,
        }

        this.handleChange = this.handleChange.bind( this )
    }

    componentDidUpdate( prevProps, prevState ) {
        const { state: { value } } = this

        if (
            value !== prevState.value
            || ( !prevState.shouldValidate && this.state.shouldValidate )
        ) {
            this.validate( value )
                .then( ( validated ) => {
                    if ( validated && this.props.onChange instanceof Function ) {
                        this.props.onChange( value )
                    }
                } )
        }
    }

    handleChange( e ) {
        if ( this.props.disabled ) {
            return
        }

        const { target: { value } } = e
        this.setState( { value } )

        if ( !this.state.shouldValidate ) {
            this.setState( { shouldValidate: true } )
        }

        if ( this.props.setFormState instanceof Function ) {
            this.props.setFormState( e.target.name, e.target.value )
        }
    }

    validate( value ) {
        const self = this

        if ( this.state.shouldValidate && this.props.validator instanceof Function ) {
            // Do this async so that we can optionally use validators that return promises
            return new Promise( ( resolve ) => {
                resolve( this.props.validator( value ) )
            } )
                .then( ( result ) => {
                    // result is an error message string, or true
                    const validated = ( result === true )
                    const validationMessage = ( result !== true ) ? result : null

                    if ( self.props.formValidator instanceof Function ) {
                        self.props.formValidator( self.props.id, validated, validationMessage )
                    }

                    self.setState( { validated, validationMessage } )

                    return result
                } )
                .then( result => result === true )
        }

        if ( self.props.formValidator instanceof Function ) {
            self.props.formValidator( self.props.id, true, null )
        }

        self.setState( { validated: true, validationMessage: null } )

        return Promise.resolve( true )
    }

    render() {
        const valid = this.props.disableValidationIcon ? null : this.state.validated
        const validationMessage = this.state.validationMessage || <span>&nbsp;</span>

        return (
            <InputWrapper valid={ valid }>
                <SelectInputField
                    id={ this.props.id }
                    name={ this.props.name }
                    label={ this.props.label }
                    onChange={ this.handleChange }
                    disabled={ this.props.disabled }
                    value={ this.state.value }
                    options={ this.props.options }
                />
                {
                    this.props.disableValidationMessage
                        ? null
                        : <ValidationMessage>{ validationMessage }</ValidationMessage>
                }
            </InputWrapper>
        )
    }
}

SelectInput.propTypes = {
    value: PropTypes.string,
    disabled: PropTypes.bool,
    setFormState: PropTypes.func,
    validator: PropTypes.func,
    onChange: PropTypes.func,
    disableValidationMessage: PropTypes.bool,
    disableValidationIcon: PropTypes.bool,
    id: PropTypes.string,
    name: PropTypes.string,
    label: PropTypes.string,
    options: PropTypes.arrayOf( PropTypes.shape( {
        value: PropTypes.string,
        label: PropTypes.string,
    } ) ),
}

SelectInput.defaultProps = {
    value: '',
    disabled: false,
    setFormState: null,
    validator: null,
    onChange: null,
    disableValidationMessage: false,
    disableValidationIcon: false,
    id: '',
    name: '',
    label: '',
    options: [],
}

export default SelectInput
