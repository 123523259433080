import React from 'react'
import PropTypes from 'prop-types'
import DataLoaderGroup from './DataLoaderGroup'
import SimpleStoryPromptLoader from './SimpleStoryPromptLoader'
import SimpleStoryPromptStatusLoader from './SimpleStoryPromptStatusLoader'

const createRenderer = ( LoadingComponent, SuccessComponent, ErrorComponent ) => {
    const Renderer = ( props ) => {
        const { loaderStates, storyPrompt, storyPromptsEnabled } = props

        if ( !loaderStates.all.isComplete ) {
            return <LoadingComponent />
        }
        if ( loaderStates.all.error ) {
            return <ErrorComponent error={ loaderStates.all.error } />
        }

        return (
            <SuccessComponent
                storyPrompt={ storyPrompt }
                storyPromptsEnabled={ storyPromptsEnabled }
            />
        )
    }

    Renderer.propTypes = {
        loaderStates: PropTypes.shape( {
            all: PropTypes.shape( {
                isLoading: PropTypes.bool.isRequired,
                isComplete: PropTypes.bool.isRequired,
                error: PropTypes.string,
            } ),
        } ).isRequired,
        storyPrompt: PropTypes.shape( {
            id: PropTypes.number,
        } ),
        storyPromptsEnabled: PropTypes.bool,
    }

    Renderer.defaultProps = {
        storyPrompt: null,
        storyPromptsEnabled: null,
    }

    return Renderer
}

const StoryPromptLoader = ( props ) => {
    const {
        waitForAuth,
        loadingComponent: LoadingComponent,
        successComponent: SuccessComponent,
        errorComponent: ErrorComponent,
    } = props

    const loaders = [
        {
            key: 'storyPrompt',
            loader: render => (
                <SimpleStoryPromptLoader
                    waitForAuth={ waitForAuth }
                    render={ render }
                />
            ),
        },
        {
            key: 'storyPromptStatus',
            loader: render => (
                <SimpleStoryPromptStatusLoader
                    waitForAuth={ waitForAuth }
                    render={ render }
                />
            ),
        },
    ]

    return (
        <DataLoaderGroup
            loaders={ loaders }
            render={ createRenderer( LoadingComponent, SuccessComponent, ErrorComponent ) }
        />
    )
}

StoryPromptLoader.propTypes = {
    waitForAuth: PropTypes.bool,
    loadingComponent: PropTypes.func,
    successComponent: PropTypes.func,
    errorComponent: PropTypes.func,
}

StoryPromptLoader.defaultProps = {
    waitForAuth: false,
    loadingComponent: null,
    successComponent: null,
    errorComponent: null,
}

export default StoryPromptLoader
