import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import actionCreators from '../../actions/actionCreators'
import { getUiModal } from '../../selectors/ui'
import modalTypes from './modalTypes'
import BodyScroll from '../BodyScroll'
import StoriesListModal from './StoriesListModal'
import ShareModal from './ShareModal'
import SetCollectionModal from './SetCollectionModal'
import OnboardModal from './OnboardModal'
import DialogModal from './DialogModal'
import PublishModal from './PublishModal'
import ConfirmDeletePost from './ConfirmDeletePost'
import StoryPromptModal from './StoryPromptModal'
import ThemeModal from './ThemeModal'
import NotificationsModal from './NotificationsModal'
import RequestCollaborationModal from './RequestCollaborationModal'
import PassStoryModal from './PassStoryModal'

const Modals = ( { modal, modalClose } ) => {
    const getModalComponent = ( modalId ) => {
        switch ( modalId ) {
            case modalTypes.STORIES_LIST:
                return <StoriesListModal />

            case modalTypes.SHARE:
                return <ShareModal modalClose={ modalClose } storyId={ modal.props.storyId } />

            case modalTypes.SET_COLLECTION:
                return (
                    <SetCollectionModal
                        modalClose={ modalClose }
                        storyId={ modal.props.storyId }
                    />
                )

            case modalTypes.ONBOARD:
                return <OnboardModal modalClose={ modalClose } userId={ modal.props.userId } />

            case modalTypes.DIALOG:
                return (
                    <DialogModal
                        modalClose={ modalClose }
                        title={ modal.props.title }
                        body={ modal.props.body }
                        confirmText={ modal.props.confirmText }
                    />
                )

            case modalTypes.PUBLISH:
                return <PublishModal modalClose={ modalClose } storyId={ modal.props.storyId } />

            case modalTypes.CONFIRM_DELETE_POST:
                return (
                    <ConfirmDeletePost
                        modalClose={ modalClose }
                        postId={ modal.props.postId }
                        postUuid={ modal.props.postUuid }
                    />
                )

            case modalTypes.STORY_PROMPT:
                return <StoryPromptModal modalClose={ modalClose } />

            case modalTypes.THEME:
                return <ThemeModal modalClose={ modalClose } />

            case modalTypes.NOTIFICATIONS:
                return <NotificationsModal userId={ modal.props.userId } />

            case modalTypes.REQUEST_COLLABORATION:
                return <RequestCollaborationModal storyId={ modal.props.storyId } />

            case modalTypes.PASS_STORY:
                return <PassStoryModal storyId={ modal.props.storyId } />

            default:
                return null
        }
    }

    const modalComponent = getModalComponent( modal.id )

    return (
        <BodyScroll shouldScroll={ !modalComponent }>
            { modalComponent }
        </BodyScroll>
    )
}

Modals.propTypes = {
    modal: PropTypes.shape( {
        id: PropTypes.string,
        props: PropTypes.object, /* eslint-disable-line  */
    } ).isRequired,
    modalClose: PropTypes.func.isRequired,
}

const mapStateToProps = state => ( {
    modal: getUiModal( state ),
} )

const mapDispatchToProps = {
    modalClose: actionCreators.ui.modal.close,
}

export default connect( mapStateToProps, mapDispatchToProps )( Modals )
