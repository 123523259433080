import React from 'react'
import PropTypes from 'prop-types'
import { Route, Redirect, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { getAuthFailed, getNoRedirect } from 'fabled/selectors/auth'
import { getAuthUser } from 'fabled/selectors/users'
import Api from 'fabled/api'

const PrivateRoute = ( {
    component: Component,
    roles,
    authUser,
    authFailed,
    noRedirect,
    ...rest
} ) => (
    <Route
        { ...rest }
        render={
            ( props ) => {
                if ( authUser && roles && !roles.includes( authUser.role ) ) {
                    Api.logout()
                }
                else if ( !authUser && authFailed ) {
                    const state = noRedirect ? {} : { from: props.location }

                    return (
                        <Redirect
                            to={ {
                                pathname: '/login',
                                state,
                            } }
                        />
                    )
                }

                return <Component { ...props } />
            }
        }
    />
)

PrivateRoute.propTypes = {
    component: PropTypes.func.isRequired,
    roles: PropTypes.arrayOf( PropTypes.string ),
    location: PropTypes.shape( {
        pathname: PropTypes.string,
    } ).isRequired,

    // from connect
    authUser: PropTypes.shape( {
        id: PropTypes.number,
        role: PropTypes.string,
    } ),
    authFailed: PropTypes.bool,
    noRedirect: PropTypes.bool,
}

PrivateRoute.defaultProps = {
    roles: null,
    authUser: null,
    authFailed: null,
    noRedirect: false,
}

const mapStateToProps = state => ( {
    authUser: getAuthUser( state ),
    authFailed: getAuthFailed( state ),
    noRedirect: getNoRedirect( state ),
} )

export default withRouter( connect( mapStateToProps )( PrivateRoute ) )
