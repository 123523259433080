import React from 'react'
import ScrollToTopOnMount from 'fabled/components/ScrollToTopOnMount'
import PageHeader from 'fabled/components/PageHeader'
import MarketingHero from 'fabled/components/MarketingHero'
import Background from 'fabled/components/Background'
import Section from 'fabled/components/Section'
import SectionTitle from 'fabled/components/SectionTitle'
import SectionText from 'fabled/components/SectionText'
import FeaturesGrid from 'fabled/components/FeaturesGrid'
import FeaturesHero from 'fabled/components/FeaturesHero'
import Button from 'fabled/components/Button'
import HeroButton from 'fabled/components/HeroButton'
import PodcastLink from 'fabled/components/PodcastLink'
import NewsletterForm from 'fabled/components/NewsletterForm'
import LayoutBox from 'fabled/components/LayoutBox'
import Footer from 'fabled/components/Footer'

const heroIllis = [
    {
        url: '/images/inspiration/flying-carpet.svg',
        title: 'Flying Carpet',
        top: '-5%',
        left: 0,
        width: '215px',
    },
    {
        url: '/images/inspiration/fish.svg',
        title: 'Fish',
        top: '20%',
        left: '60%',
        width: '114px',
    },
    {
        url: '/images/inspiration/woolly-mammoth.svg',
        title: 'Wolley Mammoth',
        top: '55%',
        left: '5%',
        width: '40%',
        rotate: '6deg',
    },
    {
        url: '/images/inspiration/cake.svg',
        title: 'Cake',
        top: '80%',
        left: '60%',
        width: '20%',
        rotate: '-10deg',
    },
    {
        url: '/images/inspiration/alien.svg',
        title: 'Alien',
        top: '55%',
        left: '88%',
        width: '12%',
        rotate: '8deg',
    },
]

const features = [
    {
        id: 1,
        title: 'Create and share stories',
        description: 'Fabled is like the best notebook ever - it chats back, gives inspiration, and even publishes! Just sign up and start typing, recording, or uploading.',
        image: '/images/inspiration/submarine.svg',
        color: 'yellow',
    },
    {
        id: 2,
        title: 'Work together with friends',
        description: 'Why not start a story chain and collaborate with a friend? You write a line, they write a line and soon you’re story-storming!',
        image: '/images/inspiration/magic-wand.svg',
        color: 'green',
    },
    {
        id: 3,
        title: 'Totally (in)visible',
        description: 'Your Grown Ups can set up their own Fabled accounts to enjoy your great stories, and create secure one-off links to share your tales.',
        image: '/images/inspiration/telescope.svg',
        color: 'salmon',
    },
]

const featuresTextItems = [
    {
        title: 'Listen',
        body: 'A blank page with no marking, no rules, just for you. Support and encouragement, and that’s all.',
    },
    {
        title: 'Inspire',
        body: 'A nifty button to press if you feel like some extra story sparking ideas.',
    },
    {
        title: 'Celebrate',
        body: 'Publish your stories, then get your Grown Up to help share them for family and friends to enjoy!',
    },
]

const Marketing = () => (
    <>
        <ScrollToTopOnMount />
        <PageHeader bgColor="midBlue" />
        <MarketingHero illis={ heroIllis } illiSmall={ heroIllis[ 0 ] } />
        <Background bgColor="almostWhite">
            <Section top="hero" bottom="hero">
                <SectionTitle color="red">A Child-led Platform for Creating & Sharing Stories.</SectionTitle>
                <FeaturesGrid features={ features } />
            </Section>
            <FeaturesHero textItems={ featuresTextItems } />
        </Background>
        <Background bgColor="red">
            <Section
                top="megaHero"
                bottom="hero"
                columnsContent={ [
                    <>
                        <SectionTitle color="paper" left vPad="medium">Let’s make a story</SectionTitle>
                        <SectionText
                            titleColor="paper"
                            bodyColor="paper"
                        >
                            <p>We think the world would be better with a bit more wonder in it. With more of your stories. Did you know that winners of some of the world’s highest awards (Nobel prizes) also spent more time as kids in imaginary worlds? We think you’re a creative superhero in training, let Fabled be your imagination dojo!</p>
                            <p>We’re on a mission to help you be part of the most imaginative generation the world has ever known. Join us. The future is Make Believe. Make Believe it!</p>
                        </SectionText>
                    </>,
                    <LayoutBox align="center" justify="center">
                        <Button
                            to="/signup"
                            primary
                            arrow
                            fontSize={ 22 }
                        >
                            Start using Fabled now
                        </Button>
                    </LayoutBox>
                    ,
                ] }
            />
        </Background>
        <Background bgColor="almostWhite">
        <Section
        top="hero"
        bottom="hero"
        columnsContent={ [
          <img src="/images/fimb-bluecover.jpg" alt="The Future is Make Believe book cover" />,
          <>
            <SectionTitle color="deepBlue" left vPad="medium">The Future Is Make Believe</SectionTitle>
            <SectionText
              titleColor="deepBlue"
              bodyColor="deepBlue"
            >
              <p>Fall in love with creative writing and fly away with stories with our new book - The Future is Make Believe! A beautifully illustrated treasury of tales and storytelling advice from authors aged 2-12. Who better to inspire you than other kids!</p>
              <p>There are over 40 stories to dive into, and over 20 tips, as well as our Fabled Field Guide to Storytelling — creative exercises and guidance from Fabled. A book on a mission to inspire kids and grown-ups everywhere to make believe better.</p>
              <Button
                inline
                arrow
                bgColor="greenBlue"
                href="https://shop.fabledkids.com/"
              >Buy the book</Button>
            </SectionText>
          </>
        ] }
      />
        </Background>
        <Background bgColor="deepBlue">
            <Section top="hero">
                <SectionTitle color="greenBlue" small>The Fabled Podcast</SectionTitle>
            </Section>
            <Section
                top="none"
                bottom="hero"
                columnsContent={ [
                    <>
                        <SectionTitle color="greenBlue" left vPad="medium">Celebrating Imagination</SectionTitle>
                        <SectionText
                            titleColor="greenBlue"
                            bodyColor="white"
                        >
                            <p>Tune into the Fabled podcast to hear stories galore, and help us celebrate kids’ storytelling and creativity.</p>
                        </SectionText>
                    </>,
                    <>
                        <PodcastLink
              title="The Boring Old Granny"
              date="May 11, 2020"
              url="https://podcasts.apple.com/au/podcast/the-boring-old-granny/id1415540060?i=1000474257895"
            />
            <PodcastLink
              title="Once in a Far Away Land"
              date="May 5, 2020"
              url="https://podcasts.apple.com/au/podcast/once-in-a-far-away-land/id1415540060?i=1000473651506"
            />
            <PodcastLink
              title="The Enchanted Garden"
              date="April 18, 2020"
              url="https://podcasts.apple.com/au/podcast/the-enchanted-garden/id1415540060?i=1000471860619"
            />
                        <HeroButton
                            small
                            to="https://podcasts.apple.com/au/podcast/fabled-kids/id1415540060"
                            color="greenBlue"
                            hoverColor="white"
                        >
                            View more
                        </HeroButton>
                    </>,
                ] }
            />
        </Background>
        <Background bgColor="almostWhite">
            <Section top="hero" bottom="medium">
              <SectionTitle color="purple" small vPad="medium">Get fairies and flying carpets delivered to your inbox!</SectionTitle>
              <SectionText bodyColor="purple" narrow centered>
                <p>The Fabled newsletter shares author tips, inspiration, activities and stories galore. Sign up for our delicious missives of make believe.</p>
              </SectionText>
            </Section>
            <Section top="none" bottom="large">
                <NewsletterForm
                    actionUrl="https://fabledstories.us15.list-manage.com/subscribe/post"
                    mailchimpU="2e60ff9fce7aabca5de2b35e2"
                    mailchimpID="84c5348138"
                />
            </Section>
            <Section top="medium" bottom="medium">
              <SectionText bodyColor="almostBlack" narrow centered>
                <p>Founded by <a href="https://twitter.com/hammlauralee?lang=en" target="_blank" rel="noopener">Laura Hamm</a>. Design and development by <a href="https://madebyfieldwork.com" target="_blank" rel="noopener">Fieldwork</a>.</p>
              </SectionText>
            </Section>
            <Footer />
        </Background>
    </>
)

export default Marketing