import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import actionCreators from 'fabled/actions/actionCreators'
import NotificationsCountLoader from 'fabled/components/loaders/NotificationsCountLoader'
import AppNavItem from './AppNavItem'

const NotificationsNav = ( props ) => {
    const onClick = () => {
        if ( props.onClick ) {
            props.onClick()
        }

        props.modalOpenNotifications( { userId: props.user.id } )
    }

    const navItem = notificationsCount => (
        <AppNavItem
            icon={ { url: '/images/notifications-nav-icon.svg' } }
            onClick={ onClick }
            count={ notificationsCount }
        />
    )

    if ( !props.user ) {
        return navItem( 0 )
    }

    return (
        <NotificationsCountLoader
            userId={ props.user.id }
            render={ ( { notificationsCount } ) => navItem( notificationsCount ) }
        />
    )
}

NotificationsNav.propTypes = {
    user: PropTypes.shape( {
        id: PropTypes.number.isRequired,
    } ),
    onClick: PropTypes.func,

    // from connect
    modalOpenNotifications: PropTypes.func.isRequired,
}

NotificationsNav.defaultProps = {
    user: null,
    onClick: null,
}

const mapDispatchToProps = {
    modalOpenNotifications: actionCreators.ui.modal.openNotifications,
}

export default connect( null, mapDispatchToProps )( NotificationsNav )
