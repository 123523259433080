import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import actionCreators from 'fabled/actions/actionCreators'
import { getNotificationsCount } from 'fabled/selectors/notifications'
import DataLoader from './DataLoader'

const NotificationsCountLoader = ( props ) => {
    const onSuccess = ( response ) => {
        if ( response ) {
            props.notificationsCountSet( response )
        }
    }

    const listener = {
        service: 'notifications',
        events: ['count'],
        key: `userNotificationsCount:${ props.userId }`,
        listener: ( serviceName, eventName, message ) => {
            props.notificationsCountSet( message.count )
        },
    }

    const render = propsFromDataLoader => props.render( {
        notificationsCount: props.notificationsCount,
        ...propsFromDataLoader,
    } )

    return (
        <DataLoader
            service="notificationsCount"
            method="get"
            query={ props.userId }
            onSuccess={ onSuccess }
            listener={ listener }
            render={ render }
            waitForAuth
        />
    )
}

NotificationsCountLoader.propTypes = {
    userId: PropTypes.oneOfType( [
        PropTypes.number,
        PropTypes.arrayOf( PropTypes.number ),
    ] ).isRequired,
    render: PropTypes.func.isRequired,

    // from connect
    notificationsCountSet: PropTypes.func.isRequired,
    notificationsCount: PropTypes.number.isRequired,
}

const mapStateToProps = state => ( {
    notificationsCount: getNotificationsCount( state ),
} )

const mapDispatchToProps = {
    notificationsCountSet: actionCreators.notifications.count.set,
}

export default connect( mapStateToProps, mapDispatchToProps )( NotificationsCountLoader )
