import React from 'react'
import PropTypes from 'prop-types'
import Footer from './Footer'

const AuthFormWrapper = props => (
    <div className="fab-c-signup-wrapper">

        <div className="fab-c-signup-background">
            { props.signupLink ? props.signupLink( 'large-screen' ) : null }
        </div>

        <div className={ `fab-c-signup-panel ${ props.isWide ? 'fab-c-signup-panel--wide' : '' }` }>
            { props.signupLink ? props.signupLink( 'small-screen' ) : null }

            <h1 className="fab-c-signup-panel__header">
                <img src="/images/fabled-logo-signup.svg" alt="Fabled" />
            </h1>

            { props.children }

        </div>

        <Footer />
    </div>
)

AuthFormWrapper.propTypes = {
    signupLink: PropTypes.func,
    isWide: PropTypes.bool,
    children: PropTypes.oneOfType( [
        PropTypes.arrayOf( PropTypes.node ),
        PropTypes.node,
    ] ),
}

AuthFormWrapper.defaultProps = {
    signupLink: null,
    isWide: false,
    children: null,
}

export default AuthFormWrapper
