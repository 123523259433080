import React from 'react'
import PropTypes from 'prop-types'
import MyStoriesButton from 'fabled/components/MyStoriesButton'
import DropdownMenu from 'fabled/components/DropdownMenu'
import UserLogout from './UserLogout'

const UserMenu = ( { user } ) => {
    const menuItems = []

    switch ( user.role ) {
        case 'child':
            menuItems.push( <MyStoriesButton key="My stories" /> )
            break

        case 'parent':
            menuItems.push( {
                text: 'My dashboard',
                href: '/parent-dashboard',
            } )
            break

        case 'admin':
            menuItems.push( {
                text: 'Admin dashboard',
                href: '/admin',
            } )
            break

        default:
            break
    }

    menuItems.push( {
        text: 'My settings',
        href: `/user/${ user.id }/settings`,
    } )
    menuItems.push( <UserLogout key="Log out" /> )

    return (
        <DropdownMenu menuItems={ menuItems } tip="right" marginTop="66" />
    )
}

UserMenu.propTypes = {
    user: PropTypes.shape( {
        role: PropTypes.string,
    } ).isRequired,
}

export default UserMenu
