// @flow
import React from 'react'
import styled from 'styled-components'
import UserAvatar from 'fabled/components/UserAvatar'

type Props = {
    avatar: string,
    onSelect: () => void,
}

type State = {
    hasHover: boolean,
    hasFocus: boolean,
}

class AvatarSelectItem extends React.Component<Props, State> {
    state = {
        hasHover: false,
        hasFocus: false,
    }

    onSelect = ( e: SyntheticEvent<> | SyntheticKeyboardEvent<> ) => {
        if ( e.key && !['Enter', ' '].includes( e.key ) ) {
            return
        }

        e.preventDefault()
        this.props.onSelect()
    }

    onMouseEnter = () => {
        this.setState( { hasHover: true } )
    }

    onMouseLeave = () => {
        this.setState( { hasHover: false } )
    }

    onFocus = () => {
        this.setState( { hasFocus: true } )
    }

    onBlur = () => {
        this.setState( { hasFocus: false } )
    }

    render() {
        const user = {
            id: 0,
            username: this.props.avatar,
            parentId: null,
            avatar: this.props.avatar,
        }

        return (
            <StyledAvatarSelectItem
                onMouseEnter={ this.onMouseEnter }
                onMouseLeave={ this.onMouseLeave }
                onFocus={ this.onFocus }
                onBlur={ this.onBlur }
                onClick={ this.onSelect }
                onKeyDown={ this.onSelect }
            >
                <UserAvatar
                    user={ user }
                    isHighlighted={ this.state.hasHover }
                    hasFocus={ this.state.hasFocus }
                />
            </StyledAvatarSelectItem>
        )
    }
}

const StyledAvatarSelectItem = styled.button`
    display: block;
    flex-grow: 1;
    flex-basis: 25%;
    padding: 0.7em;
    border: 0;
    background: transparent;

    &:focus {
        outline: none;
    }
`

export default AvatarSelectItem
