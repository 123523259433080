import { createSelector } from 'reselect'
import sortBy from 'lodash/sortBy'
import groupBy from 'lodash/groupBy'
import { INSPIRATION, ENCOURAGEMENT } from '../postTypes'

const normalisePostPositions = posts => posts.map( p => ( {
    ...p,
    position: p.position || 0,
} ) )

export const getPosts = state => state.posts.data

export const getPostsAsArray = createSelector(
    getPosts,
    posts => Object.values( posts ),
)

export const getPostsByStoryId = createSelector(
    getPostsAsArray,
    ( state, storyId ) => storyId,
    ( posts, storyId ) => {
        const storyPosts = posts.filter( p => p.storyId === storyId )
        const storyPostsWithPositions = normalisePostPositions( storyPosts )
        return sortBy( storyPostsWithPositions, ['position', 'createdAt'] )
    },
)

export const arrangePostsIntoChapters = posts => Object.entries( groupBy( posts, 'chapterId' ) )
    .map( ( [chapterId, psts] ) => ( {
        id: chapterId * 1,
        key: chapterId,
        posts: psts,
    } ) )

export const visiblePosts = ( posts, includeLastStoryMakerPost ) => posts.filter( ( p, i ) => {
    if ( [INSPIRATION, ENCOURAGEMENT].includes( p.postType ) ) {
        if ( includeLastStoryMakerPost && i === posts.length - 1 ) {
            return true
        }

        return false
    }

    return true
} )
