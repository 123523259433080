import React from 'react'
import { Link } from 'react-router-dom'
import Api from '../api'
import AuthFormWrapper from './AuthFormWrapper'
import Form from './Form'
import TextInput from './FormInputs/TextInput'
import Loader from './Loader'
import { eventTracker } from '../eventTracker'

class Login extends React.Component {
    constructor( props ) {
        super( props )
        this.state = {
            waiting: false,
            failed: false,
            errorMessage: null,
            usernameLabel: 'Nickname',
        }
        this.handleSubmit = this.handleSubmit.bind( this )
        this.setUsernameLabel = this.setUsernameLabel.bind( this )
    }

    setUsernameLabel( usernameLabel ) {
        return ( e ) => {
            e.preventDefault()
            this.setState( { usernameLabel } )
        }
    }

    handleSubmit( e, formComponent ) {
        const authPayload = {
            username: formComponent.getFields().username,
            password: formComponent.getFields().password,
            strategy: 'local-username',
        }

        this.setState( { waiting: true } )

        Api.authenticate( authPayload )
            .catch( () => {
                // try again with thhe local-email strategy
                authPayload.strategy = 'local-email'
                authPayload.email = authPayload.username
                return Api.authenticate( authPayload )
            } )
            .catch( ( error ) => {
                this.setState( {
                    waiting: false,
                    failed: true,
                    errorMessage: 'These log in details don‘t work. Did you mistype them?',
                } )
                eventTracker( {
                    category: 'Users',
                    action: 'Attempted login in via the login form',
                    label: 'Failed',
                } )

                throw error
            } )
    }

    render() {
        const usernameValidator = ( value ) => {
            if ( !value.length ) {
                return 'Please enter your nickname.'
            }

            return true
        }

        const passwordValidator = ( value ) => {
            if ( !value.length ) {
                return 'Please enter your password.'
            }

            return true
        }

        const errorMessage = ( failed ) => {
            if ( failed ) {
                return (
                    <p className="fab-c-signup-form__error-msg">
                        { this.state.errorMessage }
                    </p>
                )
            }

            return null
        }

        const signupLink = classNameModifier => (
            <div className={ `fab-c-signup-note ${ classNameModifier ? `fab-c-signup-note--${ classNameModifier }` : '' }` }>
                <p>
                    Want to be a Fabled kid? <Link to="/signup" href="/signup" className="fab-c-signup-note__link">Sign up here</Link>
                </p>
                <p>
                    What is Fabled? <a href="/about" className="fab-c-signup-note__link">Find out here</a>
                </p>
            </div>
        )

        return (
            <AuthFormWrapper signupLink={ signupLink }>

                <div className="fab-c-signup-form-wrapper">

                    <Form className="fab-c-signup-form fab-c-signup-form--front" onSubmit={ this.handleSubmit }>
                        <div className="fab-c-signup-form__header">
                            <h2 className="fab-c-signup-form__heading">Tell your story…</h2>
                            <p className="fab-c-signup-form__sub-heading">Log in</p>
                        </div>

                        {
                            this.state.usernameLabel === 'Email'
                                ? (
                                    <p>
                                        {
                                            'To speed things up next time, you can log in using your email, '
                                            + 'even when the label here says “Nickname.”'
                                        }
                                    </p>
                                )
                                : null
                        }

                        { errorMessage( this.state.failed ) }

                        <TextInput
                            name="username"
                            id="fab-id-signup-username"
                            label={ this.state.usernameLabel }
                            validator={ usernameValidator }
                        />
                        <TextInput
                            name="password"
                            id="fab-id-signup-password"
                            label="Password"
                            validator={ passwordValidator }
                        />
                        <div className="fab-c-signup-form__input-row">
                            <button type="submit" className="fab-c-signup-form__submit-btn">Log in</button>
                        </div>
                        <Link
                            to="/auth/password"
                            href="/auth/password"
                            className="fab-c-signup-form__link"
                        >
                            I forgot my password
                        </Link>
                        {
                            this.state.usernameLabel !== 'Email'
                                ? (
                                    <button
                                        type="button"
                                        className="fab-c-signup-form__link"
                                        onClick={ this.setUsernameLabel( 'Email' ) }
                                    >
                                        I don’t have a nickname because I’m a parent
                                    </button>
                                )
                                : (
                                    <button
                                        type="button"
                                        className="fab-c-signup-form__link"
                                        onClick={ this.setUsernameLabel( 'Nickname' ) }
                                    >
                                        I want to log in with my nickname
                                    </button>
                                )
                        }

                        <Loader isLoading={ this.state.waiting } />
                    </Form>

                </div>

            </AuthFormWrapper>
        )
    }
}

export default Login
