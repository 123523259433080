import React from 'react'
import PropTypes from 'prop-types'
import DataLoaderGroup from './DataLoaderGroup'
import NotificationsLoader from './NotificationsLoader'
import UsersLoader from './UsersLoader'

/**
 * The NotificationsWithUsersLoader component combines NotificationsLoader and UsersLoader
 * in sequence to load all necessary data to display a user’s notifications
 */

const NotificationsWithUsersLoader = ( props ) => {
    const { userId } = props

    const loaders = [
        {
            key: 'notifications',
            loader: render => (
                <NotificationsLoader
                    userId={ userId }
                    waitForAuth
                    render={ render }
                />
            ),
        },
        {
            key: 'users',
            loader: ( render, { notifications } ) => {
                if ( !notifications.length ) {
                    return null
                }

                const userIds = notifications.reduce(
                    ( uIds, notification ) => {
                        if ( uIds.indexOf( notification.userId ) === -1 ) {
                            uIds.push( notification.userId )
                        }

                        if (
                            notification.associatedUserId
                            && uIds.indexOf( notification.associatedUserId ) === -1
                        ) {
                            uIds.push( notification.associatedUserId )
                        }

                        return uIds
                    },
                    [],
                )

                return (
                    <UsersLoader
                        service="usernames"
                        userId={ userIds }
                        render={ render }
                    />
                )
            },
        },
    ]

    return (
        <DataLoaderGroup
            loaders={ loaders }
            render={ props.render }
        />
    )
}

NotificationsWithUsersLoader.propTypes = {
    userId: PropTypes.number.isRequired,
    render: PropTypes.func.isRequired,
}

export default NotificationsWithUsersLoader
