import styled from 'styled-components'
import media from 'fabled/styles/media'

const ContentWrapper = styled.div`
    ${ media.tablet`
        padding-top: 7%;
    ` }
`

export default ContentWrapper
