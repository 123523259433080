import React from 'react'
import PropTypes from 'prop-types'

const CollectionHeader = ( { collection } ) => (
    <div className="fab-c-collection-header">
        <div className="fab-c-collection-header__inner">
            <div className="fab-c-collection-header__title">
                <h2 className="fab-c-collection-header__type">
                    Stories about <strong>{ collection ? collection.title : '…' }</strong>
                </h2>
            </div>
        </div>
    </div>
)

CollectionHeader.propTypes = {
    collection: PropTypes.shape( {
        title: PropTypes.string,
    } ),
}

CollectionHeader.defaultProps = {
    collection: null,
}

export default CollectionHeader
