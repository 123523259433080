// @flow
import React from 'react'
import { colors } from 'fabled/styles'
import { eventTracker } from 'fabled/eventTracker'
import ActionButton from 'fabled/components/ActionButton'

type Props = {
    onClick: () => void,
}

const CompletedBtn = ( { onClick }: Props ) => {
    const handleClick = ( e ) => {
        if ( e.key && !['Enter', ' '].includes( e.key ) ) {
            return
        }

        e.preventDefault()
        onClick()
        eventTracker( {
            category: 'Story Writer',
            action: 'Clicked finish button',
        } )
    }

    return (
        <ActionButton
            label="Finished?"
            color={ colors.green }
            textColor="white"
            icon="/images/i-tick.svg"
            iconSize={ 50 }
            position="right"
            onClick={ handleClick }
            onKeyDown={ handleClick }
        />
    )
}

export default CompletedBtn
