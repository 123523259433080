import { handleActions } from 'redux-actions'
import actionCreators from 'fabled/actions/actionCreators'

const auth = handleActions(
    {
        [ actionCreators.auth.userId.set ]( state, action ) {
            const newState = Object.assign( {}, state )
            newState.userId = action.payload
            return newState
        },

        [ actionCreators.auth.userId.unset ]( state ) {
            const newState = Object.assign( {}, state )
            newState.userId = null
            return newState
        },

        [ actionCreators.auth.failed.set ]( state ) {
            const newState = Object.assign( {}, state )
            newState.failed = true
            return newState
        },

        [ actionCreators.auth.failed.unset ]( state ) {
            const newState = Object.assign( {}, state )
            newState.failed = false
            return newState
        },

        [ actionCreators.auth.noRedirect ]( state, action ) {
            const newState = Object.assign( {}, state )
            newState.noRedirect = !!action.payload
            return newState
        },
    },
    {
        userId: null,
        failed: false,
        noRedirect: false,
        isWaiting: false,
    },
)

export default auth
