import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Motion, spring } from 'react-motion'
import FormattedContent from 'fabled/components/FormattedContent'

const NotificationBody = class extends React.Component {
    constructor( props ) {
        super( props )
        this.state = { contentHeight: 0 }
    }

    componentDidMount() {
        this.setContentHeight()
    }

    // TODO update content height when screen is resized
    // componentDidUpdate() {
    //     this.setContentHeight()
    // }

    setContentHeight() {
        const contentHeight = this.contentElement.clientHeight || 0

        if ( contentHeight !== this.state.contentHeight ) {
            this.setState( { contentHeight } )
        }
    }

    render() {
        const { props: { content: { body } } } = this
        const height = this.props.isCompact ? 0 : this.state.contentHeight

        return (
            <Motion style={ { height: spring( height, { stiffness: 200, damping: 20 } ) } }>
                {
                    motion => (
                        <StyledBody height={ motion.height }>
                            <div ref={ ( node ) => {
                                this.contentElement = node
                            } }
                            >
                                <StyledContentWrapper>
                                    <FormattedContent>
                                        { body }
                                    </FormattedContent>
                                </StyledContentWrapper>
                            </div>
                        </StyledBody>
                    )
                }
            </Motion>
        )
    }
}

NotificationBody.propTypes = {
    content: PropTypes.shape( {
        body: PropTypes.node.isRequired,
    } ).isRequired,
    isCompact: PropTypes.bool,
}

NotificationBody.defaultProps = {
    isCompact: false,
}

const StyledContentWrapper = styled.div`
    padding-top: 5em;
`

const StyledBody = styled.div.attrs( {
    style: p => ( { height: `${ p.height || 0 }px` } ),
} )`
    overflow: hidden;
`

export default NotificationBody
