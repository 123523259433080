import React from 'react'
import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'

const getLink = ( { to, children } ) => {
    if ( /^https?:\/\//i.test( to ) ) {
        return <a href={ to }>{ children }</a>
    }

    return <Link to={ to }>{ children }</Link>
}

const RoundedButton = ( { to, solid, color = 'deepBlue', bgColor = 'white', children } ) => (
    <StyledRoundedButton
        color={ color }
        bgColor={ bgColor }
        solid={ solid }
    >
        { getLink( { to, children } ) }
    </StyledRoundedButton>
)

const StyledRoundedButton = styled.div`
    a {
        display: inline-block;
        padding: 0.6em 1em;
        line-height: 1.2em;
        border-radius: 1.6em;
        border: 1px solid var( --${ p => p.color } );
        color: var( --${ p => p.color } );
        text-decoration: none;
        transition:
            background-color .3s ease-out,
            color .3s ease-out;

        &:hover {
            background: var( --${ p => p.color } );
            color: var( --${ p => p.bgColor } );
        }

        ${ p => p.solid && css`
            background: var( --${ p => p.color } );
            color: var( --${ p => p.bgColor } );

            &:hover {
                background: transparent;
                color: var( --${ p => p.color } );
            }
        ` }

        @media all and ( min-width: 500px ) {
            padding: 1em 2em;
        }
    }
`

export default RoundedButton