// @flow
import { handleActions } from 'redux-actions'
import { dataObject } from './helpers'
import actionCreators from '../actions/actionCreators'

const collaborations = handleActions(
    {
        ...dataObject( actionCreators, 'collaborations.data', true ),
    },
    {
        data: {},
    },
)

export default collaborations
