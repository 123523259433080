import React from 'react'
import PropTypes from 'prop-types'
import Api from 'fabled/api'
import Form from 'fabled/components/Form'
import FabledTextInput from 'fabled/components/FormInputs/FabledTextInput'
import Loader from 'fabled/components/Loader'
import Modal from './Modal'

class ThemeModal extends React.Component {
    static themeTitleValidator( value ) {
        return value.length ? true : 'Add the theme title'
    }

    constructor( props ) {
        super( props )
        this.state = {
            waiting: false,
            themeTitleValue: '',
        }
        this.onChange = this.onChange.bind( this )
        this.onSubmit = this.onSubmit.bind( this )
    }

    onChange( value ) {
        this.setState( { themeTitleValue: value } )
    }

    onSubmit( e ) {
        e.preventDefault()
        this.setState( { waiting: true } )
        Api.service( 'themes' ).create( { title: this.state.themeTitleValue } )
            .then( () => {
                this.setState( {
                    waiting: false,
                    themeTitleValue: '',
                } )
                this.props.modalClose()
            } )
    }

    render() {
        const errorMessage = ( failed ) => {
            if ( failed ) {
                return (
                    <p className="fab-c-signup-form__error-msg">
                        There was a problem. Please try again.
                    </p>
                )
            }

            return null
        }

        return (
            <Modal title="New Theme">
                <Form onSubmit={ this.handleSubmit }>
                    <FabledTextInput
                        id="story-prompt"
                        name="story-prompt"
                        label="Theme title"
                        onChange={ this.onChange }
                        value={ this.state.themeTitleValue }
                        validator={ this.themeTitleValidator }
                    />

                    { errorMessage( this.state.failed ) }

                    <div className="fab-c-signup-form__input-row">
                        <button type="submit" className="fab-c-signup-form__submit-btn" onClick={ this.onSubmit }>Save</button>
                    </div>

                    <Loader isLoading={ this.state.waiting } />
                </Form>
            </Modal>
        )
    }
}

ThemeModal.propTypes = {
    modalClose: PropTypes.func.isRequired,
}

export default ThemeModal
