// @flow
import React from 'react'
import type { Story } from 'fabled/types/Story'
import type { ChildUser, AuthUser } from 'fabled/types/User'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { getAuthUser } from 'fabled/selectors/users'
import find from 'lodash/find'
import ActionManager from 'fabled/components/ActionManager'
import StoryMenu from './StoryMenu'

type Props = {
    story: Story,
    storyAuthors: Array<ChildUser>,
    exclude: Array<'view' | 'edit' | 'share' | 'collection'>,

    // from connect
    authUser: AuthUser,
}

type State = {
    storyMenuActive: boolean,
}

class StoryMenuContainer extends React.Component<Props, State> {
    static menuItemView( story ) {
        return (
            <Link
                key="view"
                to={ `/story/${ story.id }` }
                href={ `/story/${ story.id }` }
            >
                See the published story
            </Link>
        )
    }

    static menuItemEdit( story ) {
        return (
            <Link
                key="edit"
                to={ `/create/${ story.id }` }
                href={ `/create/${ story.id }` }
            >
                Add to this story
            </Link>
        )
    }

    static menuItemShare( openShareModal ) {
        return (
            <button
                type="button"
                key="share"
                onClick={ openShareModal }
            >
                Share this story
            </button>
        )
    }

    static menuItemCollection( openCollectionModal ) {
        return (
            <button
                type="button"
                key="collection"
                onClick={ openCollectionModal }
            >
                Collection
            </button>
        )
    }

    static menuItemRequestCollaboration( openRequestCollaborationModal ) {
        return (
            <button
                type="button"
                key="request-collaboration"
                onClick={ openRequestCollaborationModal }
            >
                Create with a friend
            </button>
        )
    }

    static defaultProps = {
        storyAuthor: null,
        exclude: [],
        authUser: null,
    }

    constructor( props ) {
        super( props )
        this.state = {
            storyMenuActive: false,
        }
    }

    toggleMenu = () => {
        this.setState( s => ( { storyMenuActive: ( !s.storyMenuActive ) } ) )
    }

    menuItems( actions ) {
        const menuItems = {}

        if ( this.props.story.userIds.includes( this.props.authUser.id ) ) {
            menuItems.edit = StoryMenuContainer.menuItemEdit( this.props.story )
            menuItems.view = StoryMenuContainer.menuItemView( this.props.story )
        }

        if ( this.props.story.userId === this.props.authUser.id ) {
            const action = actions.openRequestCollaborationModal( this.props.story )
            menuItems.collaborate = StoryMenuContainer.menuItemRequestCollaboration( action )
        }
        else if (
            find( this.props.storyAuthors, { parentId: this.props.authUser.id } )
        ) {
            const action = actions.openShareModal( this.props.story )
            menuItems.share = StoryMenuContainer.menuItemShare( action )
        }

        if (
            ['admin', 'super-admin'].includes( this.props.authUser.role )
            && this.props.story.theme
        ) {
            const action = actions.openCollectionModal( this.props.story )
            menuItems.collection = StoryMenuContainer.menuItemCollection( action )
        }

        this.props.exclude.forEach( k => delete menuItems[ k ] )

        return Object.values( menuItems )
    }

    render() {
        if ( !this.props.authUser ) {
            return null
        }

        return (
            <ActionManager>
                { ( actions ) => {
                    const menuItems = this.menuItems( actions )

                    if ( !menuItems.length ) {
                        return null
                    }

                    return (
                        <StoryMenu
                            menuItems={ menuItems }
                            isActive={ this.state.storyMenuActive }
                            toggleMenu={ this.toggleMenu }
                        />
                    )
                } }
            </ActionManager>
        )
    }
}

const mapStateToProps = state => ( {
    authUser: getAuthUser( state ),
} )

export default connect( mapStateToProps )( StoryMenuContainer )
