import React, { Fragment as F } from 'react'
import PropTypes from 'prop-types'
import AppHeader from 'fabled/components/AppHeader/AppHeader'
import StoryWrapper from 'fabled/components/StoryWrapper'
import Story from 'fabled/components/Story'
import ScrollToTopOnMount from 'fabled/components/ScrollToTopOnMount'

const CollectionStoryRoute = ( { match: { params: { storyId, collectionId } } } ) => (
    <F>
        <ScrollToTopOnMount />
        <AppHeader />
        <StoryWrapper
            key={ storyId } // recreate the component when storyId changes
            storyId={ parseInt( storyId, 10 ) }
            themeId={ parseInt( collectionId, 10 ) }
            successComponent={ Story }
            storyPromoButton
            waitForAuth
        />
    </F>
)

CollectionStoryRoute.propTypes = {
    match: PropTypes.shape( {
        params: PropTypes.shape( {
            storyId: PropTypes.string.isRequired,
        } ).isRequired,
    } ).isRequired,
}

export default CollectionStoryRoute
