import React from 'react'
import styled from 'styled-components'

const FeatureItem = ( {
    title,
    image,
    description,
    color = 'deepBlue',
} ) => (
    <StyledFeatureItem color={ color }>
        <StyledFeatureItemImage>
            <img src={ image } alt={ title } />
        </StyledFeatureItemImage>
        <h2>{ title }</h2>
        <p>{ description }</p>
    </StyledFeatureItem>
)

const StyledFeatureItem = styled.div`
    text-align: center;

    h2 {
        margin-bottom: 1em;
        font-size: 22px;
        font-weight: bold;
        color: var( --${ p => p.color } );
    }

    p {
        font-size: 18px;
        line-height: 1.333;
        color: var( --deepBlue );
    }
`

const StyledFeatureItemImage = styled.div`
    width: 100%;
    max-width: 110px;
    max-height: 90px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 3em;

    img {
        max-width: 100%;
        max-height: 100%;
    }

    @media all and ( min-width: 800px ) {
        height: 90px;
    }
`

export default FeatureItem