import styled, { css } from 'styled-components'

export const sizes = {
    none: 0,
    small: '1rem',
    medium: '2rem',
    large: '4rem',
    hero: '6rem',
    megaHero: '10rem',
}

export const headerPush = css`
    margin-top: 80px;

    @media all and ( min-width: 500px ) {
        margin-top: 94px;
    }

    @media all and ( min-width: 800px ) {
        margin-top: 117px;
    }
`

const VerticalPadding = styled.div`
    ${ p => p.top && p.isResponsive && css`
        padding-top: calc( ${ sizes[ p.top ] } * 0.65 );

        @media all and ( min-width: ${ p.breakpoint } ) {
            padding-top: ${ sizes[ p.top ] };
        }
    ` }

    ${ p => p.top && !p.isResponsive && css`
        padding-top: ${ sizes[ p.top ] };
    ` }

    ${ p => p.bottom && p.isResponsive && css`
        padding-bottom: calc( ${ sizes[ p.bottom ] } * 0.65 );

        @media all and ( min-width: ${ p.breakpoint } ) {
            padding-bottom: ${ sizes[ p.bottom ] };
        }
    ` }

    ${ p => p.bottom && !p.isResponsive && css`
        padding-bottom: ${ sizes[ p.bottom ] };
    ` }

    ${ p => p.headerPush && css`
        ${ headerPush }
    ` }
`

VerticalPadding.defaultProps = {
    top: 'medium',
    bottom: 'medium',
    isResponsive: true,
    breakpoint: '800px',
    headerPush: false,
}

export default VerticalPadding