import React from 'react'

const icons = {
    tick: {
        title: 'Tick icon',
        viewBox: '0 0 13.62 10.02',
        path: 'M4.31,10,0,5.59l.72-.7,3.6,3.7L12.91,0l.71.71Z',
    },
    cross: {
        title: 'Cross icon',
        viewBox: '0 0 9.66 9.66',
        path: 'M5.54,4.83,9.66,8.95,9,9.66,4.83,5.54.71,9.66,0,8.95,4.12,4.83,0,.71.71,0,4.83,4.12,9,0l.71.71Z',
    },
    arrow: {
        title: 'Right arrow',
        viewBox: '0 0 11.1 20.7',
        markup: <polygon points="0.7,20.7 0,20 9.6,10.4 0,0.7 0.7,0 11.1,10.4 "/>,
    },
    microphone: {
        title: 'Microphone icon',
        viewBox: '0 0 19 29.22',
        path: 'M13.86,29.22H5.14a1,1,0,1,1,0-2H8.5V23.68A9.51,9.51,0,0,1,0,14.24a1,1,0,0,1,2,0,7.5,7.5,0,0,0,15,0,1,1,0,0,1,2,0,9.51,9.51,0,0,1-8.5,9.45v3.54h3.36a1,1,0,0,1,0,2ZM9.5,20.35a6.29,6.29,0,0,1-6.28-6.28V6.28a6.28,6.28,0,0,1,12.57,0v7.79A6.29,6.29,0,0,1,9.5,20.35ZM9.5,2A4.29,4.29,0,0,0,5.22,6.28v7.79a4.28,4.28,0,0,0,8.57,0V6.28A4.29,4.29,0,0,0,9.5,2ZM7.3,11.8a1,1,0,0,1-1-1V6.91A3.65,3.65,0,0,1,9.94,3.27a1,1,0,1,1,0,2A1.64,1.64,0,0,0,8.3,6.91V10.8A1,1,0,0,1,7.3,11.8Z',
    },
    picture: {
        title: 'Picture icon',
        viewBox: '0 0 32 26',
        path: 'M31,26H1a1,1,0,0,1-1-1V1A1,1,0,0,1,1,0H31a1,1,0,0,1,1,1V25A1,1,0,0,1,31,26ZM2,24H30V2H2Zm25.26-2.3a1,1,0,0,1-.74-.33l-6.68-7.44L15.11,19,16,20a1,1,0,1,1-1.46,1.37L10,16.47l-4.6,4.91A1,1,0,1,1,3.91,20l5.33-5.69a1,1,0,0,1,1.46,0l3,3.25,5.38-5.81a1,1,0,0,1,.74-.32,1,1,0,0,1,.74.33L28,20a1,1,0,0,1-.74,1.67ZM10,11.24A3.54,3.54,0,1,1,13.5,7.71,3.54,3.54,0,0,1,10,11.24Zm0-5.07A1.54,1.54,0,1,0,11.5,7.71,1.54,1.54,0,0,0,10,6.17Z',
    },
}

export default icons
