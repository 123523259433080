import React from 'react'
import PropTypes from 'prop-types'
import DataLoaderGroup from './DataLoaderGroup'
import ThemesLoader from './ThemesLoader'
import SimpleThemeStatusLoader from './SimpleThemeStatusLoader'

const createRenderer = ( LoadingComponent, SuccessComponent, ErrorComponent ) => {
    const Renderer = ( props ) => {
        const { loaderStates, theme, themesEnabled } = props

        if ( !loaderStates.all.isComplete ) {
            return LoadingComponent ? <LoadingComponent /> : null
        }
        if ( loaderStates.all.error ) {
            return <ErrorComponent error={ loaderStates.all.error } />
        }

        return (
            <SuccessComponent
                theme={ theme }
                themesEnabled={ themesEnabled }
            />
        )
    }

    Renderer.propTypes = {
        loaderStates: PropTypes.shape( {
            all: PropTypes.shape( {
                isLoading: PropTypes.bool.isRequired,
                isComplete: PropTypes.bool.isRequired,
                error: PropTypes.string,
            } ),
        } ).isRequired,
        theme: PropTypes.shape( {
            id: PropTypes.number,
        } ),
        themesEnabled: PropTypes.bool,
    }

    Renderer.defaultProps = {
        theme: null,
        themesEnabled: null,
    }

    return Renderer
}

const ThemeLoader = ( props ) => {
    const {
        waitForAuth,
        loadingComponent: LoadingComponent,
        successComponent: SuccessComponent,
        errorComponent: ErrorComponent,
    } = props

    const loaders = [
        {
            key: 'themes',
            loader: render => (
                <ThemesLoader
                    waitForAuth={ waitForAuth }
                    render={ render }
                />
            ),
            mapper: ( { themes } ) => ( {
                theme: themes.length ? themes[ 0 ] : null,
            } ),
        },
        {
            key: 'themesStatus',
            loader: render => (
                <SimpleThemeStatusLoader
                    waitForAuth={ waitForAuth }
                    render={ render }
                />
            ),
        },
    ]

    return (
        <DataLoaderGroup
            loaders={ loaders }
            render={ createRenderer( LoadingComponent, SuccessComponent, ErrorComponent ) }
        />
    )
}

ThemeLoader.propTypes = {
    waitForAuth: PropTypes.bool,
    loadingComponent: PropTypes.func,
    successComponent: PropTypes.func,
    errorComponent: PropTypes.func,
}

ThemeLoader.defaultProps = {
    waitForAuth: false,
    loadingComponent: null,
    successComponent: null,
    errorComponent: null,
}

export default ThemeLoader
