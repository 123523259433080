// @flow
import React from 'react'
import type { Story } from 'fabled/types/Story'
import type { Post } from 'fabled/types/Post'
import type { AuthUser, ChildUser } from 'fabled/types/User'
import Api from 'fabled/api'
import { eventTracker } from 'fabled/eventTracker'
import postFactory from 'fabled/helpers/postFactory'
import EncouragementFactory from 'fabled/helpers/EncouragementFactory'
import encouragement from 'fabled/data/encouragement'
import { TEXT_BLOCK, ENCOURAGEMENT } from 'fabled/postTypes'

const encouragementFactory = new EncouragementFactory( encouragement )

type Props = {
    value: string,
    story: Story,
    posts: Array<Post>,
    currentChapter: number,
    activeAuthor: ChildUser,
    authUser: AuthUser,
    postReplaceOrAdd: ( post: Post ) => void,
}

const defaultProps = {
    value: '',
}

type State = {
    value: string,
    minHeight: ?number,
    maxHeight: ?number,
    height: number,
}

class PostTextArea extends React.Component<Props, State> {
    static defaultProps = defaultProps

    state = {
        value: this.props.value || '',
        minHeight: null,
        maxHeight: null,
        height: 0,
    }

    textSizerRef = null

    // eslint-disable-line react/sort-comp
    textAreaRef = null

    formRef = null

    componentDidMount() {
        window.requestAnimationFrame( () => {
            window.requestAnimationFrame( () => {
                this.setTextareaHeightBoundaries()
            } )
        } )
    }

    componentDidUpdate( prevProps: Props, prevState: State ) {
        if ( prevState.value !== this.state.value ) {
            this.setTextAreaHeight()
        }
    }

    setTextAreaHeight = () => {
        if ( this.textSizerRef && this.state.maxHeight && this.state.minHeight ) {
            this.setState( ( s ) => {
                const mh = s.maxHeight || 0
                const height = Math.min(
                    Math.max(
                        this.textSizerRef ? this.textSizerRef.clientHeight : 0,
                        s.minHeight || 0,
                    ),
                    mh,
                )

                return { height }
            } )
        }
    }

    setTextareaHeightBoundaries = () => {
        if ( this.textAreaRef && this.formRef ) {
            if ( !this.state.minHeight ) {
                const formStyles = window.getComputedStyle( this.formRef )
                const minHeight = Math.round( parseFloat( formStyles.height ) )
                this.setState( { minHeight } )
            }

            if ( !this.state.maxHeight ) {
                const textAreaStyles = window.getComputedStyle( this.textAreaRef )
                const maxHeight = Math.round( parseFloat( textAreaStyles.paddingTop )
                                + parseFloat( textAreaStyles.paddingBottom )
                                + ( parseFloat( textAreaStyles.fontSize ) * 6 * 1.33 ) )
                this.setState( { maxHeight } )
            }
        }
    }

    handleChange = ( e: SyntheticEvent<HTMLTextAreaElement> ) => {
        this.setState( { value: e.currentTarget.value } )
    }

    handleSubmit = ( e: SyntheticEvent<HTMLFormElement> ) => {
        e.preventDefault()
        const textValue = this.textAreaRef ? this.textAreaRef.value.trim() : ''

        if ( !textValue ) {
            return
        }

        const post = postFactory( {
            storyId: this.props.story.id,
            chapterId: this.props.currentChapter,
            userId: this.props.authUser.id,
            postType: TEXT_BLOCK,
            content: { text: textValue },
        }, this.props.posts )

        this.props.postReplaceOrAdd( post )
        Api.service( 'postsForCollaborator' ).create( post )
            .then( ( response ) => {
                this.props.postReplaceOrAdd( response )
            } )

        this.setState( { value: '' } )
        eventTracker( {
            category: 'Story Writer',
            action: 'Added a text post',
        } )

        const chanceOfFabledResponse = 0.7
        const rand = Math.random()

        if ( rand <= chanceOfFabledResponse ) {
            encouragementFactory.next().then( ( encouragementItem ) => {
                const encouragementPost = postFactory( {
                    storyId: this.props.story.id,
                    chapterId: this.props.currentChapter,
                    postType: ENCOURAGEMENT,
                    content: encouragementItem,
                }, this.props.posts )
                this.props.postReplaceOrAdd( encouragementPost )
            } )
        }
    }

    render() {
        const styles = this.state.height ? { height: `${ this.state.height }px` } : null
        const className = `fab-c-post-text-input${ this.state.value.length ? ' fab-c-post-text-input--has-value' : '' }`
        let placeholder = this.props.posts.filter( post => !['encouragement', 'inspiration'].includes( post.postType ) ).length ? 'What happens next?…' : 'What happens first?…'

        if ( this.props.authUser.id !== this.props.activeAuthor.id ) {
            placeholder = `It’s ${ this.props.activeAuthor.username }’s turn to add to the story.`
        }

        const sizerValue = this.state.value
            + ( ( this.state.value.endsWith( '\r\n' )
                || this.state.value.endsWith( '\r' )
                || this.state.value.endsWith( '\n' ) ) ? ' ' : '' )

        return (
            <form
                ref={ ( node ) => {
                    this.formRef = node
                } }
                className={ className }
                style={ styles }
                onSubmit={ this.handleSubmit }
            >
                <textarea
                    ref={ ( node ) => {
                        this.textAreaRef = node
                    } }
                    value={ this.state.value }
                    className="fab-c-post-text-input__input"
                    placeholder={ placeholder }
                    onChange={ this.handleChange }
                />
                <button type="submit" className="fab-c-post-text-input__btn">Enter</button>
                <pre
                    className="fab-c-post-text-input__input fab-c-post-text-input__input--sizer"
                    ref={ ( node ) => {
                        this.textSizerRef = node
                    } }
                >
                    { sizerValue }
                </pre>
            </form>
        )
    }
}

export default PostTextArea
