import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'

const DropdownMenu = ( { menuItems, marginTop, tip } ) => (
    <StyledDropdownMenuWrapper marginTop={ marginTop }>
        <StyledDropdownMenu tip={ tip }>
            {
                menuItems.map( ( menuItem ) => {
                    const key = menuItem.key || menuItem.text

                    return (
                        menuItem.text
                            ? <MenuItem menuItem={ menuItem } key={ key } />
                            : (
                                <StyledDropdownMenuItem key={ key }>
                                    { menuItem }
                                </StyledDropdownMenuItem>
                            )
                    )
                } )
            }
        </StyledDropdownMenu>
    </StyledDropdownMenuWrapper>
)

DropdownMenu.propTypes = {
    menuItems: PropTypes.arrayOf( PropTypes.shape( {
        text: PropTypes.string,
        href: PropTypes.string,
        onClick: PropTypes.func,
    } ) ).isRequired,
    marginTop: PropTypes.string,
    tip: PropTypes.string,
}

DropdownMenu.defaultProps = {
    marginTop: 0,
    tip: null,
}

const MenuItem = ( { menuItem } ) => {
    const onKeyDown = onClick => ( e ) => {
        if ( e.key && !['Enter', ' '].includes( e.key ) ) {
            return
        }

        onClick()
    }

    let item

    if ( menuItem.href ) {
        item = (
            <Link to={ menuItem.href } href={ menuItem.href }>
                { menuItem.text }
            </Link>
        )
    }
    else {
        item = (
            <button
                type="button"
                onClick={ menuItem.onClick }
                onKeyDown={ onKeyDown( menuItem.onClick ) }
            >
                { menuItem.text }
            </button>
        )
    }

    return <StyledDropdownMenuItem>{ item }</StyledDropdownMenuItem>
}

MenuItem.propTypes = {
    menuItem: PropTypes.shape( {
        text: PropTypes.string,
        href: PropTypes.string,
        onClick: PropTypes.func,
    } ).isRequired,
}

const StyledDropdownMenuWrapper = styled.div`
    overflow: hidden;
    position: absolute;
    top: 0;
    right: 0;
    ${ props => props.marginTop && css`
        margin-top: ${ props.marginTop }px;
    ` }
`

const getTipPositionCss = ( props ) => {
    if ( !props.tip ) {
        return 'right: 50%'
    }

    switch ( props.tip ) {
        case 'left': return 'left: 13px'
        case 'right': return 'right: 25px'
        default: return props.tip
    }
}

const StyledDropdownMenu = styled.ul`
    overflow: hidden;
    margin-top: 12px;
    background: white;
    border-radius: 4px;

    &:before {
        content: ' ';
        position: absolute;
        margin-right: -12px;
        top: 0;
        border-bottom: 12px solid white;
        border-left: 12px solid transparent;
        border-right: 12px solid transparent;
        ${ getTipPositionCss }
    }
`

const StyledDropdownMenuItem = styled.li`
    display: block;
    width: 100%;

    > * {
        display: block;
        width: 100%;
        padding: 14px 18px;
        border: 0;
        border-top: 1px solid rgb(235,235,235);
        white-space: nowrap;
        text-align: left;
        text-decoration: none;
        background: transparent;
        color: black;
        font: inherit;
        font-size: 16px;
        transition:
            background-color .3s ease-out,
            border-color .3s ease-out;

        &:hover,
        &:focus {
            outline: none;
            background: rgb(253, 193, 185);
            border-color: rgb(253, 193, 185);
            cursor: pointer;
        }
    }

    &:hover + & > * {
        border-color: rgb(253, 193, 185);
    }

    &:first-child > * {
        padding-top: 16px;
        border-top: 0;
    }

    &:last-child > * {
        padding-bottom: 16px;
    }
`

export default DropdownMenu
