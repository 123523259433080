// @flow
import React from 'react'
import type { Node } from 'react'
import type { Post } from 'fabled/types/Post'
import type { ChildUser } from 'fabled/types/User'
import styled, { css } from 'styled-components'
import { UIBox } from 'fabled/styles/shared'
import UserAvatar from 'fabled/components/UserAvatar'
import PostDeleteButton from './PostDeleteButton'
import PostAvatar from './PostAvatar'
import FabledAvatar from './FabledAvatar'

type Props = {
    post: ?Post,
    postAuthor: ?ChildUser | 'fabled',
    showPostAuthor: boolean,
    isEditable: boolean,
    storyStyle: 'editor' | 'viewer',
    color: string,
    children: Node,
}

const defaultProps = {
    post: null,
    isEditable: false,
}

const PostUIWrapper = ( props: Props ) => {
    let avatarPosition = null

    if ( props.showPostAuthor && props.postAuthor ) {
        avatarPosition = props.postAuthor.isCurrentUser ? 'right' : 'left'
    }

    const avatar = postAuthor => ( postAuthor === 'fabled'
        ? <FabledAvatar />
        : <UserAvatar user={ postAuthor } /> )

    return (
        <StyledPostUIWrapper>
            <StyledPostUIWrapperInner
                showAvatar={ !!avatarPosition }
                avatarPosition={ avatarPosition }
                boxed={ !!( props.storyStyle === 'editor' ) }
            >
                {
                    avatarPosition
                        ? (
                            <PostAvatar position={ avatarPosition } color={ props.color }>
                                { avatar( props.postAuthor ) }
                            </PostAvatar>
                        )
                        : null
                }
                { props.children }
            </StyledPostUIWrapperInner>
            {
                props.isEditable && props.post
                    ? (
                        <PostDeleteButton
                            postId={ props.post.id }
                            postUuid={ props.post.uuid }
                            position={ avatarPosition === 'left' ? 'right' : 'left' }
                        />
                    )
                    : null
            }
        </StyledPostUIWrapper>
    )
}

PostUIWrapper.defaultProps = defaultProps

const StyledPostUIWrapper = styled.div`
    position: relative;
    padding-top: 1em;
`

const StyledPostUIWrapperInner = styled.div`
    display: block;
    width: auto;
    overflow: hidden;

    ${ p => p.boxed && css`
        ${ UIBox };
    ` }

    ${ p => p.showAvatar && p.avatarPosition === 'left' && css`
        margin-left: 70px;
    ` }

    ${ p => p.showAvatar && p.avatarPosition === 'right' && css`
        margin-right: 70px;
    ` }
`

export default PostUIWrapper
