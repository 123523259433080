import isFunction from 'lodash/isFunction'
import isArray from 'lodash/isArray'
import isString from 'lodash/isString'
import isNumber from 'lodash/isNumber'
import { socketClient } from './feathers'
import restClient from './restClient'

const listenerHelper = ( serviceName, adverb ) => ( events, listener ) => {
    const theListener = isFunction( events ) ? events : listener
    const theEvents = ( () => {
        if ( isFunction( events ) ) {
            return null
        }
        if ( isArray( events ) ) {
            return events
        }

        return [events]
    } )()

    if ( theEvents ) {
        theEvents.forEach( event => (
            socketClient.service( serviceName )[ adverb ](
                event,
                message => theListener( event, message ),
            )
        ) )
    }
    else {
        /* eslint-disable-next-line no-underscore-dangle */
        socketClient.service( serviceName )._serviceEvents.forEach( event => (
            socketClient.service( serviceName )[ adverb ](
                event,
                message => theListener( event, message ),
            )
        ) )
    }
}

const socketService = serviceName => ( {
    find: params => socketClient.service( serviceName ).find( params ),
    get: ( ...args ) => {
        if ( !isString( args[ 0 ] ) && !isNumber( args[ 0 ] ) ) {
            args.unshift( 'inferred' )
        }
        return socketClient.service( serviceName ).get( ...args )
    },
    create: ( data, params ) => socketClient.service( serviceName ).create( data, params ),
    patch: ( ...args ) => {
        if ( !isString( args[ 0 ] ) && !isNumber( args[ 0 ] ) ) {
            args.unshift( 'inferred' )
        }
        return socketClient.service( serviceName ).patch( ...args )
    },
    remove: ( ...args ) => {
        if ( !isString( args[ 0 ] ) && !isNumber( args[ 0 ] ) ) {
            args.unshift( 'inferred' )
        }
        socketClient.service( serviceName ).remove( ...args )
    },
    on: listenerHelper( serviceName, 'on' ),
    off: listenerHelper( serviceName, 'off' ),
} )

// const Api = {
//     authenticate: data => socketClient.authenticate( data ),
//     logout: () => socketClient.logout(),
//     onAuthenticated: listener => socketClient.on( 'authenticated', listener ),
//     onLogout: listener => socketClient.on( 'logout', listener ),
//     authManagement: params => socketClient.service( 'authManagement' ).create( params ),
//     passwordResetUsers: resetToken =>
//      socketClient.service( 'passwordResetUsers' ).get( resetToken ),
//
//     users: Object.assign( {}, socketService( 'users' ), {
//         usernameExists: username => socketClient.service( 'usernameExists' ).get( username ),
//         parentSignupFromToken: token => socketClient.service( 'parentSignup' ).get( token ),
//     } ),
//
//     usernames: socketService( 'usernames' ),
//     stories: socketService( 'stories' ),
//     publicStories: socketService( 'publicStories' ),
//     storiesInTheme: socketService( 'storiesInTheme' ),
//     notifications: socketService( 'notifications' ),
//     notificationsCount: { get: userId =>
//        socketClient.service( 'notificationsCount' ).get( userId ) },
//     collaborations: socketService( 'collaborations' ),
//     storyPrompts: socketService( 'storyPrompts' ),
//     themes: socketService( 'themes' ),
//     globals: socketService( 'globals' ),
//
//     posts: Object.assign( {}, socketService( 'posts' ), {
//         uploadImage: data => restClient( 'uploads' ).create( data ),
//         uploadAudio: data => restClient( 'uploads' ).create( data ),
//     } ),
//     publicPosts: socketService( 'publicPosts' ),
//     postsInTheme: socketService( 'postsInTheme' ),
// }

const Api = {
    authenticate: data => socketClient.authenticate( data ),
    logout: () => socketClient.logout(),
    onAuthenticated: listener => socketClient.on( 'authenticated', listener ),
    onLogout: listener => socketClient.on( 'logout', listener ),
    uploadImage: data => restClient( 'uploads' ).create( data ),
    uploadAudio: data => restClient( 'uploads' ).create( data ),
    service: service => socketService( service ),
}

export default Api
