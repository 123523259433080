/* global module, process */
import 'whatwg-fetch'
import React from 'react'
import { hydrate } from 'react-dom'
import Raven from 'raven-js'
import ReactGA from 'react-ga'
import history from 'fabled/history'
import AppWithRouterAndProvider from 'fabled/components/AppWithRouterAndProvider'
import { sentryUrl } from 'fabled/sentry'
import 'fabled/scss/fabled.scss'

/**
 * Sentry error logging for production
 */
if ( !['development', 'test'].includes( process.env.APP_ENV ) ) {
    Raven.config( sentryUrl ).install( {
        environment: process.env.APP_ENV,
    } )
}

/**
 * Google Analytics
 */
if ( process.env.APP_ENV ) {
    if ( ['development', 'qa'].includes( process.env.APP_ENV ) ) {
        ReactGA.initialize( 'UA-108567312-1', { debug: true } )
    }
    else if ( process.env.APP_ENV === 'production' ) {
        ReactGA.initialize( 'UA-108567312-1' )
    }

    history.listen( ( location ) => {
        ReactGA.set( { page: location.pathname } )
        ReactGA.pageview( location.pathname )
    } )
}

hydrate(
    <AppWithRouterAndProvider />,
    document.getElementById( 'root' ),
)
