// @flow
import React from 'react'
import { colors } from 'fabled/styles'
import ActionButton from 'fabled/components/ActionButton'

type Props = {
    onClick: () => void,
}

const PassBtn = ( props: Props ) => {
    const handleClick = () => {
        props.onClick()
    }

    return (
        <ActionButton
            label="Pass it on…"
            color={ colors.yellow }
            textColor="white"
            icon="/images/i-pass-it-on.svg"
            iconSize={ 53 }
            position="left"
            onClick={ handleClick }
            onKeyDown={ handleClick }
        />
    )
}

export default PassBtn
