import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { rgba } from 'polished'
import { colors } from '../../styles'

const RadioInputOptions = props => (
    <StyledRadioOptionsWrapper>
        {
            props.options.map( ( option, i ) => {
                const inputId = `${ props.id }-${ option.value }-${ i }`
                const checked = option.value === props.value
                const hasFocus = option.value === props.optionWithFocus

                return (
                    <StyledRadioInputOption
                        key={ inputId }
                        htmlFor={ inputId }
                        active={ checked }
                        hasFocus={ hasFocus }
                    >
                        <StyledRadioInputOptionInput
                            type="radio"
                            name={ props.name }
                            id={ inputId }
                            onChange={ props.onChange }
                            onFocus={ props.onFocus }
                            onBlur={ props.onBlur }
                            checked={ checked }
                            disabled={ props.disabled }
                            value={ option.value }
                        />
                        { option.label }
                    </StyledRadioInputOption>
                )
            } )
        }
    </StyledRadioOptionsWrapper>
)

RadioInputOptions.propTypes = {
    id: PropTypes.string,
    name: PropTypes.string,
    options: PropTypes.arrayOf( PropTypes.shape( {
        value: PropTypes.string,
        label: PropTypes.string,
    } ) ),
    value: PropTypes.string,
    optionWithFocus: PropTypes.string,
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    disabled: PropTypes.bool,
}

RadioInputOptions.defaultProps = {
    id: '',
    name: '',
    options: [],
    value: '',
    optionWithFocus: null,
    onChange: null,
    onFocus: null,
    onBlur: null,
    disabled: false,
}

const StyledRadioOptionsWrapper = styled.div`
    display: flex;
`

const StyledRadioInputOption = styled.label`
    flex: 1;
    padding: 16px;
    border: 1px solid ${ colors.purple };
    border-right: 0;
    text-align: center;
    font-weight: bold;
    font-size: 18px;
    background: ${ props => ( props.active ? colors.purple : 'transparent' ) };
    color: ${ props => ( props.active ? 'white' : colors.purple ) };

    ${ props => props.hasFocus && css`
        box-shadow: inset 0 0 0 3px ${ props.active ? rgba( 'white', 0.3 ) : rgba( colors.purple, 0.3 ) };
    ` }

    &:first-child {
        border-top-left-radius: 2px;
        border-bottom-left-radius: 2px;
    }

    &:last-child {
        border-right: 1px solid ${ colors.purple };
        border-top-right-radius: 2px;
        border-bottom-right-radius: 2px;
    }
`

const StyledRadioInputOptionInput = styled.input`
    position: absolute;
    left: -999em;
`

export default RadioInputOptions
