// @flow
import React from 'react'
import type { InspirationPost as InspirationPostType } from 'fabled/types/Post'
import styled from 'styled-components'

type Props = {
    post: InspirationPostType,
}

const InspirationPost = ( { post }: Props ) => {
    const icon = `/images/inspiration/${ post.content.icon }.svg`

    return (
        <StyledInspirationPost>
            <StyledImagePanel
                style={ { backgroundColor: post.content.iconBackgroundColor } }
            >
                <img src={ icon } alt="" />
            </StyledImagePanel>
            <StyledTextPanel
                style={ {
                    backgroundColor: post.content.textBackgroundColor,
                    color: post.content.textColor,
                } }
            >
                { post.content.text }
            </StyledTextPanel>
        </StyledInspirationPost>
    )
}

const StyledInspirationPost = styled.div`
    position: relative;
    color: white;
    overflow: hidden;
    display: flex;
    flex-direction: rows;
    height: 170px;
`

const StyledImagePanel = styled.div`
    flex-basis: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
        max-width: 50%;
        max-height: 60%;
    }
`

const StyledTextPanel = styled.div`
    padding: 0.6em;
    flex-basis: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 28px;
    font-weight: bold;
    text-align: center;
`

export default InspirationPost
