import { statusHelper } from './helpers'
import config from '../config'

class RestClient {
    constructor( servicePath ) {
        this.url = `${ config.apiUrl }/${ servicePath }`
    }

    create( data ) {
        return fetch( this.url, {
            method: 'POST',
            body: data,
            'Content-Type': 'multipart/form-data',
        } )
            .then( statusHelper )
            .then( response => response.json() )
    }
}

export default function restClient( servicePath ) {
    return new RestClient( servicePath )
}
