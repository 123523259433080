import { createActions } from 'redux-actions'
import shortid from 'shortid'
import isArray from 'lodash/isArray'
import modalTypes from 'fabled/components/Modals/modalTypes'

const addUuidIfNotExists = ( p ) => {
    if ( !p.uuid ) {
        return Object.assign( p, { uuid: shortid.generate() } )
    }

    return p
}

const payloadCreators = {
    thru: p => p,
    addUuidIfNotExists: ( p ) => {
        if ( isArray( p ) ) {
            return p.map( item => addUuidIfNotExists( item ) )
        }

        return addUuidIfNotExists( p )
    },
}

const actionCreators = createActions( {
    AUTH: {
        USER_ID: {
            SET: payloadCreators.thru,
            UNSET: payloadCreators.thru,
        },
        FAILED: {
            SET: payloadCreators.thru,
            UNSET: payloadCreators.thru,
        },
        NO_REDIRECT: payloadCreators.thru,
    },
    SIGNUP: {
        SUCCESS: payloadCreators.thru,
        ERROR: payloadCreators.thru,
        COMPLETED: payloadCreators.thru,
    },
    USERS: {
        DATA: {
            RESET: payloadCreators.thru,
            ADD: payloadCreators.addUuidIfNotExists,
            UPDATE: payloadCreators.thru,
            UPDATE_OR_ADD: payloadCreators.addUuidIfNotExists,
            REPLACE: payloadCreators.addUuidIfNotExists,
            REPLACE_OR_ADD: payloadCreators.addUuidIfNotExists,
            REMOVE: payloadCreators.thru,
        },
    },
    STORIES: {
        DATA: {
            RESET: payloadCreators.thru,
            ADD: payloadCreators.addUuidIfNotExists,
            UPDATE: payloadCreators.thru,
            REPLACE: payloadCreators.addUuidIfNotExists,
            REPLACE_OR_ADD: payloadCreators.addUuidIfNotExists,
            REMOVE: payloadCreators.thru,
        },
    },
    POSTS: {
        DATA: {
            RESET: payloadCreators.thru,
            ADD: payloadCreators.addUuidIfNotExists,
            REPLACE: payloadCreators.addUuidIfNotExists,
            REPLACE_OR_ADD: payloadCreators.addUuidIfNotExists,
            REMOVE: payloadCreators.thru,
        },
    },
    STORY_PROMPTS: {
        SET_CURRENT: payloadCreators.thru,
        ENABLE: payloadCreators.thru,
        DISABLE: payloadCreators.thru,
    },
    THEMES: {
        SET_CURRENT: payloadCreators.thru,
        ENABLE: payloadCreators.thru,
        DISABLE: payloadCreators.thru,
        DATA: {
            RESET: payloadCreators.thru,
            REPLACE_OR_ADD: payloadCreators.addUuidIfNotExists,
        },
    },
    NOTIFICATIONS: {
        DATA: {
            RESET: payloadCreators.thru,
            REPLACE_OR_ADD: payloadCreators.addUuidIfNotExists,
        },
        COUNT: {
            SET: payloadCreators.thru,
        },
    },
    COLLABORATIONS: {
        DATA: {
            RESET: payloadCreators.thru,
            REPLACE_OR_ADD: payloadCreators.addUuidIfNotExists,
            REMOVE: payloadCreators.thru,
        },
    },
    UI: {
        AUDIO_RECORDER: {
            START: payloadCreators.thru,
            STOP: payloadCreators.thru,
        },
        MODAL: {
            SET: payloadCreators.thru,
            CLOSE: () => ( {
                id: null,
                props: {},
            } ),
            OPEN_STORIES_LIST: () => ( {
                id: modalTypes.STORIES_LIST,
                props: {},
            } ),
            OPEN_SHARE: storyId => ( {
                id: modalTypes.SHARE,
                props: { storyId },
            } ),
            OPEN_SET_COLLECTION: storyId => ( {
                id: modalTypes.SET_COLLECTION,
                props: { storyId },
            } ),
            OPEN_ONBOARD: ( { userId } ) => ( {
                id: modalTypes.ONBOARD,
                props: { userId },
            } ),
            OPEN_DIALOG: ( { title, body, confirmText } ) => ( {
                id: modalTypes.DIALOG,
                props: { title, body, confirmText },
            } ),
            OPEN_PUBLISH: storyId => ( {
                id: modalTypes.PUBLISH,
                props: { storyId },
            } ),
            OPEN_CONFIRM_DELETE_POST: ( { postId, postUuid } ) => ( {
                id: modalTypes.CONFIRM_DELETE_POST,
                props: { postId, postUuid },
            } ),
            OPEN_STORY_PROMPT: () => ( {
                id: modalTypes.STORY_PROMPT,
                props: {},
            } ),
            OPEN_THEME: () => ( {
                id: modalTypes.THEME,
                props: {},
            } ),
            OPEN_NOTIFICATIONS: ( { userId } ) => ( {
                id: modalTypes.NOTIFICATIONS,
                props: { userId },
            } ),
            OPEN_REQUEST_COLLABORATION: storyId => ( {
                id: modalTypes.REQUEST_COLLABORATION,
                props: { storyId },
            } ),
            OPEN_PASS_STORY: storyId => ( {
                id: modalTypes.PASS_STORY,
                props: { storyId },
            } ),
        },
        NOTICE: {
            SET: payloadCreators.thru,
        },
    },
} )

export default actionCreators

const flatten = ( obj, keyPrefix = '', arr = [] ) => {
    Object.entries( obj ).forEach( ( [key, value] ) => {
        const newKey = keyPrefix + key.charAt( 0 ).toUpperCase() + key.slice( 1 )

        if ( value instanceof Function ) {
            arr.push( { key: newKey, value } )
        }
        else {
            flatten( value, newKey, arr )
        }
    } )

    return arr
}

const flattenActionCreators = ( actnCreators, keyPrefix = '' ) => {
    const flattened = {}
    const flatActnCreators = flatten( actnCreators, keyPrefix )

    flatActnCreators.forEach( ( item ) => {
        flattened[ item.key ] = item.value
    } )

    return flattened
}

export const flattenedActionCreators = flattenActionCreators( actionCreators, 'actions' )
