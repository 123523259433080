// @flow
import type { Collaboration } from 'fabled/types/Collaboration'
import { createSelector } from 'reselect'
import { return2ndArgAsArray } from './utils'

type State = {
    collaborations: {
        [key: string]: Collaboration,
    }
}

export const getCollaborations = ( state: State ) => state.collaborations.data

export const getCollaborationsAsArray = createSelector(
    getCollaborations,
    collaborations => Object.values( collaborations ),
)

export const getCollaborationById = createSelector(
    getCollaborationsAsArray,
    ( state, id ) => id,
    ( collaborations, id ) => collaborations.find( c => c.id === id ),
)

export const getCollaborationsByUserIdOrCollaboratorId = createSelector(
    getCollaborationsAsArray,
    return2ndArgAsArray,
    ( collaborations, userIds ) => collaborations.filter( c => (
        userIds.includes( c.userId ) || userIds.includes( c.collaboratorId )
    ) ),
)
