import { socketClient } from './feathers'

class Listeners {
    constructor() {
        this.listeners = {}
        this.events = {}
    }

    static eventKey( serviceName, event ) {
        return `${ serviceName }:${ event }`
    }

    createAndAdd( serviceName, events, key, listener ) {
        this.create( key, listener )
        this.add( serviceName, events, key )
    }

    create( key, listener ) {
        if ( this.listeners[ key ] ) {
            return
        }

        this.listeners[ key ] = listener
    }

    add( serviceName, events, key ) {
        events.forEach( ( event ) => {
            this.createInternalListener( serviceName, event )
            const eventKey = Listeners.eventKey( serviceName, event )

            if ( !this.events[ eventKey ].listenerKeys.includes( key ) ) {
                this.events[ eventKey ].listenerKeys.push( key )
            }
        } )
    }

    remove( serviceName, events, key ) {
        events.forEach( ( event ) => {
            const eventKey = Listeners.eventKey( serviceName, event )

            if ( !this.events[ eventKey ] ) {
                throw new Error( `No listeners found for ${ eventKey }` )
            }

            if ( this.events[ eventKey ].listenerKeys.includes( key ) ) {
                const index = this.events[ eventKey ].listenerKeys.indexOf( key )
                this.events[ eventKey ].listenerKeys.splice( index, 1 )
            }
        } )
    }

    createInternalListener( serviceName, event ) {
        const eventKey = Listeners.eventKey( serviceName, event )

        if ( !this.events[ eventKey ] ) {
            const internalListener = ( message ) => {
                this.events[ eventKey ].listenerKeys.forEach( ( key ) => {
                    this.listeners[ key ]( serviceName, event, message )
                } )
            }

            this.events[ eventKey ] = {
                internalListener,
                listenerKeys: [],
            }

            socketClient.service( serviceName ).on( event, internalListener )
        }
    }
}

const listeners = new Listeners()
export default listeners
