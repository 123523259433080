import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import actionCreators from 'fabled/actions/actionCreators'
import { getAuthUser } from 'fabled/selectors/users'
import FormattedContent from 'fabled/components/FormattedContent'
import StoriesList from 'fabled/components/StoriesList'
import StartStoryButton from 'fabled/components/StartStoryButton'
import Modal from './Modal'

const StoriesListModal = props => (
    <Modal title="My Stories">
        <FormattedContent>
            <p>
                <StartStoryButton />
            </p>
        </FormattedContent>

        {
            props.authUser
                ? (
                    <StoriesList
                        userId={ props.authUser.id }
                        onStorySelect={ props.modalClose }
                    />
                )
                : null
        }
    </Modal>
)

StoriesListModal.propTypes = {
    authUser: PropTypes.shape( {
        id: PropTypes.number,
    } ),
    modalClose: PropTypes.func.isRequired,
}

StoriesListModal.defaultProps = {
    authUser: null,
}

const mapStateToProps = state => ( {
    authUser: getAuthUser( state ),
} )

const mapDispatchToProps = {
    modalClose: actionCreators.ui.modal.close,
}

export default connect( mapStateToProps, mapDispatchToProps )( StoriesListModal )
