import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { colors } from 'fabled/styles'
import actionCreators from 'fabled/actions/actionCreators'
import CloseButton from 'fabled/components/CloseButton'

const Modal = props => (
    <StyledModal>
        <StyledModalInner>
            <StyledModalContent>
                { props.hasCloseButton ? <CloseButton onClick={ props.modalClose } /> : null }
                { props.title ? ( <StyledModalTitle>{ props.title }</StyledModalTitle> ) : null }
                { props.children }
            </StyledModalContent>
        </StyledModalInner>
    </StyledModal>
)

Modal.propTypes = {
    children: PropTypes.oneOfType( [
        PropTypes.node,
        PropTypes.arrayOf( PropTypes.number ),
    ] ).isRequired,
    hasCloseButton: PropTypes.bool,
    title: PropTypes.string,

    // from connect
    modalClose: PropTypes.func.isRequired,
}

Modal.defaultProps = {
    hasCloseButton: true,
    title: null,
}

const StyledModal = styled.div`
    position: fixed;
    z-index: 99;
    overflow: auto;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0,0,0,0.6);
`

const StyledModalInner = styled.div`
    position: relative;
    margin: 3em auto;
    padding: 0;
    width: 90%;
    max-width: 500px;
    line-height: 1.35em;

    &--wide {
        max-width: 800px;
    }

    img {
        max-width: 100%;
        margin-bottom: 1em;
    }
`

const StyledModalContent = styled.div`
    position: relative;
    overflow: hidden;
    padding: 2em;
    background: ${ colors.paper };
    border-radius: 10px;
`

const StyledModalTitle = styled.h2`
    margin-bottom: 1em;
    margin-right: 30px;
    font-size: 24px;
    font-weight: bold;
    line-height: 1.15;
    color: ${ colors.red };
`

const mapDispatchToProps = {
    modalClose: actionCreators.ui.modal.close,
}

export default connect( null, mapDispatchToProps )( Modal )
