// @flow
import React from 'react'
import isString from 'lodash/isString'
import FormattedContent from 'fabled/components/FormattedContent'
import Button from 'fabled/components/Button'
import Modal from './Modal'

type Props = {
    title?: string,
    body?: Node | string,
    confirmText?: string,
    modalClose: () => void,
}

const DialogModal = ( props: Props ) => {
    const modalClose = ( e ) => {
        e.preventDefault()
        props.modalClose()
    }

    const body = isString( props.body ) && typeof props.body === 'string'
        ? <p>{ props.body }</p>
        : props.body

    return (
        <Modal title={ props.title }>

            <FormattedContent>{ body }</FormattedContent>

            <div className="fab-c-modal__single-btn">
                <Button
                    primary
                    onClick={ modalClose }
                    autoFocus
                >
                    { props.confirmText }
                </Button>
            </div>
        </Modal>
    )
}

DialogModal.defaultProps = {
    title: '',
    body: '',
    confirmText: 'Confirm',
}

export default DialogModal
