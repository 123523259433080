// @flow
import React from 'react'
import type { Story } from 'fabled/types/Story'
import { Link } from 'react-router-dom'
import StoryCollaborators from 'fabled/components/StoryCollaborators'
import Button from 'fabled/components/Button'
import type { ButtonEvent } from 'fabled/components/Button'

type Props = {
    stories: Array<Story>,
    onShare: ( story: Story ) => void,
    onCollection: ( story: Story ) => void,
}

const ParentStoriesList = ( props: Props ) => {
    const onShareStory = story => ( e ) => {
        if ( e.key && !['Enter', ' '].includes( e.key ) ) {
            return
        }

        props.onShare( story )
    }

    const onManageStoryCollection = story => ( e ) => {
        if ( e.key && !['Enter', ' '].includes( e.key ) ) {
            return
        }

        props.onCollection( story )
    }

    return (
        <ul className="fab-c-parent-stories-list">
            { props.stories.map( ( story ) => {
                if ( story ) {
                    return (
                        <ListItem
                            story={ story }
                            onShare={ onShareStory( story ) }
                            onCollection={ onManageStoryCollection( story ) }
                            key={ story.uuid }
                        />
                    )
                }

                return null
            } ) }
        </ul>
    )
}

type ListItemProps = {
    story: Story,
    onShare: ( event: ButtonEvent ) => void,
    onCollection: ( event: ButtonEvent ) => void,
}

const ListItem = ( props: ListItemProps ) => (
    <li className="fab-c-parent-stories-list__item">
        <Link
            className="fab-c-parent-stories-list__story-title"
            to={ `/story/${ props.story.id }` }
            href={ `/story/${ props.story.id }` }
            key={ props.story.id }
        >
            { props.story.title || 'No title yet (working on it!)' }
            <StoryCollaborators userIds={ props.story.userIds } />
        </Link>
        <ShareBtn onShare={ props.onShare } />
        <CollectionBtn story={ props.story } onCollection={ props.onCollection } />
    </li>
)

type ShareButnProps = {
    onShare: ( event: ButtonEvent ) => void,
}

const ShareBtn = ( props: ShareButnProps ) => (
    <div className="fab-c-parent-stories-list__share-btn">
        <Button
            small
            primary
            onClick={ props.onShare }
            onKeyDown={ props.onShare }
        >
            Share
        </Button>
    </div>
)

type CollectionBtnProps = {
    story: Story,
    onCollection: ( event: ButtonEvent ) => void,
}

const CollectionBtn = ( props: CollectionBtnProps ) => {
    if ( !props.story.theme ) {
        return null
    }

    return (
        <div className="fab-c-parent-stories-list__share-btn">
            <Button
                small
                primary
                onClick={ props.onCollection }
                onKeyDown={ props.onCollection }
            >
                Collection
            </Button>
        </div>
    )
}

export default ParentStoriesList
