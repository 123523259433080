import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import actionCreators from 'fabled/actions/actionCreators'
import { getStoryById } from 'fabled/selectors/stories'
import Api from 'fabled/api'
import { eventTracker } from 'fabled/eventTracker'
import FormattedContent from 'fabled/components/FormattedContent'
import RadioInput from 'fabled/components/FormInputs/RadioInput'
import ShareLink from 'fabled/components/ShareLink'
import Modal from './Modal'

const ShareModal = ( props ) => {
    const setPublic = ( value ) => {
        const boolValue = value === 'true'

        if ( boolValue !== props.story.public ) {
            Api.service( 'storiesForParent' ).patch( props.story.id, {
                id: props.story.id,
                uuid: props.story.uuid,
                public: boolValue,
            } )
                .then( ( response ) => {
                    props.storyReplaceOrAdd( response )
                } )
                .then( () => {
                    eventTracker( {
                        category: 'Share Modal',
                        action: boolValue
                            ? 'Made story publicly visible with a secret link'
                            : 'Made story private',
                    } )
                } )
        }
    }

    return (
        <Modal title="Share this story?">
            <FormattedContent>
                <p>
                    {
                        'You can create a secret link for this story. Anyone with '
                        + 'the secret link can see the story even if they don’t have a Fabled '
                        + 'account or aren’t logged in. It’s a great way to send stories to friends and family!'
                    }
                </p>
            </FormattedContent>

            <RadioInput
                name="public"
                id="fab-id-story-public"
                label="Give this story a secret link?"
                options={ [
                    { value: 'true', label: 'Yes' },
                    { value: 'false', label: 'No' },
                ] }
                value={ props.story.public ? 'true' : 'false' }
                onChange={ setPublic }
            />

            {
                props.story.public && props.story.publicId ? (
                    <div>
                        <p>This is the secret link. Visit the page now, and then share it!</p>
                        <ShareLink story={ props.story } />
                    </div>
                )
                    : null
            }
        </Modal>
    )
}

ShareModal.propTypes = {
    // eslint-disable-next-line react/no-unused-prop-types
    storyId: PropTypes.number.isRequired,

    // from connect
    story: PropTypes.shape( {
        id: PropTypes.number,
        uuid: PropTypes.string,
        publicId: PropTypes.string,
        public: PropTypes.bool,
    } ).isRequired,
    storyReplaceOrAdd: PropTypes.func.isRequired,
}

const mapStateToProps = ( state, { storyId } ) => ( {
    story: getStoryById( state, storyId ),
} )

const mapDispatchToProps = {
    storyReplaceOrAdd: actionCreators.stories.data.replaceOrAdd,
    modalOpenShare: actionCreators.ui.modal.openShare,
}

export default connect( mapStateToProps, mapDispatchToProps )( ShareModal )
