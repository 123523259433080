import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { parasAndNewlines } from 'fabled/helpers/text'

const FormattedContent = ( props ) => {
    const content = props.content ? parasAndNewlines( props.content ) : props.children
    return <StyledFormattedContent topMargin={ props.topMargin }>{ content }</StyledFormattedContent>
}

FormattedContent.propTypes = {
    content: PropTypes.string,
    children: PropTypes.node,
    topMargin: PropTypes.bool,
}

FormattedContent.defaultProps = {
    content: null,
    children: null,
    topMargin: false,
}

const StyledFormattedContent = styled.div`
    margin-top: ${ p => ( p.topMargin ? '1.6em' : '0' ) };

    h3 {
        margin-bottom: 1em;
        font-weight: bold;
    }

    p {
        padding-bottom: 1em;
    }

    ${ p => p.collapseBottom && css`
        p:last-child {
            margin-bottom: 0;
        }
    ` }

    strong {
        font-weight: bold;
    }
`

export default FormattedContent
