// @flow
import React from 'react'
import type { Collaboration } from 'fabled/types/Collaboration'
import type { User, ChildUser, AuthUser } from 'fabled/types/User'
import DataLoaderGroup from './DataLoaderGroup'
import CollaborationsLoader from './CollaborationsLoader'
import UsersLoader from './UsersLoader'

type Props = {
    token: string,
    render: ( {
        collaboration?: Collaboration,
        requestingUser?: User,
        childUsers: Array<ChildUser>,
        authUser: AuthUser,
    } ) => mixed,
}

const CollaborationApprovalLoader = ( props: Props ) => {
    const { token } = props

    const loaders = [
        {
            key: 'collaborations',
            loader: render => (
                <CollaborationsLoader
                    service="collaborationsForParent"
                    requestId={ token }
                    waitForAuth
                    render={ render }
                />
            ),
        },
        {
            key: 'requestingUser',
            requiredProps: ['collaborations'],
            loader: ( render, { collaborations } ) => {
                const collaboration = collaborations.length ? collaborations[ 0 ] : null

                return (
                    <UsersLoader
                        userId={ collaboration ? collaboration.userId : null }
                        waitForAuth
                        render={ render }
                    />
                )
            },
            mapper: ( { users, authUser }, { collaborations } ) => ( {
                collaboration: collaborations && collaborations.length ? collaborations[ 0 ] : null,
                requestingUser: users && users.length ? users[ 0 ] : null,
                authUser,
            } ),
        },
        {
            key: 'childUsers',
            loader: ( render, { authUser } ) => (
                <UsersLoader
                    parentId={ authUser ? authUser.id : null }
                    service="myChildren"
                    waitForAuth
                    render={ render }
                />
            ),
            mapper: ( { users, authUser }, { requestingUser, collaboration } ) => ( {
                collaboration,
                requestingUser,
                childUsers: users,
                authUser,
            } ),
        },
    ]

    return (
        <DataLoaderGroup
            loaders={ loaders }
            render={ props.render }
        />
    )
}

export default CollaborationApprovalLoader
