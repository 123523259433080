import React from 'react'
import styled, { css } from 'styled-components'

const SectionText = ( {
    superNarrow = false,
    centered = false,
    titleColor = 'white',
    bodyColor = 'white',
    children,
} ) => (
    <StyledSectionText
        superNarrow={ superNarrow }
        centered={ centered }
        titleColor={ titleColor }
        bodyColor={ bodyColor }
    >
        { children }
    </StyledSectionText>
)

const StyledSectionText = styled.div`
    ${ p => p.superNarrow && css`
        max-width: 460px;
        margin-left: auto;
        margin-right: auto;
    ` }

    ${ p => p.centered && css`
        text-align: center;
    ` }

    h1, h2, h3, h4, h5, h6 {
        margin-bottom: 1em;
        color: var( --${ p => p.titleColor } );
        font-size: 22px;
        font-weight: bold;
    }

    p {
        color: var( --${ p => p.bodyColor } );
        font-size: 18px;
        font-weight: normal;
        line-height: 1.333;

        &:not( :last-child ) {
            margin-bottom: 1em;
        }
    }

    a {
        color: inherit;
        text-decoration: underline;
    }
`

export default SectionText