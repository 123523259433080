import React from 'react'
import PropTypes from 'prop-types'
import InputWrapper from './InputWrapper'
import TextInputField from './TextInputField'
import ValidationMessage from './ValidationMessage'

class TextInput extends React.Component {
    constructor( props ) {
        super( props )
        this.state = {
            value: props.value !== null && typeof props.value !== 'undefined' ? props.value : '',
            shouldValidate: false,
            validated: null,
            validationMessage: null,
            hasFocus: false,
        }

        this.setValue = this.setValue.bind( this )
        this.handleChange = this.handleChange.bind( this )
        this.handleFocus = this.handleFocus.bind( this )
        this.handleBlur = this.handleBlur.bind( this )
    }

    componentDidUpdate( prevProps ) {
        if ( this.props.value !== prevProps.value ) {
            let value

            if ( this.props.value !== null && typeof this.props.value !== 'undefined' ) {
                ( { props: { value } } = this )
            }
            else {
                value = ''
            }

            this.setValue( value )
        }
    }

    setValue( value ) {
        this.setState( { value } )
    }

    handleFocus() {
        this.setState( { hasFocus: true } )
    }

    handleBlur( e ) {
        const { target: { value } } = e
        this.setState( { hasFocus: false } )

        if ( !this.state.shouldValidate ) {
            this.setState( { shouldValidate: true }, () => this.validate( value ) )
        }
    }

    handleChange( e ) {
        if ( this.props.disabled ) {
            return
        }

        const { target: { value } } = e
        this.setState( { value } )

        if ( this.props.setFormState instanceof Function ) {
            this.props.setFormState( e.target.name, value )
        }

        this.validate( value )
            .then( ( validated ) => {
                if ( validated && this.props.onChange instanceof Function ) {
                    this.props.onChange( value )
                }
            } )
    }

    validate( value ) {
        const self = this

        if ( this.state.shouldValidate && this.props.validator instanceof Function ) {
            // Do this async so that we can optionally use validators that return promises
            return new Promise( ( resolve ) => {
                resolve( this.props.validator( value ) )
            } )
                .then( ( result ) => {
                    // result is an error message string, or true
                    const validated = ( result === true )
                    const validationMessage = ( result !== true ) ? result : null

                    if ( self.props.formValidator instanceof Function ) {
                        self.props.formValidator( self.props.id, validated, validationMessage )
                    }

                    self.setState( { validated, validationMessage } )

                    return result
                } )
                .then( result => result === true )
        }

        if ( self.props.formValidator instanceof Function ) {
            self.props.formValidator( self.props.id, true, null )
        }

        self.setState( { validated: true, validationMessage: null } )

        return Promise.resolve( true )
    }

    render() {
        const valid = this.props.disableValidationIcon ? null : this.state.validated
        const validationMessage = this.state.validationMessage || <span>&nbsp;</span>

        return (
            <InputWrapper valid={ valid }>
                <TextInputField
                    id={ this.props.id }
                    name={ this.props.name }
                    type="text"
                    label={ this.props.label }
                    onChange={ this.handleChange }
                    onFocus={ this.handleFocus }
                    onBlur={ this.handleBlur }
                    hasFocus={ this.state.hasFocus }
                    value={ this.state.value }
                    disabled={ this.props.disabled }
                />
                {
                    this.props.disableValidationMessage
                        ? null
                        : <ValidationMessage>{ validationMessage }</ValidationMessage>
                }
            </InputWrapper>
        )
    }
}

TextInput.propTypes = {
    value: PropTypes.string,
    disabled: PropTypes.bool,
    setFormState: PropTypes.func,
    validator: PropTypes.func,
    onChange: PropTypes.func,
    disableValidationMessage: PropTypes.bool,
    disableValidationIcon: PropTypes.bool,
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
}

TextInput.defaultProps = {
    value: '',
    disabled: false,
    setFormState: null,
    validator: null,
    onChange: null,
    disableValidationMessage: false,
    disableValidationIcon: false,
}

export default TextInput
