/* global process */
import ReactGA from 'react-ga'
import trackedActions from './actions/trackedActions'

if ( process.env.APP_ENV !== 'production' ) {
    ReactGA.initialize( 'UA-108567312-1', { debug: true } )
}
else {
    ReactGA.initialize( 'UA-108567312-1' )
}

export const eventTracker = ( event ) => {
    ReactGA.event( event )
}

export const actionEventTracker = () => next => ( action ) => {
    if ( trackedActions[ action.type ] ) {
        eventTracker( trackedActions[ action.type ]( action ) )
    }

    return next( action )
}

export const gaSet = ( fieldsObject ) => {
    ReactGA.set( fieldsObject )
}
