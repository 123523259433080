import React, { Fragment as F } from 'react'
import AppHeader from 'fabled/components/AppHeader/AppHeader'
import ParentDashboard from 'fabled/components/ParentDashboard'
import ScrollToTopOnMount from 'fabled/components/ScrollToTopOnMount'

const ParentDashboardRoute = () => (
    <F>
        <ScrollToTopOnMount />
        <AppHeader />
        <ParentDashboard />
    </F>
)

export default ParentDashboardRoute
