export const statusHelper = ( response ) => {
    if ( response.status >= 200 && response.status < 300 ) {
        return Promise.resolve( response )
    }

    return Promise.reject( response )
}

export const formData = ( data ) => {
    const fData = new FormData()

    Object.entries( data ).forEach( ( [k, v] ) => {
        fData.append( k, v )
    } )

    return fData
}
