import React from 'react'
import PropTypes from 'prop-types'

class BodyScroll extends React.Component {
    componentDidMount() {
        if ( document ) {
            document.body.classList.toggle( 'fab-no-scroll', !this.props.shouldScroll )
        }
    }

    componentDidUpdate( prevProps ) {
        if ( document && this.props.shouldScroll !== prevProps.shouldScroll ) {
            document.body.classList.toggle( 'fab-no-scroll', !this.props.shouldScroll )
        }
    }

    componentWillUnmount() {
        if ( document ) {
            document.body.classList.remove( 'fab-no-scroll' )
        }
    }

    render() {
        return this.props.children
    }
}

BodyScroll.propTypes = {
    shouldScroll: PropTypes.bool,
    children: PropTypes.element,
}

BodyScroll.defaultProps = {
    shouldScroll: true,
    children: null,
}

export default BodyScroll
