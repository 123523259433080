import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import actionCreators from 'fabled/actions/actionCreators'
import { getUiAudioIsRecording } from 'fabled/selectors/ui'
import { eventTracker } from 'fabled/eventTracker'
import MediaButton from './MediaButton'

const PostAudioUploader = ( props ) => {
    const isAudioRecordingSupported = () => (
        typeof navigator !== 'undefined'
            && typeof navigator.mediaDevices !== 'undefined'
            && typeof navigator.mediaDevices.getUserMedia !== 'undefined'
            && typeof MediaRecorder !== 'undefined'
            && MediaRecorder.isTypeSupported( 'audio/webm' )
    )

    const handleAudioBtn = ( e ) => {
        if ( e.key && !['Enter', ' '].includes( e.key ) ) {
            return
        }

        e.preventDefault()

        if ( isAudioRecordingSupported() ) {
            props.audioRecorderStart()
        }
        else {
            props.modalOpenDialog( {
                title: 'Sorry!',
                body: 'Audio recording isn’t working for your browser yet. To use audio recording, '
                    + 'please try Google Chrome on a desktop or laptop computer.',
                confirmText: 'Ok',
            } )
            eventTracker( {
                category: 'Story Writer',
                action: 'Attempted to start an audio recording using an unsupported browser.',
                label: 'Failed',
            } )
        }
    }

    // const handleAudio = ( fileOrStream ) => {
    //     const payload = {
    //         storyId: props.story.id,
    //         chapterId: props.currentChapter,
    //         uri: fileOrStream,
    //     }
    //
    //     Controllers.posts.createAudioPost( payload )
    //     eventTracker( {
    //         category: 'Story Writer',
    //         action: 'Added an audio post using file upload',
    //     } )
    // }
    //
    // const handleAudioInput = ( e ) => {
    //     e.preventDefault()
    //
    //     Array.from( e.target.files ).forEach( ( file ) => {
    //         handleAudio( file )
    //     } )
    // }

    return (
        <div role="button" tabIndex="0" onClick={ handleAudioBtn } onKeyDown={ handleAudioBtn }>
            <MediaButton
                audio
                isActive={ props.audioIsRecording }
                htmlFor="audio-upload-input"
            >
                Record audio
                <input
                    id="audio-upload-input"
                    className="fab-h-visually-hidden"
                    type="file"
                    name="image"
                    accept="audio/*"
                    capture="capture"
                    // onChange={ handleAudioInput }
                />
            </MediaButton>
        </div>
    )
}

PostAudioUploader.propTypes = {
    // story: PropTypes.shape( {
    //     id: PropTypes.number,
    // } ).isRequired,
    // currentChapter: PropTypes.number.isRequired,
    // postReplaceOrAdd: PropTypes.func.isRequired,

    // from connect
    audioIsRecording: PropTypes.bool.isRequired,
    modalOpenDialog: PropTypes.func.isRequired,
    audioRecorderStart: PropTypes.func.isRequired,
}

const mapStateToProps = state => ( {
    audioIsRecording: getUiAudioIsRecording( state ),
} )

const mapDispatchToProps = {
    modalOpenDialog: actionCreators.ui.modal.openDialog,
    audioRecorderStart: actionCreators.ui.audioRecorder.start,
}

export default connect( mapStateToProps, mapDispatchToProps )( PostAudioUploader )
