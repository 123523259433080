// @flow
import React from 'react'
import type { User } from 'fabled/types/User'
import styled, { css } from 'styled-components'
import { colors } from 'fabled/styles'
import userAvatar from 'fabled/helpers/userAvatar'

type Props = {
    user: ?User,
    isHighlighted: boolean,
    hasFocus: boolean,
    size: ?number,
    onClick: ?( e: SyntheticEvent<> ) => void,
}

const defaultProps = {
    user: null,
    isHighlighted: false,
    hasFocus: false,
    size: null,
    onClick: null,
}

const UserAvatar = ( props: Props ) => {
    const onClick = ( e: SyntheticEvent<> | SyntheticKeyboardEvent<> ) => {
        if ( e.key && !['Enter', ' '].includes( e.key ) ) {
            return
        }

        if ( props.onClick ) {
            props.onClick( e )
        }
    }

    return (
        <StyledUserAvatar
            isHighlighted={ props.isHighlighted }
            hasFocus={ props.hasFocus }
            size={ props.size }
            onClick={ onClick }
            onKeyDown={ onClick }
        >
            <div>
                <img
                    src={ userAvatar( props.user ).url }
                    alt={ userAvatar( props.user ).altText }
                />
            </div>
        </StyledUserAvatar>
    )
}

UserAvatar.defaultProps = defaultProps

const StyledUserAvatar = styled.div`
    position: relative;
    display: inline-block;
    vertical-align: middle;
    width: 100%;
    border-radius: 100%;
    transition: box-shadow .3s ease-out;

    ${ props => props.size && css`
        width: ${ props.size }px;
        height: ${ props.size }px;
    ` }

    ${ props => ( props.isHighlighted || props.hasFocus ? css`
        box-shadow: 0 0 0 3px ${ colors.greenBlue };
    ` : null ) }

    div {
        position: relative;
        padding-bottom: 100%;
    }

    img {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
    }
`

export default UserAvatar
