const encouragement = [
    { text: 'That’s brilliant!' },
    { text: 'Love that!' },
    { text: 'Oooooooh!' },
    { text: 'How interesting!' },
    { text: 'Fantastic' },
    { text: 'How about that!?!' },
    { text: 'Cool' },
    { text: 'Wow, love where this is going!' },
    { text: 'This is the best!' },
    { text: 'I’m on the edge of my seat' },
    { text: 'Can’t wait to hear what happens next' },
    { text: 'Intriguing!' },
    { text: 'Wow' },
    { text: 'Storytelling gold!' },
    { text: 'What a story you’re telling!' },
    { text: 'Fascinating' },
    { text: 'This is electrifying stuff!' },
    { text: 'You’re on fire!' },
    { text: 'Keep going it’s great!' },
    { text: 'Great' },
    { text: 'Fabulous' },
    { text: 'What an imagination!' },
    { text: 'So impressed' },
    { text: 'Woweeeee!' },
    { text: 'Didn’t see that coming….' },
    { text: 'What a story!' },
    { text: 'Gosh, this is quite the story' },
    { text: 'Ooooh unexpected' },
    { text: 'I’m so curious to hear more' },
]

export default encouragement
