// @flow
import React, { Fragment as F } from 'react'
import type { Node } from 'react'
import { connect } from 'react-redux'
import actionCreators from 'fabled/actions/actionCreators'
import { getStoryById } from 'fabled/selectors/stories'
import { getUserById } from 'fabled/selectors/users'
import Api from 'fabled/api'
import { eventTracker } from 'fabled/eventTracker'
import UsersLoader from 'fabled/components/loaders/UsersLoader'
import Form from 'fabled/components/Form'
import TextInput from 'fabled/components/FormInputs/TextInput'
import SubmitButton from 'fabled/components/FormInputs/SubmitButton'
import FormattedContent from 'fabled/components/FormattedContent'
import Loader from 'fabled/components/Loader'
import Modal from './Modal'

type Props = {
    // from connect
    story: {
        id: number,
    },
    storyAuthor: {
        parentId: string,
    },
    modalOpenDialog: ( {
        title: string,
        body: string | Node,
        confirmText?: string,
    } ) => mixed,
}

type State = {
    isWaiting: boolean,
}

const RequestCollaborationModal = class extends React.Component<Props, State> {
    static nameValidator( value ) {
        if ( !value.length ) {
            return 'Please enter a name or Fabled username.'
        }

        return true
    }

    constructor( props ) {
        super( props )
        this.state = {
            isWaiting: false,
        }
    }

    requestCollaboration = parentUser => ( e, formComponent ) => {
        e.preventDefault()

        if ( parentUser ) {
            this.setState( { isWaiting: true } )
            const { collaboratorName } = formComponent.getFields()

            Api.service( 'myCollaborations' ).create( {
                collaboratorName,
                storyId: this.props.story.id,
            } )
                .then( () => {
                    eventTracker( {
                        category: 'Story Writer',
                        action: 'Requested collaboration',
                    } )
                    this.props.modalOpenDialog( {
                        title: `We sent an email to ${ parentUser.parentName }`,
                        body: `An email has been sent to ${ parentUser.parentName } at ${ parentUser.email }.`,
                        confirmText: 'Ok',
                    } )
                } )
        }
    }

    render() {
        return (
            <Modal title="Create this story with a friend…">
                <UsersLoader
                    service="myParent"
                    render={
                        ( { users } ) => {
                            const parentUser = users && users.length ? users[ 0 ] : null
                            const parentName = parentUser ? parentUser.parentName : '…'

                            return (
                                <F>
                                    <FormattedContent>
                                        <p>
                                            To create a story with a friend, enter the name of the person you’d like to work with here. We’ll send <strong>{ parentName }</strong> an email so that they can arrange it for you.
                                        </p>
                                    </FormattedContent>

                                    <Form onSubmit={ this.requestCollaboration( parentUser ) }>
                                        <TextInput
                                            id="collaboratorName"
                                            name="collaboratorName"
                                            label="Name of your friend"
                                            validator={ RequestCollaborationModal.nameValidator }
                                        />
                                        <SubmitButton buttonText="Save" />
                                    </Form>

                                    <Loader isLoading={ this.state.isWaiting } />
                                </F>
                            )
                        }
                    }
                />
            </Modal>
        )
    }
}

type ConnectProps = {
    storyId: number,
}

const mapStateToProps = ( state, { storyId }: ConnectProps ) => {
    const story = getStoryById( state, storyId )

    return {
        story,
        storyAuthor: getUserById( state, story.userId ),
    }
}

const mapDispatchToProps = {
    modalOpenDialog: actionCreators.ui.modal.openDialog,
}

export default connect( mapStateToProps, mapDispatchToProps )( RequestCollaborationModal )
