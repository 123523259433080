// @flow
import React, { Fragment as F } from 'react'
import AppHeader from 'fabled/components/AppHeader/AppHeader'
import StoryWrapper from 'fabled/components/StoryWrapper'
import Story from 'fabled/components/Story'
import ScrollToTopOnMount from 'fabled/components/ScrollToTopOnMount'

type Props = {
    match: { params: { storyId: number } }
}

const StoryRoute = ( { match: { params: { storyId } } }: Props ) => (
    <F>
        <ScrollToTopOnMount />
        <AppHeader />
        <StoryWrapper
            key={ storyId } // recreate the component when storyId changes
            storyId={ parseInt( storyId, 10 ) }
            successComponent={ Story }
            storyPromoButton
            waitForAuth
        />
    </F>
)

export default StoryRoute
