import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import actionCreators from '../../actions/actionCreators'
import { getCurrentStoryPrompt } from '../../selectors/storyPrompts'
import DataLoader from './DataLoader'

const SimpleStoryPromptLoader = ( props ) => {
    const query = {
        active: true,
        $sort: {
            id: -1,
        },
        $limit: 1,
    }

    const onSuccess = ( response ) => {
        if ( response && response.total ) {
            props.storyPromptsSetCurrent( response.data[ 0 ].content )
        }
    }

    const listener = {
        events: ['created', 'updated', 'patched'],
        key: 'storyPromptLoader',
        listener: ( serviceName, eventName, message ) => {
            props.storyPromptsSetCurrent( message.content )
        },
    }

    const render = propsFromDataLoader => props.render( {
        storyPrompt: props.storyPrompt,
        ...propsFromDataLoader,
    } )

    return (
        <DataLoader
            service="storyPrompts"
            query={ query }
            onSuccess={ onSuccess }
            listener={ listener }
            render={ render }
            waitForAuth={ props.waitForAuth }
        />
    )
}

SimpleStoryPromptLoader.propTypes = {
    waitForAuth: PropTypes.bool,
    storyPrompt: PropTypes.string,
    storyPromptsSetCurrent: PropTypes.func.isRequired,
    render: PropTypes.func.isRequired,
}

SimpleStoryPromptLoader.defaultProps = {
    waitForAuth: false,
    storyPrompt: null,
}

const mapStateToProps = state => ( {
    storyPrompt: getCurrentStoryPrompt( state ),
} )

const mapDispatchToProps = {
    storyPromptsSetCurrent: actionCreators.storyPrompts.setCurrent,
}

export default connect( mapStateToProps, mapDispatchToProps )( SimpleStoryPromptLoader )
