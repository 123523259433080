import { handleActions } from 'redux-actions'
import actionCreators from '../actions/actionCreators'

const signup = handleActions(
    Object.assign( {
        // userSignupSuccess
        [ actionCreators.signup.success ]( state, action ) {
            const newState = Object.assign( {}, state )
            newState.user = action.payload
            return newState
        },

        // userSignupError
        [ actionCreators.signup.error ]( state ) {
            const newState = Object.assign( {}, state )
            newState.errorMessage = 'Something went wrong. Please try again.'
            return newState
        },

        // userSignupCompleted
        [ actionCreators.signup.completed ]( state, action ) {
            const newState = Object.assign( {}, state )
            newState.isCompleted = !!action.payload.isCompleted
            return newState
        },
    } ),
    {
        user: null,
        errorMessage: null,
        isCompleted: false,
    },
)

export default signup
