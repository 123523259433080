// @flow
import React from 'react'
import type { Story } from 'fabled/types/Story'
import type { Post } from 'fabled/types/Post'
import styled from 'styled-components'
import ActionManager from 'fabled/components/ActionManager'
import InspirationBtn from './InspirationBtn'
import CompletedBtn from './CompletedBtn'
import PassBtn from './PassBtn'

type Props = {
    story: Story,
    isCollaborative: boolean,
    posts: Array<Post>,
    currentChapter: number,
    postReplaceOrAdd: ( post: Post ) => void,
}

const StoryActionButtons = ( props: Props ) => (
    <ActionManager>
        { ( {
            openRequestCollaborationModal,
            openPassStoryModal,
            openPublishModal,
        } ) => {
            const onPassBtnClick = props.isCollaborative
                ? openPassStoryModal
                : openRequestCollaborationModal

            return (
                <StyledStoryActionButtons>
                    <InspirationBtn
                        story={ props.story }
                        posts={ props.posts }
                        currentChapter={ props.currentChapter }
                        postReplaceOrAdd={ props.postReplaceOrAdd }
                    />
                    <PassBtn onClick={ onPassBtnClick( props.story ) } />
                    <CompletedBtn onClick={ openPublishModal( props.story ) } />
                </StyledStoryActionButtons>
            )
        } }
    </ActionManager>
)

const StyledStoryActionButtons = styled.div`
    position: absolute;
    display: flex;
    justify-content: center;
    bottom: var(--post-input-btn-size);
    padding-bottom: 20px;
    left: 0;
    right: 0;
    text-align: center;
`

export default StoryActionButtons
