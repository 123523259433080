import React from 'react'
import PropTypes from 'prop-types'
import ThemesLoader from '../loaders/ThemesLoader'
import CollectionHeader from './CollectionHeader'
import CollectionStories from './CollectionStories'
import AdminCollectionStories from './AdminCollectionStories'

const Collection = props => (
    <ThemesLoader
        themeId={ props.collectionId }
        waitForAuth
        render={
            ( { themes, authUser } ) => {
                const theme = themes && themes.length ? themes[ 0 ] : null

                return (
                    <div className="fab-c-collection">
                        <CollectionHeader collection={ theme } />

                        <div className="fab-c-collection__inner">
                            <CollectionStories collectionId={ props.collectionId } />
                            {
                                authUser && authUser.role === 'admin'
                                    ? <AdminCollectionStories collectionId={ props.collectionId } />
                                    : null
                            }
                        </div>
                    </div>
                )
            }
        }
    />
)

Collection.propTypes = {
    collectionId: PropTypes.number.isRequired,
}


// const StyledCollection = styled.div`
//     > div {
//         position: relative;
//         max-width: 1200px;
//         margin: 3em auto;
//         padding: 0 5%;
//     }
//
//     &__heading {
//         font-size: 18px;
//         font-weight: bold;
//         padding: 2em 0 2em;
//     }
// `

export default Collection
