/**
 * Take an array and iterate over it randomly according to the following rules:
 * - don’t repeat items (until starting a new loop)
 * - loop infinitely
 * - don’t let the last item for one loop be the same as the first item in the next
 */

class RandomIterator {
    constructor( items ) {
        this.items = items
        this.lastItemIndex = null
        this.reset()
    }

    next() {
        if ( !this.keys.length ) {
            this.reset()
        }

        const key = this.keys.shift()

        if ( !this.keys.length ) {
            this.lastItemIndex = key
        }

        return this.items[ key ]
    }

    reset() {
        this.keys = this.shuffle( this.items )
    }

    shuffle( items ) {
        const keys = [...items.keys()]
        let currentIndex = items.length
        let temporaryValue
        let randomIndex

        while ( currentIndex !== 0 ) {
            randomIndex = Math.floor( Math.random() * currentIndex )
            currentIndex -= 1
            temporaryValue = keys[ currentIndex ]
            keys[ currentIndex ] = keys[ randomIndex ]
            keys[ randomIndex ] = temporaryValue
        }

        if ( keys[ 0 ] === this.lastItemIndex ) {
            keys.reverse()
        }

        return keys
    }
}

export default RandomIterator
