import { createSelector } from 'reselect'
import { getAuthUserId } from './auth'
import { return2ndArgAsArray } from './utils'

export const getUsers = state => state.users.data

export const getUsersAsArray = createSelector(
    getUsers,
    users => Object.values( users ),
)

export const getUserById = createSelector(
    getUsersAsArray,
    ( state, userId ) => userId,
    ( users, userId ) => users.find( u => u.id === userId ),
)

export const getAuthUser = state => getUserById( state, getAuthUserId( state ) )

export const getUsersById = createSelector(
    getUsersAsArray,
    return2ndArgAsArray,
    ( users, userIds ) => users.filter( u => userIds.includes( u.id ) ),
)

export const getUsersByParentId = createSelector(
    getUsersAsArray,
    return2ndArgAsArray,
    ( users, parentIds ) => users.filter( u => parentIds.includes( u.parentId ) ),
)

export const getParentOfChildWithId = createSelector(
    getUsersAsArray,
    getUserById,
    ( users, childUser ) => ( childUser ? users.find( u => u.id === childUser.parentId ) : null ),
)

export const getParentOfAuthUser = state => getParentOfChildWithId( state, getAuthUserId( state ) )
