import React from 'react'
import PropTypes from 'prop-types'
import differenceInDays from 'date-fns/differenceInDays'
import formatRelative from 'date-fns/formatRelative'
import formatDistance from 'date-fns/formatDistance'

const DateTime = ( props ) => {
    const diff = differenceInDays( props.dateTime, new Date() )
    const dateTimeString = diff > -6
        ? formatRelative( props.dateTime, new Date() )
        : `${ formatDistance( props.dateTime, new Date() ) } ago`

    return <span>{ dateTimeString }</span>
}

DateTime.propTypes = {
    dateTime: PropTypes.instanceOf( Date ).isRequired,
}

export default DateTime
