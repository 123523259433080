// @flow
import React, { Fragment as F } from 'react'
import type { Node } from 'react'
import type { AuthUser } from 'fabled/types/User'
import type { Collaboration } from 'fabled/types/Collaboration'
import { connect } from 'react-redux'
import actionCreators from 'fabled/actions/actionCreators'
import { getAuthUser } from 'fabled/selectors/users'
import UsersLoader from 'fabled/components/loaders/UsersLoader'
import StoriesLoader from 'fabled/components/loaders/StoriesLoader'
import CollaborationsLoader from 'fabled/components/loaders/CollaborationsLoader'
import ParentStoriesList from './ParentStoriesList'
import CollaborationsList from './CollaborationsList'
import Loader from './Loader'
import ContentWrapper from './ContentWrapper'
import Dashboard, {
    DashboardTitle,
    DashboardIntro,
    DashboardSection,
    DashboardSectionTitle,
} from './Dashboard/Dashboard'
import UserRow from './UserRow'

type Props = {
    // from connect
    authUser: ?AuthUser,
    modalOpenShare: ( storyId: number ) => void,
    modalOpenSetCollection: ( storyId: number ) => void,
    modalOpenDialog: ( {
        title: string,
        body: Node | string,
        confirmText?: string
    } ) => void,
}

type CollaborationsLoaderRenderProps = {
    collaborations: Array<Collaboration>,
}

const collaborationsLoaderRenderWithChildUser = ( child ) => {
    const CollaborationsLoaderRender = ( {
        collaborations,
    }: CollaborationsLoaderRenderProps ) => {
        const userIds = collaborations.reduce(
            ( agg, c ) => [...agg, c.userId, c.collaboratorId],
            [],
        )

        return (
            <UsersLoader
                userId={ userIds }
                render={
                    ( { users: collaboratingUsers } ) => (
                        <F>
                            <DashboardSectionTitle>
                                { child.firstName } is creating stories with these Fabled users
                            </DashboardSectionTitle>
                            <CollaborationsList
                                user={ child }
                                users={ collaboratingUsers }
                                collaborations={ collaborations }
                            />
                        </F>
                    )
                }
            />
        )
    }

    return CollaborationsLoaderRender
}

const ParentDashboard = ( props: Props ) => {
    const onShareStory = ( story ) => {
        if ( story.published ) {
            props.modalOpenShare( story.id )
        }
        else {
            props.modalOpenDialog( {
                title: 'This story isn’t published yet',
                body: 'This story isn’t finished yet. Encourage your child to finish the story '
                    + 'using the green “Finished?” button when they’re writing their story.',
                confirmText: 'Ok',
            } )
        }
    }

    const onCollectionBtn = ( story ) => {
        if ( story.published ) {
            props.modalOpenSetCollection( story.id )
        }
        else {
            props.modalOpenDialog( {
                title: 'This story isn’t published yet',
                body: 'This story isn’t finished yet. Encourage your child to finish the story '
                    + 'using the green “Finished?” button when they’re writing their story.',
                confirmText: 'Ok',
            } )
        }
    }

    if ( !props.authUser ) {
        return <Loader isWaiting />
    }

    return (
        <ContentWrapper>
            <Dashboard>
                <DashboardTitle>
                    Hi { props.authUser.firstName }
                </DashboardTitle>

                <DashboardIntro>
                    <p>
                        {
                            'This is your parent dashboard. Use the links below to see the creative '
                        + 'stories that your child has been making with Fabled.'
                        }
                    </p>
                    <p>Use the “Share” buttons to share stories using a secret link.</p>
                </DashboardIntro>

                <UsersLoader
                    service="myChildren"
                    parentId={ props.authUser.id }
                    waitForAuth
                    render={
                        ( { users } ) => users.map( child => (
                            <DashboardSection key={ child.id } well>
                                <DashboardSectionTitle>
                                    <UserRow user={ child } />
                                </DashboardSectionTitle>
                                <StoriesLoader
                                    service="storiesForParent"
                                    userIds={ child.id }
                                    waitForAuth
                                    render={
                                        ( { stories } ) => (
                                            <ParentStoriesList
                                                stories={ stories }
                                                onShare={ onShareStory }
                                                onCollection={ onCollectionBtn }
                                            />
                                        )
                                    }
                                />
                                <CollaborationsLoader
                                    service="collaborationsForParent"
                                    userId={ child.id }
                                    waitForAuth
                                    render={ collaborationsLoaderRenderWithChildUser( child ) }
                                />
                            </DashboardSection>
                        ) )
                    }
                />

            </Dashboard>
        </ContentWrapper>
    )
}

ParentDashboard.defaultProps = {
    authUser: null,
}

const mapStateToProps = state => ( {
    authUser: getAuthUser( state ),
} )

const mapDispatchToProps = {
    modalOpenShare: actionCreators.ui.modal.openShare,
    modalOpenSetCollection: actionCreators.ui.modal.openSetCollection,
    modalOpenDialog: actionCreators.ui.modal.openDialog,
}

export default connect( mapStateToProps, mapDispatchToProps )( ParentDashboard )
