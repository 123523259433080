// @flow
import React from 'react'
import type { ChildUser } from 'fabled/types/User'
import type { Story as StoryType } from 'fabled/types/Story'
import type { Post } from 'fabled/types/Post'
import { visiblePosts, arrangePostsIntoChapters } from 'fabled/selectors/posts'
import getByline from 'fabled/helpers/getByline'
import StoryMenuContainer from './StoryMenu/StoryMenuContainer'
import Title from './Title'
import Chapters from './sharedStoryComponents/Chapters'

type Props = {
    story: StoryType,
    posts: Array<Post>,
    storyAuthors: Array<ChildUser>,
}

const Story = ( { story, posts, storyAuthors }: Props ) => {
    const storyTitle = story && story.title ? story.title : '(Work in progress…)'
    const byline = getByline( storyAuthors )
    const chaptersWithVisiblePosts = arrangePostsIntoChapters( visiblePosts( posts ) )

    return (
        <div className="fab-c-story">
            <StoryMenuContainer
                story={ story }
                storyAuthors={ storyAuthors }
                exclude={ ['view'] }
            />

            <div className="fab-c-story__inner">
                <Title title={ storyTitle } subtitle={ byline } align="center" />
                <Chapters
                    chaptersWithVisiblePosts={ chaptersWithVisiblePosts }
                    storyAuthors={ storyAuthors }
                    showPostAuthor={ false }
                    isEditable={ false }
                    storyStyle="viewer"
                />
            </div>
        </div>
    )
}

export default Story
