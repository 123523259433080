import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import history from 'fabled/history'
import AppNavIcon from './AppNavIcon'

const AppNavItem = class extends React.Component {
    constructor( props ) {
        super( props )
        this.state = {
            hasHover: false,
            hasFocus: false,
        }
        this.handleClick = this.handleClick.bind( this )
        this.handleMouseEnter = this.handleMouseEnter.bind( this )
        this.handleMouseLeave = this.handleMouseLeave.bind( this )
        this.handleFocus = this.handleFocus.bind( this )
        this.handleBlur = this.handleBlur.bind( this )
    }

    handleClick( e ) {
        if ( e.key && !['Enter', ' '].includes( e.key ) ) {
            return
        }

        e.preventDefault()

        if ( this.props.onClick ) {
            this.props.onClick()
        }

        if ( this.props.subNav ) {
            this.setState( s => ( { hasOpenMenu: ( !s.hasOpenMenu ) } ) )
        }
        else if ( this.props.href ) {
            history.push( this.props.href )
        }
    }

    handleMouseEnter() {
        this.setState( { hasHover: true } )
    }

    handleMouseLeave() {
        this.setState( { hasHover: false } )
    }

    handleFocus() {
        this.setState( { hasFocus: true } )
    }

    handleBlur() {
        this.setState( { hasFocus: false } )
    }

    render() {
        const itemText = this.props.textLabel
            ? <StyledAppNavItemTextLabel>{ this.props.textLabel }</StyledAppNavItemTextLabel>
            : null

        return (
            <StyledAppNavItem
                onClick={ this.handleClick }
                onKeyDown={ this.handleClick }
                onMouseEnter={ this.handleMouseEnter }
                onMouseLeave={ this.handleMouseLeave }
                onFocus={ this.handleFocus }
                onBlur={ this.handleBlur }
                role="button"
                tabIndex="0"
            >
                { itemText }
                <AppNavIcon
                    iconUrl={ this.props.icon.url }
                    altText={ this.props.icon.altText }
                    count={ this.props.count }
                    isHighlighted={ this.state.hasHover }
                    hasFocus={ this.state.hasFocus }
                />
                { this.props.isSubNavOpen ? this.props.subNav : null }
            </StyledAppNavItem>
        )
    }
}

AppNavItem.propTypes = {
    icon: PropTypes.shape( {
        url: PropTypes.string.isRequired,
        altText: PropTypes.string,
    } ).isRequired,
    textLabel: PropTypes.node,
    subNav: PropTypes.element,
    isSubNavOpen: PropTypes.bool,
    href: PropTypes.string,
    count: PropTypes.number,
    onClick: PropTypes.func,
}

AppNavItem.defaultProps = {
    textLabel: '',
    subNav: null,
    isSubNavOpen: false,
    href: null,
    count: null,
    onClick: null,
}

const StyledAppNavItem = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    height: 100%;
    cursor: pointer;
    text-decoration: none;

    &:focus {
        outline: none;
    }
`

const StyledAppNavItemTextLabel = styled.div`
    margin-right: 3px;
    color: rgb(87, 87, 86);
`

export default AppNavItem
