/**
 * Notification parser for Fabled
 */
import React from 'react'
import get from 'lodash/get'
import isString from 'lodash/isString'
import isArray from 'lodash/isArray'
import isObject from 'lodash/isObject'
import Button from 'fabled/components/Button'

const parsers = {
    varIsTruthyTag: {
        // {? var }
        literal: true,
        pattern: new RegExp( /^{\?\s*([\w.]+)\s*}$/ ),
        replacer: data => ( match, $1 ) => !!get( data, $1 ),
    },
    varIsFalsyTag: {
        // {?! var }
        literal: true,
        pattern: new RegExp( /^{\?!\s*([\w.]+)\s*}$/ ),
        replacer: data => ( match, $1 ) => !get( data, $1 ),
    },
    varTag: {
        // {{ var }}
        pattern: new RegExp( /{{\s*([\w.]+)\s*}}/g ),
        replacer: data => ( match, $1 ) => get( data, $1 ),
    },
    bold: {
        // **bold text**
        literal: true,
        pattern: new RegExp( /(\*\*|__)(.*?)\1/g ),
        replacer: () => ( key, match, $1, $2 ) => <strong key={ key }>{ `${ $2 }` }</strong>,
    },
    link: {
        // [Link text](http://example.com)
        literal: true,
        pattern: new RegExp( /\[([^[]+)\]\(([^)]+)\)/g ),
        replacer: () => ( key, match, $1, $2 ) => <a key={ key } href={ $2 }>{ $1 }</a>,
    },
}

class Parser {
    static parse( parser, template, data ) {
        return template.replace( parser.pattern, parser.replacer( data ) )
    }

    static parseLiteral( parser, template, data ) {
        if ( parser.pattern.test( template ) ) {
            if ( parser.pattern.global && template.match( parser.pattern ) ) {
                const items = []
                let lastIndex = 0
                let match
                // eslint-disable-next-line no-cond-assign
                while ( ( match = parser.pattern.exec( template ) ) !== null ) {
                    if ( match.index > lastIndex ) {
                        items.push( match.input.substring( lastIndex, match.index ) )
                    }

                    items.push( parser.replacer().apply( null, [lastIndex, ...match] ) )
                    lastIndex = match.index + match[ 0 ].length
                }

                if ( lastIndex < template.length ) {
                    items.push( template.substring( lastIndex ) )
                }

                return items
            }

            const matches = template.match( parser.pattern )

            if ( matches.length ) {
                return parser.replacer( data )( ...matches )
            }
        }

        return template
    }

    constructor( template, data ) {
        this.parsed = template
        this.data = data
    }

    parseWith( parser ) {
        if ( parser.literal ) {
            this.parsed = Parser.parseLiteral( parser, this.parsed, this.data )
        }
        else if ( isString( this.parsed ) ) {
            this.parsed = Parser.parse( parser, this.parsed, this.data )
        }

        return this
    }

    getParsed() {
        return this.parsed
    }
}

const flattenArray = list => list.reduce(
    ( arr, item ) => arr.concat( isArray( item ) ? flattenArray( item ) : item ),
    [],
)

const parseObject = ( obj, onActionClick ) => {
    if ( obj.action ) {
        return (
            <Button
                small
                inline
                primary
                to={ obj.action.href }
                onClick={ onActionClick }
            >
                { obj.action.text }
            </Button>
        )
    }

    return null
}

export const parseNotification = ( template, data, key, onActionClick ) => {
    if ( isString( template ) ) {
        const parsed = new Parser( template, data )
            .parseWith( parsers.varIsTruthyTag )
            .parseWith( parsers.varIsFalsyTag )
            .parseWith( parsers.varTag )
            .parseWith( parsers.bold )
            .parseWith( parsers.link )
            .getParsed()

        return [true, false, null].includes( parsed ) ? parsed : <p key={ key }>{ parsed }</p>
    }

    if ( isArray( template ) ) {
        const parsedArray = template.map( ( val, i ) => (
            parseNotification( val, data, i, onActionClick ) ) )

        if ( parsedArray.some( item => item === false ) ) {
            return null
        }

        const filteredArray = parsedArray.filter( item => ![true, false, null].includes( item ) )
        return <React.Fragment key={ key }>{ flattenArray( filteredArray ) }</React.Fragment>
    }

    if ( isObject( template ) ) {
        return <div key={ key }>{ parseObject( template, onActionClick ) }</div>
    }

    return template
}

export const parseNotificationTitle = ( template, data ) => new Parser( template, data )
    .parseWith( parsers.varTag )
    .getParsed()

export const parseNotificationBody = ( template, data, onActionClick ) => (
    parseNotification( template, data, null, onActionClick )
)
