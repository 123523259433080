import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { rgba } from 'polished'
import InstagramIcon from 'react-icons/lib/io/social-instagram'
import TwitterIcon from 'react-icons/lib/io/social-twitter'
import config from 'fabled/config'
import colors from 'fabled/styles/colors'
import media from 'fabled/styles/media'

const Footer = () => (
    <StyledFooter>
        <StyledFooterGridContainer>
            <StyledFooterItem>
                Need help? <a href={ `mailto:${ config.supportEmail }` }>Email us</a>
            </StyledFooterItem>
            <StyledFooterItem>
                <a href="/about">About Fabled</a>
            </StyledFooterItem>
            <StyledFooterItem>
                <a href="/terms">Privacy, terms and conditions</a>
            </StyledFooterItem>
            <StyledFooterItemGroup>
                <StyledFooterItem>
                    <FooterIconLink instagram href="https://www.instagram.com/fabled_kids/" />
                </StyledFooterItem>
                <StyledFooterItem>
                    <FooterIconLink twitter href="https://twitter.com/fabled_kids" />
                </StyledFooterItem>
            </StyledFooterItemGroup>
        </StyledFooterGridContainer>
    </StyledFooter>
)

const textAndIconColor = 'rgba(255,255,255)'

const FooterIconLink = props => (
    <StylesFooterIconLink href={ props.href } target="_blank">
        <FooterIcon { ...props } />
    </StylesFooterIconLink>
)

FooterIconLink.propTypes = {
    href: PropTypes.string.isRequired,
    instagram: PropTypes.bool,
    twitter: PropTypes.bool,
}

FooterIconLink.defaultProps = {
    instagram: false,
    twitter: false,
}

const StylesFooterIconLink = styled.a`
    color: inherit;
`

const FooterIcon = ( props ) => {
    if ( props.instagram ) {
        return <InstagramIcon size={ 20 } />
    }

    if ( props.twitter ) {
        return <TwitterIcon size={ 20 } />
    }

    return null
}

FooterIcon.propTypes = {
    instagram: PropTypes.bool,
    twitter: PropTypes.bool,
}

FooterIcon.defaultProps = {
    instagram: false,
    twitter: false,
}

const StyledFooter = styled.div`
    position: relative;
    margin: 0 auto;
    padding: 2em 8% 2em;
    text-align: center;
    max-width: 500px;
    border-top: 1px solid ${ rgba( colors.purple, 0.3 ) };

    ${ media.forms`
        padding-top: 0;
        border-top: 0;
    ` }

    @media all and (min-width: 1101px) {
        /* text-align: left; */
        max-width: none;
    }
`

const StyledFooterGridContainer = styled.div`
    margin-left: -0.5em;
    margin-left: -0.5em;
`

const StyledFooterItemGroup = styled.div`
    display: inline-block;
    margin-left: 0.5em;
    margin-right: 0.5em;
    padding-top: 1em;
`

const StyledFooterItem = styled.div`
    display: inline-block;
    margin-left: 0.5em;
    margin-right: 0.5em;
    color: ${ colors.purple };
    white-space: nowrap;

    a {
        color: ${ colors.purple };
        font-weight: bold;
        text-decoration: none;
        opacity: 0.8;
        transition: opacity .3s ease-out;

        &:hover {
            opacity: 1;
        }
    }

    /* ${ media.forms`
        color: ${ textAndIconColor };
    ` } */
`

export default Footer
