import React from 'react'
import { Link } from 'react-router-dom'
import Form from '../Form'
import TextInput from '../FormInputs/TextInput'
import UINotice from '../UINotice'
import Loader from '../Loader'
import Api from '../../api'

class PasswordReset extends React.Component {
    constructor() {
        super()
        this.state = {
            waiting: false,
            error: null,
            succeeded: false,
            passwordResetEmail: null,
            usedOwnEmail: null,
        }
        this.handleSubmit = this.handleSubmit.bind( this )
        this.usernameInput = null
    }

    handleSubmit( e, formComponent ) {
        if ( this.state.waiting ) {
            return
        }

        this.setState( { waiting: true, error: null } )
        Api.service( 'authManagement' ).create( {
            action: 'sendResetPwd',
            value: { email: formComponent.getFields().username },
        } )
            .then( ( response ) => {
                this.setState( {
                    waiting: false,
                    succeeded: true,
                    passwordResetEmail: response.passwordResetEmail,
                    usedOwnEmail: response.passwordResetEmail === response.email,
                } )
            } )
            .catch( error => this.setState( { error, waiting: false } ) )
    }

    render() {
        const errorMessage = ( error ) => {
            if ( error ) {
                return (
                    <UINotice type="error">
                        We don’t have an email address for this username.
                    </UINotice>
                )
            }

            return null
        }

        if ( this.state.succeeded ) {
            const { state: { usedOwnEmail } } = this
            const { state: { passwordResetEmail } } = this
            const message = usedOwnEmail
                ? (
                    <p>
                        We sent an email to <strong>{ passwordResetEmail }</strong>.
                    </p>
                )
                : (
                    <p>
                        We sent an email to your parent or guardian at <strong>{ passwordResetEmail }</strong>.
                    </p>
                )

            return (
                <div className="fab-c-signup-form-wrapper">
                    <div className="fab-c-signup-form fab-c-signup-form--front">
                        <div className="fab-c-signup-form__header">
                            <h2 className="fab-c-signup-form__heading">{ usedOwnEmail ? 'Check your email…' : 'We sent an email…' }</h2>
                        </div>
                        <div className="fab-c-signup-form__copy">
                            { message }
                        </div>
                    </div>
                </div>
            )
        }

        return (
            <div className="fab-c-signup-form-wrapper">

                <Form className="fab-c-signup-form fab-c-signup-form--front" onSubmit={ this.handleSubmit }>
                    <div className="fab-c-signup-form__header">
                        <h2 className="fab-c-signup-form__heading">Reset your password</h2>
                    </div>

                    <div className="fab-c-signup-form__copy">
                        <p>Enter your nickname below, and we’ll send an email to your Grown-Up so you can reset your password. If you can’t remember your nickname your Grown-Up can find it listed in their account.</p>
                        <p>If you’re a Grown-Up trying to reset your account, enter your email address instead.</p>
                    </div>

                    { errorMessage( this.state.error ) }

                    <TextInput
                        name="username"
                        id="fab-id-signup-username"
                        label="Nickname or email"
                    />

                    <div className="fab-c-signup-form__input-row">
                        <input type="submit" className="fab-c-signup-form__submit-btn" value="Send email" />
                    </div>
                    <Link to="/login" href="/login" className="fab-c-signup-form__link">Back to login</Link>

                    <Loader isLoading={ this.state.waiting } />
                </Form>

            </div>
        )
    }
}

export default PasswordReset
