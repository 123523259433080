import React from 'react'
import styled, { css } from 'styled-components'
import Background from 'fabled/components/Background'
import Section from 'fabled/components/Section'
import HeroTitle from 'fabled/components/HeroTitle'
import HeroButton from 'fabled/components/HeroButton'
import SectionText from 'fabled/components/SectionText'

const MarketingHero = ( { illis = [], illiSmall } ) => (
    <Background bgColor="midBlue">
        <Section headerPush bottom="none">
            <StyledHeroTop>
                <StyledTitleBlock>
                    <HeroTitle color="almostWhite">The Future is Make Believe</HeroTitle>
                    <HeroButton to="/signup">Start telling your story</HeroButton>
                </StyledTitleBlock>
                <FloatingIllis illis={ illis } illiSmall={ illiSmall }  />
            </StyledHeroTop>
            <StyledHeroBottom>
                <SectionText titleColor="deepBlue">
                    <h2>Being a kid is a superpower</h2>
                    <p>But it’s running out fast! Right now you are full of magic – stories, ideas and ways of seeing things that grown-ups have lost. You’ve got the greatest stories never told, and we want to hear them. We think there’s enough telling you what to do. So at Fabled, we want you to be in charge. It’s your voice, we just turn up the volume.</p>
                </SectionText>
                <img src="/images/inspiration/house.svg" alt="House" />
            </StyledHeroBottom>
        </Section>
    </Background>
)

const breakpointMedium = 600
const breakpointLarge = 900

const StyledHeroTop = styled.div`
    @media screen and (min-width: ${ breakpointMedium }px) {
        display: flex;
    }
`

const StyledTitleBlock = styled.div`
    align-self: center;

    > div + div {
        margin-top: 3rem;
    }

    @media all and ( max-width: ${ breakpointMedium -1 }px ) {
        padding-top: 2rem;
    }

    @media all and ( min-width: ${ breakpointMedium }px ) {
        flex: 0 1 45%;
    }

    @media all and ( min-width: ${ breakpointLarge }px ) {
        flex: 0 1 35%;
    }
`

const FloatingIllis = ( { illis, illiSmall } ) => (
    <StyledFloatingIllis>
        { illis.map( ( illi, i ) => (
            <StyledIlli
                key={ i }
                top={ illi.top }
                left={ illi.left }
                width={ illi.width }
                rotate={ illi.rotate }
                className="largeScreenOnly"
            >
                <img src={ illi.url } alt={ illi.title } />
            </StyledIlli>
        ) ) }

        <StyledIlliSmall
            width={ illiSmall.width }
            rotate={ illiSmall.rotate }
            className="smallScreenOnly"
        >
            <img src={ illiSmall.url } alt={ illiSmall.title } />
        </StyledIlliSmall>
    </StyledFloatingIllis>
)

const StyledFloatingIllis = styled.div`
    flex-grow: 1;

    @media all and ( max-width: ${ breakpointMedium - 1 }px ) {
        display: none;
    }

    @media all and ( max-width: ${ breakpointLarge - 1 }px ) {
        .largeScreenOnly {
            display: none;
        }
    }

    @media all and ( min-width: ${ breakpointMedium }px ) {
        flex: 0 1 50%;
        margin-left: auto;
    }

    @media all and ( min-width: ${ breakpointLarge }px ) {
        flex: 0 1 60%;
        position: relative;
        padding-bottom: 45%;

        .smallScreenOnly {
            display: none;
        }
    }
`

const StyledIlli = styled.div`
    position: absolute;
    top: ${ p => p.top };
    left: ${ p => p.left };
    width: ${ p => p.width };

    ${ p => p.rotate && css`
        transform: rotateZ( ${ p.rotate } );
    ` }

    img {
        width: 100%;
    }
`

const StyledIlliSmall = styled.div`
    ${ p => p.rotate && css`
        transform: rotateZ( ${ p.rotate } );
    ` }

    img {
        width: 100%;
    }
`

const StyledHeroBottom = styled.div`
    margin-top: 3rem;

    img {
        display: block;
    }

    > div {
        margin-bottom: 3rem;
    }

    @media screen and ( min-width: ${ breakpointMedium }px ) {
        display: flex;
        flex-direction: row-reverse;

        img {
            flex: 0 1 40%;
            max-width: 40%;
            align-self: flex-end;
        }

        > div {
            flex: 0 1 50%;
            align-self: center;
            margin-left: auto;
        }
    }

    @media screen and ( min-width: ${ breakpointLarge }px ) {
        img {
            transform: translateX( -10% );
        }
    }

    @media screen and ( max-width: ${ breakpointMedium - 1 }px ) {
        > div {
            margin: 6rem 0 3rem;
        }
    }
`

export default MarketingHero