import React from 'react'
import PropTypes from 'prop-types'
import Api from 'fabled/api'
import Form from 'fabled/components/Form'
import TextInput from 'fabled/components/FormInputs/TextInput'
import UINotice from 'fabled/components/UINotice'
import Loader from 'fabled/components/Loader'
import Button from 'fabled/components/Button'

class NewPassword extends React.Component {
    constructor( props ) {
        super( props )
        this.state = {
            waiting: false,
            failed: false,
            error: null,
            succeeded: null,
            user: null,
        }
        this.handleSubmit = this.handleSubmit.bind( this )
    }

    componentDidMount() {
        Api.service( 'passwordResetUsers' ).get( this.props.token )
            .then( user => this.setState( { user } ) )
            .catch( error => this.setState( { error } ) )
    }

    handleSubmit( e, formComponent ) {
        this.setState( { waiting: true } )
        Api.service( 'authManagement' ).create( {
            action: 'resetPwdLong',
            value: {
                token: this.props.token,
                password: formComponent.getFields().password,
            },
        } )
            .then( () => this.setState( {
                waiting: false,
                succeeded: true,
            } ) )
            .catch( error => this.setState( {
                waiting: false,
                failed: true,
                error,
            } ) )
    }

    render() {
        const passwordValidator = ( value ) => {
            if ( value.length < 6 ) {
                return 'Your password must be at least 6 letters.'
            }

            return true
        }

        const errorMessage = ( state ) => {
            if ( state.error ) {
                const msg = state.error.name === 'BadRequest'
                    ? (
                        <span>
                            Your password reset token is out of date. Please <a href="/auth/password">request a new one here</a>.
                        </span>
                    )
                    : <span>Something went wrong.</span>

                return (
                    <UINotice type="error">
                        { msg }
                    </UINotice>
                )
            }

            return null
        }

        if ( this.state.succeeded ) {
            return (
                <div className="fab-c-signup-form-wrapper">
                    <div className="fab-c-signup-form fab-c-signup-form--front">
                        <div className="fab-c-signup-form__header">
                            <h2 className="fab-c-signup-form__heading">Your password has been reset</h2>
                        </div>
                        <div className="fab-c-signup-form__copy">
                            <p>You can now log in with your new password.</p>
                            <Button type="button" primary href="/login">Log in…</Button>
                        </div>
                    </div>
                </div>
            )
        }

        return (
            <div className="fab-c-signup-form-wrapper">

                <Form className="fab-c-signup-form fab-c-signup-form--front" onSubmit={ this.handleSubmit }>
                    <div className="fab-c-signup-form__header">
                        <h2 className="fab-c-signup-form__heading">Reset your password</h2>
                    </div>

                    {
                        this.state.user
                            ? (
                                <p>
                                    You are resetting the password for the user with
                                    <strong>
                                        {
                                            this.state.user.username
                                                ? ` username: ${ this.state.user.username }`
                                                : ` email address: ${ this.state.user.email }`
                                        }
                                    </strong>
                                </p>
                            )
                            : null
                    }

                    { errorMessage( this.state ) }

                    <TextInput
                        name="password"
                        id="fab-id-signup-password"
                        label="Pick a password"
                        validator={ passwordValidator }
                    />

                    <Button primary>Reset password</Button>

                    <Loader isLoading={ this.state.waiting } />
                </Form>

            </div>
        )
    }
}

NewPassword.propTypes = {
    token: PropTypes.string,
}

NewPassword.defaultProps = {
    token: null,
}

export default NewPassword
