import React from 'react'

const NotFound = () => (
    <div className="fab-c-not-found">
        <a href="/">
            <img src="/images/fabled-logo-full.svg" alt="" />
        </a>
        <p>Page not found.</p>
    </div>
)

export default NotFound
