import styled, { css } from 'styled-components'

const LayoutBox = styled.div`
    display: flex;
    height: 100%;

    ${ p => p.direction && css`
        flex-direction: ${ p.direction };
    ` }

    ${ p => p.justify && css`
        justify-content: ${ p.justify };
    ` }

    ${ p => p.align && css`
        align-items: ${ p.align };
    ` }
`

export default LayoutBox