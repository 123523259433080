// @flow
import React from 'react'
import styled, { css } from 'styled-components'
import { rgba } from 'polished'
import { colors } from '../styles'
import { UIBox } from '../styles/shared'

type Props = {
    label: string,
    color: string,
    textColor: string,
    icon: string,
    iconSize: number,
    position: 'left' | 'right',
    onClick: ( e: SyntheticEvent<> ) => void,
    onKeyDown: ( e: SyntheticKeyboardEvent<> ) => void,
}

const defaultProps = {
    label: '',
    color: colors.purple,
    textColor: 'white',
    icon: null,
    iconSize: 45,
    position: 'left',
    onClick: null,
    onKeyDown: null,
}

const ActionButton = ( props: Props ) => (
    <StyledActionButtonWrapper
        position={ props.position }
    >
        <StyledIActionButtonIcon
            position={ props.position }
            color={ props.color }
            icon={ props.icon }
            iconSize={ props.iconSize }
            role="button"
            tabIndex="0"
            onClick={ props.onClick }
            onKeyDown={ props.onKeyDown }
        >
            <StyledActionButtonLabel
                position={ props.position }
                color={ props.color }
                textColor={ props.textColor }
            >
                { props.label }
            </StyledActionButtonLabel>
        </StyledIActionButtonIcon>
    </StyledActionButtonWrapper>
)

ActionButton.defaultProps = defaultProps

const StyledActionButtonWrapper = styled.div`
    position: relative;
    cursor: default;
    margin: 0 10px;

    &:first-child {
        margin-left: 0;
    }

    &:last-child {
        margin-right: 0;
    }
`

const StyledIActionButtonIcon = styled.div`
    display: inline-block;

    ${ props => props.position === 'left' && css`
        text-align: right;
        float: right;
    ` }

    ${ props => props.position === 'right' && css`
        text-align: left;
        float: left;
    ` }

    &:${ props => ( props.position === 'left' ? 'after' : 'before' ) } {
        content: ' ';
        display: inline-block;
        vertical-align: middle;
        margin: 0 auto;
        width: 55px;
        height: 55px;
        background:
            ${ props => props.color }
            url(${ props => props.icon })
            no-repeat
            center center;
        background-size: ${ props => props.iconSize }%;
        border-radius: 100%;
        transition: transform .3s ease-out;
    }

    &:focus {
        outline: none;

        &:after,
        &:before {
            box-shadow: 0 0 0 5px ${ props => rgba( props.color, 0.3 ) };
        }
    }

    ${ StyledActionButtonWrapper }:hover &,
    &:focus {
        &:after,
        &:before {
            transform: rotateZ(25deg);
        }
    }
`

const StyledActionButtonLabel = styled.span`
    ${ UIBox }
    position: absolute;
    bottom: 65px;
    left: 50%;
    transform: translate( -50%, 0 );
    display: block;
    padding: 0.6em 1em;
    font-size: 18px;
    font-weight: bold;
    white-space: nowrap;
    color: ${ p => p.textColor };
    background: ${ p => p.color };
    pointer-events: none;
    opacity: 0;
    transition:
        opacity .3s ease-out,
        transform .2s ease-out;

    &:before {
        content: ' ';
        position: absolute;
        left: 50%;
        margin-left: -8px;
        bottom: -8px;
        display: block;
        width: 0;
        height: 0;
        border-left: 8px transparent solid;
        border-right: 8px transparent solid;
        border-top: 8px ${ p => p.color } solid;
    }

    ${ StyledActionButtonWrapper }:hover & {
        opacity: 1;
        transform: translate( -50%, -5px );
    }
`

export default ActionButton
