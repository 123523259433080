import React from 'react'
import PropTypes from 'prop-types'
import { colors } from '../styles'
import { slugify } from '../helpers/text'

const Icon = ( props ) => {
    const styles = {
        svg: {
            display: 'inline-block',
            verticalAlign: 'middle',
        },
        path: {
            fill: props.color || 'inherit',
        },
    }
    const title = props.title || props.icon.title
    const className = props.className || slugify( `icon-${ title }` )
    const icon = props.icon.path
        ? <path style={ styles.path } d={ props.icon.path } />
        : props.icon.markup

    const svgProps = {
        style: styles.svg,
        viewBox: props.icon.viewBox,
        className,
    }

    if ( props.size ) {
        svgProps.width = `${ props.size }px`
        svgProps.height = `${ props.size }px`
    }

    return (
        <svg { ...svgProps } aria-labelledby={ title }>
            <title>{ title }</title>
            { icon }
        </svg>
    )
}

Icon.propTypes = {
    icon: PropTypes.shape( {
        title: PropTypes.string,
        path: PropTypes.string,
        markup: PropTypes.node,
        viewBox: PropTypes.string,
    } ).isRequired,
    title: PropTypes.string,
    color: PropTypes.string,
    size: PropTypes.number,
    className: PropTypes.string,
}

Icon.defaultProps = {
    title: null,
    color: colors.typeGrey,
    size: null,
    className: null,
}

export default Icon
