// @flow
import React from 'react'
import type { Node } from 'react'
import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'
import { rgba, darken, getLuminance } from 'polished'
import { colors } from '../styles'
import icons from 'fabled/icons'
import Icon from 'fabled/components/Icon'

export type ButtonEvent =
    SyntheticMouseEvent<HTMLButtonElement> | SyntheticKeyboardEvent<HTMLButtonElement>

type Props = {
    children?: Node | Array<Node>,
    to?: ?string,
    href?: ?string,
    small?: boolean,
    tiny?: boolean,
    inline?: boolean,
    primary?: boolean,
    arrow?: boolean,
    fontSize?: ?number,
    bgColor?: ?string,
    buttonType: 'submit' | 'button' | 'reset',
}

const Button = ( props: Props ) => {
    // create an htmlProps object to pass to <button> or <a> tag,
    // by removing invalid props used for style variations
    const {
        small,
        tiny,
        inline,
        primary,
        arrow,
        fontSize = 16,
        bgColor = 'purple',
        buttonType,
        ...htmlProps
    } = props

    const buttonProps = {
        small, tiny, inline, primary, arrow, fontSize, bgColor
    }

    const arrowIcon = () => {
        if ( !arrow ) {
            return null
        }

        return (
            <Icon
                icon={ icons.arrow }
                size={ fontSize }
            />
        )
    }

    const buttonComponent = () => {
        if ( props.to ) {
            return (
                <Link { ...htmlProps } href={ props.to }>
                    { props.children }
                    { arrowIcon() }
                </Link>
            )
        }
        else if ( props.href ) {
            return (
                <a { ...htmlProps }>
                    { props.children }
                    { arrowIcon() }
                </a>
            )
        }
        else {
            // eslint-disable-next-line react/button-has-type
            return (
                <button type={ buttonType } { ...htmlProps }>
                    { props.children }
                    { arrowIcon() }
                </button>
            )
        }
    }

    return (
        <StyledButton { ...buttonProps }>
            { buttonComponent() }
        </StyledButton>
    )
}

Button.defaultProps = {
    children: null,
    to: null,
    href: null,
    small: false,
    tiny: false,
    inline: false,
    primary: false,
    buttonType: 'submit',
}

export const StyledButton = styled.span`
    > a,
    > button {
        position: relative;
        display: block;
        width: 100%;
        padding: 1.4375em 2em;
        text-align: inherit;
        font-size: inherit;
        line-height: inherit;
        font-weight: 700;
        text-decoration: none !important;
        border: 1px solid ${ colors.purple };
        border-radius: 2px;
        background: transparent;
        color: ${ colors.purple };
        transition:
            border-color .3s ease-out,
            background-color .3s ease-out;

        &:hover {
            background: ${ colors.green };
            border-color: ${ colors.green };
            color: white;
        }

        &:focus {
            outline: none;
            box-shadow: 0 0 0 3px ${ rgba( colors.purple, 0.3 ) };
        }

        ${ props => props.primary && css`
            background: ${ p => colors[ p.bgColor ] };
            color: white;

            &:hover {
                background: ${ colors.green };
            }
        ` }

        ${ p => p.fontSize && css`
            font-size: ${ p.fontSize }px;
        ` }

        ${ p => p.inline && css`
            display: inline-block;
            width: auto;
        ` }

        ${ p => p.small && css`
            padding: 10px 16px;
        ` }

        ${ p => p.tiny && css`
            padding: 4px 8px;
            font-weight: normal;
        ` }

        ${ p => p.bgColor && css`
            background-color: ${ colors[ p.bgColor ] };
            border-color: ${ colors[ p.bgColor ] };
            color: ${ getLuminance( colors[ p.bgColor ] ) < 0.5
                ? 'white'
                : colors.almostBlack
            } !important;

            &:hover {
                background: ${ darken( 0.1, colors[ p.bgColor ] ) };
                border-color: ${ darken( 0.1, colors[ p.bgColor ] ) };
            }

            &:focus {
                box-shadow: 0 0 0 3px ${ rgba( colors[ p.bgColor ], 0.3 ) };
            }

            svg {
                polygon {
                  fill: ${ getLuminance( colors[ p.bgColor ] ) < 0.5
                      ? 'white'
                      : colors.almostBlack
                  };
                }
            }
        ` }

        ${ p => p.arrow && css`
            padding-right: 3.4em;

            svg {
                position: absolute;
                top: 50%;
                transform: translateY( -50% );
                right: 1em;
            }
        ` }
    }
`

export default Button
