// @flow
import React, { Fragment as F } from 'react'
import styled from 'styled-components'
import avatars from 'fabled/data/avatars'
import Button from 'fabled/components/Button'
import UserAvatar from 'fabled/components/UserAvatar'
import InputWrapper from '../InputWrapper'
import AvatarSelectItem from './AvatarSelectItem'

type Props = {
    name: string,
    value: string,

    // from Form
    setFormState: ?( name: string, value: mixed ) => void,
}

const defaultProps = {
    setFormState: null,
}

type State = {
    value: string,
    isOpen: boolean,
}

class AvatarSelectInput extends React.Component<Props, State> {
    defaultProps = defaultProps

    state = {
        value: this.props.value,
        isOpen: false,
    }

    onSelect = ( avatar: string ) => () => {
        this.setState( {
            isOpen: false,
            value: avatar,
        } )

        if ( this.props.setFormState instanceof Function ) {
            this.props.setFormState( this.props.name, avatar )
        }
    }

    toggleDropdown = ( e: SyntheticEvent<> ) => {
        e.preventDefault()
        this.setState( s => ( { isOpen: !s.isOpen } ) )
    }

    render() {
        const user = {
            id: 0,
            username: 'unknown',
            parentId: null,
            avatar: this.state.value,
        }

        return (
            <InputWrapper>
                <StyledAvatarSelect>
                    <UserAvatar size={ 78 } user={ user } />
                    <Button
                        small
                        inline
                        onClick={ this.toggleDropdown }
                        type="button"
                    >
                        Change avatar
                    </Button>

                    { this.state.isOpen
                        ? (
                            <F>
                                <StyledOverlay onClick={ this.toggleDropdown } />
                                <StyledAvatarDropdown>
                                    {
                                        avatars.map( a => (
                                            <AvatarSelectItem
                                                avatar={ a }
                                                onSelect={ this.onSelect( a ) }
                                                key={ a }
                                            />
                                        ) )
                                    }
                                </StyledAvatarDropdown>
                            </F>
                        )
                        : null
                    }

                </StyledAvatarSelect>
            </InputWrapper>
        )
    }
}

const StyledAvatarSelect = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    align-content: space-between;
    margin-bottom: 1.6em;

    > * {
        margin-left: 1em;

        &:first-child {
            margin-left: 0;
        }
    }
`

const StyledOverlay = styled.div`
    position: fixed;
    z-index: 1;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0;
    padding: 0;
`

const StyledAvatarDropdown = styled.div`
    position: absolute;
    z-index: 2;
    display: flex;
    flex-wrap: wrap;
    top: 0;
    left: 0;
    right: 0;
    margin: 0;
    background: white;
    padding: 1.6em;
    box-shadow: 0 0 4px 4px rgba(120, 120, 120, 0.1);
    border-radius: 3px;
`

export default AvatarSelectInput
