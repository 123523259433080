import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import ThemesLoader from 'fabled/components/loaders/ThemesLoader'
import UserNav from './UserNav/UserNav'
import NotificationsNav from './NotificationsNav'
import ContentNav from './ContentNav'
import AppNavItem from './AppNavItem'

class AppNav extends React.Component {
    constructor( props ) {
        super( props )
        this.state = { openSubNavId: null }
        this.USER_MENU = 'USER_MENU'
        this.CONTENT_MENU = 'CONTENT_MENU'
        this.toggleMenu = this.toggleMenu.bind( this )
    }

    toggleMenu( menuId ) {
        return () => {
            if ( this.state.openSubNavId === menuId ) {
                this.setState( { openSubNavId: null } )
            }
            else {
                this.setState( { openSubNavId: menuId } )
            }
        }
    }

    render() {
        return (
            <StyledAppNav>
                <UserNav
                    user={ this.props.authUser }
                    isSubNavOpen={ this.state.openSubNavId === this.USER_MENU }
                    onClick={ this.toggleMenu( this.USER_MENU ) }
                />
                <NotificationsNav
                    user={ this.props.authUser }
                    onClick={ this.toggleMenu( null ) }
                />
                <ThemesLoader
                    waitForAuth
                    render={
                        ( { themes } ) => {
                            const theme = themes && themes.length ? themes[ 0 ] : null

                            if ( !theme ) {
                                return null
                            }

                            return (
                                <AppNavItem
                                    icon={ {
                                        url: '/images/featured-stories-icon.svg',
                                        altText: 'Featured Stories',
                                    } }
                                    href={ `/collection/${ theme.id }` }
                                />
                            )
                        }
                    }
                />
                <ContentNav
                    isSubNavOpen={ this.state.openSubNavId === this.CONTENT_MENU }
                    onClick={ this.toggleMenu( this.CONTENT_MENU ) }
                />
            </StyledAppNav>
        )
    }
}

AppNav.propTypes = {
    authUser: PropTypes.shape( {
        id: PropTypes.number.isRequired,
        role: PropTypes.string.isRequired,
    } ),
}

AppNav.defaultProps = {
    authUser: null,
}

const StyledAppNav = styled.div`
    display: flex;
    float: right;
    height: 100%;
`

export default AppNav
