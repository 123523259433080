import { handleActions } from 'redux-actions'
import { dataObject } from './helpers'
import actionCreators from '../actions/actionCreators'

const users = handleActions(
    Object.assign( dataObject( actionCreators, 'users.data' ) ),
    {
        data: {},
    },
)

export default users
