import React from 'react'
import PropTypes from 'prop-types'
import InputWrapper from './InputWrapper'
import Checkbox from './Checkbox'
import ValidationMessage from './ValidationMessage'

class CheckboxInput extends React.Component {
    constructor( props ) {
        super( props )
        this.state = {
            value: props.checked ? props.value : null,
            checked: props.checked || false,
            hasFocus: props.hasFocus || false,
            shouldValidate: false,
            validationMessage: null,
        }

        this.handleChange = this.handleChange.bind( this )
        this.handleFocus = this.handleFocus.bind( this )
        this.handleBlur = this.handleBlur.bind( this )
    }

    componentDidUpdate( prevProps, prevState ) {
        const { state: { value } } = this

        if (
            value !== prevState.value
            || ( !prevState.shouldValidate && this.state.shouldValidate )
        ) {
            this.validate( value )
                .then( ( validated ) => {
                    if ( validated && this.props.onChange instanceof Function ) {
                        this.props.onChange( value )
                    }
                } )
        }
    }

    handleChange( e ) {
        if ( this.props.disabled ) {
            return
        }

        const { target: { checked } } = e
        const value = checked ? e.target.value : null
        this.setState( { value, checked } )

        if ( !this.state.shouldValidate ) {
            this.setState( { shouldValidate: true } )
        }

        if ( this.props.setFormState instanceof Function ) {
            this.props.setFormState( e.target.name, value )
        }
    }

    handleFocus() {
        this.setState( { hasFocus: true } )
    }

    handleBlur( e ) {
        const { target: { value } } = e
        this.setState( { hasFocus: false } )

        if ( !this.state.shouldValidate ) {
            this.setState( { shouldValidate: true }, () => this.validate( value ) )
        }
    }

    validate( value ) {
        const self = this

        if ( this.state.shouldValidate && this.props.validator instanceof Function ) {
            // Do this async so that we can optionally use validators that return promises
            return new Promise( ( resolve ) => {
                resolve( this.props.validator( value ) )
            } )
                .then( ( result ) => {
                    // result is an error message string, or true
                    const validated = ( result === true )
                    const validationMessage = ( result !== true ) ? result : null

                    if ( self.props.formValidator instanceof Function ) {
                        self.props.formValidator( self.props.id, validated, validationMessage )
                    }

                    self.setState( { validated, validationMessage } )

                    return result
                } )
                .then( result => result === true )
        }

        if ( self.props.formValidator instanceof Function ) {
            self.props.formValidator( self.props.id, true, null )
        }

        self.setState( { validated: true, validationMessage: null } )

        return Promise.resolve( true )
    }

    render() {
        const validationMessage = this.state.validationMessage || <span>&nbsp;</span>

        return (
            <InputWrapper>
                <Checkbox
                    id={ this.props.id }
                    name={ this.props.name }
                    label={ this.props.label }
                    type="checkbox"
                    onChange={ this.handleChange }
                    onFocus={ this.handleFocus }
                    onBlur={ this.handleBlur }
                    value={ this.props.value }
                    checked={ this.state.checked }
                    disabled={ this.props.disabled }
                    hasFocus={ this.state.hasFocus }
                />
                {
                    this.props.disableValidationMessage
                        ? null
                        : <ValidationMessage>{ validationMessage }</ValidationMessage>
                }
            </InputWrapper>
        )
    }
}

CheckboxInput.propTypes = {
    value: PropTypes.string,
    checked: PropTypes.bool,
    hasFocus: PropTypes.bool,
    disabled: PropTypes.bool,
    setFormState: PropTypes.func,
    validator: PropTypes.func,
    onChange: PropTypes.func,
    disableValidationMessage: PropTypes.bool,
    id: PropTypes.string,
    name: PropTypes.string,
    label: PropTypes.node,
}

CheckboxInput.defaultProps = {
    value: '',
    checked: false,
    hasFocus: false,
    disabled: false,
    setFormState: null,
    validator: null,
    onChange: null,
    disableValidationMessage: false,
    id: '',
    name: '',
    label: '',
}

export default CheckboxInput
