import React, { useState } from 'react'
import styled from 'styled-components'

const NewsletterForm = ( {
    actionUrl,
    mailchimpU,
    mailchimpID,
} ) => {
    const [email, setEmail] = useState( '' )

    return (
        <StyledNewsletterForm>
            <form action={ actionUrl } method="POST">
                { mailchimpU && <input type="hidden" name="u" value={ mailchimpU } /> }
                { mailchimpID && <input type="hidden" name="id" value={ mailchimpID } /> }
                <input
                    type="email"
                    name="MERGE0"
                    id="MERGE0"
                    autoCorrect="off"
                    autoCapitalize="off"
                    placeholder="Your email address…"
                    value={ email }
                    onChange={ e => setEmail( e.target.value ) }
                />
                <input
                    type="submit"
                    name="submit"
                    value="Subscribe"
                />

                <div aria-label="Please leave the following three fields empty">
                    <StyledSecretFields>
                        <label htmlFor="b_name">Name: </label>
                        <input type="text" name="b_name" tabIndex="-1" defaultValue="" placeholder="Freddie" id="b_name" />

                        <label htmlFor="b_email">Email: </label>
                        <input type="email" name="b_email" tabIndex="-1" defaultValue="" placeholder="youremail@gmail.com" id="b_email" />

                        <label htmlFor="b_comment">Comment: </label>
                        <textarea name="b_comment" tabIndex="-1" placeholder="Please comment" id="b_comment"></textarea>
                    </StyledSecretFields>
                </div>

            </form>
        </StyledNewsletterForm>
    )
}

const StyledNewsletterForm = styled.div`
    flex-grow: 1;

    form {
        height: 3.5rem;
        max-width: 460px;
        margin: 0 auto;
        display: flex;
        align-items: center;
        font-size: 18px;
        border: 1px solid var( --purple );
        border-radius: 3.5rem;
    }

    input[type=email] {
        flex: 1;
        min-width: 120px;
        padding-left: 1.5em;
        background: transparent;
        border: 0;
        color: var( --purple );
        line-height: 2.5em;
        font-family: 'GT Eesti', sans-serif;
        font-size: 18px;
        font-weight: normal;
        -webkit-appearance:none;
    }

    input[type=submit] {
        flex: 0 0 auto;
        margin-right: 0.35em;
        background: var( --purple );
        color: var( --white );
        border: 0;
        line-height: 2.5em;
        border-radius: 2.5em;
        padding: 0 1.4em;
        font-family: 'GT Eesti', sans-serif;
        font-size: 18px;
        font-weight: normal;
        -webkit-appearance: none;
    }
`

const StyledSecretFields = styled.div`
    left: -9999px;
    position: absolute;
`

export default NewsletterForm