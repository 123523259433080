/* eslint-disable max-len */
import React from 'react'
import AuthFormWrapper from 'fabled/components/AuthFormWrapper'
import ScrollToTopOnMount from 'fabled/components/ScrollToTopOnMount'

const About = () => (
    <AuthFormWrapper isWide>
        <ScrollToTopOnMount />

        <div className="fab-c-signup-form-wrapper">

            <div className="fab-c-signup-form fab-c-signup-form--front">
                <div className="fab-c-signup-form__header">
                    <h2 className="fab-c-signup-form__heading">What Fabled is for</h2>
                </div>

                <p>Being a kid is a superpower. But it’s running out fast! Right now you are full of magic – stories, ideas and ways of seeing things that grown-ups have lost. You’ve got the greatest stories never told, and we want to hear them. We think there’s enough telling you what to do. So at Fabled, we want you to be in charge. It’s your voice, we just turn up the volume. </p>
                <p>But we also happen to think the world would be better with a bit more wonder in it. Did you know that winners of some of the world’s biggest awards (Nobel Prizes) also did more imaginative play as kids? We think you’re a creative superhero in training, let Fabled be your imagination dojo!</p>

                <div className="fab-c-signup-form__header">
                    <h2 className="fab-c-signup-form__heading">How it works</h2>
                </div>

                <p>Fabled is a place to create & share your stories. It’s like the best notebook ever – it chats back, gives inspiration, and even publishes. Just sign up and start typing, recording, or uploading, then get parent/guardian help to share with family and friends. Release the wonder!</p>

                <div className="fab-c-signup-form__header">
                    <h2 className="fab-c-signup-form__heading">How you can help us</h2>
                </div>

                <p>Fabled is a kid too, a much littler kid than you in fact. We are still growing and learning. It helps so much to hear what you think – things you like, things you don’t like, how we could make Fabled better, so please <a href="mailto:feedback@fabledkids.com">get in touch at feedback@fabledkids.com</a>.</p>

                <a href="/signup">Create your profile and start telling your story…</a>
            </div>

        </div>
    </AuthFormWrapper>
)

export default About
