// @flow
import { css } from 'styled-components'
import variables from './variables'

const body = css`
    font-size: 16px;
    background: var( --background );

    @media all and (max-width: ${ variables.layoutSmallWriterWidth } - 1) {
        background: var( --paper );
    }

    * {
        box-sizing: border-box;
    }
`

export default body
