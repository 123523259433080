import React from 'react'
import styled from 'styled-components'

const FabledLogo = ( { marqueOnly = false, color = 'red' } ) => (
    <StyledFabledLogo color={ color }>
        { marqueOnly ? <MarqueOnly /> : <FullLogo /> }
    </StyledFabledLogo>
)

const MarqueOnly = () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40.61 40.61">
        <title>Fabled</title>
        <path className="cls-1" d="M0,17.56v22.5a.54.54,0,0,0,.81.47L20.31,29.29Z"/>
        <path className="cls-2" d="M40.61,17.56V.54A.54.54,0,0,0,40.07,0H.54A.54.54,0,0,0,0,.54v17L20.31,29.29Z"/>
        <path className="cls-3" d="M20.31,29.29,39.79,40.54a.54.54,0,0,0,.81-.47V17.56Z"/>
    </svg>
)

const FullLogo = () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 110 20.77">
        <title>Fabled</title>
        <path d="M41.35,1.82V.2a.2.2,0,0,0-.2-.2H30.66a.4.4,0,0,0-.4.4V20a.2.2,0,0,0,.2.2h1.61a.2.2,0,0,0,.2-.2V10.29a.2.2,0,0,1,.2-.2h7.66a.2.2,0,0,0,.2-.2V8.27a.2.2,0,0,0-.2-.2H32.47a.2.2,0,0,1-.2-.2V2.22a.2.2,0,0,1,.2-.2h8.67A.2.2,0,0,0,41.35,1.82Z"/>
        <path d="M54.32,6.86V8.46a7,7,0,0,0-5.11-2.21,7.26,7.26,0,0,0,0,14.52,7,7,0,0,0,5.11-2.21V20a.2.2,0,0,0,.2.2h1.61a.2.2,0,0,0,.2-.2V6.86a.2.2,0,0,0-.2-.2H54.53A.2.2,0,0,0,54.32,6.86ZM44.11,13.52a5.18,5.18,0,0,1,5.11-5.24,5,5,0,0,1,1.18.14,5.26,5.26,0,0,1-1.18,10.34,5,5,0,0,1-1.48-.23A5.23,5.23,0,0,1,44.11,13.52Z"/>
        <rect x="76.05" width="2.02" height="20.17" rx="0.2" ry="0.2"/>
        <path d="M66.63,6.26a7,7,0,0,0-5.11,2.21V.2a.2.2,0,0,0-.2-.2H59.71a.2.2,0,0,0-.2.2V13.51h0V20a.2.2,0,0,0,.2.2h1.61a.2.2,0,0,0,.2-.2v-1.4a7,7,0,0,0,5.11,2.21,7.26,7.26,0,0,0,0-14.52Zm1.48,12.27a5,5,0,0,1-1.48.23,5.18,5.18,0,0,1-5.11-5.24h0a5.22,5.22,0,0,1,3.93-5.1,5,5,0,0,1,1.18-.14,5.18,5.18,0,0,1,5.11,5.24A5.23,5.23,0,0,1,68.11,18.53Z"/>
        <path d="M108,.2V8.46a7,7,0,0,0-5.11-2.21,7.26,7.26,0,0,0,0,14.52A7,7,0,0,0,108,18.57V20a.2.2,0,0,0,.2.2h1.61a.2.2,0,0,0,.2-.2V.2a.2.2,0,0,0-.2-.2h-1.61A.2.2,0,0,0,108,.2ZM97.77,13.52a5.18,5.18,0,0,1,5.11-5.24,5,5,0,0,1,1.18.14,5.26,5.26,0,0,1-1.18,10.34,5,5,0,0,1-1.48-.23A5.23,5.23,0,0,1,97.77,13.52Z"/>
        <path d="M93.78,10.8A.54.54,0,0,0,94,10l-.26-.41a7.23,7.23,0,0,0-6.12-3.37h-.13a7.26,7.26,0,0,0,0,14.52,7,7,0,0,0,5.13-2.22,7.26,7.26,0,0,0,1.15-1.61.2.2,0,0,0-.09-.27L92.19,16a.2.2,0,0,0-.27.09,5.24,5.24,0,0,1-.81,1.12,5,5,0,0,1-7.67-.4l4-2.3ZM82.54,15a5.29,5.29,0,0,1,.34-3.77h0a5.24,5.24,0,0,1,8.46-1.32l-4.93,2.85Z"/>
        <path className="cls-1" d="M0,8.72V19.9a.27.27,0,0,0,.4.23l9.68-5.59Z"/>
        <path className="cls-2" d="M20.17,8.72V.27A.27.27,0,0,0,19.9,0H.27A.27.27,0,0,0,0,.27V8.72l10.08,5.82Z"/>
        <path className="cls-3" d="M10.08,14.55l9.68,5.59a.27.27,0,0,0,.4-.23V8.72Z"/>
    </svg>
)

const StyledFabledLogo = styled.div`
    height: 100%;

    svg {
        max-width: 100%;
        max-height: 100%;

        path,
        rect {
            fill: var( --${ p => p.color } );

            &.cls-1 {
                opacity: 0.8;
            }

            &.cls-3 {
                opacity: 0.6;
            }
        }
    }
`

export default FabledLogo