import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import FormattedContent from 'fabled/components/FormattedContent'
import Button from 'fabled/components/Button'
import Modal from './Modal'

const ConfirmModal = props => (
    <Modal title={ props.title }>

        <FormattedContent>{ props.content }</FormattedContent>

        <div className="fab-c-modal__confirm-btns">
            <ConfirmButton>
                <Button
                    onClick={ props.onCancel }
                >
                    { props.cancelText }
                </Button>
            </ConfirmButton>
            <ConfirmButton>
                <Button
                    primary
                    onClick={ props.onConfirm }
                    autoFocus
                >
                    { props.confirmText }
                </Button>
            </ConfirmButton>
        </div>
    </Modal>
)

ConfirmModal.propTypes = {
    title: PropTypes.string,
    content: PropTypes.node,
    cancelText: PropTypes.string,
    confirmText: PropTypes.string,
    onCancel: PropTypes.func,
    onConfirm: PropTypes.func,
}

ConfirmModal.defaultProps = {
    title: '',
    content: null,
    cancelText: 'Cancel',
    confirmText: 'Confirm',
    onCancel: null,
    onConfirm: null,
}

const ConfirmButton = styled.div`
    width: 46%;
    float: left;
    text-align: center;

    &:last-child {
        float: right;
    }
`

export default ConfirmModal
