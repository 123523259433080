import React from 'react'
import PropTypes from 'prop-types'

const StoryErrorMessage = ( { error } ) => (
    <div className="fab-c-writer">
        <div className="fab-c-writer__story">
            <p>{ error.message }</p>
        </div>
    </div>
)

StoryErrorMessage.propTypes = {
    error: PropTypes.shape( {
        message: PropTypes.string,
    } ).isRequired,
}

export default StoryErrorMessage
