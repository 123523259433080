import React, { Fragment as F } from 'react'
import PropTypes from 'prop-types'
import ParentSignup from 'fabled/components/ParentSignup'
import ScrollToTopOnMount from 'fabled/components/ScrollToTopOnMount'

const ParentSignupRoute = ( { match: { params: { token } } } ) => (
    <F>
        <ScrollToTopOnMount />
        <ParentSignup token={ token } />
    </F>
)

ParentSignupRoute.propTypes = {
    match: PropTypes.shape( {
        params: PropTypes.shape( {
            token: PropTypes.string.isRequired,
        } ).isRequired,
    } ).isRequired,
}

export default ParentSignupRoute
