// @flow
import React from 'react'
import type { ChildUser, AuthUser } from 'fabled/types/User'
import type { Story } from 'fabled/types/Story'
import type { Post } from 'fabled/types/Post'
import { Prompt } from 'react-router-dom'
import memoizeOne from 'memoize-one'
import { visiblePosts, arrangePostsIntoChapters } from 'fabled/selectors/posts'
import getByline from 'fabled/helpers/getByline'
import arrayToList from 'fabled/helpers/arrayToList'
import StoryMenuContainer from 'fabled/components/StoryMenu/StoryMenuContainer'
import PostUIWrapper from 'fabled/components/sharedStoryComponents/Post/PostUIWrapper'
import FabledPost from 'fabled/components/sharedStoryComponents/Post/FabledPost'
import Title from 'fabled/components/Title'
import Chapters from 'fabled/components/sharedStoryComponents/Chapters'
import StoryInputUI from 'fabled/components/StoryInputUI/StoryInputUI'

type Props = {
    story: Story,
    posts: Array<Post>,
    storyAuthors: Array<ChildUser>,
    authUser: AuthUser,
}

const scrollToBottom = () => {
    if ( document.body ) {
        window.scrollTo( 0, document.body.offsetHeight )
    }
}

const countUnsavedPosts = ( posts ) => {
    if ( !posts.length ) {
        return 0
    }

    const memoizedCount = memoizeOne( ( psts ) => {
        const filteredPosts = psts.filter( post => (
            typeof post.id === 'undefined'
            && post.postType !== 'encouragement'
            && post.postType !== 'inspiration'
        ) )

        return filteredPosts.length
    } )

    return memoizedCount( posts )
}

const getFabledIntro = ( story, storyAuthors, authUser ) => {
    const storyPromptTemplate = story && story.storyPrompt ? story.storyPrompt : 'Tell me a story!'
    let storyPrompt = authUser
        ? storyPromptTemplate.replace( '{name}', authUser.firstName )
        : storyPromptTemplate.replace( '{name}', '' )

    if ( storyAuthors.length > 1 ) {
        const otherAuthors = storyAuthors.filter( u => u.id !== authUser.id )
        const activeAuthor = storyAuthors.find( u => u.id === story.activeUserId )
        const multipleOtherUsers = storyAuthors.length > 2
        let collabIntro = `You’re working with ${ arrayToList( otherAuthors, 'username' ) } on this story.`

        if ( activeAuthor && activeAuthor.id === authUser.id ) {
            collabIntro = `${ collabIntro } It’s your turn to add to the story! When you’re ready, pass the story ${ multipleOtherUsers ? 'on' : 'back' } again.`
        }
        else if ( activeAuthor ) {
            collabIntro = `${ collabIntro } It’s ${ activeAuthor.username }’s turn to add to the story. When they’re finished they can pass the story ${ multipleOtherUsers ? 'on' : 'back' } again.`
        }

        storyPrompt = collabIntro
    }

    return { content: { text: storyPrompt } }
}

class StoryMaker extends React.Component<Props> {
    componentDidMount() {
        scrollToBottom()
    }

    componentDidUpdate( prevProps: Props ) {
        const currentPostsCount = this.props.posts.length
        const prevPostsCount = prevProps.posts.length

        if ( currentPostsCount > prevPostsCount ) {
            scrollToBottom()
        }
    }

    render() {
        const {
            props: {
                story, posts, storyAuthors, authUser,
            },
        } = this
        const storyTitle = story && story.title ? story.title : null
        const hasUnsavedPosts = countUnsavedPosts( posts ) > 0
        const chaptersWithVisiblePosts = arrangePostsIntoChapters( visiblePosts( posts, true ) )
        const excludedMenuItems = ['edit']
        const activeAuthor = storyAuthors.find( u => u.id === story.activeUserId )

        if ( !story.published ) {
            excludedMenuItems.push( 'view' )
        }

        return (
            <div>
                <Prompt
                    when={ hasUnsavedPosts }
                    message={ () => 'Some of your story is still saving! Please click the cancel button, wait a few seconds, and try again.' }
                />
                <div className="fab-c-writer">
                    <div className="fab-c-writer__story">
                        <StoryMenuContainer
                            story={ story }
                            storyAuthors={ storyAuthors }
                            exclude={ excludedMenuItems }
                        />

                        <PostUIWrapper
                            postAuthor="fabled"
                            showPostAuthor
                            storyStyle="editor"
                            color="red"
                        >
                            <FabledPost post={ getFabledIntro( story, storyAuthors, authUser ) } />
                        </PostUIWrapper>

                        {
                            storyTitle ? (
                                <Title
                                    title={ storyTitle }
                                    subtitle={ getByline( storyAuthors ) }
                                    align="center"
                                />
                            )
                                : null
                        }
                        <Chapters
                            chaptersWithVisiblePosts={ chaptersWithVisiblePosts }
                            storyAuthors={ storyAuthors }
                            storyStyle="editor"
                        />
                    </div>
                    <StoryInputUI
                        story={ story }
                        storyAuthors={ storyAuthors }
                        currentChapter={ 1 }
                        posts={ posts }
                        disabled={ activeAuthor && activeAuthor.id !== authUser.id }
                    />
                </div>
            </div>
        )
    }
}

export default StoryMaker
