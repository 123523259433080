import cloneDeep from 'lodash/cloneDeep'

const allConfigs = {
    '*': {
        cdnUrl: 'https://static.fabled.madebyfieldwork.com',
        supportEmail: 'feedback@fabledkids.com',
    },

    development: {
        apiUrl: 'https://api.fabledkids.localhost',
        webclientUrl: 'https://fabledkids.localhost',
    },

    qa: {
        apiUrl: 'https://api.qa.fabledkids.com',
        webclientUrl: 'https://qa.fabledkids.com',
    },

    production: {
        apiUrl: 'https://api.fabledkids.com',
        webclientUrl: 'https://fabledkids.com',
    },

    test: {
        apiUrl: 'https://api.fabledkids.localhost',
        webclientUrl: 'https://fabledkids.localhost',
    },
}

const env = process.env.APP_ENV || 'test'
const config = { ...cloneDeep( allConfigs[ '*' ] ), ...cloneDeep( allConfigs[ env ] ) }

export default config
