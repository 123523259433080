import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { rgba } from 'polished'
import CheckIcon from 'react-icons/lib/go/check'
import { colors } from '../../styles'

const Checkbox = props => (
    <StyledLabel
        checked={ props.checked }
        htmlFor={ props.id }
    >
        <StyledInput
            id={ props.id }
            name={ props.name }
            type="checkbox"
            onChange={ props.onChange }
            onFocus={ props.onFocus }
            onBlur={ props.onBlur }
            value={ props.value }
            defaultChecked={ props.checked }
            disabled={ props.disabled }
        />
        <Checkmark checked={ props.checked } hasFocus={ props.hasFocus } />
        { props.label }
    </StyledLabel>
)

Checkbox.propTypes = {
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    label: PropTypes.node.isRequired,
    checked: PropTypes.bool,
    value: PropTypes.string,
    disabled: PropTypes.bool,
    hasFocus: PropTypes.bool,
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
}

Checkbox.defaultProps = {
    checked: false,
    value: 'on',
    disabled: false,
    hasFocus: false,
    onChange: null,
    onFocus: null,
    onBlur: null,
}

const Checkmark = ( { checked, hasFocus } ) => (
    <div>
        <StyledCheckmarkBox hasFocus={ hasFocus } />
        {
            checked ? (
                <StyledCheckmarkWrapper>
                    <CheckIcon size={ 28 } color={ colors.purple } />
                </StyledCheckmarkWrapper>
            )
                : null
        }
    </div>
)

Checkmark.propTypes = {
    checked: PropTypes.bool,
    hasFocus: PropTypes.bool,
}

Checkmark.defaultProps = {
    checked: false,
    hasFocus: false,
}

const StyledCheckmarkBox = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;
    border: 1px solid ${ colors.purple };
    border-radius: 2px;
    background: ${ colors.paper };

    ${ props => props.hasFocus && css`
        box-shadow: inset 0 0 0 3px ${ rgba( colors.purple, 0.3 ) };
    ` }
`

StyledCheckmarkBox.propTypes = {
    hasFocus: PropTypes.bool,
}

StyledCheckmarkBox.defaultProps = {
    hasFocus: false,
}

const StyledCheckmarkWrapper = styled.div`
    position: absolute;
    top: -8px;
    left: 0px;
`

const StyledLabel = styled.label`
    position: relative;
    display: block;
    padding-left: 30px;
`

const StyledInput = styled.input`
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
`

export default Checkbox
