// @flow
import React from 'react'
import type { Story } from 'fabled/types/Story'
import type { Post } from 'fabled/types/Post'
import type { AuthUser } from 'fabled/types/User'
import { connect } from 'react-redux'
import actionCreators from '../../actions/actionCreators'
import { getUiAudioIsRecording } from '../../selectors/ui'
import Api from '../../api'
import { formData } from '../../api/helpers'
import postFactory from '../../helpers/postFactory'
import { AUDIO } from '../../postTypes'
import { eventTracker } from '../../eventTracker'
import { secondsToMinutesAndSeconds } from '../../helpers/time'
import Icon from '../Icon'
import icons from '../../icons'
import BodyScroll from '../BodyScroll'

type Props = {
    story: Story,
    posts: Array<Post>,
    authUser: AuthUser,
    currentChapter: number,
    postReplaceOrAdd: ( post: Post ) => void,

    // from connect
    audioIsRecording: boolean,
    audioRecorderStop: () => void,
}

type State = {
    isRecording: boolean,
    runningTime: number,
    timer: IntervalID | null,
    willSave: boolean,
}

class AudioRecorder extends React.Component<Props, State> {
    state = {
        isRecording: false,
        runningTime: 0,
        timer: null,
        willSave: true,
    }

    // eslint-disable-next-line react/sort-comp
    mediaRecorder = null

    componentDidUpdate( prevProps ) {
        if ( !prevProps.audioIsRecording && this.props.audioIsRecording ) {
            this.startRecording()
        }
    }

    componentWillUnmount() {
        this.mediaRecorder = null

        if ( this.state.timer ) {
            clearInterval( this.state.timer )
        }
    }

    startRecording = () => {
        if ( navigator && navigator.mediaDevices ) {
            navigator.mediaDevices.getUserMedia( {
                audio: true,
                video: false,
            } )
                .then( this.audioRecorder )
        }
    }

    stopRecordingAndSave = () => {
        if ( this.state.isRecording && this.mediaRecorder ) {
            this.mediaRecorder.stop()
            this.mediaRecorder = null
            this.setState( { isRecording: false } )
            this.clearTimer()
        }
    }

    stopRecordingAndDiscard = () => {
        if ( this.state.isRecording && this.mediaRecorder ) {
            const { mediaRecorder } = this
            this.setState( { isRecording: false, willSave: false }, () => {
                mediaRecorder.stop()
                this.mediaRecorder = null
            } )
            this.clearTimer()
        }
    }

    audioRecorder = ( stream ) => {
        const options = { mimeType: 'audio/webm' }
        const recordedChunks = []

        // $FlowFixMe
        this.mediaRecorder = new MediaRecorder( stream, options )

        this.mediaRecorder.ondataavailable = ( e ) => {
            if ( e.data.size > 0 ) {
                recordedChunks.push( e.data )
            }
        }

        this.mediaRecorder.onstop = () => {
            if ( this.state.willSave ) {
                const blob = new Blob( recordedChunks, { type: 'audio/webm' } )
                this.handleAudio( blob )
            }

            this.props.audioRecorderStop()
        }

        this.mediaRecorder.start( 10000 )
        this.setState( { isRecording: true } )
        this.startTimer()
    }

    startTimer = () => {
        const self = this

        this.clearTimer()

        const timer = setInterval( () => {
            const time = this.state.runningTime
            self.setState( { runningTime: time + 1 } )
        }, 1000 )

        this.setState( { timer } )
    }

    clearTimer = () => {
        if ( this.state.timer ) {
            clearInterval( this.state.timer )
            this.setState( { timer: null, runningTime: 0 } )
        }
    }

    handleAudio = ( blob ) => {
        const stream = window.URL ? window.URL.createObjectURL( blob ) : blob
        const post = postFactory( {
            storyId: this.props.story.id,
            chapterId: this.props.currentChapter,
            userId: this.props.authUser.id,
            postType: AUDIO,
            content: { stream },
        }, this.props.posts )

        this.props.postReplaceOrAdd( post )

        const uploadPayload = formData( { uri: blob, storyId: post.storyId } )
        return Api.uploadAudio( uploadPayload )
            .then( ( audioUploadResponse ) => {
                post.content = {
                    audioId: audioUploadResponse.id,
                }
                return Api.service( 'postsForCollaborator' ).create( post )
            } )
            .then( ( response ) => {
                this.props.postReplaceOrAdd( response )
                eventTracker( {
                    category: 'Story Writer',
                    action: 'Added an audio post',
                } )
            } )
    }

    render() {
        const className = `fab-c-audio-recorder${ this.state.isRecording ? ' fab-is-recording' : '' }`
        const time = secondsToMinutesAndSeconds( this.state.runningTime )

        return (
            <BodyScroll shouldScroll={ !this.state.isRecording }>
                <div className={ className }>
                    <div className="fab-c-audio-recorder__inner">
                        <div className="fab-c-audio-recorder__header">
                            <p className="fab-c-audio-recorder__title">Recording</p>
                            <div className="fab-c-audio-recorder__timer">{ time }</div>
                        </div>
                        <button
                            type="button"
                            className="fab-c-audio-recorder__btn fab-c-audio-recorder__btn--save"
                            onClick={ this.stopRecordingAndSave }
                        >
                            Save
                            <Icon icon={ icons.tick } color="white" />
                        </button>
                        <button
                            type="button"
                            className="fab-c-audio-recorder__btn fab-c-audio-recorder__btn--delete"
                            onClick={ this.stopRecordingAndDiscard }
                        >
                            Delete
                            <Icon icon={ icons.cross } color="white" />
                        </button>
                    </div>
                </div>
            </BodyScroll>
        )
    }
}

const mapStateToProps = state => ( {
    audioIsRecording: getUiAudioIsRecording( state ),
} )

const mapDispatchToProps = {
    audioRecorderStop: actionCreators.ui.audioRecorder.stop,
}

export default connect( mapStateToProps, mapDispatchToProps )( AudioRecorder )
