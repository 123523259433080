// @flow
import { css } from 'styled-components'

export const typo = {
  body: css`
    font-family: 'GT Eesti', sans-serif;
    font-weight: 400;
    font-size: 18px;
    line-height: 1.36;
  `,
}

const fonts = css`
    @font-face {
        font-family: 'GT Eesti';
        src: url('/fonts/gt-eesti-display-light.eot');
        src: url('/fonts/gt-eesti-display-light.eot?#iefix') format('embedded-opentype'),
            url('/fonts/gt-eesti-display-light.woff') format('woff'),
            url('/fonts/gt-eesti-display-light.ttf') format('truetype');
        font-weight: normal;
        font-style: normal;
    }

    @font-face {
        font-family: 'GT Eesti';
        src: url('/fonts/gt-eesti-display-medium.eot');
        src: url('/fonts/gt-eesti-display-medium.eot?#iefix') format('embedded-opentype'),
            url('/fonts/gt-eesti-display-medium.woff') format('woff'),
            url('/fonts/gt-eesti-display-medium.ttf') format('truetype');
        font-weight: bold;
        font-style: normal;
    }

    html,
    body,
    input,
    textarea,
    button {
        font-family: 'GT Eesti', sans-serif;
        font-weight: normal;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
`

export default fonts
