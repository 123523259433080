import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { rgba } from 'polished'
import { colors } from '../../styles'
import InputLabel from './InputLabel'

const TextInputField = props => (
    <div>
        <StyledInput
            id={ props.id }
            name={ props.name }
            type="text"
            onChange={ props.onChange }
            onFocus={ props.onFocus }
            onBlur={ props.onBlur }
            value={ props.value }
            disabled={ props.disabled }
            hasFocus={ props.hasFocus }
        />
        <InputLabel
            htmlFor={ props.id }
            minimized={ !!( ( props.hasFocus || props.value ) ) }
        >
            { props.label }
        </InputLabel>
    </div>
)

TextInputField.propTypes = {
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    label: PropTypes.node.isRequired,
    value: PropTypes.string,
    disabled: PropTypes.bool,
    hasFocus: PropTypes.bool,
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
}

TextInputField.defaultProps = {
    value: null,
    disabled: false,
    hasFocus: false,
    onChange: null,
    onFocus: null,
    onBlur: null,
}

const StyledInput = styled.input`
    z-index: 1;
    width: 100%;
    padding: 30px 16px 16px;
    font-size: inherit;
    line-height: inherit;
    font-weight: 700;
    border: 1px solid ${ colors.purple };
    border-radius: 2px;
    background: transparent;
    color: ${ colors.purple };
    transition: all .3s ease-out;

    ${ props => props.hasFocus && css`
        box-shadow: inset 0 0 0 3px ${ rgba( colors.purple, 0.3 ) };
    ` }

    &:focus {
        outline: none;
    }
`

export default TextInputField
