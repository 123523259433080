import PropTypes from 'prop-types'
import styled from 'styled-components'
import { colors } from '../../styles'

const InputLabel = styled.label`
    z-index: -1;
    position: absolute;
    top: ${ props => ( props.minimized ? '8px' : '23px' ) };
    left: 16px;
    font-size: ${ props => ( props.minimized ? '13px' : 'inherit' ) };
    line-height: inherit;
    font-weight: 700;
    color: ${ colors.typeGrey_light };
    transition: all .2s ease-out;
`

InputLabel.propTypes = {
    minimized: PropTypes.bool,
}

export default InputLabel
