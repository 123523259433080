import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { colors } from '../../styles'
import DashboardTitle from './DashboardTitle'
import DashboardSection, {
    DashboardSectionTitle,
    DashboardSectionTitleButton,
    DashboardSectionContent,
    DashboardSectionField,
} from './DashboardSection'

const Dashboard = ( { children } ) => (
    <StyledDashboard>
        <StyledDashboardInner>
            { children }
        </StyledDashboardInner>
    </StyledDashboard>
)

Dashboard.propTypes = {
    children: PropTypes.node,
}

Dashboard.defaultProps = {
    children: null,
}

const StyledDashboard = styled.div`
    position: relative;
    width: 100%;
    max-width: 770px;
    margin: 0 auto;
    padding: 0 5%;
`

const StyledDashboardInner = styled.div`
    padding: 0 5% 5%;
    line-height: 1.3;
    background: ${ colors.paper };
`

const DashboardIntro = styled.div`
    p {
        margin-bottom: 1em;
    }
`

export default Dashboard

export {
    DashboardTitle,
    DashboardIntro,
    DashboardSection,
    DashboardSectionTitle,
    DashboardSectionTitleButton,
    DashboardSectionContent,
    DashboardSectionField,
}
