import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { colors } from '../styles'

const UINotice = ( { type, children } ) => (
    <StyledUINotice type={ type }>
        { children }
    </StyledUINotice>
)

UINotice.propTypes = {
    type: PropTypes.oneOf( [
        'error',
        'warning',
        'success',
    ] ).isRequired,
    children: PropTypes.node.isRequired,
}

const noticeColors = {
    error: colors.red,
    warning: colors.yellow,
    success: colors.green,
}

const StyledUINotice = styled.div`
    margin-bottom: 2em;
    padding: 1em;
    background: ${ p => noticeColors[ p.type ] };
    color: white;

    a {
        color: white;
        text-decoration: underline;
        text-decoration-color: rgba(255,255,255,0.5);

        &:hover {
            color: white;
            text-decoration-color: rgba(255,255,255,1)
        }
    }
`

export default UINotice
