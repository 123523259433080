import styled from 'styled-components'
import { colors } from '../../styles'

const ValidationMessage = styled.p`
    margin-top: 0.3em;
    font-size: 14px;
    color: ${ colors.red };
`

export default ValidationMessage
