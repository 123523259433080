import React from 'react'
import TextInput from './TextInput'

const FabledTextInput = props => (
    <TextInput
        { ...props }
    />
)

export default FabledTextInput
