const inspiration = [
    {
        icon: 'alien',
        iconBackgroundColor: 'rgb(0, 58, 112)',
        textColor: 'white',
        textBackgroundColor: 'rgb(105, 201, 186)',
        text: [
            'Alien',
            'Monster',
            'Creature from space',
        ],
    },
    {
        icon: 'cake',
        iconBackgroundColor: 'rgb(105, 201, 186)',
        textColor: 'white',
        textBackgroundColor: 'rgb(253, 146, 149)',
        text: [
            'Cake',
            'Birthday',
            'Celebration',
        ],
    },
    {
        icon: 'camera',
        iconBackgroundColor: 'rgb(255, 183, 0)',
        textColor: 'white',
        textBackgroundColor: 'rgb(0, 58, 112)',
        text: [
            'Camera',
            'Photos',
        ],
    },
    {
        icon: 'circus-tent',
        iconBackgroundColor: 'rgb(253, 146, 149)',
        textColor: 'rgb(105, 201, 186)',
        textBackgroundColor: 'rgb(255, 248, 236)',
        text: [
            'Circus',
        ],
    },
    {
        icon: 'coins',
        iconBackgroundColor: 'rgb(255, 89, 89)',
        textColor: 'white',
        textBackgroundColor: 'rgb(97, 95, 155)',
        text: [
            'Gold coins',
        ],
    },
    {
        icon: 'desert-island',
        iconBackgroundColor: 'rgb(253, 146, 149)',
        textColor: 'rgb(0, 58, 112)',
        textBackgroundColor: 'rgb(255, 248, 236)',
        text: [
            'Desert island',
            'Shipwrecked',
        ],
    },
    {
        icon: 'doctors-bag',
        iconBackgroundColor: 'rgb(255, 89, 89)',
        textColor: 'white',
        textBackgroundColor: 'rgb(97, 95, 155)',
        text: [
            'Doctor’s bag',
            'Feeling unwell',
        ],
    },
    {
        icon: 'fish',
        iconBackgroundColor: 'rgb(0, 58, 112)',
        textColor: 'white',
        textBackgroundColor: 'rgb(255, 183, 0)',
        text: [
            'Under the sea',
            'A fish called…',
        ],
    },
    {
        icon: 'flying-carpet',
        iconBackgroundColor: 'rgb(97, 95, 155)',
        textColor: 'white',
        textBackgroundColor: 'rgb(255, 89, 89)',
        text: [
            'Flying carpet',
        ],
    },
    {
        icon: 'house',
        iconBackgroundColor: 'rgb(255, 183, 0)',
        textColor: 'white',
        textBackgroundColor: 'rgb(97, 95, 155)',
        text: [
            'Spooky house',
            'Old house',
            'Deserted house',
        ],
    },
    {
        icon: 'lightening-cloud',
        iconBackgroundColor: 'rgb(0, 58, 112)',
        textColor: 'white',
        textBackgroundColor: 'rgb(255, 183, 0)',
        text: [
            'Thunderstorm',
            'Lightning',
        ],
    },
    {
        icon: 'lock',
        iconBackgroundColor: 'rgb(255, 89, 89)',
        textColor: 'rgb(0, 58, 112)',
        textBackgroundColor: 'rgb(255, 212, 101)',
        text: [
            'Secret',
            'Unlock',
            'Locked door',
        ],
    },
    {
        icon: 'magic-wand',
        iconBackgroundColor: 'rgb(97, 95, 155)',
        textColor: 'white',
        textBackgroundColor: 'rgb(0, 58, 112)',
        text: [
            'Magic wand',
            'Magic spell',
        ],
    },
    {
        icon: 'map',
        iconBackgroundColor: 'rgb(255, 183, 0)',
        textColor: 'white',
        textBackgroundColor: 'rgb(97, 95, 155)',
        text: [
            'Treasure map',
            'Adventure',
            'X marks the spot',
        ],
    },
    {
        icon: 'mountains',
        iconBackgroundColor: 'rgb(0, 58, 112)',
        textColor: 'white',
        textBackgroundColor: 'rgb(105, 201, 186)',
        text: [
            'Mountains',
        ],
    },
    {
        icon: 'planet',
        iconBackgroundColor: 'rgb(0, 58, 112)',
        textColor: 'white',
        textBackgroundColor: 'rgb(105, 201, 186)',
        text: [
            'Planet',
            'Space',
            'Stars',
        ],
    },
    {
        icon: 'spiders-web',
        iconBackgroundColor: 'rgb(105, 201, 186)',
        textColor: 'white',
        textBackgroundColor: 'rgb(255, 89, 89)',
        text: [
            'Spider’s web',
            'Spider',
        ],
    },
    {
        icon: 'stopwatch',
        iconBackgroundColor: 'rgb(255, 89, 89)',
        textColor: 'rgb(255, 89, 89)',
        textBackgroundColor: 'rgb(255, 248, 236)',
        text: [
            'Stopwatch',
            'Clock',
            'Time',
        ],
    },
    {
        icon: 'submarine',
        iconBackgroundColor: 'rgb(97, 95, 155)',
        textColor: 'white',
        textBackgroundColor: 'rgb(255, 183, 0)',
        text: [
            'Under the sea',
            'Submarine',
            'Water',
        ],
    },
    {
        icon: 'telescope',
        iconBackgroundColor: 'rgb(97, 95, 155)',
        textColor: 'white',
        textBackgroundColor: 'rgb(105, 201, 186)',
        text: [
            'Telescope',
            'Stargazing',
        ],
    },
    {
        icon: 'woolly-mammoth',
        iconBackgroundColor: 'rgb(105, 201, 186)',
        textColor: 'white',
        textBackgroundColor: 'rgb(0, 58, 112)',
        text: [
            'Woolly Mammoth',
        ],
    },
]

export default inspiration
