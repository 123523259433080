import styled, { css } from 'styled-components'

const PlayPauseButton = styled.button`
    position: relative;
    overflow: hidden;
    width: 60px;
    height: 90px;
    margin-left: 70px;
    text-indent: -999em;
    background: transparent;
    border: 0;

    ${ props => !props.isPlaying && css`
        &:before {
            content: ' ';
            position: absolute;
            top: 50%;
            left: 50%;
            margin-top: -12px;
            margin-left: -6px;
            width: 0;
            height: 0;
            border-left: 12px solid white;
            border-top: 12px solid transparent;
            border-bottom: 12px solid transparent;
        }

        &:hover:before {
            border-left-color: rgb(249, 175, 78);
        }
    ` }

    ${ props => props.isPlaying && css`
        &:before,
        &:after {
            content: ' ';
            position: absolute;
            top: 50%;
            left: 50%;
            margin-top: -10px;
            margin-left: -8px;
            height: 20px;
            width: 6px;
            background: white;
        }

        &:after {
            margin-left: 2px;
        }

        &:hover {
            &:before,
            &:after {
                background: rgb(249, 175, 78);
            }
        }
    ` }
`

export default PlayPauseButton
