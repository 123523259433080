import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import colors from '../styles/colors'

const Title = ( { title, subtitle, align } ) => (
    <StyledTitleWrapper align={ align }>
        <StyledTitle>{ title }</StyledTitle>
        {
            subtitle ? <StyledSubTitle>{ subtitle }</StyledSubTitle> : null
        }
    </StyledTitleWrapper>
)

Title.propTypes = {
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string,
    align: PropTypes.oneOf( [
        'left',
        'right',
        'center',
    ] ),
}

Title.defaultProps = {
    subtitle: null,
    align: 'left',
}

const StyledTitle = styled.h1`
    font-size: 1.875rem;
    font-weight: 700;
    color: ${ colors.red };
`

const StyledSubTitle = styled.p`
    color: ${ colors.purple_dark };
`

const StyledTitleWrapper = styled.div`
    padding-top: 3.4rem;
    padding-bottom: 3.4rem;

    > ${ StyledTitle } + ${ StyledSubTitle } {
        margin-top: 0.8rem;
    }

    ${ props => props.align === 'center' && css`
        text-align: center;
    ` }

    ${ props => props.align === 'right' && css`
        text-align: right;
    ` }
`

StyledTitleWrapper.propTypes = {
    align: PropTypes.oneOf( [
        'left',
        'right',
        'center',
    ] ).isRequired,
}

export default Title
