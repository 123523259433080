import React from 'react'
import PropTypes from 'prop-types'
import Notifications from 'fabled/components/Notifications/Notifications'
import Modal from './Modal'

const NotificationsModal = props => (
    <Modal title="My Messages">
        <Notifications userId={ props.userId } />
    </Modal>
)

NotificationsModal.propTypes = {
    userId: PropTypes.number.isRequired,
}

export default NotificationsModal
