// @flow
import styled from 'styled-components'

const FabledAvatar = styled.div`
    width: 100%;
    height: 100%;
    border-radius: 100%;
    background: transparent url(/images/fabled-logo-light.svg) no-repeat center center;
    background-size: 38%;
`

export default FabledAvatar
