import React from 'react'
import styled from 'styled-components'
import FeatureItem from './FeatureItem'

const FeaturesGrid = ( { features } ) => {
  return (
    <StyledFeaturesGrid>
      { features.map( feature => (
        <FeatureItem
            key={ feature.id }
            title={ feature.title }
            description={ feature.description }
            image={ feature.image }
            color={ feature.color }
        />
      ) ) }
    </StyledFeaturesGrid>
  )
}

const StyledFeaturesGrid = styled.div`
    @media all and ( max-width: 799px ) {
        > * {
            max-width: 400px;
            margin: 0 auto 5rem;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    @media all and ( min-width: 800px ) {
        display: flex;
        justify-content: space-between;

        > * {
            flex: 0 1 calc( 33.333% - 2rem );
        }
    }
`

export default FeaturesGrid