import { createSelector } from 'reselect'
import { return2ndArgAsArray } from './utils'

export const getCurrentThemeId = state => state.themes.currentThemeId
export const getThemesEnabled = state => state.themes.isEnabled
export const getThemes = state => state.themes.data

export const getThemesAsArray = createSelector(
    getThemes,
    themes => Object.values( themes ),
)

export const getThemeById = createSelector(
    getThemesAsArray,
    ( state, themeId ) => themeId,
    ( themes, themeId ) => themes.find( t => t.id === themeId ),
)

export const getThemesById = createSelector(
    getThemesAsArray,
    return2ndArgAsArray,
    ( themes, themeIds ) => themes.filter( t => themeIds.includes( t.id ) ),
)

export const getCurrentTheme = state => getThemeById( state, getCurrentThemeId( state ) )
