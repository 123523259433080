import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { darken } from 'polished'
import { colors } from '../../styles'
import Button from '../Button'

const DashboardSection = styled.div`
    margin-bottom: 2em;

    ${ p => p.well && css`
        padding: 1.6em;
        background: white;
    ` }
`

const DashboardSectionTitle = styled.h3`
    padding-bottom: 0.4em;
    margin: 2em 0 1em;
    font-weight: bold;
`

const DashboardSectionTitleButton = props => (
    <Button
        tiny
        inline
        backgroundColor="yellow"
        onClick={ props.onClick }
    >
        { props.children }
    </Button>
)

DashboardSectionTitleButton.propTypes = {
    onClick: PropTypes.func,
    children: PropTypes.node.isRequired,
}

DashboardSectionTitleButton.defaultProps = {
    onClick: null,
}

const DashboardSectionContent = styled.div`
    margin-bottom: 1em;
`

const DashboardSectionField = styled.p`
    padding: 1em;
    background: ${ darken( 0.1, colors.paper ) };
`

export default DashboardSection

export {
    DashboardSectionTitle,
    DashboardSectionTitleButton,
    DashboardSectionContent,
    DashboardSectionField,
}
