import React from 'react'
import { Router, withRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import store from '../store'
import history from '../history'
import HotAppContainer from './HotAppContainer'

const HotAppContainerWithRouter = withRouter( HotAppContainer )

const AppWithRouterAndProvider = () => (
    <Router history={ history }>
        <Provider store={ store }>
            <HotAppContainerWithRouter />
        </Provider>
    </Router>
)

export default AppWithRouterAndProvider
