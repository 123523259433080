// @flow
import { createGlobalStyle, css } from 'styled-components'
import reset from 'styled-reset'
import body from './body'
import fonts from './fonts'
import { colorsCss } from './colors'

const GlobalStyles = createGlobalStyle`
    ${ reset }
    ${ fonts }
    ${ colorsCss }
    ${ body }
`

export default GlobalStyles
