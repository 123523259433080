// @flow
import React from 'react'
import type { ChildUser, AuthUser } from 'fabled/types/User'
import { connect } from 'react-redux'
import actionCreators from 'fabled/actions/actionCreators'
import { getStoryById } from 'fabled/selectors/stories'
import { getAuthUser, getUsersById } from 'fabled/selectors/users'
import Api from 'fabled/api'
import FormattedContent from 'fabled/components/FormattedContent'
import UserRow from 'fabled/components/UserRow'
import Button from 'fabled/components/Button'
import Modal from './Modal'

type Props = {
    storyId: number,

    // from connect
    storyAuthors: Array<ChildUser>,
    authUser: AuthUser,
    modalOpenDialog: ( {
        title: string,
        body: Node | string,
        confirmText?: string,
    } ) => void,
}

const PassStoryModal = ( props: Props ) => {
    const storyAuthorsThatArentMe = props.storyAuthors.filter( u => u.id !== props.authUser.id )

    const passStoryTo = author => () => {
        Api.service( 'storiesForCollaborator' ).patch(
            props.storyId,
            { activeUserId: author.id },
        )
            .then( () => {
                props.modalOpenDialog( {
                    title: `Passed on to ${ author.username }`,
                    body: `Look out for new updates to your story from ${ author.username }!`,
                    confirmText: 'Ok',
                } )
            } )
    }

    return (
        <Modal title="Pass this story on to…">
            <FormattedContent>
                <p>When you pass the story over, the other person can add to it and then pass it back to you.</p>
            </FormattedContent>
            {
                storyAuthorsThatArentMe.map( ( author ) => {
                    const passStory = passStoryTo( author )
                    const multipleOptions = storyAuthorsThatArentMe.length > 1

                    return (
                        <UserRow
                            layout={ multipleOptions ? 'row' : 'card' }
                            key={ author.id }
                            user={ author }
                        >
                            <Button
                                small
                                inline
                                primary
                                onClick={ passStory }
                                onKeyDown={ passStory }
                            >
                                Pass to { author.username }
                            </Button>
                        </UserRow>
                    )
                } )
            }
        </Modal>
    )
}

const mapStateToProps = ( state, props: { storyId: number } ) => {
    const story = getStoryById( state, props.storyId )

    return {
        authUser: getAuthUser( state ),
        storyAuthors: getUsersById( state, story.userIds ),
    }
}

const mapDispatchToProps = {
    modalOpenDialog: actionCreators.ui.modal.openDialog,
}

export default connect( mapStateToProps, mapDispatchToProps )( PassStoryModal )
