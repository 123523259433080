import React from 'react'
import styled, { css } from 'styled-components'
import VerticalPadding from 'fabled/styles/VerticalPadding'
import SimpleGrid from './SimpleGrid'

const Section = ( {
    fullWidth = false,
    top = 'medium',
    bottom = 'medium',
    responsivePadding = true,
    headerPush = false,
    columnsContent = null,
    children,
} ) => {
    let content = null

    if ( columnsContent && columnsContent.length ) {
        content = (
            <SimpleGrid
                minColumnWidth="250"
                gridGap="40"
                maxColumns={ columnsContent.length }
            >
                { columnsContent.map( ( columnContent, i ) => (
                    <div key={ i }>{ columnContent }</div>
                ) ) }
            </SimpleGrid>
        )
    }
    else {
        content = children
    }

    return (
        <StyledSection
            fullWidth={ fullWidth }
            top={ top }
            bottom={ bottom }
            isResponsive={ responsivePadding }
            headerPush={ headerPush }
        >
            { content }
        </StyledSection>
    )
}

const StyledSection = styled( VerticalPadding )`
    position: relative;
    box-sizing: content-box;
    margin-left: auto;
    margin-right: auto;

    ${ p => !p.fullWidth && css`
        max-width: 1200px;
        padding-left: 5%;
        padding-right: 5%;
    ` }

    img {
      max-width: 100%;
    }
`

export default Section