import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import actionCreators from 'fabled/actions/actionCreators'
import Api from 'fabled/api'
import ConfirmModal from './ConfirmModal'

const ConfirmDeletePost = ( props ) => {
    const modalContent = <p>Are you sure you want to delete this post? This can’t be undone.</p>

    const deletePost = ( e ) => {
        e.preventDefault()

        if ( props.postId ) {
            Api.service( 'myPosts' ).remove( props.postId )
        }

        if ( props.postUuid ) {
            props.postsDataRemove( props.postUuid )
        }

        props.modalClose()
    }

    return (
        <ConfirmModal
            title="Are you sure?"
            content={ modalContent }
            confirmText="Yes, delete"
            cancelText="No, don't!"
            onCancel={ props.modalClose }
            onConfirm={ deletePost }
        />
    )
}

ConfirmDeletePost.propTypes = {
    postId: PropTypes.number,
    postUuid: PropTypes.string,
    modalClose: PropTypes.func.isRequired,

    // from connect
    postsDataRemove: PropTypes.func.isRequired,
}

ConfirmDeletePost.defaultProps = {
    postId: null,
    postUuid: null,
}

const mapDispatchToProps = {
    postsDataRemove: actionCreators.posts.data.remove,
}

export default connect( null, mapDispatchToProps )( ConfirmDeletePost )
