import isArray from 'lodash/isArray'
import cloneDeep from 'lodash/cloneDeep'
import RandomIterator from './RandomIterator'

class InspirationFactory {
    constructor( inspirationMap ) {
        const clonedInspirationMap = cloneDeep( inspirationMap )
        const inspirationMapWithTextIterators = clonedInspirationMap.map( ( item ) => {
            if ( isArray( item.text ) ) {
                /* eslint-disable-next-line no-param-reassign */
                item.text = new RandomIterator( item.text )
            }

            return item
        } )
        this.inspiration = new RandomIterator( inspirationMapWithTextIterators )
    }

    next() {
        const inspirationItem = this.inspiration.next()
        const inspirationContent = {}

        Object.entries( inspirationItem ).forEach( ( [k, v] ) => {
            if ( typeof v === 'string' ) {
                inspirationContent[ k ] = v
            }
            else if ( v instanceof RandomIterator ) {
                inspirationContent[ k ] = v.next()
            }
        } )

        return inspirationContent
    }
}

export default InspirationFactory
