const userAvatar = ( user ) => {
    const avatar = (
        ( u ) => {
            if ( u && u.avatar ) {
                return u.avatar
            }

            if ( u && u.gender ) {
                switch ( u.gender ) {
                    case 'female':
                        return 'girl'
                    case 'male':
                        return 'boyhat'
                    default:
                        return 'greenface'
                }
            }

            return 'smiler'
        }
    )( user )

    const url = `/images/avatars/avatar-${ avatar }.svg`
    const altText = user && user.username ? user.username : 'username'

    return { url, altText }
}

export default userAvatar
