import React, { Fragment as F } from 'react'
import AppHeader from 'fabled/components/AppHeader/AppHeader'
import AdminDashboard from 'fabled/components/AdminDashboard'
import ScrollToTopOnMount from 'fabled/components/ScrollToTopOnMount'

const AdminDashboardRoute = () => (
    <F>
        <ScrollToTopOnMount />
        <AppHeader />
        <AdminDashboard />
    </F>
)

export default AdminDashboardRoute
