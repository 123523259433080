import React from 'react'
import PropTypes from 'prop-types'
import StoriesLoader from '../loaders/StoriesLoader'
import StoriesGrid from './StoriesGrid'
import Loader from '../Loader'

const CollectionStories = ( { collectionId } ) => (
    <StoriesLoader
        themeId={ collectionId }
        service="storiesInTheme"
        waitForAuth
        render={
            ( { stories } ) => (
                stories
                    ? (
                        <StoriesGrid
                            stories={ stories }
                            href={ story => `/collection/${ story.themeId }/story/${ story.id }` }
                        />
                    )
                    : <Loader isLoading />
            )
        }
    />
)

CollectionStories.propTypes = {
    collectionId: PropTypes.number.isRequired,
}

export default CollectionStories
