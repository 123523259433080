import React from 'react'
import styled from 'styled-components'
import Section from 'fabled/components/Section'

const FeaturesHero = ( { textItems } ) => (
    <StyledFeaturesHero>
        <Section>
            <PhoneImage />
            <StyledTextItems>
                { textItems.map( ( textItem, i ) => (
                    <StyledTextItem key={ i }>
                        <h4>{ textItem.title }</h4>
                        <p>{ textItem.body }</p>
                    </StyledTextItem>
                ) ) }
            </StyledTextItems>
        </Section>
    </StyledFeaturesHero>
)

const StyledFeaturesHero = styled.div`
    position: relative;
    background: transparent url( /images/marketing-page-bg-small.svg ) no-repeat;
    background-size: 800px;
    background-position: left 50% bottom -34px;

    @media all and ( min-width: 1000px ) {
        background: transparent url( /images/marketing-page-bg.svg ) no-repeat;
        background-position: left 70% bottom -34px;
    }

    @media all and ( min-width: 600px ) and ( max-width: 999px ) {
        > div {
            display: flex;
        }
    }
`

const PhoneImage = () => (
    <StyledPhoneImage>
        <img src="/images/marketing-page-iphone.png" alt="fabledkids.com web application viewed on an iPhone." />
    </StyledPhoneImage>
)

const StyledPhoneImage = styled.div`
    position: relative;
    width: 100%;
    max-width: 360px;

    img {
        width: 100%;
    }

    @media all and ( max-width: 599px ) {
        margin: 0 auto 2rem;
    }

    @media all and ( min-width: 1000px ) {
        margin: 0 auto;

        img {
            z-index: 1;
            position: absolute;
            margin-top: 2rem;
        }
    }
`

const StyledTextItems = styled.div`
    z-index: 1;
    position: relative;

    @media all and ( max-width: 999px ) {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    @media all and ( min-width: 600px ) and ( max-width: 999px ) {
        padding-left: 2rem;
    }

    @media all and ( min-width: 1000px ) {
        padding-bottom: 520px;
    }
`

const StyledTextItem = styled.div`
    max-width: 265px;
    padding: 1em;
    font-size: 18px;
    line-height: 1.3;
    color: var( --almostWhite );

    h4 {
        font-weight: bold;
    }

    &:nth-child( 1 ) {
        background: var( --purple );
        top: 18%;
        left: 5%;
        align-self: flex-start;
    }

    &:nth-child( 2 ) {
        background: var( --yellow );
        top: 0;
        right: 10%;
    }

    &:nth-child( 3 ) {
        background: var( --green );
        top: 40%;
        right: 0;
        align-self: flex-end;
    }

    @media all and ( max-width: 999px ) {
        margin-bottom: 1rem;
    }

    @media all and ( min-width: 1000px ) {
        position: absolute;
    }
`

export default FeaturesHero