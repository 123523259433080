import { combineReducers } from 'redux'

import auth from './auth'
import signup from './signup'
import users from './users'
import stories from './stories'
import posts from './posts'
import currentChapter from './currentChapter'
import ui from './ui'
import storyPrompts from './storyPrompts'
import themes from './themes'
import notifications from './notifications'
import collaborations from './collaborations'

const appReducer = combineReducers( {
    auth,
    signup,
    users,
    stories,
    posts,
    currentChapter,
    ui,
    storyPrompts,
    themes,
    notifications,
    collaborations,
} )

const rootReducer = ( state, action ) => {
    let newState = state

    if ( action.type === 'AUTH/USER_RESET' ) {
        newState = undefined
    }

    return appReducer( newState, action )
}

export default rootReducer
