import React, { Fragment as F } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import actionCreators from 'fabled/actions/actionCreators'
import { getAuthUser } from 'fabled/selectors/users'
import AppHeader from 'fabled/components/AppHeader/AppHeader'
import StoryWrapper from 'fabled/components/StoryWrapper'
import StoryMaker from 'fabled/components/StoryMaker'
import ScrollToTopOnMount from 'fabled/components/ScrollToTopOnMount'

const StoryMakerRoute = class extends React.Component {
    componentDidMount() {
        if ( this.props.authUser ) {
            this.openOnboardingIfNotDismissed( this.props.authUser )
        }
    }

    componentDidUpdate( prevProps ) {
        if ( !prevProps.authUser && this.props.authUser ) {
            this.openOnboardingIfNotDismissed( this.props.authUser )
        }
    }

    openOnboardingIfNotDismissed( authUser ) {
        if ( !authUser.onboardingDismissed ) {
            this.props.modalOpenOnboard( { userId: authUser.id } )
        }
    }

    render() {
        const { props: { match: { params: { storyId } } } } = this

        return (
            <F>
                <ScrollToTopOnMount />
                <AppHeader />
                <StoryWrapper
                    key={ storyId } // recreate the component when storyId changes
                    storyId={ parseInt( storyId, 10 ) }
                    successComponent={ StoryMaker }
                    waitForAuth
                />
            </F>
        )
    }
}

StoryMakerRoute.propTypes = {
    match: PropTypes.shape( {
        params: PropTypes.shape( {
            storyId: PropTypes.string.isRequired,
        } ).isRequired,
    } ).isRequired,

    // from connect
    authUser: PropTypes.shape( {
        id: PropTypes.number.isRequired,
        onboardingDismissed: PropTypes.bool,
    } ),
    modalOpenOnboard: PropTypes.func.isRequired,
}

StoryMakerRoute.defaultProps = {
    authUser: null,
}

const mapStateToProps = state => ( {
    authUser: getAuthUser( state ),
} )

const mapDispatchToProps = {
    modalOpenOnboard: actionCreators.ui.modal.openOnboard,
}

export default connect( mapStateToProps, mapDispatchToProps )( StoryMakerRoute )
