import { css } from 'styled-components'

const sizes = {
    tablet: 850,
    forms: 520,
}

// use em in breakpoints to work properly cross-browser and support users
// changing their browsers font-size: https://zellwk.com/blog/media-query-units/
export const minWidth = size => ( ...args ) => css`
    @media (min-width: ${ size / 16 }em) {
        ${ css( ...args ) }
    }
`

// iterate through the sizes and create a media template for each
const media = Object.keys( sizes ).reduce( ( accumulator, label ) => {
    accumulator[ label ] = minWidth( sizes[ label ] ) /* eslint-disable-line no-param-reassign */
    return accumulator
}, {} )

export default media
