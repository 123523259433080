import { createSelector } from 'reselect'
import { return2ndArgAsArray } from './utils'

export const getStories = state => state.stories.data

export const getStoriesAsArray = createSelector(
    getStories,
    stories => Object.values( stories ),
)

export const getStoryById = createSelector(
    getStoriesAsArray,
    ( state, storyId ) => storyId,
    ( stories, storyId ) => stories.find( s => s.id === storyId ),
)

export const getStoriesById = createSelector(
    getStoriesAsArray,
    return2ndArgAsArray,
    ( stories, storyIds ) => stories.filter( s => storyIds.includes( s.id ) ),
)

export const getStoryByPublicId = createSelector(
    getStoriesAsArray,
    ( state, publicStoryId ) => publicStoryId,
    ( stories, publicStoryId ) => stories.find( s => s.publicId === publicStoryId ),
)

export const getStoriesByPublicId = createSelector(
    getStoriesAsArray,
    return2ndArgAsArray,
    ( stories, publicStoryIds ) => stories.filter( s => publicStoryIds.includes( s.publicId ) ),
)

export const getStoriesByUserId = createSelector(
    getStoriesAsArray,
    return2ndArgAsArray,
    ( stories, userIds ) => stories.filter( s => userIds.includes( s.userId ) ),
)

export const getStoriesByUserIds = createSelector(
    getStoriesAsArray,
    return2ndArgAsArray,
    ( stories, userIds ) => stories.filter( s => userIds.some( uid => s.userIds.includes( uid ) ) ),
)

export const getStoriesByThemeId = createSelector(
    getStoriesAsArray,
    return2ndArgAsArray,
    ( stories, themeIds ) => stories.filter( s => themeIds.includes( s.themeId ) ),
)

/**
 * Returns stories that are assigned to a theme and approved for that
 * theme by parent and admin
 */
export const getStoriesByThemeIdApproved = createSelector(
    getStoriesAsArray,
    return2ndArgAsArray,
    ( stories, themeIds ) => stories.filter( s => (
        themeIds.includes( s.themeId )
        && s.themeApprovedByParent === s.themeId
        && s.themeApprovedByAdmin === s.themeId
    ) ),
)

/**
 * return stories that are assigned to a theme and NOT approved for that
 * theme by admin
 */
export const getStoriesByThemeIdNotApprovedByAdmin = createSelector(
    getStoriesAsArray,
    return2ndArgAsArray,
    ( stories, themeIds ) => stories.filter( s => (
        themeIds.includes( s.themeId )
        && s.themeApprovedByAdmin !== s.themeId
    ) ),
)

/**
 * return stories that are assigned to a theme and NOT approved for that
 * theme by parent
 */
export const getStoriesByThemeIdNotApprovedByParent = createSelector(
    getStoriesAsArray,
    return2ndArgAsArray,
    ( stories, themeIds ) => stories.filter( s => (
        themeIds.includes( s.themeId )
        && s.themeApprovedByParent !== s.themeId
    ) ),
)
