import { handleActions } from 'redux-actions'
import { enableDisable } from './helpers'
import actionCreators from '../actions/actionCreators'

const storyPrompts = handleActions(
    Object.assign(
        enableDisable( actionCreators, 'storyPrompts' ),
        {
            [ actionCreators.storyPrompts.setCurrent ]( state, action ) {
                const newState = Object.assign( {}, state )
                newState.current = action.payload
                return newState
            },
        },
    ),
    {
        isEnabled: false,
        current: null,
    },
)

export default storyPrompts
