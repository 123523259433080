import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import config from '../../../../config'
import AudioPostWrapper from './AudioPostWrapper'
import PlayPauseButton from './PlayPauseButton'
import PostDeleteButton from '../PostDeleteButton'
import { secondsToMinutesAndSeconds } from '../../../../helpers/time'

class AudioPost extends React.Component {
    constructor( props ) {
        super( props )
        this.state = {
            isPlaying: false,
            duration: 0,
            currentTime: 0,
        }
        this.toggleAudio = this.toggleAudio.bind( this )
        this.onPlay = this.onPlay.bind( this )
        this.onPause = this.onPause.bind( this )
        this.onEnded = this.onEnded.bind( this )
        this.onCanPlayThrough = this.onCanPlayThrough.bind( this )
        this.onTimeUpdate = this.onTimeUpdate.bind( this )
    }

    componentDidMount() {
        const { player } = this

        player.addEventListener( 'play', this.onPlay )
        player.addEventListener( 'pause', this.onPause )
        player.addEventListener( 'ended', this.onEnded )
        player.addEventListener( 'canplaythrough', this.onCanPlayThrough )
        player.addEventListener( 'timeupdate', this.onTimeUpdate )
    }

    componentWillUnmount() {
        const { player } = this

        player.removeEventListener( 'play', this.onPlay )
        player.removeEventListener( 'pause', this.onPause )
        player.removeEventListener( 'ended', this.onEnded )
        player.removeEventListener( 'canplaythrough', this.onCanPlayThrough )
        player.removeEventListener( 'timeupdate', this.onTimeUpdate )
    }

    onPlay() {
        this.setState( { isPlaying: true } )
    }

    onPause() {
        this.setState( { isPlaying: false } )
    }

    onEnded() {
        this.setState( { isPlaying: false, currentTime: 0 } )
    }

    onCanPlayThrough( e ) {
        const { target: player } = e
        this.setState( { duration: player.duration < Infinity ? player.duration : 0 } )
    }

    onTimeUpdate( e ) {
        const { target: player } = e
        this.setState( { currentTime: player.currentTime } )
    }

    toggleAudio() {
        // console.log('toggleAudio', this.state)
        // if (this.state.isReady) {
        if ( !this.state.isPlaying ) {
            this.player.play()
        }
        else {
            this.player.pause()
        }
        // }
    }

    render() {
        const { props: { post } } = this
        const { props: { isEditable } } = this
        const audioUrl = post.content.stream || ( `${ config.cdnUrl }/${ post.content.audioId }` )
        const seekerPercentage = 100 * ( this.state.currentTime / this.state.duration )
        const secondsRemaining = Math.ceil( this.state.duration - this.state.currentTime )
        const timer = secondsToMinutesAndSeconds( Math.ceil( secondsRemaining ) )

        const deleteButton = isEditable
            ? <PostDeleteButton postId={ post.id } postUuid={ post.uuid } />
            : null

        return (
            <AudioPostWrapper deleteButton={ deleteButton }>
                <StyledAudioPlayer>
                    <audio /* eslint-disable-line jsx-a11y/media-has-caption */
                        ref={ ( node ) => {
                            this.player = node
                        } }
                        id="player"
                        src={ audioUrl }
                    />
                    <PlayPauseButton
                        isPlaying={ this.state.isPlaying }
                        onClick={ this.toggleAudio }
                    >
                        Play/Pause
                    </PlayPauseButton>
                    <StyledAudioPlayerTimeline>
                        <StyledAudioPlayerSeeker
                            isPlaying={ this.state.isPlaying }
                            seekerPercentage={ seekerPercentage }
                        />
                    </StyledAudioPlayerTimeline>
                    <StyledAudioPlayerTimer>{ timer }</StyledAudioPlayerTimer>
                </StyledAudioPlayer>

            </AudioPostWrapper>
        )
    }
}

AudioPost.propTypes = {
    post: PropTypes.shape( {
        id: PropTypes.number,
        uuid: PropTypes.string,
        content: PropTypes.shape( {
            stream: PropTypes.string,
            audioId: PropTypes.string,
        } ),
    } ),
    isEditable: PropTypes.bool,
}

AudioPost.defaultProps = {
    post: null,
    isEditable: false,
}

const StyledAudioPlayer = styled.div`
    position: relative;

    &:before {
        content: ' ';
        position: absolute;
        top: 50%;
        margin-top: -25px;
        left: 20px;
        width: 50px;
        height: 50px;
        border-radius: 100%;
        background: rgb(222, 222, 222);
        mix-blend-mode: multiply;
    }

    &:after {
        content: ' ';
        position: absolute;
        top: 50%;
        margin-top: -25px;
        left: 20px;
        width: 50px;
        height: 50px;
        background: transparent url(/images/audio-icon-white.svg) no-repeat center center;
        background-size: auto 60%;
    }
`

const StyledAudioPlayerTimeline = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 130px;
    right: 35px;

    &:before {
        content: ' ';
        position: absolute;
        left: 0;
        right: 0;
        top: 50%;
        margin-top: -3px;
        height: 6px;
        background: rgb(203, 203, 203);
        mix-blend-mode: multiply;
    }
`

const StyledAudioPlayerSeeker = styled.div`
    position: absolute;
    top: 50%;
    margin-top: -10px;
    left: ${ props => props.seekerPercentage }%;
    width: 4px;
    height: 20px;
    background: rgb(249, 175, 78);

    ${ props => props.isPlaying && css`
        transition: left 1s linear;
    ` }
`

const StyledAudioPlayerTimer = styled.div`
    position: absolute;
    top: 50%;
    margin-top: 15px;
    left: 130px;
    color: white;
`

export default AudioPost
